import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithDialogContext, withDialogContext } from "@components/dialog/withDialogContext";
import { csvToArray, detectEncoding } from "@components/fileUploader/File.utils";
import { ArrowIcon, CleanFilterIcon } from "@components/icon";
import { Select } from "@components/inputs/select";
import { SelectedHighLightType } from "@components/simpleTable";
import { IActionRendererArgs, IColumn, IRow, TId } from "@components/table";
import { NoData } from "@components/table/NoData";
import { ODataError } from "@odata/Data.types";
import { OData } from "@odata/OData";
import { parseResponse } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { isDefined, isNotDefined } from "@utils/general";
import { removeWhiteSpace } from "@utils/string";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { withTheme } from "styled-components/macro";

import { Button, ButtonGroup, IconButton } from "../../components/button";
import ImportSettings from "../../components/importSettings/ImportSettings";
import ImportSettingsDropItem, { IImportSettingsItemProps } from "../../components/importSettings/ImportSettingsDropItem";
import Checkbox from "../../components/inputs/checkbox";
import Field from "../../components/inputs/field";
import { ScrollBar } from "../../components/scrollBar";
import SimpleTableWithPaginator from "../../components/simpleTable/SimpleTableWithPaginator";
import TableWithAutoSizedColumns from "../../components/table/TableWithAutoSizedColumns";
import Tabs from "../../components/tabs";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { ActionState, BasicInputSizes, FieldType, IconSize, RowAction, Status, ToggleState } from "../../enums";
import { PropsWithTheme } from "../../theme";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import View from "../../views/View";
import ChangeValueDialog from "./ChangeValueDialog";
import { HeaderStyled, StyledSubtitle, TitleStyled } from "./CsvImport.styles";
import {
    csvRowsParse,
    encodings,
    findLongestRow,
    getAlphabetKey,
    getBankAccountCSVImportSettings,
    getDateFormats,
    getImportTableEditableErrorValue,
    IBankAccountCSVImportSettings,
    IFieldToUpdate,
    localizationItems,
    saveBankAccountSettings,
    transactionPropsDef,
    transformItems
} from "./CsvImport.utils";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";


export interface IUploadArgs {
    transformedRows: IRow[];
    bankAccountId: number;
    numberRangeId: number;
    fileId?: number;
    dateFormat: string;
    oData?: OData;
}

interface IProps extends WithDialogContext, WithOData, WithTranslation, PropsWithTheme, WithAlert {
    file: File;
    bankAccountId: number;
    onCancel: () => void;
    onUpload: (args: IUploadArgs) => Promise<Response | void>;
}

interface IState {
    page: number;
    columns: IColumn[];
    rows: IRow[];
    transformedRows: IRow[];
    selectedPreview: number;
    fieldToUpdate: IFieldToUpdate;
    selectedTab: string;
    columnsMap: Record<string, number>;
    dataStartPosition: number;
    encoding: string;
    selectedDateFormat: string;
    selectedNumberLocalization: string;
    currentPage: number; // pagination
}

export enum CsvImportTab {
    All = "All",
    Errors = "Errors"
}

const ScrollbarStyles: React.CSSProperties = {
    margin: "0 -38px",
    height: "fit-content",
    maxHeight: "calc(100%)",
    width: "calc(100% + 38px)"
};

const ImportSettingsStyle: React.CSSProperties = {
    marginTop: "24px"
};

const DefaultDataStartPosition = 1;
const DefaultHeaderPosition = 0;
const defaultSelectedNumberLocalization = "cs-CZ";

class CsvImport extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    _scrollRef = React.createRef<HTMLDivElement>();
    dateFormatItems: ISelectItem<string>[] = [];
    bankAccountSettings: IBankAccountCSVImportSettings;
    accountCurrency: string;
    detectedEncoding: string;

    state: IState = {
        page: 1,
        currentPage: 0, // pagination on first page
        columns: [],
        rows: [],
        transformedRows: [],
        fieldToUpdate: null,
        selectedTab: CsvImportTab.All,
        selectedPreview: null,
        selectedDateFormat: null,
        selectedNumberLocalization: null,
        columnsMap: {},
        dataStartPosition: 1,
        encoding: ""
    };

    constructor(props: IProps, context: IAppContext) {
        super(props, context);

        props.dialogContext.setBusy(true);
    }

    componentDidMount = async () => {
        this.dateFormatItems = await getDateFormats(this.props.oData);
        const baInfo = await getBankAccountCSVImportSettings(this.props.bankAccountId, this.props.oData);
        this.accountCurrency = baInfo.currencyCode;
        this.bankAccountSettings = baInfo.settings;
        this.detectedEncoding = await detectEncoding(this.props.file);
        const encoding = this.bankAccountSettings.FileEncoding ?? this.detectedEncoding;

        const columnsMap: Record<string, number> = {};

        for (const col of this.bankAccountSettings.ColumnMap) {
            columnsMap[col.PropertyName] = col.ColumnId;
        }

        const dataArray = await csvToArray(this.props.file, encoding); // for empty file returns data as empty array []
        const csvRows = dataArray.data;
        const savedDateStartPosition = this.bankAccountSettings?.DataStartPosition ?? DefaultDataStartPosition;
        const dataStartPosition = savedDateStartPosition > csvRows.length ? (csvRows.length === 1 ? 0 : DefaultDataStartPosition) : savedDateStartPosition;

        if (csvRows.length) {
            const longestRow = findLongestRow(csvRows);
            const columns = Object.values(longestRow).map((header: string, index: number) => {
                return { id: index.toString(), label: getAlphabetKey(index), hasGreyHeader: true };
            });

            const rows = csvRowsParse(csvRows, this.bankAccountSettings.HeaderPosition ?? DefaultHeaderPosition);

            this.setState({
                columns, rows, columnsMap, dataStartPosition, encoding,
                selectedDateFormat: this.bankAccountSettings?.DateFormat,
                selectedNumberLocalization: this.bankAccountSettings?.AmountFormat ?? defaultSelectedNumberLocalization
            });

            if (isDefined(columnsMap["DateBankTransaction"]) && isDefined(columnsMap["TransactionAmount"])) {

                if (!this.state.selectedDateFormat) {
                    this.selectDateFormat(columnsMap["DateBankTransaction"]);
                }

                const transformedRows = transformItems({
                    rows,
                    settings: this.bankAccountSettings,
                    context: this.context,
                    t: this.props.t,
                    handleValueChange: this.handleValueChange,
                    localization: this.state.selectedNumberLocalization
                });

                this.setState({ transformedRows, page: 1 });
            }
        }

        this.props.dialogContext.setBusy(false);
    };

    handleValueChange = (fieldToUpdate: IFieldToUpdate) => {
        this.setState({ fieldToUpdate });
    };

    prevPage = () => {
        this.setState({ page: this.state.page - 1 });
    };

    nextPage = async () => {
        if (this.state.page === 2) {
            this.props.dialogContext.setBusy(true);
            const bankAccountSettings: IBankAccountCSVImportSettings = {
                ColumnMap: Object.entries(this.state.columnsMap).map(([key, value]) => ({
                    PropertyName: key,
                    ColumnId: value
                })),
                HeaderPosition: this.state.rows.findIndex(row => row.selected),
                DataStartPosition: this.state.dataStartPosition,
                FileEncoding: this.state.encoding,
                DateFormat: this.state.selectedDateFormat,
                AmountFormat: this.state.selectedNumberLocalization
            };

            await saveBankAccountSettings(this.props.bankAccountId, bankAccountSettings, this.props.oData);

            const transformedRows = transformItems({
                rows: this.state.rows,
                settings: bankAccountSettings,
                context: this.context,
                t: this.props.t,
                handleValueChange: this.handleValueChange,
                localization: this.state.selectedNumberLocalization,
                storeOriginalValue: true
            });

            this.props.dialogContext.setBusy(false);
            this.setState({ transformedRows });
        }

        this.setState({ page: this.state.page + 1 });
    };

    getTabDefinition = () => {
        return [{
            id: CsvImportTab.All,
            title: this.props.t("CsvImport:Tabs.All", { count: this.state.transformedRows.length })
        }, {
            id: CsvImportTab.Errors,
            title: this.props.t("CsvImport:Tabs.Errors", { count: this.state.transformedRows.filter(row => row.customData.hasOriginalError).length })
        }];
    };

    handleRowSelect = (id: TId, linePosition: number) => {
        const rows = [...this.state.rows];
        if (linePosition <= rows.findIndex(row => row.id === id) + 1) {
            return;
        }
        for (const row of rows) {
            row.selected = !row.selected && row.id === id;
        }
        this.setState({ rows });
    };

    selectDateFormat = (columnIndex: number): void => {
        const formats = this.dateFormatItems;
        const date = removeWhiteSpace(this.state.rows[this.state.dataStartPosition]?.values[columnIndex] as string) ?? "";
        const selectedDateFormat = formats.find(format => dayjs(date.substring(0, format.id?.length), format.id, true).isValid())?.id ?? formats[0].id;
        this.setState({ selectedDateFormat });
    };

    handleChange = (mappedColumnId: string, availableColumnId: string) => {
        const columnsMap = this.state.columnsMap;
        const val = parseInt(availableColumnId);

        columnsMap[mappedColumnId] = val;

        if (mappedColumnId === "DateBankTransaction") {
            this.selectDateFormat(val);
        }

        this.setState({ columnsMap });
        this.forceUpdate();
    };

    handleRemove = (key: string) => {
        const columnsMap = this.state.columnsMap;
        delete columnsMap[key];
        this.setState({ columnsMap });
        this.forceUpdate();
    };

    handleSplitLineMove = (e: MouseEvent): void => {
        const mousePos = e.clientY;
        const scrollElementBoundingRect = this._scrollRef.current.getBoundingClientRect();

        if (mousePos > scrollElementBoundingRect.bottom && mousePos) {
            this._scrollRef.current.scrollTop += mousePos - scrollElementBoundingRect.bottom + 50;
        } else if (mousePos < scrollElementBoundingRect.top) {
            this._scrollRef.current.scrollTop -= scrollElementBoundingRect.top - mousePos + 50;
        }
    };

    handleSplitLineMoveEnd = (lineIndex: number) => {
        this.setState({ dataStartPosition: lineIndex });
    };


    reloadRows = async () => {
        const dataArray = await csvToArray(this.props.file, this.state.encoding);
        const csvRows = dataArray.data;
        if (csvRows) {
            const currentHeader = this.state.rows.findIndex(row => row.selected);
            const rows = csvRowsParse(csvRows, currentHeader !== -1 ? currentHeader : this.bankAccountSettings.HeaderPosition ?? 0);
            this.setState({ rows });
        }
    };

    get encodingItems() {
        return encodings.map(e => ({ id: e, label: e }));
    }

    handleEncodingChange = (args: ISelectionChangeArgs) => {
        this.setState({ encoding: args.value as string }, () => this.reloadRows());
    };

    handlePageChange = (currentPage: number) => {
        this.setState({ currentPage });
    };

    getDropItemSelectProps = (type: FieldType): Pick<IImportSettingsItemProps, "selectItems" | "selectValue" | "onSelectChange"> => {
        if (type === FieldType.Date) {
            return {
                selectItems: this.dateFormatItems,
                selectValue: this.state.selectedDateFormat,
                onSelectChange: (args: ISelectionChangeArgs) => {
                    this.setState({ selectedDateFormat: args.value as string });
                }
            };
        } else if (type === FieldType.NumberInput) {
            return {
                selectItems: localizationItems,
                selectValue: this.state.selectedNumberLocalization,
                onSelectChange: (args: ISelectionChangeArgs) => {
                    this.setState({ selectedNumberLocalization: args.value as string });
                }
            };
        } else {
            return {};
        }
    };

    getMappedColumns = (args: Pick<IImportSettingsItemProps, "onDragEnter" | "onDragLeave" | "onDrop" | "onRemove">) => {
        const selectedRow = this.state.rows.find((row) => row.selected);

        return Object.entries(transactionPropsDef(this.context)).map(([key, def]) => {
            const assignedValue = selectedRow ? Object.values(selectedRow.values)[this.state.columnsMap[key]] : "";
            const value = assignedValue === "" ? getAlphabetKey(this.state.columnsMap[key]) : assignedValue;

            return <ImportSettingsDropItem
                    id={key}
                    key={key}
                    title={def.title}
                    value={value?.toString()}
                    isRequired={def.required}
                    {...args}
                {...this.getDropItemSelectProps(def.type)}/>;
        });
    };

    getAvailableColumns = () => {
        const selectedRow = this.state.rows.find((row) => row.selected);
        const csvColumns = selectedRow ? Object.values(selectedRow.values) : this.state.columns.map(col => col.label);

        return csvColumns.map((value, index) => {
            return { id: index.toString(), value: value ? value.toString() : getAlphabetKey(index) };
        });
    };

    getPreviewValues = (): string[] => {
        if (isNotDefined(this.state.selectedPreview)) {
            return [];
        }

        return this.state.rows.slice(this.state.dataStartPosition, this.state.dataStartPosition + 20).map((row) => {
            const val = row.values[this.state.selectedPreview];

            return val.toString();
        });
    };

    handlePreviewValuesNeeded = (availableColumnId: string) => {
        this.setState({
            selectedPreview: parseInt(availableColumnId)
        });
    };

    renderImportSettings = (): React.ReactElement => {
        return (
            <ImportSettings
                mappedColumns={this.getMappedColumns}
                availableColumns={this.getAvailableColumns()}
                previewValues={this.getPreviewValues()}
                onChange={this.handleChange}
                onRemove={this.handleRemove}
                onPreviewValuesNeeded={this.handlePreviewValuesNeeded}
                firstColumnHeader={this.props.t("CsvImport:EvalaFields")}
                secondColumnHeader={this.props.t("CsvImport:AssignedCsvColumns")}
                thirdColumnHeader={this.props.t("CsvImport:CsvColumns")}
                fourthColumnHeader={this.props.t("CsvImport:CsvColumnValues")}
                style={ImportSettingsStyle}
            />
        );
    };

    onToggleChange = (allChecked: boolean) => {
        this.setState({
            transformedRows: this.state.transformedRows.map(row => ({
                ...row,
                customData: {
                    ...row.customData,
                    isChecked: !allChecked
                }
            }))
        });
    };

    handleClearSavedSettings = (): void => {
        this.setState({
            columnsMap: {},
            dataStartPosition: DefaultDataStartPosition,
            rows: this.state.rows.map((row, index) => ({
                ...row,
                selected: index === DefaultHeaderPosition
            })),
            encoding: this.detectedEncoding,
            selectedDateFormat: null,
            selectedNumberLocalization: null
        });
    };

    renderPage = (): React.ReactElement => {
        switch (this.state.page) {
            case 1:
                return (
                    <>
                        <HeaderStyled>
                            <div>
                                <TitleStyled>{this.props.t("CsvImport:PageOneTitle")}</TitleStyled>
                                <StyledSubtitle>{this.props.t("CsvImport:PageOneSubtitle")}</StyledSubtitle>
                            </div>
                            <ButtonGroup>
                                <Field label={this.props.t("CsvImport:Encoding")} name="encoding">
                                    <Select width={BasicInputSizes.M}
                                            value={this.state.encoding}
                                            name="encoding"
                                            items={this.encodingItems}
                                            onChange={this.handleEncodingChange}
                                    />
                                </Field>
                                <IconButton title={this.props.t("CsvImport:ClearSavedSettings")}
                                            hotspotId={"clearSavedSettings"}
                                            isTransparent
                                            onClick={this.handleClearSavedSettings}
                                            style={{
                                                marginLeft: "-30px",
                                                top: "8px"
                                            }}>
                                    <CleanFilterIcon/>
                                </IconButton>
                            </ButtonGroup>
                        </HeaderStyled>
                        {!this.state.rows.length && <NoData noDataText={this.props.t("CsvImport:EmptyFile")}/>}
                        {!!this.state.rows.length && <ScrollBar style={ScrollbarStyles} isInFlex
                                   scrollableNodeProps={{
                                       ref: this._scrollRef
                                   }}>
                            <SimpleTableWithPaginator
                                pageSize={300}
                                currentPage={this.state.currentPage}
                                onPageChange={this.handlePageChange}
                                rows={this.state.rows}
                                onRowSelect={this.handleRowSelect}
                                columns={this.state.columns}
                                showOrder={true}
                                selectedHighLightType={SelectedHighLightType.Bold}
                                onSplitLineMove={this.handleSplitLineMove}
                                onSplitLineMoveEnd={this.handleSplitLineMoveEnd}
                                splitLinePosition={this.state.dataStartPosition}
                            />
                        </ScrollBar>}
                    </>
                );
            case 2:
                return (
                    <>
                        <TitleStyled>{this.props.t("CsvImport:PageTwoTitle")}</TitleStyled>
                        <StyledSubtitle>{this.props.t("CsvImport:PageTwoSubtitle")}</StyledSubtitle>
                        {this.renderImportSettings()}
                    </>
                );
            case 3:
                const defs = transactionPropsDef(this.context);
                const columns = Object.entries(defs).map(([key, value]) => ({
                    id: key,
                    label: this.props.t(`CsvImport:Properties.${key}`),
                    disableSort: true,
                    ...(value.columnProps ?? {})
                }));
                let rows = cloneDeep(this.state.transformedRows);

                if (this.state.selectedTab === CsvImportTab.Errors) {
                    rows = rows.filter(row => row.customData.hasOriginalError);
                }

                const dateFormat = this.state.selectedDateFormat ?? this.bankAccountSettings.DateFormat;
                for (const row of rows) {
                    for (const [key, val] of Object.entries(row.values)) {
                        if (!!defs[key].formatter && defs[key].validator(val as string, this.context, dateFormat) === true) {
                            row.values[key] = defs[key].formatter(val as string, {
                                dateFormat,
                                currencyCode: this.accountCurrency
                            });
                        }
                    }
                }

                const allChecked = this.state.transformedRows?.every(row => row.customData.isChecked);

                return (
                    <>
                        <TitleStyled>{this.props.t("CsvImport:PageThreeTitle")}</TitleStyled>
                        <Tabs data={this.getTabDefinition()}
                              style={{ marginTop: "40px" }}
                              selectedTabId={this.state.selectedTab}
                              onChange={(selectedTab) => this.setState({ selectedTab })}
                        />
                        <TableWithAutoSizedColumns
                            columns={columns}
                            style={{ height: "calc(100% - 100px)" }}
                            rowAction={{
                                actionType: RowAction.Custom,
                                getActionState: (id: TId) => {
                                    return rows.find(r => r.id === id).customData.isChecked ? ActionState.Active : ActionState.Inactive;
                                },
                                onClick: (id: TId) => {
                                    this.setState({
                                        transformedRows: this.state.transformedRows.map(row => ({
                                            ...row,
                                            customData: {
                                                ...row.customData,
                                                isChecked: row.id === id ? !row.customData.isChecked : row.customData.isChecked
                                            }
                                        }))
                                    });
                                },
                                toggleState: allChecked ? ToggleState.AllChecked : ToggleState.AllUnchecked,
                                onToggleChange: this.onToggleChange.bind(this, allChecked),
                                render: (args: IActionRendererArgs) => {
                                    return <Checkbox checked={args.actionState === ActionState.Active}
                                                     onChange={args.onClick}/>;
                                }
                            }}
                            rows={rows}
                        />
                    </>
                );
            default:
                return <></>;
        }
    };

    handleCloseDialog = () => {
        this.setState({ fieldToUpdate: null });
    };

    handleConfirmDialog = (value: string) => {
        const fieldToUpdate = this.state.fieldToUpdate;
        const transformedRows = [...this.state.transformedRows];
        const row = transformedRows[fieldToUpdate.row];
        const propsDefs = transactionPropsDef(this.context);
        const dateFormat = this.state.selectedDateFormat ?? this.bankAccountSettings.DateFormat;

        const formattedValue = propsDefs[fieldToUpdate.column]?.formatter?.(value, {
            dateFormat,
            currencyCode: this.accountCurrency
        }) ?? value;

        row.customData.entity[fieldToUpdate.column] = value;
        row.customData.hasError = Object.entries(propsDefs).some(([key, def]) => {
            const val = row.customData.entity[key];
            return !!def.validator && def.validator(val as string, this.context, this.state.selectedDateFormat) !== true;
        });

        row.values[fieldToUpdate.column] = getImportTableEditableErrorValue(formattedValue, () => {
            this.handleValueChange?.({
                value,
                originalValue: row.customData.originalValue,
                row: fieldToUpdate.row,
                column: fieldToUpdate.column,
                label: this.props.t(`CsvImport:Properties.${fieldToUpdate.column}`),
                fieldDef: fieldToUpdate.fieldDef
            });
        }, true); // value confirmed from dialog is always valid
        row.statusHighlight = row.customData?.hasError ? Status.Error : null;

        this.setState({
            transformedRows,
            fieldToUpdate: null
        });
    };

    handleUpload = async () => {
        this.props.dialogContext.setBusy(true);
        const checkedRows = this.state.transformedRows?.filter(row => {
            return row.customData.isChecked;
        });

        const result = await this.props.onUpload({
            transformedRows: checkedRows,
            bankAccountId: this.props.bankAccountId,
            numberRangeId: this.bankAccountSettings.BankStatementNumberRangeDefinitionId,
            dateFormat: this.state.selectedDateFormat
        });
        this.props.dialogContext.setBusy(false);
        if (!result || result.ok) {
            this.props.onCancel?.();
        } else {
            const error: ODataError = await parseResponse(result);
            this.props.setAlert(getAlertFromError(error));
        }
    };

    renderFooter = () => {
        const requiredFieldIsMissing = Object.entries(transactionPropsDef(this.context)).some(([key, def]) => def.required && !isDefined(this.state.columnsMap[key]));
        const isContinueDisabled = (this.state.page === 2 && requiredFieldIsMissing) || (this.state.page === 1 && !this.state.rows?.length);

        const checkedCount = this.state.transformedRows.filter(row => row.customData.isChecked).length;

        return (
            <>
                {this.state.page > 1 &&
                    <Button icon={<ArrowIcon width={IconSize.S}/>} onClick={this.prevPage}
                            isTransparent>
                        {this.props.t("Common:General.Back")}
                    </Button>
                }
                <ButtonGroup>
                    <Button onClick={this.props.onCancel}
                            isTransparent>{this.props.t("Common:General.Cancel")}</Button>
                    {this.state.page < 3 &&
                        <Button
                            isDisabled={isContinueDisabled}
                            onClick={this.nextPage}>
                            {this.props.t("Common:General.Continue")}
                        </Button>}
                    {this.state.page === 3 &&
                        <Button
                            isDisabled={!checkedCount || this.state.transformedRows.some((row) => row.customData.hasError && row.customData.isChecked)}
                            onClick={this.handleUpload}>
                            {this.props.t("CsvImport:Upload", { count: checkedCount })}
                        </Button>}
                </ButtonGroup>
            </>
        );
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }

        return (
            <>
                <View hotspotContextId={"csvImport"}>
                    <SmartHeaderStyled title={this.props.t("CsvImport:UploadCSVFile")}
                                       shouldHideVariant/>
                    {this.renderPage()}
                    {this.props.alert}
                    {this.props.dialogContext.footerGroupRef.current &&
                        ReactDOM.createPortal(this.renderFooter(), this.props.dialogContext.footerGroupRef.current)}
                </View>
                {this.state.fieldToUpdate &&
                    <ChangeValueDialog
                        onClose={this.handleCloseDialog}
                        onConfirm={this.handleConfirmDialog}
                        selectedDateFormat={this.state.selectedDateFormat}
                        selectedNumberLocalization={this.state.selectedNumberLocalization}
                        fieldToUpdate={this.state.fieldToUpdate}/>}
            </>
        );
    }
}

export default withTranslation(["Common", "CsvImport"])(withDialogContext(withAlert({ autoHide: true })(withTheme(withOData(CsvImport)))));