import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import {
    DescriptionSeparator,
    InputDescriptionText,
    InputTabularAllText
} from "@components/inputs/select/Select.styles";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { SwitchType } from "@components/inputs/switch/Switch";
import { ifAll, IFieldDef, IFieldDefFn, IFieldInfoProperties, IGetValueArgs } from "@components/smart/FieldInfo";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormGroupDef, TFormTable } from "@components/smart/smartFormGroup/SmartFormGroup";
import { fetchAndSetItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import { smartStrongTextFormatter } from "@components/smart/smartTable/SmartTable.utils";
import { TCellValue, TId } from "@components/table";
import { getBoundValue } from "@odata/Data.utils";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import {
    CbaCategoryEntity,
    CbaEntryEntity,
    DocumentCbaCategoryEntity,
    DocumentEntity,
    DocumentItemCbaCategoryEntity,
    EntitySetName,
    EntityTypeName,
    ICbaCategoryEntity,
    ICbaEntryEntity,
    IDocumentCbaCategoryEntity,
    IDocumentItemCbaCategoryEntity,
    IPaymentDocumentEntity,
    PaymentDocumentEntity,
    PaymentDocumentItemEntity,
    RegularDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import { CbaCategoryTaxImpactCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { isCashBasisAccountingCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { isDefined, isNotDefined, isObjectEmpty } from "@utils/general";
import i18next from "i18next";
import React from "react";

import Field from "../../components/inputs/field";
import Input from "../../components/inputs/input/Input";
import Text from "../../components/text";
import Tooltip from "../../components/tooltip";
import { DASH_CHARACTER } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, Sort, ValidatorType } from "../../enums";
import { TValue } from "../../global.types";
import BindingContext, { createPath, IEntity } from "../../odata/BindingContext";
import TestIds from "../../testIds";
import { getCurrentLineItem } from "../../views/formView/Form.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import { IBankCustomData, TPairedDocumentCategoriesMap } from "../banks/bankTransactions/BankTransactions.utils";
import { TFieldsDefinition } from "../PageUtils";

export const CASH_BASIS_ACCOUNTING_GROUP_ID = "CashBasisAccounting";

export const CBA_CATEGORY_PATH = BindingContext.localContext("CategorySelection");

const isCashBasisAccounting = (args: IGetValueArgs): boolean => {
    return isCashBasisAccountingCompany(args.context ?? args.storage.context);
};

const hasPartialImpact = (args: IGetValueArgs) => {
    const category = args.storage.getValue(args.bindingContext.getParent()) as ICbaCategoryEntity;
    const code = args.storage.getTemporalData(args.bindingContext.getParent().navigate(CbaCategoryEntity.TaxImpact))?.value ?? category?.TaxImpact?.Code;
    return code === CbaCategoryTaxImpactCode.Partial;
};

const hasNoImpact = (args: IGetValueArgs) => {
    const category = args.storage.getValue(args.bindingContext.getParent()) as ICbaCategoryEntity;
    const code = args.storage.getTemporalData(args.bindingContext.getParent().navigate(CbaCategoryEntity.TaxImpact))?.value ?? category?.TaxImpact?.Code;
    return code === CbaCategoryTaxImpactCode.Nontax;
};

interface ICbaItemCategorySettings {
    isDefault: boolean;
    TaxImpactCode: CbaCategoryTaxImpactCode;
    CategoryId?: number;
    CategoryName?: string;
    IsAssetAcquisition?: boolean;
    TaxPercentage?: number;
}

// function returns CBA Item category settings
export function getCBAItemCategory(storage: FormStorage, itemBc: BindingContext, hasDefaultCategory = true): ICbaItemCategorySettings {
    const itemCategoryBc = itemBc.navigate(PaymentDocumentItemEntity.CbaCategory);
    const itemCategory = storage.getValue(itemCategoryBc, { skipTemporaryValue: true });
    const isDefault = isObjectEmpty(itemCategory) && hasDefaultCategory;

    const settings: ICbaItemCategorySettings = {
        isDefault,
        TaxImpactCode: CbaCategoryTaxImpactCode.Tax
    };

    const { entity } = storage.data;

    if (!isDefault || hasDefaultCategory) {
        const category = (isDefault ? entity.CbaCategory : itemCategory) ?? {};
        settings.TaxImpactCode = category.TaxImpact?.Code ?? category.TaxImpactCode;
        settings.CategoryId = category.Category?.Id;
        settings.CategoryName = category.Category?.Name;
        settings.IsAssetAcquisition = !!category.IsAssetAcquisition;
        settings.TaxPercentage = category.TaxPercentage ?? (settings.TaxImpactCode === CbaCategoryTaxImpactCode.Nontax ? 0 : 100);
    }

    return settings;
}

export const getCashBasisAccountingGroup = (): IFormGroupDef => {
    return {
        id: CASH_BASIS_ACCOUNTING_GROUP_ID,
        isVisible: isCashBasisAccounting,
        customizationData: {
            useForCustomization: isCashBasisAccounting
        },
        rows: [[{ id: createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.Category) }],
            [{ id: createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.TaxImpact) }, {
                id: createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.IsAssetAcquisition)
            }, {
                id: createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.TaxPercentage)
            }]],
        title: i18next.t("Document:FormGroup.CashBasisAccounting")
    };
};

const getFieldId = (id: string, prefix: string) => {
    return `${prefix ? prefix + "/" : ""}${id}`;
};

export const getCbaCategoriesCommonFieldsDef = (prefix = "", isCollection: boolean, isIssued: boolean): Record<string, IFieldInfoProperties> => {
    return {
        [getFieldId(CbaCategoryEntity.IsAssetAcquisition, prefix)]: {
            type: FieldType.Switch,
            clearIfInvisible: false,
            fieldSettings: {
                type: SwitchType.Default
            },
            defaultValue: false,
            isVisible: (args: IGetValueArgs) => !isIssued && hasNoImpact(args),
            customizationData: {
                useForCustomization: ifAll(!isIssued, !isCollection)
            },
            isConfirmable: !!isCollection
        },
        [getFieldId(CbaCategoryEntity.TaxImpact, prefix)]: {
            type: FieldType.SegmentedButton,
            width: BasicInputSizes.XL,
            label: i18next.t(`Categories:${isCollection ? "" : "Default"}TaxImpact`),
            isRequired: false, // is required, but we don't want red dot TODO: maybe there should not be red dot on any segmented button field
            fieldSettings: {
                items: [
                    {
                        id: CbaCategoryTaxImpactCode.Tax,
                        label: i18next.t("Categories:CbaCategoryTaxImpactCode.Tax")
                    },
                    {
                        id: CbaCategoryTaxImpactCode.Nontax,
                        label: i18next.t("Categories:CbaCategoryTaxImpactCode.NonTax")
                    },
                    {
                        id: CbaCategoryTaxImpactCode.Partial,
                        label: i18next.t("Categories:CbaCategoryTaxImpactCode.Partial")
                    }
                ]
            },
            defaultValue: CbaCategoryTaxImpactCode.Tax,
            isVisible: !isIssued,
            customizationData: {
                useForCustomization: ifAll(!isIssued, !isCollection)
            },
            isConfirmable: !!isCollection
        },
        [getFieldId(CbaCategoryEntity.TaxPercentage, prefix)]: {
            type: FieldType.NumberInput,
            fieldSettings: {
                unit: "%",
                showSteppers: true,
                min: 0,
                max: 100
            },
            defaultValue: 100,
            validator: {
                type: ValidatorType.Number,
                settings: {
                    min: 0,
                    max: 100
                }
            },
            isVisible: (args) => !isIssued && hasPartialImpact(args),
            customizationData: {
                useForCustomization: ifAll(!isIssued, !isCollection)
            },
            isConfirmable: !!isCollection
        }
    };
};

const categoriesFilterByTypeFn = (isIssued: boolean) => {
    return () => {
        let filter = `${CbaCategoryEntity.IsActive} eq true`;
        if (isIssued) {
            filter += ` AND ${CbaCategoryEntity.TaxImpactCode} eq '${CbaCategoryTaxImpactCode.Tax}'`;
        }
        return filter;
    };
};

export const getCashBasisAccountingFieldsDef = (prefix: string = DocumentEntity.CbaCategory, isCollection: boolean, isIssued: boolean): TFieldsDefinition => {
    return {
        [`${prefix ? prefix + "/" : ""}Category`]: {
            type: FieldType.ComboBox,
            label: i18next.t(`Categories:${isCollection ? "" : "Default"}Category`),
            filter: {
                select: categoriesFilterByTypeFn(isIssued)
            },
            fieldSettings: {
                displayName: "Name",
                additionalProperties: [{
                    id: CbaCategoryEntity.TaxPercentage
                }, {
                    id: CbaCategoryEntity.TaxImpact
                }, {
                    id: CbaCategoryEntity.IsAssetAcquisition
                }],
                additionalItems: [{
                    id: null,
                    label: i18next.t("Categories:WithoutCategory")
                }]
            },
            formatter: (val: TValue, args: IFormatOptions): string => {
                return args.item?.Name ?? args.storage.getTemporalData(args.bindingContext)?.additionalData?.Name ?? i18next.t("Categories:WithoutCategory").toString();
            },
            defaultValue: null,
            customizationData: {
                useForCustomization: !isCollection
            },
            isConfirmable: !!isCollection
        },
        ...getCbaCategoriesCommonFieldsDef(prefix, isCollection, isIssued)
    };
};

export enum CbaSelectionCode {
    Default = "Default",
    Own = "Own"
}

export async function setCorrectSpecialItemsForItemCategory(storage: FormStorage, collectionName = "Items"): Promise<void> {
    if (!isCashBasisAccountingCompany(storage.context)) {
        return;
    }
    const baseBc = storage.data.bindingContext.navigate(collectionName);
    const info = storage.getInfo(baseBc.navigate(CBA_CATEGORY_PATH));
    const items = await fetchAndSetItemsByInfo(storage, info, false);

    for (const item of (storage.data.entity[collectionName] || [])) {
        const itemBc = baseBc.addKey(item);
        const itemCategory = getCBAItemCategory(storage, itemBc, true);
        let value: TId = CbaSelectionCode.Default;
        if (!itemCategory.isDefault) {
            if (!itemCategory.CategoryId && !itemCategory.IsAssetAcquisition && [CbaCategoryTaxImpactCode.Tax, CbaCategoryTaxImpactCode.Nontax, CbaCategoryTaxImpactCode.VATCorrection].includes(itemCategory.TaxImpactCode)) {
                value = itemCategory.TaxImpactCode;
            } else {
                // find item with same properties
                const matchingItem: ISelectItem = items.find(item => {
                    const data = item.additionalData ?? {};
                    return data?.TaxImpactCode === itemCategory.TaxImpactCode && data.Id === itemCategory.CategoryId &&
                        data.TaxPercentage === itemCategory.TaxPercentage && data.IsAssetAcquisition === itemCategory.IsAssetAcquisition;
                });
                value = matchingItem?.id ?? CbaSelectionCode.Own;
            }
        }
        storage.setValue(itemBc.navigate(CBA_CATEGORY_PATH), value);
    }
}

export function getPartialImpactLabel(impact: string, percentage: number): string {
    return `${impact}: ${percentage} %`;
}

export function getAssetAcquisitionLabel(impact: string): string {
    return `${impact}: ${i18next.t("Categories:AcquisitionOfAssets")}`;
}

export const getCbaCategoryItemDef = (collectionName: string, isBank: boolean, isIssued: boolean): TFieldsDefinition => {
    const def: TFieldsDefinition = {
        [createPath(collectionName, CBA_CATEGORY_PATH)]: {
            type: FieldType.ComboBox,
            label: i18next.t("Categories:Records"),
            isRequired: true,
            useForValidation: false,
            width: isBank ? BasicInputSizes.XL : BasicInputSizes.L,
            isDisabled: args => {
                const categoryBc = args.bindingContext.getParent().navigate(DocumentEntity.CbaCategory);
                return !!(args.storage as FormStorage).getBackendDisabledFieldMetadata(categoryBc);
            },
            filter: {
                select: categoriesFilterByTypeFn(isIssued)
            },
            fieldSettings: {
                displayName: "TaxImpact/Name",
                entitySet: EntitySetName.CbaCategories,
                additionalProperties: [{
                    id: CbaCategoryEntity.TaxPercentage
                }, {
                    id: CbaCategoryEntity.TaxImpact
                }, {
                    id: CbaCategoryEntity.IsAssetAcquisition
                }],
                additionalItems: [{
                    id: CbaCategoryTaxImpactCode.Tax,
                    label: i18next.t("Categories:CbaCategoryTaxImpactCode.Tax")
                },
                    ...(isIssued ? [] : [
                        {
                            id: CbaCategoryTaxImpactCode.Nontax,
                            label: i18next.t("Categories:CbaCategoryTaxImpactCode.NonTax")
                        },
                        {
                            id: CbaSelectionCode.Own,
                            label: i18next.t("Categories:CbaSelectionCode.Own")
                        }
                    ])]
            },
            customizationData: {
                useForCustomization: isCashBasisAccounting
            },
            comparisonFunction: (entity1: IEntity, entity2: IEntity, bc: BindingContext): boolean => {
                const categoryBc = bc.getParent().navigate(RegularDocumentItemEntity.CbaCategory);
                const dataBindingContext = bc.getRootParent();

                const cat1 = getBoundValue({ bindingContext: categoryBc, data: entity1, dataBindingContext });
                const cat2 = getBoundValue({ bindingContext: categoryBc, data: entity2, dataBindingContext });

                if (isObjectEmpty(cat1) && isObjectEmpty(cat2)) {
                    // both categories are not defined -> they are same
                    return true;
                }

                // if one is defined and second not, or they have different impact code => categories are not same
                if (isObjectEmpty(cat1) !== isObjectEmpty(cat2) || cat1?.TaxImpact?.Code !== cat2?.TaxImpact?.Code) {
                    return false;
                }

                // both defined and code is same -> compare additional properties
                return (cat1.IsAssetAcquisition === cat2.IsAssetAcquisition) && (cat1.TaxPercentage === cat2.TaxPercentage);
            },
            formatter: (val: TValue, { storage, bindingContext, item }: IFormatOptions): string => {
                const cbaSettings = bindingContext ? getCBAItemCategory(storage as FormStorage, bindingContext.getParent(), !isBank)
                    // formatter is called also for select items, which can be used as cbaSettings
                    : item;
                const prefix = cbaSettings.isDefault ? `(${i18next.t("Categories:CbaSelectionCode.Default")}) ` : "";
                let impactLabel = storage.t(`Categories:CbaCategoryTaxImpactCode.${cbaSettings.TaxImpactCode}`);
                if (cbaSettings.TaxImpactCode === CbaCategoryTaxImpactCode.Partial) {
                    impactLabel = getPartialImpactLabel(impactLabel, cbaSettings.TaxPercentage);
                } else if (cbaSettings.IsAssetAcquisition) {
                    impactLabel = getAssetAcquisitionLabel(impactLabel);
                }

                const suffix = cbaSettings.CategoryName ? ` | ${cbaSettings.CategoryName}` : "";

                return `${prefix}${impactLabel}${suffix}`;
            },
            columns: [
                {
                    id: "TaxImpact/Name",
                    formatter: (val: TValue, args?: IFormatOptions) => {
                        const strVal = val as string;
                        if (args.item.TaxImpact?.Code === CbaCategoryTaxImpactCode.Partial) {
                            return getPartialImpactLabel(strVal, args.item.TaxPercentage);
                        } else if (args.item.IsAssetAcquisition) {
                            return getAssetAcquisitionLabel(strVal);
                        }
                        return strVal;
                    }
                },
                { id: "Name" }
            ],
            defaultValue: null,
            isReadOnly: (args: IGetValueArgs) => {
                const isInternalDoc = args.storage.data.entity.DocumentTypeCode === DocumentTypeCode.InternalDocument;
                const collectionBc = args.bindingContext.getParent();
                let linkedDoc = null;
                if (isInternalDoc || isBank) {
                    const linkedDocBc = collectionBc.navigate(PaymentDocumentItemEntity.LinkedDocument);
                    linkedDoc = args.storage.getValue(linkedDocBc);
                }
                return !!linkedDoc?.Id;
            },
            render: (args: IFieldDefFn) => {
                const collectionBc = args.props.info.bindingContext.getParent();
                const isInternalDoc = args.storage.data.entity.DocumentTypeCode === DocumentTypeCode.InternalDocument;
                const isReadOnly = args.props.isReadOnly;
                let linkedDoc = null;
                if (isInternalDoc || isBank) {
                    const linkedDocBc = collectionBc.navigate(PaymentDocumentItemEntity.LinkedDocument);
                    linkedDoc = args.storage.getValue(linkedDocBc);
                }

                if (linkedDoc?.Id) {
                    const docCategories = args.storage.getCustomData<IBankCustomData>()?.pairedDocumentCategoriesMap?.[linkedDoc.Id];
                    if (!isObjectEmpty(docCategories?.[0])) {
                        const tokens = docCategories.map(cat => {
                            const code = cat.TaxImpact?.Code ?? cat.TaxImpactCode;
                            let impact = args.storage.t(`${getEnumNameSpaceName(EntityTypeName.CbaCategoryTaxImpact)}:${code}`);
                            const name = cat.Category?.Name;
                            if (code === CbaCategoryTaxImpactCode.Partial) {
                                impact = getPartialImpactLabel(impact, cat.TaxPercentage);
                            } else if (cat.IsAssetAcquisition) {
                                impact = getAssetAcquisitionLabel(impact);
                            }
                            return {
                                name,
                                impact
                            };
                        });
                        const firstToken = tokens.shift();
                        const children = <>
                            <InputTabularAllText
                                data-testid={TestIds.Text}
                                style={{
                                    width: args.props.width,
                                    marginTop: 0,
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                <div style={{
                                    display: "flex"
                                }}>
                                    <Text isWithoutDefaultFormatting>
                                        {firstToken.impact}
                                        {firstToken.name && <InputDescriptionText>
                                            {DescriptionSeparator}{firstToken.name}
                                        </InputDescriptionText>}
                                    </Text>

                                    {!!tokens.length && <Tooltip content={() => (
                                        <>{tokens.map((t, i) => <InputTabularAllText key={i}>
                                            <span>{t.impact}</span>
                                            {t.name && <InputDescriptionText>
                                                {DescriptionSeparator}{t.name}
                                            </InputDescriptionText>}
                                        </InputTabularAllText>)}</>
                                    )}>
                                        {(ref) => {
                                            return (
                                                <div ref={ref}  data-testid={TestIds.TruncatedValue} style={{ display: "inline" }}>
                                                    &nbsp;+&nbsp;{tokens.length}
                                                </div>
                                            );
                                        }}
                                    </Tooltip>}
                                </div>
                            </InputTabularAllText>
                        </>;
                        if (!args.fieldElement) {
                            return children;
                        }
                        return <Field label={i18next.t("Categories:Records")}
                                      width={BasicInputSizes.XL}
                                      name={CBA_CATEGORY_PATH}
                                      labelStatus={args.props.labelStatus}
                                      isReadOnly={isReadOnly}>
                            {children}
                        </Field>;
                    } else if (isBank) {
                        // e.g. proforma invoice doesn't have category
                        return <Field label={i18next.t("Categories:Records")}
                                      useWidthWhenReadOnly
                                      width={args.props.width}
                                      name={CBA_CATEGORY_PATH}
                                      labelStatus={args.props.labelStatus}
                                      isReadOnly={isReadOnly}>
                            <Input
                                width={args.props.width}
                                isReadOnly={isReadOnly}
                                value={DASH_CHARACTER}
                            />
                        </Field>;
                    }
                } else if (!args.fieldElement) {
                    return args.props.formattedValue;
                }
                return args.fieldElement;
            }
        },
        ...getCashBasisAccountingFieldsDef(createPath(collectionName, RegularDocumentItemEntity.CbaCategory), true, isIssued)
    };

    if (!isBank) {
        def[createPath(collectionName, CBA_CATEGORY_PATH)].fieldSettings.additionalItems = [
            {
                id: CbaSelectionCode.Default,
                label: i18next.t("Categories:CbaSelectionCode.Default")
            },
            ...def[createPath(collectionName, CBA_CATEGORY_PATH)].fieldSettings.additionalItems
        ];
    }
    return def;
};

export const getDefaultPercetageFromImpactCode = (code: CbaCategoryTaxImpactCode): number => {
    switch (code) {
        case CbaCategoryTaxImpactCode.Tax:
        case CbaCategoryTaxImpactCode.VATCorrection:
            return 100;
        case CbaCategoryTaxImpactCode.Nontax:
            return 0;
        case CbaCategoryTaxImpactCode.Partial:
            return 60;
    }
};

export function categoryFormatter(value: TValue): TCellValue {
    if (!value) {
        return DASH_CHARACTER;
    }
    return value as string;
}

export const getCbaEntriesTable = (documentType: DocumentTypeCode, context: IAppContext): TFormTable => {
    const isInternalDoc = documentType === DocumentTypeCode.InternalDocument;
    const isBankTransaction = documentType === DocumentTypeCode.BankTransaction;
    const isVatRegistered = isVatRegisteredCompany(context);

    const paymentDocumentDef = {
        id: CbaEntryEntity.PaymentDocument,
        fieldSettings: {
            displayName: PaymentDocumentEntity.NumberOurs,
        },
        additionalProperties: [
            { id: `/${createPath(CbaEntryEntity.PaymentDocument, PaymentDocumentEntity.DocumentType)}` },
            { id: `/${createPath(CbaEntryEntity.ProformaDocument, DocumentEntity.DocumentType)}` },
            { id: `/${createPath(CbaEntryEntity.ProformaDocument, DocumentEntity.NumberOurs)}` }
        ],
        formatter: (val: TValue, args: IFormatOptions) => {
            const entity = args.entity as unknown as ICbaEntryEntity;
            const doc = entity.PaymentDocument?.Id ? entity.PaymentDocument : entity.ProformaDocument;

            return getTableIntentLink(doc.NumberOurs, {
                route: `${getRouteByDocumentType(doc?.DocumentType?.Code as DocumentTypeCode)}/${doc.Id}`,
                context: args.storage.context,
                storage: args.storage
            });
        },
        label: i18next.t("Document:PairedDocuments.Document")
    };
    const pairedDocumentDef = {
        id: CbaEntryEntity.PairedDocument,
        fieldSettings: {
            displayName: "NumberOurs"
        },
        label: i18next.t("Document:PairedDocuments.Document")
    };

    const columns: IFieldDef[] = [
        { id: CbaEntryEntity.Date },
        !isInternalDoc && (isBankTransaction ? pairedDocumentDef : paymentDocumentDef),
        {
            id: CbaEntryEntity.Type,
            fieldSettings: {
                displayName: "Name"
            }
        },
        !isInternalDoc && {
            id: CbaEntryEntity.TransactionAmountCleared,
            formatter: smartStrongTextFormatter
        },
        { id: CbaEntryEntity.TransactionAmountTaxed },
        { id: CbaEntryEntity.TransactionAmountNonTaxed },
        ...(!isVatRegistered ? [] : [{ id: CbaEntryEntity.TransactionAmountVat }]),
        {
            id: CbaEntryEntity.Category,
            fieldSettings: {
                displayName: "Name"
            },
            formatter: categoryFormatter
        },
        { id: CbaEntryEntity.Description }
    ].filter(item => !!item);

    const additionalProps = isBankTransaction ? {
        filter: (args: IGetValueArgs) => {
            const docId = args.storage.data.bindingContext.getKey();
            return `PaymentDocument/Id eq ${docId} OR InternalDocument/Id eq ${docId}`;
        },
    } : { parentKey: "PairedDocument/Id" };

    return {
        id: `cbaLedger`,
        entitySet: EntitySetName.CbaEntries,
        ...additionalProps,
        initialSortBy: [{ id: "Date", sort: Sort.Desc }],
        columns
    };
};

export const loadCategoriesMap = (storage: FormStorage<IEntity, IBankCustomData>): void => {
    const entity = storage.data.entity as IPaymentDocumentEntity;
    const pairedItems = entity.Items?.filter(i => !isObjectEmpty(i.LinkedDocument));
    const pairedDocumentCategoriesMap: TPairedDocumentCategoriesMap = {};

    for (const item of (pairedItems ?? [])) {
        let defaultInserted = false;
        const linkedDocument = item.LinkedDocument;
        const categories: (IDocumentCbaCategoryEntity | IDocumentItemCbaCategoryEntity)[] = [];
        if (linkedDocument.DocumentItems) {
            for (const docItem of linkedDocument.DocumentItems) {
                if (isObjectEmpty(docItem.CbaCategory)) {
                    if (!defaultInserted) {
                        if (!categories.some(c => isSameCbaCategory(linkedDocument.CbaCategory, c))) {
                            categories.push(linkedDocument.CbaCategory);
                        }
                        defaultInserted = true;
                    }
                } else {
                    if (!categories.some(c => isSameCbaCategory(docItem.CbaCategory, c))) {
                        categories.push(docItem.CbaCategory);
                    }
                }
            }
        }
        pairedDocumentCategoriesMap[linkedDocument.Id] = categories;
        storage.setCustomData({ pairedDocumentCategoriesMap });
    }
};

// moved from DocumentFormView
// TODO: temp hotfix - For documents created from ISDOC, some fields might be missing -> calculate/default them if possible
// also in DraftMassAccountingDialogFormView, we need to set the default values even thought the fields are not visible
export const setCbaDefaultValuesForNotVisibleFields = (storage: FormStorage): void => {
    if (isNotDefined(storage.data.entity.CbaCategory.TaxImpact)) {
        const taxImpactBc = storage.data.bindingContext.navigate(`${DocumentEntity.CbaCategory}/${DocumentCbaCategoryEntity.TaxImpact}`);
        storage.setDefaultValue(taxImpactBc);
    }
    if (isNotDefined(storage.data.entity.CbaCategory.TaxPercentage)) {
        const taxPercentageBc = storage.data.bindingContext.navigate(`${DocumentEntity.CbaCategory}/${DocumentCbaCategoryEntity.TaxPercentage}`);
        storage.setDefaultValue(taxPercentageBc);
    }
    if (isNotDefined(storage.data.entity.CbaCategory.IsAssetAcquisition)) {
        const isAssetAcquisitionBc = storage.data.bindingContext.navigate(`${DocumentEntity.CbaCategory}/${DocumentCbaCategoryEntity.IsAssetAcquisition}`);
        storage.setDefaultValue(isAssetAcquisitionBc);
    }
};

export const handleItemCbaCategoryChange = (args: ISmartFieldChange, storage: FormStorage<IEntity, IBankCustomData>): void => {
    if (args.bindingContext.getPath() === CBA_CATEGORY_PATH && args.triggerAdditionalTasks) {
        const itemBc = args.bindingContext.getParent();
        const catBc = itemBc.navigate(PaymentDocumentItemEntity.CbaCategory);
        let category: IDocumentCbaCategoryEntity = {};
        if (args.value === CbaSelectionCode.Default) {
            category = null;
        } else if (args.value === CbaSelectionCode.Own) {
            const dialogCurrentLineItem = getCurrentLineItem(storage, args.bindingContext.getParent());
            storage.setCustomData({
                dialogCurrentLineItem,
                isCbaCategoryDialogOpen: true
            });
            const origCat = storage.getValue(catBc);
            storage.setTemporalData(catBc.navigate(DocumentItemCbaCategoryEntity.TaxPercentage), { value: origCat?.TaxPercentage ?? 100 });
            storage.setTemporalData(catBc.navigate(DocumentItemCbaCategoryEntity.TaxImpact), { value: origCat?.TaxImpact?.Code ?? origCat?.TaxImpactCode ?? CbaCategoryTaxImpactCode.Tax });
            storage.setTemporalData(catBc.navigate(DocumentItemCbaCategoryEntity.IsAssetAcquisition), { value: isDefined(origCat?.IsAssetAcquisition) ? origCat?.IsAssetAcquisition : false });
            storage.refresh();
            return;
        } else if (args.value === CbaCategoryTaxImpactCode.Tax || args.value === CbaCategoryTaxImpactCode.VATCorrection) {
            category = {
                TaxPercentage: 100,
                TaxImpactCode: args.value,
                Category: null
            };
        } else if (args.value === CbaCategoryTaxImpactCode.Nontax) {
            category = {
                TaxPercentage: 0,
                TaxImpactCode: CbaCategoryTaxImpactCode.Nontax,
                Category: null
            };
        } else {
            category = {
                TaxPercentage: args.additionalData?.TaxPercentage,
                TaxImpactCode: args.additionalData?.TaxImpactCode,
                IsAssetAcquisition: args.additionalData?.IsAssetAcquisition,
                Category: args.additionalData
            };
        }
        storage.setValue(catBc, category);
    }
};

export const handleCustomCbaCategoryChange = (e: ISmartFieldChange, storage: FormStorage): void => {
    const itemCategoryBc = e.bindingContext.getParent();
    if (e.bindingContext.getPath() === DocumentItemCbaCategoryEntity.Category) {
        const cbaCategory: Partial<ICbaCategoryEntity> = e.additionalData;
        if (!isObjectEmpty(cbaCategory)) {
            storage.setTemporalData(itemCategoryBc.navigate(DocumentItemCbaCategoryEntity.TaxPercentage), { value: cbaCategory.TaxPercentage });
            storage.setTemporalData(itemCategoryBc.navigate(DocumentItemCbaCategoryEntity.TaxImpact), { value: cbaCategory.TaxImpact?.Code });
            storage.setTemporalData(itemCategoryBc.navigate(DocumentItemCbaCategoryEntity.IsAssetAcquisition), { value: cbaCategory.IsAssetAcquisition });
        }
    }
    if (e.bindingContext.getPath() === DocumentItemCbaCategoryEntity.TaxImpact) {
        if (e.value !== CbaCategoryTaxImpactCode.Nontax) {
            storage.setTemporalData(itemCategoryBc.navigate(DocumentItemCbaCategoryEntity.IsAssetAcquisition), { value: false });
        }

        switch (e.value) {
            case CbaCategoryTaxImpactCode.Partial:
                storage.setTemporalData(itemCategoryBc.navigate(DocumentItemCbaCategoryEntity.TaxPercentage), { value: 60 });
                break;
            case CbaCategoryTaxImpactCode.Tax:
            case CbaCategoryTaxImpactCode.VATCorrection:
                storage.setTemporalData(itemCategoryBc.navigate(DocumentItemCbaCategoryEntity.TaxPercentage), { value: 100 });
                break;
            case CbaCategoryTaxImpactCode.Nontax:
                storage.setTemporalData(itemCategoryBc.navigate(DocumentItemCbaCategoryEntity.TaxPercentage), { value: 0 });
                break;
            default:
                break;
        }
    }

    // storage refresh is needed to update related fields (https://solitea-cz.atlassian.net/browse/DEV-28647)
    storage.refresh();
};

export const isSameCbaCategory = (c1: IDocumentCbaCategoryEntity, c2: IDocumentCbaCategoryEntity): boolean => {
    return c1?.TaxImpact?.Code === c2?.TaxImpact?.Code && c1?.Category?.Name === c2?.Category?.Name && c1?.TaxPercentage === c2?.TaxPercentage && c1?.IsAssetAcquisition === c2?.IsAssetAcquisition;
};