import { AlertAction, IAlertProps, IBaseAlertProps } from "@components/alert/Alert";
import { getInfoValue, IGetValueArgs, TGetValueFn, TInfoValue, TInfoValueProp } from "@components/smart/FieldInfo";
import { IFormGroupDef, IFormLineItemsDef, TFormTable } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { isODataError, ODataError } from "@odata/Data.types";
import { getBoundValue } from "@odata/Data.utils";
import { getOldestActiveFY } from "@pages/fiscalYear/FiscalYear.utils";
import { IDefinition } from "@pages/PageUtils";
import { isNetworkError } from "@utils/customFetch";
import { getFocusableInputs } from "@utils/general";
import { Dayjs } from "dayjs";
import i18next from "i18next";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BackendErrorCode, FormMode, Status } from "../../enums";
import { TRecordAny } from "../../global.types";
import { IFilterQuery } from "../../model/TableStorage";
import BindingContext from "../../odata/BindingContext";
import { getUtcDayjs } from "../../types/Date";
import { FormStorage } from "./FormStorage";

export const setBreadCrumbs = (storage: FormStorage): void => {
    storage.context.setViewBreadcrumbs({
        items: [
            {
                key: "selectedAccount",
                link: null,
                title: storage.data.definition.getItemBreadCrumbText(storage)
            }
        ],
        lockable: false
    });
};

export const getSummaryItems = (storage: FormStorage): ISummaryItem[] => {
    const def = storage?.data?.definition;
    let items;
    if (def) {
        items = storage.formMode === FormMode.AuditTrail ? def.auditTrailSummary || def.summary : def.summary;
    }

    return items || [];
};

export const getEntitySetFromDefinition = (definition: IDefinition, context: IAppContext, parentId?: string): string => {
    return getInfoValue(definition, "entitySet", {
        context: context,
        data: { parentId: parentId }
    });
};

/**
 * Sets group status as new / editing -> if it's set to creating, we expand the group, otherwise we keep the previous state
 * @param groupId
 * @param storage
 * @param isCreating
 * @param forceExpand
 */
export function setGroupStatus(groupId: string, storage: FormStorage, isCreating: boolean, forceExpand?: boolean): void {
    const groupStatus = storage.getGroupStatus(groupId);
    // expand the group when group status change to isCreating, otherwise we keep the previous state
    const isExpanded = forceExpand || isCreating || groupStatus?.isExpanded;

    storage.setGroupStatus({
        isCreating,
        isExpanded
    }, groupId);
}

export const getFormTableFilterQuery = (storage: FormStorage, tableDef: TFormTable, bindingContext: BindingContext): IFilterQuery => {
    const defaultFilter = getInfoValue(tableDef, "filter", {
        storage
    });

    return {
        query: (typeof defaultFilter === "string" ? defaultFilter : defaultFilter?.query) ?? `${tableDef.parentKey} eq ${bindingContext.getKey()}`,
        collectionQueries: defaultFilter?.collectionQueries
    };
};

export function getAlertFromError(error: ODataError | Error | string): IBaseAlertProps {
    // use Set instead of Array, so that none of the messages is repeated multiple times
    const subTitle = new Set<string>();
    let detailData: TRecordAny;
    let title = i18next.t("Common:Errors.ErrorHappened");
    const internalError = i18next.t("Common:Errors.InternalError");

    if (isODataError(error)) {
        const isFieldValidationError = error._code === BackendErrorCode.ValidationError;

        if (isFieldValidationError) {
            title = i18next.t("Common:General.FormValidationErrorTitle");
        }

        detailData = error;

        if (error?._validationMessages?.length) {
            for (const valMessage of error._validationMessages) {
                subTitle.add(valMessage.message);
            }
        } else if (!!BackendErrorCode[error?._code]) {
            subTitle.add(error._message ?? internalError);
        } else {
            // unknown error, just show not translated code & message
            // show nothing in subtitle and display full error in detail
            subTitle.add(internalError);
        }
    } else if (isNetworkError(error)) {
        title = i18next.t("Common:Errors.NoConnection");
        subTitle.add(i18next.t("Common:Errors.NoConnectionMessage"));
    } else {
        if (typeof error === "string") {
            subTitle.add(error);
        } else {
            subTitle.add(internalError);
            detailData = { "Error": (error as unknown)?.toString() };
        }
    }

    return {
        detailData,
        action: AlertAction.Close,
        status: Status.Error,
        useFade: false,
        title,
        subTitle: Array.from(subTitle)
    };
}

export function getDisabledFormPageAlert(): IAlertProps {
    return {
        status: Status.Warning,
        title: i18next.t("Common:Errors.PageLocked"),
        isSmall: true,
        useFade: true
    };
}

export function addLineActionInfoValueCallback(itemsGroup: IFormGroupDef, name: TInfoValueProp, actionIds: string[], callback: TGetValueFn<boolean>): void {
    const originalValue = itemsGroup.lineItems[name as keyof IFormLineItemsDef] as TInfoValue<boolean>;

    itemsGroup.lineItems = {
        ...itemsGroup.lineItems,
        [name]: (args: IGetValueArgs, smartFastEntryListProps: TRecordAny): boolean => {
            const customActionId = smartFastEntryListProps.customAction?.id;

            // possibility for multiple callbacks from different wrappers
            // (e.g. if assets wanted to have this check as well)
            if (!actionIds.includes(customActionId)) {
                return typeof originalValue === "boolean" ? originalValue : originalValue?.(args, smartFastEntryListProps);
            }

            return callback(args, smartFastEntryListProps);
        }
    };
}

/**
 * pick new Date from active FY, so there is a match with loaded accounts and the default
 * value of the form is valid. Use it when form contains accounts, so they are correctly
 * loaded or the date is not set, so user sees the validation in that case
 */
export function defaultDateFromActiveFiscalYear(args: IGetValueArgs): Dayjs {
    const activeFY = getOldestActiveFY(args.context);
    const now = getUtcDayjs();
    if (!activeFY) {
        return null;
    } else if (now.isBetween(activeFY.DateStart, activeFY.DateEnd, "day", "[]")) {
        return now;
    } else if (now.isBefore(activeFY.DateStart, "day")) {
        return getUtcDayjs(activeFY.DateStart);
    }
    return getUtcDayjs(activeFY.DateEnd);
}

export function isFormReadOnly(args: IGetValueArgs): boolean {
    return !!(args.storage as FormStorage).isReadOnly;
}

export function focusFirstInputField(el: HTMLElement): void {
    const elements = Array.from(getFocusableInputs(el)) ?? [];
    const firstElement = elements[0];

    firstElement?.focus({
        // only works in Firefox so far :/
        // @ts-ignore
        focusVisible: true
    });
}

export const getCurrentLineItem = (storage: FormStorage, bc: BindingContext) => {
    const items = storage.data.entity.Items;
    return getBoundValue({
        bindingContext: bc,
        dataBindingContext: storage.data.bindingContext,
        data: items
    });
};