import { IMenuGroup, IMenuItem, IMenuSelected } from "@components/navigation";
import { IGetValueArgs, TInfoValue } from "@components/smart/FieldInfo";
import { EntitySetName } from "@odata/GeneratedEntityTypes";
import {
    AccountingCode,
    CompanyPermissionCode,
    DocumentTypeCode,
    FeatureCode,
    GeneralPermissionCode
} from "@odata/GeneratedEnums";
import { BatchRequest, IBatchResult } from "@odata/OData";
import { ODataQueryResult } from "@odata/ODataParser";
import { isFreeSubscription, isNotYetPayedForSubscription } from "@pages/admin/subscriptions/Subscriptions.utils";
import { ProformaType } from "@pages/documents/proformaInvoices/ProformaInvoice.utils";
import { getInboxFilterByType, InboxFilter } from "@pages/inbox/Inbox.utils";
import {
    getCompanyAccountingCode,
    hasEverBeenVatRegisteredCompany,
    isDemoTenant,
    isVatRegisteredCompany
} from "@utils/CompanyUtils";
import { isBasicDraftFilter } from "@utils/DraftUtils";
import { getValue, isDefined } from "@utils/general";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "./contexts/appContext/AppContext.types";
import { IPermissionContext } from "./contexts/permissionContext/PermissionContext";
import {
    ROUTE_ABSENCE_CATEGORIES,
    ROUTE_ACCOUNT_ANALYSIS,
    ROUTE_ACCOUNT_ASSIGNMENT,
    ROUTE_ACCOUNTING_JOURNAL,
    ROUTE_ACTIONS,
    ROUTE_AGING_AP,
    ROUTE_AGING_AR,
    ROUTE_ASSET_ANALYSIS,
    ROUTE_ASSETS,
    ROUTE_ATTENDANCE,
    ROUTE_AUDIT,
    ROUTE_BALANCE_SHEET,
    ROUTE_BANK_ACCOUNTS,
    ROUTE_BANK_STATEMENTS,
    ROUTE_BANK_TRANSACTIONS,
    ROUTE_BUSINESS_PARTNER,
    ROUTE_CASH_BOXES,
    ROUTE_CASH_RECEIPTS,
    ROUTE_CATEGORIES,
    ROUTE_CBA_ENTRY_LEDGER,
    ROUTE_CHARTS_OF_ACCOUNTS,
    ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES,
    ROUTE_COMPANY_ROLES,
    ROUTE_COMPANY_SETTING,
    ROUTE_CORRECTIVE_INVOICE_ISSUED,
    ROUTE_CORRECTIVE_INVOICE_RECEIVED,
    ROUTE_CURRENCY_SETTINGS,
    ROUTE_CUSTOMER_PORTAL_PAYMENT,
    ROUTE_DATA_BOX,
    ROUTE_DATA_EXPORT,
    ROUTE_DATA_IMPORT,
    ROUTE_DELETE_LOG,
    ROUTE_DOCUMENT_ITEM_TEMPLATES,
    ROUTE_DOCUMENT_JOURNAL,
    ROUTE_DOCUMENTS,
    ROUTE_ELECTRONIC_SUBMISSION,
    ROUTE_EMPLOYEES,
    ROUTE_EMPLOYMENT,
    ROUTE_EMPLOYMENT_TEMPLATE,
    ROUTE_EXCHANGE_RATES,
    ROUTE_EXTRA_SALARIES,
    ROUTE_FISCAL_YEAR,
    ROUTE_FISCAL_YEAR_CLOSE,
    ROUTE_FIXED_ASSET,
    ROUTE_GENERAL_LEDGER,
    ROUTE_GOVERNMENT_INSTITUTION,
    ROUTE_GROUP_DEDUCTIONS,
    ROUTE_HEALTH_INSURANCE_COMPANIES,
    ROUTE_HOME,
    ROUTE_INBOX,
    ROUTE_INBOX_CUSTOMER_APPROVAL,
    ROUTE_INBOX_SEND_TO_ACCOUNTANT,
    ROUTE_INBOX_SETTINGS,
    ROUTE_INCOME_AND_EXPENDITURE,
    ROUTE_INCOME_STATEMENT,
    ROUTE_INDIVIDUAL_DEDUCTIONS,
    ROUTE_INITIAL_ACCOUNT_BALANCES,
    ROUTE_INTERNAL_DOCUMENT,
    ROUTE_INVOICE_ISSUED,
    ROUTE_INVOICE_RECEIVED,
    ROUTE_LABEL_HIERARCHIES,
    ROUTE_LIFE_INSURANCE_COMPANIES,
    ROUTE_MENU_COMMON_SETTINGS,
    ROUTE_MENU_SUBSCRIPTION,
    ROUTE_MENU_USERS,
    ROUTE_MESSAGES,
    ROUTE_MINOR_ASSET,
    ROUTE_NUMBER_RANGES,
    ROUTE_OTHER_LIABILITY,
    ROUTE_OTHER_RECEIVABLE,
    ROUTE_PAYMENT_JOURNAL,
    ROUTE_PAYMENTS,
    ROUTE_PAYROLL,
    ROUTE_PAYSLIP,
    ROUTE_PENSION_INSURANCE_COMPANIES,
    ROUTE_PROFORMA_INVOICE_ISSUED,
    ROUTE_PROFORMA_INVOICE_RECEIVED,
    ROUTE_RECEIVABLE_ADJUSTMENTS,
    ROUTE_RECURRING_TASKS,
    ROUTE_REPORTING,
    ROUTE_SALARY_ADVANCES,
    ROUTE_SALARY_COMPONENTS,
    ROUTE_SALDO,
    ROUTE_SUBSCRIPTIONS_EXTENSIONS,
    ROUTE_SUBSCRIPTIONS_INVOICING,
    ROUTE_SUBSCRIPTIONS_PAYMENT_HISTORY,
    ROUTE_SUBSCRIPTIONS_PLAN,
    ROUTE_SYSTEM_ROLES,
    ROUTE_TICKETS,
    ROUTE_UNORGANIZED_ASSET,
    ROUTE_USERS,
    ROUTE_VAT,
    ROUTE_VAT_CONTROL_STATEMENT_REPORT,
    ROUTE_VAT_STATEMENT_REPORT,
    ROUTE_VAT_VIES_STATEMENT_REPORT,
    ROUTE_WORKING_PATTERNS
} from "./routes";
import accountAnalysisSvg from "./svg/menu/accountAnalysis.svg";
import accountAssignmentSvg from "./svg/menu/accountAssignment.svg";
import accountingJournalSvg from "./svg/menu/accountingJournal.svg";
import assetAnalysisSvg from "./svg/menu/assetAnalysis.svg";
import attendanceSvg from "./svg/menu/attendance.svg";
import auditSvg from "./svg/menu/audit.svg";
import balanceSvg from "./svg/menu/balance.svg";
import bankStatementsSvg from "./svg/menu/bankStatements.svg";
import bankTransactionsSvg from "./svg/menu/bankTransactions.svg";
import cashReceiptsSvg from "./svg/menu/cashReceipts.svg";
import cbaEntryLedgerSvg from "./svg/menu/cbaEntryLedger.svg";
import cbaIncomeExpenseSvg from "./svg/menu/cbaIncomeExpense.svg";
import coaTemplatesSvg from "./svg/menu/coaTemplates.svg";
import commitmentsSvg from "./svg/menu/commitments.svg";
import companyRolesSvg from "./svg/menu/companyRoles.svg";
import correctiveInvoicesIssuedSvg from "./svg/menu/correctiveInvoicesIssued.svg";
import correctiveInvoicesReceivedSvg from "./svg/menu/correctiveInvoicesReceived.svg";
import documentJournalSvg from "./svg/menu/documentJournal.svg";
import electronicSubmissionSvg from "./svg/menu/electronicSubmission.svg";
import employeesSvg from "./svg/menu/employees.svg";
import employmentSvg from "./svg/menu/employment.svg";
import employmentTemplatesSvg from "./svg/menu/employmentTemplates.svg";
import exchangeRatesSvg from "./svg/menu/exchangeRates.svg";
import extraSalariesSvg from "./svg/menu/extraSalaries.svg";
import fiscalYearCloseSvg from "./svg/menu/fiscalYearClose.svg";
import fixedAssetSvg from "./svg/menu/fixedAsset.svg";
import generalLedgerSvg from "./svg/menu/generalLedger.svg";
import generalRolesSvg from "./svg/menu/generalRoles.svg";
import inboxSvg from "./svg/menu/inbox.svg";
import incomeStatementSvg from "./svg/menu/incomeStatement.svg";
import internalDocumentsSvg from "./svg/menu/internalDocuments.svg";
import invoicesIssuedSvg from "./svg/menu/invoicesIssued.svg";
import invoicesReceivedSvg from "./svg/menu/invoicesReceived.svg";
import itemTemplatesSvg from "./svg/menu/itemTemplates.svg";
import messagesSvg from "./svg/menu/messages.svg";
import minorAssetSvg from "./svg/menu/minorAsset.svg";
import openBalanceSvg from "./svg/menu/openBalance.svg";
import otherLiabilitySvg from "./svg/menu/otherLiability.svg";
import otherReceivablesSvg from "./svg/menu/otherReceivables.svg";
import paymentHistorySvg from "./svg/menu/paymentHistory.svg";
import paymentJournalSvg from "./svg/menu/paymentJournal.svg";
import proformaInvoicesIssuedSvg from "./svg/menu/proformaInvoicesIssued.svg";
import proformaInvoicesReceivedSvg from "./svg/menu/proformaInvoicesReceived.svg";
import receivableAdjustmentsMenuSvg from "./svg/menu/receivableAdjustmentsMenu.svg";
import salaryAdvancesSvg from "./svg/menu/salaryAdvances.svg";
import salaryComponentsSvg from "./svg/menu/salaryComponents.svg";
import saldoSvg from "./svg/menu/saldo.svg";
import subscriptionExtensionsSvg from "./svg/menu/subscriptionExtensions.svg";
import subscriptionInvoicingSvg from "./svg/menu/subscriptionInvoicing.svg";
import subscriptionPlanSvg from "./svg/menu/subscriptionPlan.svg";
import unsortedAssetSvg from "./svg/menu/unsortedAsset.svg";
import usersSvg from "./svg/menu/users.svg";
import vatControlStatementReportSvg from "./svg/menu/vatControlStatementReport.svg";
import vatRulesSvg from "./svg/menu/vatRules.svg";
import vatStatementReportSvg from "./svg/menu/vatStetementReport.svg";
import vatViesReportSvg from "./svg/menu/vatViesStatementReport.svg";
import workingPatternsSvg from "./svg/menu/workingPatterns.svg";

export interface IMenuDefItem extends IMenuItem {
    permission?: (CompanyPermissionCode | GeneralPermissionCode) | (CompanyPermissionCode | GeneralPermissionCode)[];
    countGetter?: (batch: BatchRequest) => Promise<number>;
    isDisabledFn?: (context: IAppContext) => IDisabledMenuItem;
    accountingTypes?: AccountingCode[];
    isVisible?: TInfoValue<boolean>;
}

interface IDisabledMenuItem {
    reason: string;
}

export interface IMenuDefGroup extends IMenuGroup {
    items: IMenuDefItem[];
}

const isBankStuffDisabled = (context: IAppContext): IDisabledMenuItem => {
    const acocunts = context.getCompanyBankAccounts();
    const isDisabled = !acocunts || acocunts.length === 0;

    if (isDisabled) {
        return {
            reason: i18next.t("Common:Menu.NoBankAccount")
        };
    } else {
        return null;
    }
};

export const SettingsDefinition: IMenuDefGroup[] = [
    {
        key: "Settings.GeneralSettings",
        title: "Settings.GeneralSettings",
        items: [
            {
                key: "CompanySetting",
                title: "Settings.CompanySetting",
                url: ROUTE_COMPANY_SETTING,
                permission: CompanyPermissionCode.CustomerSettings
            },
            {
                key: "BusinessPartners",
                title: "Settings.BusinessPartners",
                url: ROUTE_BUSINESS_PARTNER,
                permission: CompanyPermissionCode.CustomerSettings
            },
            {
                key: "BankAccounts",
                title: "Settings.BankAccounts",
                permission: CompanyPermissionCode.CustomerSettings,
                url: ROUTE_BANK_ACCOUNTS
            },
            {
                key: "CashBoxes",
                title: "Settings.CashBoxes",
                url: ROUTE_CASH_BOXES,
                permission: CompanyPermissionCode.CustomerSettings
            },
            {
                key: "InboxSettings",
                title: "Settings.InboxSettings",
                url: ROUTE_INBOX_SETTINGS,
                permission: CompanyPermissionCode.CustomerSettings
            },
            {
                key: "DataBox",
                title: "Settings.DataBox",
                url: ROUTE_DATA_BOX,
                permission: CompanyPermissionCode.CustomerSettings
            },
            {
                key: "RecurringTasks",
                title: "Settings.RecurringTasks",
                url: ROUTE_RECURRING_TASKS,
                permission: CompanyPermissionCode.RecurringTask
            },
            {
                key: "DataImport",
                title: "Settings.DataImport",
                url: ROUTE_DATA_IMPORT,
                permission: CompanyPermissionCode.CanImport
            },
            {
                key: "DataExport",
                title: "Settings.DataExport",
                url: ROUTE_DATA_EXPORT,
                permission: GeneralPermissionCode.CanExportDataFromTheSystem
            }
        ]
    },
    {
        title: "Settings.Accounting",
        key: "Settings.Accounting",
        items: [
            {
                key: "Categories",
                title: "Settings.Categories",
                url: ROUTE_CATEGORIES,
                permission: CompanyPermissionCode.CustomerSettings,
                accountingTypes: [AccountingCode.CashBasisAccounting]
            },
            {
                key: "InitialBalances",
                title: "Settings.InitialBalances",
                url: ROUTE_INITIAL_ACCOUNT_BALANCES,
                permission: CompanyPermissionCode.CustomerSettings,
                accountingTypes: [AccountingCode.AccountingForBusiness]
            },
            {
                key: "FiscalYear",
                title: "Settings.FiscalYear",
                url: ROUTE_FISCAL_YEAR,
                permission: CompanyPermissionCode.CustomerSettings,
                accountingTypes: [AccountingCode.AccountingForBusiness]
            },
            {
                key: "ChartsOfAccounts",
                title: "Settings.ChartsOfAccounts",
                url: ROUTE_CHARTS_OF_ACCOUNTS,
                permission: CompanyPermissionCode.CustomerSettings,
                accountingTypes: [AccountingCode.AccountingForBusiness]
            },
            {
                key: "NumberRanges",
                title: "Settings.NumberRanges",
                url: ROUTE_NUMBER_RANGES,
                permission: CompanyPermissionCode.CustomerSettings
            },
            {
                key: "Labels",
                title: "Settings.Labels",
                url: ROUTE_LABEL_HIERARCHIES,
                permission: CompanyPermissionCode.CustomerSettings
            },
            {
                key: "CurrencySettings",
                title: "Settings.CurrencySettings",
                url: ROUTE_CURRENCY_SETTINGS,
                permission: CompanyPermissionCode.CustomerSettings
            }
        ]
    },
    {
        key: "Settings.PayrollSettings",
        title: "Settings.PayrollSettings",
        items: [
            {
                key: "HealthInsuranceCompanies",
                title: "Menu.HealthInsuranceCompanies",
                url: ROUTE_HEALTH_INSURANCE_COMPANIES,
                permission: CompanyPermissionCode.PayrollSettings
            },
            {
                key: "PensionInsuranceCompanies",
                title: "Menu.PensionInsuranceCompanies",
                url: ROUTE_PENSION_INSURANCE_COMPANIES,
                permission: CompanyPermissionCode.PayrollSettings
            },
            {
                key: "LifeInsuranceCompanies",
                title: "Menu.LifeInsuranceCompanies",
                url: ROUTE_LIFE_INSURANCE_COMPANIES,
                permission: CompanyPermissionCode.PayrollSettings
            },
            {
                key: "GovernmentInstitution",
                title: "Menu.GovernmentInstitution",
                url: ROUTE_GOVERNMENT_INSTITUTION,
                permission: CompanyPermissionCode.PayrollSettings
            },
            {
                key: "AbsenceCategories",
                title: "Menu.AbsenceCategories",
                url: ROUTE_ABSENCE_CATEGORIES,
                permission: CompanyPermissionCode.PayrollSettings
            }
        ],
        isVisible: (args: IGetValueArgs): boolean => {
            return args.context.isFeatureSwitchEnabled(FeatureCode.Payroll);
        }
    }
];

const getDraftCountFilter = (documentType: DocumentTypeCode) => {
    return async (batch: BatchRequest): Promise<number> => {
        const query = batch.getEntitySetWrapper(EntitySetName.DocumentDrafts).query()
            .filter(`${isBasicDraftFilter} AND DocumentTypeCode eq '${documentType}'`).count().top(0);
        const res = (await batch.awaitRequest(query.getId())) as IBatchResult<ODataQueryResult>;

        return res.body._metadata.count;
    };
};

export const getMainMenuDefinition = (context: IAppContext): IMenuDefGroup[] => {
    return [
        {
            key: "Documents",
            title: "Menu.Documents",
            url: ROUTE_DOCUMENTS,
            items: [
                {
                    key: "InvoicesReceived",
                    iconPath: invoicesReceivedSvg,
                    title: "Menu.InvoicesReceived",
                    url: ROUTE_INVOICE_RECEIVED,
                    permission: CompanyPermissionCode.InvoicesReceived,
                    countGetter: getDraftCountFilter(DocumentTypeCode.InvoiceReceived)
                },
                {
                    key: "InvoicesIssued",
                    iconPath: invoicesIssuedSvg,
                    title: "Menu.InvoicesIssued",
                    url: ROUTE_INVOICE_ISSUED,
                    permission: CompanyPermissionCode.InvoicesIssued,
                    countGetter: getDraftCountFilter(DocumentTypeCode.InvoiceIssued)
                },
                {
                    key: "ProformaInvoicesReceived",
                    iconPath: proformaInvoicesReceivedSvg,
                    title: (): string => {
                        return isVatRegisteredCompany(context) ? "Menu.ProformaInvoicesReceived" : "Menu.ProformaInvoicesReceived_WithoutVat";
                    },
                    url: ROUTE_PROFORMA_INVOICE_RECEIVED,
                    permission: CompanyPermissionCode.InvoicesReceived,
                    countGetter: getDraftCountFilter(DocumentTypeCode.ProformaInvoiceReceived)
                },
                {
                    key: "ProformaInvoicesIssued",
                    iconPath: proformaInvoicesIssuedSvg,
                    title: (): string => {
                        return isVatRegisteredCompany(context) ? "Menu.ProformaInvoicesIssued" : "Menu.ProformaInvoicesIssued_WithoutVat";
                    },
                    url: ROUTE_PROFORMA_INVOICE_ISSUED,
                    permission: CompanyPermissionCode.InvoicesIssued,
                    countGetter: getDraftCountFilter(DocumentTypeCode.ProformaInvoiceIssued)
                },
                {
                    key: "OtherLiability",
                    iconPath: otherLiabilitySvg,
                    title: "Menu.OtherLiability",
                    url: ROUTE_OTHER_LIABILITY,
                    permission: CompanyPermissionCode.InvoicesReceived,
                    countGetter: getDraftCountFilter(DocumentTypeCode.OtherLiability)
                },
                {
                    key: "OtherReceivables",
                    iconPath: otherReceivablesSvg,
                    title: "Menu.OtherReceivables",
                    url: ROUTE_OTHER_RECEIVABLE,
                    permission: CompanyPermissionCode.InvoicesIssued,
                    countGetter: getDraftCountFilter(DocumentTypeCode.OtherReceivable)
                },
                {
                    key: "CorrectiveInvoicesReceived",
                    iconPath: correctiveInvoicesReceivedSvg,
                    title: "Menu.CorrectiveInvoicesReceived",
                    url: ROUTE_CORRECTIVE_INVOICE_RECEIVED,
                    permission: CompanyPermissionCode.InternalAndCorrectiveDocuments,
                    countGetter: getDraftCountFilter(DocumentTypeCode.CorrectiveInvoiceReceived)
                },
                {
                    key: "CorrectiveInvoicesIssued",
                    iconPath: correctiveInvoicesIssuedSvg,
                    title: "Menu.CorrectiveInvoicesIssued",
                    url: ROUTE_CORRECTIVE_INVOICE_ISSUED,
                    permission: CompanyPermissionCode.InternalAndCorrectiveDocuments,
                    countGetter: getDraftCountFilter(DocumentTypeCode.CorrectiveInvoiceIssued)
                },
                {
                    key: "InternalDocuments",
                    iconPath: internalDocumentsSvg,
                    title: "Menu.InternalDocuments",
                    url: ROUTE_INTERNAL_DOCUMENT,
                    permission: CompanyPermissionCode.InternalAndCorrectiveDocuments,
                    countGetter: getDraftCountFilter(DocumentTypeCode.InternalDocument)
                },
                {
                    key: "Inbox",
                    iconPath: inboxSvg,
                    title: "Menu.Inbox",
                    permission: CompanyPermissionCode.SortingFilesFromAPhotoFile,
                    url: ROUTE_INBOX,
                    countGetter: async (batch: BatchRequest): Promise<number> => {
                        const query = batch.getEntitySetWrapper(EntitySetName.InboxFiles).query()
                            .filter(getInboxFilterByType(InboxFilter.Unsorted)).count().top(0);
                        const res = (await batch.awaitRequest(query.getId())) as IBatchResult<ODataQueryResult>;

                        return res.body._metadata.count;
                    }
                }
            ]
        },
        {
            key: "Payments",
            title: "Menu.Payments",
            url: ROUTE_PAYMENTS,
            items: [
                {
                    key: "BankTransactions",
                    iconPath: bankTransactionsSvg,
                    title: "Menu.BankTransactions",
                    permission: CompanyPermissionCode.Bank,
                    url: ROUTE_BANK_TRANSACTIONS,
                    isDisabledFn: isBankStuffDisabled
                },
                {
                    key: "BankStatements",
                    iconPath: bankStatementsSvg,
                    title: "Menu.BankStatements",
                    permission: CompanyPermissionCode.Bank,
                    url: ROUTE_BANK_STATEMENTS,
                    isDisabledFn: isBankStuffDisabled
                },
                {
                    key: "CashReceipts",
                    iconPath: cashReceiptsSvg,
                    title: "Menu.CashReceipts",
                    permission: CompanyPermissionCode.CashBox,
                    url: ROUTE_CASH_RECEIPTS,
                    isDisabledFn: (context: IAppContext): IDisabledMenuItem => {
                        const cashBoxes = context.getCashBoxes();
                        const isDisabled = !cashBoxes || cashBoxes.length === 0;

                        if (isDisabled) {
                            return {
                                reason: i18next.t("Common:Menu.NoCashBbox")
                            };
                        } else {
                            return null;
                        }
                    }
                }
            ]
        },
        {
            key: "Assets",
            title: "Menu.Assets",
            url: ROUTE_ASSETS,
            items: [
                {
                    key: "UnorganizedAsset",
                    iconPath: unsortedAssetSvg,
                    title: "Menu.UnorganizedAsset",
                    permission: CompanyPermissionCode.Asset,
                    url: ROUTE_UNORGANIZED_ASSET
                },
                {
                    key: "FixedAsset",
                    iconPath: fixedAssetSvg,
                    title: "Menu.FixedAssets",
                    permission: CompanyPermissionCode.Asset,
                    url: ROUTE_FIXED_ASSET
                },
                {
                    key: "MinorAsset",
                    iconPath: minorAssetSvg,
                    title: "Menu.MinorAsset",
                    permission: CompanyPermissionCode.Asset,
                    url: ROUTE_MINOR_ASSET
                }
            ]
        },
        {
            key: "Actions",
            title: "Menu.Actions",
            url: ROUTE_ACTIONS,
            items: [
                {
                    key: "ElectronicSubmission",
                    iconPath: electronicSubmissionSvg,
                    title: "Menu.ElectronicSubmissions",
                    permission: CompanyPermissionCode.VatStatement,
                    url: ROUTE_ELECTRONIC_SUBMISSION,
                    isVisible: (args: IGetValueArgs): boolean => {
                        return hasEverBeenVatRegisteredCompany(args.context);
                    }
                },
                {
                    key: "ReceivableAdjustmentsMenu",
                    iconPath: receivableAdjustmentsMenuSvg,
                    title: "Menu.ReceivableAdjustments",
                    permission: CompanyPermissionCode.ReceivableAdjustments,
                    url: ROUTE_RECEIVABLE_ADJUSTMENTS,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "FiscalYearClose",
                    iconPath: fiscalYearCloseSvg,
                    title: "Menu.FiscalYearClose",
                    permission: CompanyPermissionCode.FiscalYearClose,
                    url: ROUTE_FISCAL_YEAR_CLOSE
                }
            ]
        },
        {
            key: "A7",
            title: "Menu.Reporting",
            url: ROUTE_REPORTING,
            items: [
                {
                    key: "CbaIncomeExpense",
                    iconPath: cbaIncomeExpenseSvg,
                    title: "Menu.CbaIncomeExpense",
                    url: ROUTE_INCOME_AND_EXPENDITURE,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.CashBasisAccounting]
                },
                {
                    key: "CbaEntryLedger",
                    iconPath: cbaEntryLedgerSvg,
                    title: "Menu.CbaEntryLedger",
                    url: ROUTE_CBA_ENTRY_LEDGER,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.CashBasisAccounting]
                },
                {
                    key: "GeneralLedger",
                    iconPath: generalLedgerSvg,
                    title: "Menu.GeneralLedger",
                    url: ROUTE_GENERAL_LEDGER,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "AccountingJournal",
                    iconPath: accountingJournalSvg,
                    title: "Menu.Journal",
                    url: ROUTE_ACCOUNTING_JOURNAL,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "AccountAnalysis",
                    iconPath: accountAnalysisSvg,
                    title: "Menu.AccountAnalysis",
                    url: ROUTE_ACCOUNT_ANALYSIS,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "AssetAnalysis",
                    iconPath: assetAnalysisSvg,
                    title: "Menu.AssetAnalysis",
                    url: ROUTE_ASSET_ANALYSIS,
                    permission: CompanyPermissionCode.Reports
                },
                {
                    key: "DocumentJournal",
                    iconPath: documentJournalSvg,
                    title: "Menu.DocumentJournal",
                    url: ROUTE_DOCUMENT_JOURNAL,
                    permission: CompanyPermissionCode.Reports
                },
                {
                    key: "VatStatementReport",
                    title: "Menu.VatStatementReport",
                    iconPath: vatStatementReportSvg,
                    url: ROUTE_VAT_STATEMENT_REPORT,
                    permission: CompanyPermissionCode.Reports,
                    isVisible: (args: IGetValueArgs): boolean => {
                        return hasEverBeenVatRegisteredCompany(args.context);
                    }
                },
                {
                    key: "VatControlStatementReport",
                    title: "Menu.VatControlStatementReport",
                    iconPath: vatControlStatementReportSvg,
                    url: ROUTE_VAT_CONTROL_STATEMENT_REPORT,
                    permission: CompanyPermissionCode.Reports,
                    isVisible: (args: IGetValueArgs): boolean => {
                        return hasEverBeenVatRegisteredCompany(args.context);
                    }
                },
                {
                    key: "VatVIESStatementReport",
                    title: "Menu.VatVIESStatementReport",
                    iconPath: vatViesReportSvg,
                    url: ROUTE_VAT_VIES_STATEMENT_REPORT,
                    permission: CompanyPermissionCode.Reports,
                    isVisible: (args: IGetValueArgs): boolean => {
                        return hasEverBeenVatRegisteredCompany(args.context);
                    }
                },
                {
                    key: "PaymentJournal",
                    iconPath: paymentJournalSvg,
                    title: "Menu.PaymentJournal",
                    url: ROUTE_PAYMENT_JOURNAL,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "Saldo",
                    iconPath: saldoSvg,
                    title: "Menu.Saldo",
                    url: ROUTE_SALDO,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "IncomeStatement",
                    iconPath: incomeStatementSvg,
                    title: "Menu.IncomeStatement",
                    url: ROUTE_INCOME_STATEMENT,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "Balance",
                    iconPath: balanceSvg,
                    title: "Menu.Balance",
                    url: ROUTE_BALANCE_SHEET,
                    permission: CompanyPermissionCode.Reports,
                    accountingTypes: [AccountingCode.AccountingForBusiness]
                },
                {
                    key: "Commitments",
                    iconPath: commitmentsSvg,
                    title: "Menu.Commitments",
                    url: ROUTE_AGING_AP,
                    permission: CompanyPermissionCode.Reports
                },
                {
                    key: "OpenBalance",
                    iconPath: openBalanceSvg,
                    title: "Menu.OpenBalance",
                    url: ROUTE_AGING_AR,
                    permission: CompanyPermissionCode.Reports
                },
                {
                    key: "ExchangeRates",
                    iconPath: exchangeRatesSvg,
                    title: "Settings.ExchangeRates",
                    url: ROUTE_EXCHANGE_RATES,
                    permission: CompanyPermissionCode.Reports
                }
            ]
        },
        {
            key: "Audit",
            title: "Menu.Audit",
            url: ROUTE_AUDIT,
            items: [
                {
                    key: "Messages",
                    iconPath: messagesSvg,
                    title: "Menu.Messages",
                    url: ROUTE_MESSAGES,
                    permission: CompanyPermissionCode.Audit
                }, {
                    key: "DeleteLog",
                    iconPath: auditSvg,
                    title: "Menu.DeleteLog",
                    url: ROUTE_DELETE_LOG,
                    permission: CompanyPermissionCode.Audit
                }
            ]
        },
        {
            key: "Payroll",
            title: "Menu.Payroll",
            url: ROUTE_PAYROLL,
            items: [
                {
                    key: "WorkingPatterns",
                    title: "Menu.WorkingPatterns",
                    url: ROUTE_WORKING_PATTERNS,
                    iconPath: workingPatternsSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "Employees",
                    title: "Menu.Employees",
                    url: ROUTE_EMPLOYEES,
                    iconPath: employeesSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "EmploymentTemplates",
                    title: "Menu.EmploymentTemplates",
                    url: ROUTE_EMPLOYMENT_TEMPLATE,
                    iconPath: employmentTemplatesSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "Employment",
                    title: "Menu.Employments",
                    url: ROUTE_EMPLOYMENT,
                    iconPath: employmentSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "SalaryComponents",
                    title: "Menu.SalaryComponents",
                    url: ROUTE_SALARY_COMPONENTS,
                    iconPath: salaryComponentsSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "SalaryAdvances",
                    title: "Menu.SalaryAdvances",
                    url: ROUTE_SALARY_ADVANCES,
                    iconPath: salaryAdvancesSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "ExtraSalaries",
                    title: "Menu.ExtraSalaries",
                    url: ROUTE_EXTRA_SALARIES,
                    iconPath: extraSalariesSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "Attendance",
                    title: "Menu.Attendance",
                    url: ROUTE_ATTENDANCE,
                    iconPath: attendanceSvg,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "Payslip",
                    title: "Menu.Payslip",
                    url: ROUTE_PAYSLIP,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "IndividualDeductions",
                    title: "Menu.IndividualDeductions",
                    url: ROUTE_INDIVIDUAL_DEDUCTIONS,
                    permission: CompanyPermissionCode.Payroll
                },
                {
                    key: "GroupDeductions",
                    title: "Menu.GroupDeductions",
                    url: ROUTE_GROUP_DEDUCTIONS,
                    permission: CompanyPermissionCode.Payroll
                }
            ],
            isVisible: (args: IGetValueArgs): boolean => {
                return args.context.isFeatureSwitchEnabled(FeatureCode.Payroll);
            }
        }
    ];
};

// there are some pages/sections, which are not directly displayed in menu, however we want to have them
// organized in structure, so things like breadcrumbs works automagically
export const NonMenuNavigations: IMenuDefGroup[] = [
    {
        key: "Customer",
        title: "Menu.Customer",
        url: ROUTE_HOME,
        items: [
            {
                key: "Tickets",
                title: "Menu.Tickets",
                url: ROUTE_TICKETS
            },
            {
                key: "InboxApproval",
                title: "Menu.InboxApproval",
                url: ROUTE_INBOX_CUSTOMER_APPROVAL
            },
            {
                key: "InboxSendToAccountant",
                title: "Menu.InboxSendToAccountant",
                url: ROUTE_INBOX_SEND_TO_ACCOUNTANT
            },
            {
                key: "CustomerPortalPayment",
                title: "Menu.CustomerPortalPayment",
                url: ROUTE_CUSTOMER_PORTAL_PAYMENT
            }
        ]
    }
];

const notYetPaidForDisabled: IMenuDefItem["isDisabledFn"] = (context: IAppContext): IDisabledMenuItem => {
    const subscription = context.getData().subscription;
    const isDisabled = isNotYetPayedForSubscription(subscription) && !isFreeSubscription(subscription);

    if (!isDisabled) {
        return null;
    }

    return {
        reason: i18next.t("Common:OrganizationSettingsMenu.WithoutSubscriptionDisabledMenuTile")
    };
};

const notYedPaidForOrFreeDisabled: IMenuDefItem["isDisabledFn"] = (context: IAppContext): IDisabledMenuItem => {
    const subscription = context.getData().subscription;
    const isDisabled = isNotYetPayedForSubscription(subscription) || isFreeSubscription(subscription);

    if (!isDisabled) {
        return null;
    }

    return {
        reason: i18next.t("Common:OrganizationSettingsMenu.WithoutSubscriptionDisabledMenuTile")
    };
};

const isDisabledForDemoTenant: IMenuDefItem["isDisabledFn"] = (context: IAppContext): IDisabledMenuItem => {
    if (isDemoTenant(context)) {
        return {
            reason: i18next.t("Common:OrganizationSettingsMenu.DemoTenantDisabledMenuTile")
        };
    }
    return null;
};

/**
 * Note: order matters, first function is checked first
 * @param params
 */
function merge(...params: IMenuDefItem["isDisabledFn"][]): IMenuDefItem["isDisabledFn"] {
    return (context: IAppContext): IDisabledMenuItem => {
        for (const param of params) {
            const result = param(context);
            if (result) {
                return result;
            }
        }
        return null;
    };
}

export const OrganizationSettingsMenuDefinition: IMenuDefGroup[] = [
    {
        key: "UsersMenu",
        title: "OrganizationSettingsMenu.Users",
        url: ROUTE_MENU_USERS,
        items: [
            {
                key: "Users",
                iconPath: usersSvg,
                title: "OrganizationSettingsMenu.UsersSettings",
                url: ROUTE_USERS,
                permission: GeneralPermissionCode.OrganizationManagement
            },
            {
                key: "CompanyRoles",
                iconPath: companyRolesSvg,
                title: "OrganizationSettingsMenu.CompanyRoles",
                url: ROUTE_COMPANY_ROLES,
                permission: GeneralPermissionCode.OrganizationManagement
            },
            {
                key: "GeneralRoles",
                iconPath: generalRolesSvg,
                title: "OrganizationSettingsMenu.GeneralRoles",
                url: ROUTE_SYSTEM_ROLES,
                permission: GeneralPermissionCode.OrganizationManagement
            }
        ]
    },
    {
        key: "Subscriptions",
        title: "OrganizationSettingsMenu.Subscriptions",
        url: ROUTE_MENU_SUBSCRIPTION,
        items: [
            {
                key: "Plan",
                iconPath: subscriptionPlanSvg,
                title: "OrganizationSettingsMenu.Plan",
                url: ROUTE_SUBSCRIPTIONS_PLAN,
                permission: GeneralPermissionCode.OrganizationManagement,
                isDisabledFn: isDisabledForDemoTenant
            },
            {
                key: "Invoicing",
                iconPath: subscriptionInvoicingSvg,
                title: "OrganizationSettingsMenu.Invoicing",
                url: ROUTE_SUBSCRIPTIONS_INVOICING,
                permission: GeneralPermissionCode.OrganizationManagement,
                isDisabledFn: merge(isDisabledForDemoTenant, notYedPaidForOrFreeDisabled)
            },
            {
                key: "Extensions",
                iconPath: subscriptionExtensionsSvg,
                title: "OrganizationSettingsMenu.Extensions",
                url: ROUTE_SUBSCRIPTIONS_EXTENSIONS,
                permission: GeneralPermissionCode.OrganizationManagement,
                isDisabledFn: merge(isDisabledForDemoTenant, notYedPaidForOrFreeDisabled)
            },
            {
                key: "PaymentHistory",
                iconPath: paymentHistorySvg,
                title: "OrganizationSettingsMenu.PaymentHistory",
                url: ROUTE_SUBSCRIPTIONS_PAYMENT_HISTORY,
                permission: GeneralPermissionCode.OrganizationManagement,
                isDisabledFn: merge(isDisabledForDemoTenant, notYetPaidForDisabled)
            }
        ]
    },
    {
        key: "SharedAgendaSettings",
        title: "OrganizationSettingsMenu.SharedAgendaSettings",
        url: ROUTE_MENU_COMMON_SETTINGS,
        items: [
            {
                key: "AccountAssignment",
                iconPath: accountAssignmentSvg,
                title: "OrganizationSettingsMenu.AccountAssignment",
                url: ROUTE_ACCOUNT_ASSIGNMENT,
                permission: GeneralPermissionCode.CommonSettingsManagement
            },
            {
                key: "Vat",
                iconPath: vatRulesSvg,
                title: "OrganizationSettingsMenu.Vats",
                url: ROUTE_VAT,
                permission: GeneralPermissionCode.CommonSettingsManagement
            },
            {
                key: "Templates",
                iconPath: coaTemplatesSvg,
                title: "OrganizationSettingsMenu.Templates",
                url: ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES,
                permission: GeneralPermissionCode.CommonSettingsManagement
            },
            {
                key: "ItemTemplates",
                iconPath: itemTemplatesSvg,
                title: "OrganizationSettingsMenu.ItemTemplates",
                url: ROUTE_DOCUMENT_ITEM_TEMPLATES,
                permission: GeneralPermissionCode.CommonSettingsManagement
            }
        ]
    }
];

export const AddMenuDefinition: IMenuDefGroup[] = [
    {
        title: "AddMenu.ReceivedDocuments",
        key: "AddMenu.ReceivedDocuments",
        items: [
            {
                key: "ReceivedInvoice",
                title: "AddMenu.Invoice",
                url: `${ROUTE_INVOICE_RECEIVED}/new`,
                permission: CompanyPermissionCode.InvoicesReceived
            },
            {
                key: "ReceivedProforma",
                title: "AddMenu.Proforma",
                url: `${ROUTE_PROFORMA_INVOICE_RECEIVED}/new?type=${ProformaType.Proforma}`,
                permission: CompanyPermissionCode.InvoicesReceived
            },
            {
                key: "ReceivedDDOPP",
                title: "AddMenu.DDOPP",
                url: `${ROUTE_PROFORMA_INVOICE_RECEIVED}/new?type=${ProformaType.DDOP}`,
                permission: CompanyPermissionCode.InvoicesReceived,
                isVisible: (args: IGetValueArgs): boolean => {
                    return hasEverBeenVatRegisteredCompany(args.context);
                }
            },
            {
                key: "ReceivedCorrectiveDoc",
                title: "AddMenu.CorrectiveDoc",
                url: `${ROUTE_CORRECTIVE_INVOICE_RECEIVED}/new`,
                permission: CompanyPermissionCode.InternalAndCorrectiveDocuments
            }
        ]
    },
    {
        title: "AddMenu.IssuedDocuments",
        key: "AddMenu.IssuedDocuments",
        items: [
            {
                key: "IssuedInvoice",
                title: "AddMenu.Invoice",
                url: `${ROUTE_INVOICE_ISSUED}/new`,
                permission: CompanyPermissionCode.InvoicesIssued
            },
            {
                key: "IssuedProforma",
                title: "AddMenu.Proforma",
                url: `${ROUTE_PROFORMA_INVOICE_ISSUED}/new?type=${ProformaType.Proforma}`,
                permission: CompanyPermissionCode.InvoicesIssued
            },
            {
                key: "IssuedDDOPP",
                title: "AddMenu.DDOPP",
                url: `${ROUTE_PROFORMA_INVOICE_ISSUED}/new?type=${ProformaType.DDOP}`,
                permission: CompanyPermissionCode.InvoicesIssued,
                isVisible: (args: IGetValueArgs): boolean => {
                    return hasEverBeenVatRegisteredCompany(args.context);
                }
            },
            {
                key: "IssuedCorrectiveDoc",
                title: "AddMenu.CorrectiveDoc",
                url: `${ROUTE_CORRECTIVE_INVOICE_ISSUED}/new`,
                permission: CompanyPermissionCode.InternalAndCorrectiveDocuments
            }
        ]
    },
    {
        title: "AddMenu.OtherDocuments",
        key: "AddMenu.OtherDocuments",
        items: [
            {
                key: "OtherLiability",
                title: "AddMenu.OtherLiability",
                url: `${ROUTE_OTHER_LIABILITY}/new`,
                permission: CompanyPermissionCode.InvoicesReceived
            },
            {
                key: "OtherReceivable",
                title: "AddMenu.OtherReceivable",
                url: `${ROUTE_OTHER_RECEIVABLE}/new`,
                permission: CompanyPermissionCode.InvoicesIssued
            },
            {
                key: "InternalDoc",
                title: "AddMenu.InternalDoc",
                url: `${ROUTE_INTERNAL_DOCUMENT}/new`,
                permission: CompanyPermissionCode.InternalAndCorrectiveDocuments
            }
        ]
    }
];

export const findItemAndGroup = (definition: IMenuDefGroup[], correctPath: string): IMenuSelected => {
    let selectedView;
    const selectedGroup = definition.find((group: IMenuDefGroup) => {
        selectedView = group.items.find((item) => item.url.toLowerCase() === correctPath?.toLowerCase());
        return selectedView;
    });
    return selectedGroup && { group: selectedGroup, item: selectedView };
};

export const filterMenu = (menuGroups: IMenuDefGroup[], context: IAppContext, permissionContext: IPermissionContext): IMenuDefGroup[] => {
    const filteredMenu = cloneDeep(menuGroups);
    const accountingType = getCompanyAccountingCode(context);
    const lookForPermission = (permission: string) => {
        return permissionContext.companyPermissions.has(permission as CompanyPermissionCode) || permissionContext.generalPermissions.has(permission as GeneralPermissionCode);
    };

    for (const menuGroup of filteredMenu) {
        menuGroup.items = menuGroup.items.filter(item => {
            if (isDefined(item.isVisible) && !getValue(item.isVisible, { context })) {
                return false;
            }

            if (item.accountingTypes && !item.accountingTypes.includes(accountingType)) {
                return false;
            }

            if (!item.permission) {
                return true;
            }

            if (typeof item.permission === "string") {
                return lookForPermission(item.permission);
            } else {
                return item.permission.some(p => lookForPermission(p));
            }
        });
    }
    return filteredMenu;
};
