import { AttachmentsAdditionalProperties, AttachmentsDef } from "@components/smart/GeneralFieldDefinition";
import {
    EntitySetName,
    EntityTypeName,
    FiscalYearCloseAdvanceTaxEntity,
    FiscalYearCloseEntity,
    FiscalYearCloseExchangeRateEntity,
    FiscalYearCloseInventoryEntity,
    FiscalYearCloseSectionEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode } from "@odata/GeneratedEnums";

import { IFormDef } from "../../views/formView/Form";
import { IDefinition, IGetDefinition } from "../PageUtils";
import { documentProps } from "./FiscalYearClose.utils";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const form: IFormDef = {
        id: `${EntityTypeName.FiscalYearClose}Form`,
        files: AttachmentsDef,
        translationFiles: getDefinitions.translationFiles,
        fieldDefinition: {},
        additionalProperties: [
            {
                id: FiscalYearCloseEntity.ExchangeRates,
                additionalProperties: [{
                    id: FiscalYearCloseExchangeRateEntity.CurrencyCode
                }]
            },
            { id: FiscalYearCloseEntity.ExpenseTaxRateCode },
            { id: FiscalYearCloseEntity.InventoryTypeCode },
            { id: FiscalYearCloseEntity.HasTaxAdvisor },
            { id: FiscalYearCloseEntity.DateClosed },
            { id: FiscalYearCloseEntity.LegalEntityCategoryCode },
            {
                id: FiscalYearCloseEntity.Sections,
                additionalProperties: [{
                    id: FiscalYearCloseSectionEntity.IsChecked
                }, {
                    id: FiscalYearCloseSectionEntity.Type
                }
                ]
            },
            { id: FiscalYearCloseEntity.ClosedByUser, additionalProperties: [{ id: UserEntity.Name }] },
            documentProps(FiscalYearCloseEntity.BalanceSheetClose),
            documentProps(FiscalYearCloseEntity.IncomeExpenseTransfer),
            documentProps(FiscalYearCloseEntity.NextYearBalanceSheetOpen),
            documentProps(FiscalYearCloseEntity.NextYearProfitOrLossTransfer),
            documentProps(FiscalYearCloseEntity.ProfitOrLoss),
            documentProps(FiscalYearCloseEntity.ExchangeRateDocuments),
            documentProps(FiscalYearCloseEntity.IncomeTaxDocument),
            documentProps(FiscalYearCloseEntity.InventoryClearingDocument),
            documentProps(FiscalYearCloseEntity.InventoryCheckDocument),
            documentProps(FiscalYearCloseEntity.IncomeTaxPaymentOrReturnDocument, DocumentTypeCode.OtherLiability),
            { id: FiscalYearCloseEntity.IncomeTaxAmount},
            { id: FiscalYearCloseEntity.IncomeTaxPaidAdvanceAmount },
            { id: FiscalYearCloseEntity.InventoryAccounts,
                additionalProperties: [{
                    id: FiscalYearCloseInventoryEntity.Amount
                }, {
                    id: FiscalYearCloseInventoryEntity.InventoryAccount
                }, {
                    id: FiscalYearCloseInventoryEntity.ConsumptionAccount
                }, {
                    id: FiscalYearCloseInventoryEntity.ChartOfAccounts
                }]
            },
            {
                id: FiscalYearCloseEntity.AdvanceTaxPayments,
                additionalProperties: [documentProps(FiscalYearCloseAdvanceTaxEntity.CreatedDocument, DocumentTypeCode.OtherLiability, true)]
            },
            ...AttachmentsAdditionalProperties
        ]
    };

    return {
        form,
        entitySet: EntitySetName.FiscalYearCloses
    };
};

getDefinitions.translationFiles = [];