import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { Button } from "@components/button";
import { ConditionType, createFilterRow } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import Dialog from "@components/dialog";
import { getIntentLink } from "@components/drillDown/DrillDown.utils";
import SelectionTableViewBase, { THeaderDataGetter } from "@components/smart/SelectionTableViewBase";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { IRow, TId } from "@components/table";
import {
    DocumentEntity,
    EntityTypeName,
    IDocumentEntity,
    IDocumentLinkEntity,
    IInvoiceIssuedEntity,
    IInvoiceReceivedEntity
} from "@odata/GeneratedEntityTypes";
import { ClearedStatusCode, DocumentLinkTypeCode, DocumentTypeCode, PostedStatusCode } from "@odata/GeneratedEnums";
import { transformToODataString } from "@odata/OData.utils";
import { PropertyTranslationCache } from "@odata/PropertyTranslationCache";
import { DocumentStatusLocalPath, getStatusFilterId } from "@pages/reports/CommonDefs";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import React from "react";

import { DASH_CHARACTER } from "../../../../constants";
import { RowAction, Status, ValueType } from "../../../../enums";
import BindingContext from "../../../../odata/BindingContext";
import { ROUTE_BUSINESS_PARTNER } from "../../../../routes";
import { formatCurrency } from "../../../../types/Currency";
import DateType from "../../../../types/Date";
import { TableButtonsAction, TableButtonsActionType } from "../../../../views/table/TableToolbar";
import { ITableViewBaseProps } from "../../../../views/table/TableView";
import {
    DOCUMENT_CLEARING_FORM_TAB_ID,
    getDocumentLinkBcs,
    isNotSavedDocument,
    isReceived,
    refreshExchangeRate,
    setNotPostedStatusFilter
} from "../../Document.utils";
import { IProformaEntity } from "../../proformaInvoices/ProformaInvoice.utils";
import {
    createProformaDocumentLink,
    fetchAndApplyProformaInvoice,
    loadRelatedProformas,
    PROFORMA_FORM_TAB,
    RELATED_PROFORMA_ADDITIONAL_RESULT_IDX
} from "./Proforma.utils";
import { NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH } from "./ProformaDef";


export interface IProps extends ITableViewBaseProps, WithBusyIndicator {
    onConfirm?: () => void;
}

interface IState {
    isConfirmDialogOpened: boolean;
    rowsData?: IRow[];
}

type IInvoiceEntity = IInvoiceReceivedEntity | IInvoiceIssuedEntity;

class PairingProformaTableView extends SelectionTableViewBase<IProps, IState> {
    _originalLinks: IDocumentLinkEntity[];

    get title(): string {
        return this.props.rootStorage.t(this.props.rootStorage.data.definition.title);
    }

    get secondaryTitle(): string {
        return this.props.storage.data.definition.title;
    }

    get selectionConfirmText(): string {
        return this.props.storage.t("Proforma:Pairing.PairActionText");
    }

    get headerData(): THeaderDataGetter {
        const bc = this.props.rootStorage.data.bindingContext;
        const entity = this.props.rootStorage.data.entity as IInvoiceEntity;
        const numberOursBc = bc.navigate("NumberOurs");
        const datePath = (this.isExpense ? "DateReceived" : "DateIssued") as keyof IInvoiceEntity;
        const emptyValue = DASH_CHARACTER;
        const transactionCurrency = entity.TransactionCurrencyCode ?? entity.TransactionCurrency?.Code;

        return [
            {
                // NumberOurs is used as summary item with custom label => label in fieldInfo isn't the one we want
                label: PropertyTranslationCache.getCachedTranslation(numberOursBc.getProperty()),
                value: entity.NumberOurs ?? emptyValue
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("BusinessPartner/BusinessPartner")).label,
                value: entity.BusinessPartner?.BusinessPartner?.Id ? getIntentLink(entity.BusinessPartner.Name, {
                    route: `${ROUTE_BUSINESS_PARTNER}/${entity.BusinessPartner.BusinessPartner?.Id}`,
                    context: this.props.rootStorage.context,
                    storage: this.props.rootStorage
                }) : emptyValue
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate(datePath)).label,
                value: entity[datePath] ? DateType.format(entity[datePath] as Date) : emptyValue
            },
            {
                label: this.props.storage.t("Proforma:Pairing.TransactionAmount"),
                value: formatCurrency(entity.TransactionAmount ? entity.TransactionAmount : emptyValue, transactionCurrency)
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("TransactionAmountNet")).label,
                value: formatCurrency(entity.TransactionAmountNet ? entity.TransactionAmountNet : emptyValue, transactionCurrency)
            },
            {
                label: this.props.rootStorage.getInfo(bc.navigate("TransactionAmountDue")).label,
                value: formatCurrency(entity.TransactionAmountDue ? entity.TransactionAmountDue : emptyValue, transactionCurrency)
            }
        ];
    }

    get isExpense(): boolean {
        return isReceived(this.props.rootStorage.data.entity.DocumentTypeCode as DocumentTypeCode);
    }

    constructor(props: IProps) {
        super(props);

        this.prepareInitialData();
    }

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        return super.shouldComponentUpdate(nextProps, nextState) || this.state.isConfirmDialogOpened !== nextState.isConfirmDialogOpened;
    }

    isDraftView = (): boolean => {
        return false;
    };

    prepareInitialData() {
        const { rootStorage } = this.props;
        const document = rootStorage.data.entity as IDocumentEntity;
        const links = document.DocumentLinks ?? [];

        const proformaLinks = links.filter(link => link.TypeCode === DocumentLinkTypeCode.ProformaInvoiceDeduction);

        this._originalLinks = [...(proformaLinks ?? [])];
        this._selectedItems = getDocumentLinkBcs(rootStorage.oData, proformaLinks) ?? [];
    }

    presetDefaultFilters() {
        const { rootStorage, storage } = this.props;

        const pairedProformas = rootStorage.data.additionalResults[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX];
        const pairedProformaIds = pairedProformas?.map((item: IProformaEntity) => item.Id) ?? [];
        // empty array is considered as "not set", so we need to use "true" by default
        const pairedProformaIdQuery = pairedProformaIds.length > 0 ? transformToODataString(pairedProformaIds, ValueType.Number) : true;

        storage.store({
            predefinedFilters: {
                [NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH]: pairedProformaIdQuery
            }
        });
        storage.setFilterValueByPath(NOT_PAIRED_PROFORMAS_LOCAL_FILTER_PATH, pairedProformaIdQuery);

        // initiate default filters
        const { data } = rootStorage;

        if (!isNotSavedDocument(rootStorage) || rootStorage.isDirtyPath(DocumentEntity.TransactionCurrency)) {
            // user already selects some data in form -> we should match them:
            // -> prefilter proformas by currency
            storage.setFilterValueByPath(DocumentEntity.TransactionCurrency, [data.entity.TransactionCurrency?.Code]);
        }

        if (rootStorage.data.entity.BusinessPartner?.BusinessPartner?.Id) {
            // preselect business partner in filters
            storage.setFilterValueByPath(DocumentEntity.BusinessPartner, [data.entity.BusinessPartner?.Name]);
        }

        if (isAccountAssignmentCompany(this.props.rootStorage.context)) {
            setNotPostedStatusFilter(storage);
        }

        if (isCashBasisAccountingCompany(this.props.rootStorage.context)) {
            storage.setFilterValueByPath(DocumentStatusLocalPath, [
                createFilterRow({
                    type: ConditionType.Included,
                    value: [
                        getStatusFilterId(EntityTypeName.ClearedStatus, ClearedStatusCode.Cleared)
                    ]
                })
            ]);
        }

        storage.applyFilters();
    }

    isRowWithoutAction(rowId: TId, action: RowAction, row: IRow): boolean {
        const currentDocument = row.customData.entity as IDocumentEntity;
        const isNotPosted = currentDocument.PostedStatusCode === PostedStatusCode.NotPosted;

        return isNotPosted;
    }

    getToolbarButtons(): TableButtonsActionType[] {
        return [
            TableButtonsAction.Sorting,
            TableButtonsAction.Settings
        ];
    }

    handleToolbarConfirm = async () => {
        const { rootStorage } = this.props;
        const { tableAPI } = this.props.storage;
        const { entity } = rootStorage.data;

        const affectedRows = await tableAPI.getAffectedRows();

        // Validate currency that it's not mismatched and is same as document (if it won't be changed potentially)
        const tableState = tableAPI.getState();
        const rowsData = affectedRows.map(row => getRow(tableState.rows, row.bc));
        const mandatoryCurrency = isNotSavedDocument(this.props.rootStorage) ? rowsData?.[0].customData.entity.TransactionCurrencyCode
                : entity.TransactionCurrency?.Code;

        const rowWithInvalidCurrency = rowsData.find(rowData => rowData.customData.entity.TransactionCurrencyCode !== mandatoryCurrency);
        if (rowWithInvalidCurrency) {
            this.setAlert({
                status: Status.Error,
                subTitle: rootStorage.t("Proforma:Pairing.DifferentCurrencies")
            });
            this.forceUpdate();
            return;
        } else if (mandatoryCurrency !== entity.TransactionCurrency?.Code) {
            // set currency to document, refresh exchangeRate and recalculate amounts
            rootStorage.setValueByPath(DocumentEntity.TransactionCurrency, mandatoryCurrency);
            await refreshExchangeRate(rootStorage);
        }

        this.setState({
            rowsData
        }, () => {
            // wait for the rowsData to be set, consequent actions are dependent on it
            if (affectedRows.length > 0) {
                // ask user if he wants to copy data from proforma to invoice
                this.setState({ isConfirmDialogOpened: true });
            } else {
                // directly pair without copying as there are no rows - user may be just unpair some rows
                this.handlePairingWithoutDataCopy();
            }
        });
    };

    async pairProformaInvoices(withDataCopy: boolean) {
        const { rootStorage } = this.props;
        const { tableAPI } = this.props.storage;

        const affectedRows = await tableAPI.getAffectedRows();
        const { rowsData } = this.state;

        this.handleCancelDialog();
        this.props.setBusy(true);

        if (withDataCopy) {
            const links = (rootStorage.data.entity as IDocumentEntity).DocumentLinks;
            // User can decide to copy or not to copy the data. If so, we copy same data
            // to invoice header and always all line items
            const copyDataBcs = affectedRows
                .filter(row => !links?.find((link: IDocumentLinkEntity) => link.TargetDocument?.Id.toString() === row.bc.getKey().toString()))
                    .map(row => row.bc);
            await fetchAndApplyProformaInvoice(rootStorage, copyDataBcs);
        }

        const entity = rootStorage.data.entity as IDocumentEntity;
        const newLinks: IDocumentLinkEntity[] = [];

        // Keep all links, which are not proforma link type...
        (entity.DocumentLinks ?? []).forEach(link => {
            if (link.TypeCode !== DocumentLinkTypeCode.ProformaInvoiceDeduction) {
                newLinks.push(link);
            }
        });
        // ... adds selected proforma links
        rowsData.forEach(rowData => {
            newLinks.push(createProformaDocumentLink(rowData.id as BindingContext, rowData.customData.entity));
        });

        rootStorage.setDirty(rootStorage.data.bindingContext);
        entity.DocumentLinks = newLinks;

        // load related proformas
        const affectedRowsBc = affectedRows.map(affectedRow => affectedRow.bc);
        const res = await Promise.all([
            rootStorage.updateTabsVisibility([PROFORMA_FORM_TAB, DOCUMENT_CLEARING_FORM_TAB_ID], true),
            loadRelatedProformas(rootStorage.data.bindingContext, rootStorage.oData, affectedRowsBc)
        ]);
        rootStorage.data.additionalResults[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX] = res[1];
        rootStorage.refresh();

        this.prepareInitialData();

        this.props.setBusy(false);

        this.setAlert({
            status: Status.Success,
            subTitle: this.props.storage.t("Proforma:Pairing.SuccessSubtitle")
        });

        this.props.onConfirm?.();
    }

    handlePairingWithoutDataCopy = this.pairProformaInvoices.bind(this, false);
    handlePairingWithDataCopy = this.pairProformaInvoices.bind(this, true);

    handleCancelDialog = () => {
        this.setState({ isConfirmDialogOpened: false, rowsData: null });
    };

    renderConfirmationDialog(): React.ReactElement {
        return (
            <Dialog isConfirmation
                    onClose={this.handleCancelDialog}
                    onConfirm={this.handlePairingWithDataCopy}
                    footer={<>
                        <Button isTransparent
                                onClick={this.handleCancelDialog}>
                            {this.props.storage.t("Common:General.Cancel")}
                        </Button>
                        <Button isTransparent
                                onClick={this.handlePairingWithoutDataCopy}>
                            {this.props.storage.t("Proforma:Pairing.DontCopyButton")}
                        </Button>
                        <Button onClick={this.handlePairingWithDataCopy}>
                            {this.props.storage.t("Common:General.Confirm")}
                        </Button>
                    </>}>
                {this.props.storage.t("Proforma:Pairing.ConfirmCopyDataContent")}
            </Dialog>
        );
    }

    renderDefaultDialogs(): React.ReactElement {
        return (
                <>
                    {super.renderDefaultDialogs()}
                    {this.state.isConfirmDialogOpened && this.renderConfirmationDialog()}
                </>
        );
    }
}

export default withBusyIndicator({ passBusyIndicator: true })(PairingProformaTableView);
