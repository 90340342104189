import { THistoryLocation } from "@components/drillDown/DrillDown.utils";
import { IInputOnBlurEvent, ISharedInputProps } from "@components/inputs/input";
import { BasicSelectClean as BasicSelect } from "@components/inputs/select/BasicSelect";
import { IPropsBase } from "@components/inputs/select/SelectMenu";
import * as PopperJS from "@popperjs/core";
import React from "react";

import { TextAlign, ValueType } from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";

export interface IColumn {
    label?: string;
    textAlign?: TextAlign;
    id?: string;
}

export interface ISharedData {
    isLoading?: boolean;
    dontDisplayNoDataFound?: boolean;
    scrollDirection?: Direction;
    bottomMenuCustomContent?: React.ReactElement;
    highlightedIndex?: number;
    renderedItems?: ISelectItem[];
    searchTerm?: string;
    skipRangeSelection?: boolean;
}

export interface IContentBefore {
    title: string;
    component?: React.ReactNode;
    link?: React.ReactNode;
    zeroInputWidth: boolean;
}

export interface IBaseSelectionChangeArgs {
    additionalData?: TRecordAny;
    sharedData?: ISharedData;
    /** For some selection we don't want to fire additional stuff like loading dependent fields
     *  Usually it is the case when we iterate through the items by cursor.
     * */
    triggerAdditionalTasks?: boolean;
}

export type TOnItemsFetchedCallback = (items: ISelectItem[]) => void;

export interface ISelectionChangeArgs extends IBaseSelectionChangeArgs {
    value?: TSelectItemId;
}

export enum SelectAdditionalItems {
    SelectAll = "SelectAll",
    GlobalAll = "GlobalAll",
    SelectFiltered = "SelectFiltered"
}

export enum SelectGroups {
    Items = "Items",
    BeforeDefault = "BeforeDefault",
    Default = "Default",
    Action = "Action"
}

export enum GroupDividerSize {
    Tiny = "tiny",
    Small = "small",
    Large = "large"
}

export interface ISelectGroup {
    id: string;
    title?: string;
    hideDivider?: boolean;
    dividerSize?: GroupDividerSize;
}

export type TSelectItemId = string | number | boolean;

export interface ISelectItem<Type = TSelectItemId> {
    id: Type;
    label?: string;
    // support for segmented icon button tooltip
    title?: string;
    description?: string;
    iconName?: string;
    tabularData?: string[];
    /** Holds any other that could be needed by parent components in item selection handler.
     * because values in tabular data doesn't have key and are irretrievable because of that. */
    additionalData?: TRecordAny;
    groupId?: string;
    /** Custom content rendered before the item in the select menu.
     * itemContent callback of BasicSelect can be used instead, for dynamic rendering.*/
    itemContent?: React.ReactNode;

    // hierarchy items
    indent?: number;
    parent?: ISelectItem<Type>;
    children?: ISelectItem<Type>[];
    index?: number;
    isWithoutCheckbox?: boolean;

    /** If given, selected value is rendered as clickable link */
    link?: THistoryLocation;

    /** Force selected state (underline) of the item,
     * even though the Select itself does not have the item set as its value.
     * Needed for IconSelect used in Toolbar, where we need to show multiple underlined items.*/
    isSelected?: boolean;
    isDisabled?: boolean;
    isNotFilterable?: boolean;
    isSearchable?: boolean;
    // item that can't be highlighted, f.e. no data found for filter selects
    isNotHighlightable?: boolean;
    color?: string;
}

export enum Direction {
    Center = "center",
    Up = "up",
    Down = "down",
    None = "none"
}

export interface IGetFilteredItemsReturn {
    items?: ISelectItem[];
    index?: number;
}

// inputs so far not supported, hence no change event
export interface IFieldComponentProps {
    onClick?: (e: React.MouseEvent) => void;
    value?: TValue;
    onBlur: (args: any) => void; // todo: InputOnBlurEvent | React.FocusEvent ?
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onWheel?: (event: React.WheelEvent<HTMLElement>) => void;
    onIconClick?: (e: React.MouseEvent) => void;
    isOpen?: boolean;
    isDisabled?: boolean;
    openerRef: React.Ref<HTMLElement>;
    trailingTextWithoutFocus?: string; // to show text on blurred multiselect with tokens
}

export interface IMenuOffset {
    left: number;
    top: number;
}

export interface ISelectPropsBase extends ISharedInputProps, IPropsBase {
    /** Indicates whether close menu when user selects item */
    closeOnSelection?: boolean;
    displayName?: string;

    /** Should selection be opened when user clicks input? */
    openOnClick?: boolean;

    additionalData?: TRecordAny;

    /** Input is read only */
    inputIsReadOnly?: boolean;
    dontDisplayNoDataFound?: boolean;

    isIconSelect?: boolean;
    // directly selects currently highlighted item without any confirmation. If false, user needs to press Enter
    //  or select the item by mouse click. Reverts select to last value on Esc or leaving it without confirmation.
    useAutoSelection?: boolean;

    // automatically fulfills the rest of the item if it there is any match
    useTypingForward?: boolean;

    // props passed directly to the input
    inputProps?: TRecordAny;
    inputIcon?: React.ReactElement;
    isIconWithoutAction?: boolean;

    isMultiSelect?: boolean;
    contentBefore?: React.ReactElement;

    /** If this value is set, this value is displayed for no selections*/
    noRecordText?: string;

    // items that are statically defined (usually in def) and are appended to the rest of the items
    additionalItems?: ISelectItem[];

    // flag for handling errors for not matching items
    allowCreate?: boolean;

    onOpen?: () => void;
    onChange?: (args: ISelectionChangeArgs) => void;

    onBlur?: (e: IInputOnBlurEvent) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onWheel?: (event: React.WheelEvent<HTMLElement>) => void;
    onIconActivate?: (e: React.MouseEvent) => void;
    // Select isOpen is only stored in state of select and is not exposed via props,
    // but sometimes we still need to be notified about the change
    onIsOpenChange?: (isOpen: boolean) => void;
    // onClick callback - if default is prevented, select does not open menu
    onClick?: (e: React.MouseEvent) => void;

    ref?: React.Ref<BasicSelect>;

    // custom field component
    fieldComponent?: (args: IFieldComponentProps) => React.ReactElement;
    className?: string;
    style?: React.CSSProperties;
    menuOffset?: IMenuOffset;

    openerRef?: React.Ref<HTMLElement>;
    buttonRef?: React.Ref<HTMLButtonElement>;
    showSearchBoxInMenu?: boolean;
    showTabularHeader?: boolean;
    searchType?: ValueType;

    placement?: PopperJS.Placement;
    displayArrow?: boolean;

    shouldDisplayAdditionalColumns?: boolean;
    customTabularData?: string[];

    inputRef?: React.Ref<HTMLInputElement>;
    passRef?: React.Ref<HTMLDivElement>;
    // will not close select on blur event, used mainly for story book
    preventHideOnBlur?: boolean;
    renderDefaultGroupWithoutCheckboxes?: boolean;
}

export enum MenuLineItem {
    None = "none",
    Bottom = "bottom",
    Deep = "Deep"
}