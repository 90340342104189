import { getFocusableElements } from "@utils/general";
import { debounce } from "lodash";
import React, { FocusEvent } from "react";
import { ReferenceChildrenProps } from "react-popper";

import { TRecordAny } from "../../../global.types";
import { WithHideOnBlur, withHideOnBlur } from "../../../HOC/withHideOnBlur";
import { KeyName } from "../../../keyName";
import { IValidationError } from "../../../model/Validator.types";
import DateType from "../../../types/Date";
import Popover from "../../popover/Popover";
import { POPOVER_INPUT_OFFSET_X, POPOVER_INPUT_SHARP_LEFT_OFFSET_X } from "../../popover/Popover.utils";
import {
    getSharedInputProps,
    IInputOnBlurEvent,
    IInputOnChangeEvent,
    IInputProps,
    InputComponentType,
    ISharedInputProps
} from "../input/Input";

export interface ICustomReferenceElement {
    ref: React.Ref<any>;
    onClick: (e: React.MouseEvent) => void;
    onKeyDown: (args: any) => void;
}

interface IProps extends ISharedInputProps, WithHideOnBlur {
    value: Date;
    popupProps: TRecordAny;
    onChange: (event: IInputOnChangeEvent<Date>) => void;
    pickerPopup: React.ComponentType<any>;
    inputComponent: React.ComponentType<IInputProps>;
    inputProps: TRecordAny;
    // use this callback render something else other than Input,
    // if used, set inputComponent and inputProps as null
    customReferenceElement?: (args: ICustomReferenceElement) => React.ReactNode;
    popoverOffsetX?: number;
    popoverOffsetY?: number;
    icon: React.ReactElement;
    content?: React.ReactElement;
    onBlur: (args: IInputOnBlurEvent) => void;
    onFocus: (e: FocusEvent<HTMLInputElement>) => void;
}

class DatePickerBase extends React.Component<IProps> {
    _inputRef = React.createRef<HTMLInputElement>();
    _inputComponentRef = React.createRef<InputComponentType>();
    _popupRef = React.createRef<HTMLDivElement>();
    _disabledAutoFocus: boolean;
    _isBlurringToPopup: boolean;

    componentDidMount() {
        this.props.setHideOnBlurOptions([this._popupRef, this._inputComponentRef?.current?._wrapperRef]);
    }

    handleIconClick = (e: React.MouseEvent) => {
        if (!this.props.isVisible && this._inputRef.current !== document.activeElement) {
            // if opened by icon click, move focus into the input,
            // otherwise, withHideOnClick would allow you to open multiple date pickers by clicking on their icon,
            // because there wouldn't be any focus change and thus no blur event
            this._inputRef.current?.focus();
        }

        this.setOpen(!this.props.isVisible);
        e.preventDefault();
        e.stopPropagation();
    };

    handleClick = () => {
        this.setOpen(true);
    };

    setOpen = (isOpen: boolean, byKeyboard = false) => {
        this._isBlurringToPopup = false;

        if (this.props.isVisible === isOpen) {
            // in some cases, focus is still inside input (error is not shown on input) AND the popup is closed (via keyboard or mouse)
            // calling setIsVisible doesn't cause rerender
            // => we need to rerender DatePickerBase so that this.getError returns value and error gets visible again on the input
            //
            this.forceUpdate();
        } else {
            this.props.setIsVisible(isOpen);
        }
        this._disabledAutoFocus = !byKeyboard;
    };

    handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === KeyName.Tab && this.props.isVisible) {
            // focus calendar to pick a date
            this.focusOnPopupContent();
            event.preventDefault();
            return;
        }

        const inputValue = (event.target as HTMLInputElement)?.value;
        const selectedText = window.getSelection().toString();
        const isFirstLetter = !inputValue || inputValue === selectedText || (!selectedText && this._inputRef.current.selectionStart === 0);
        // only open date picker with Space, not with Enter https://solitea-cz.atlassian.net/browse/DEV-15213
        if ((isFirstLetter && event.key === KeyName.Space)) {
            event.stopPropagation();
            event.preventDefault(); // otherwise, somehow fires click on DatePickerPopup's YearMonth button
            this.setOpen(!this.props.isVisible, true);

            return;
        }
        this.setOpen(false);

        this.handleSharedKeyDown(event);
    };

    handlePickerKeyDown = (e: React.KeyboardEvent) => {
        if (!this.props.isContained(document.activeElement as HTMLElement)) {
            // DatePicker may loose focus on keyDown (Tab), close the popup then
            this.setOpen(false);

            return;
        }
        this.handleSharedKeyDown(e);
    };

    handleSharedKeyDown(e: React.KeyboardEvent) {
        if (e.key === KeyName.Escape) {
            this.setOpen(false);
            this.focusOnInput();
            e.preventDefault();
            e.stopPropagation();
        }
    }

    handlePickerChange = (value: any) => {
        // close and set focus to the input
        this.setOpen(false);
        // !! Keep immediate focus before change so _wasChangedSinceBlur is handled correctly and validations are triggered. !!
        this.focusOnInput();

        this.props.onChange({
            value,
            triggerAdditionalTasks: true
        });
    };

    handleChange = (event: IInputOnChangeEvent<Date>) => {
        // Input triggers change event with triggerAdditionalTasks === false when user types and may trigger
        // debounced change event if configured.
        // We don't want to propagate the debounced change event if the data are not valid
        const isValid = DateType.isValid(event.value);
        if (!event.triggerAdditionalTasks || isValid) {
            event.triggerAdditionalTasks = isValid;
            this.props.onChange(event);
        }
    };

    focusOnInput = () => {
        this._inputRef.current?.focus();
        this.selectOnInput();
    };

    selectOnInput = debounce(() => {
        // select text, so that user can open popup again with Space key, without change cursor position or selecting text manually
        this._inputRef.current?.select();
    });

    focusOnPopupContent = () => {
        const focusable = getFocusableElements(this._popupRef.current);
        (focusable?.[0] as HTMLElement)?.focus();
    };

    handleBlur = (e: IInputOnBlurEvent) => {
        this.props.onBlur?.(e);
        const elem = e.origEvent.relatedTarget as HTMLElement;
        this._isBlurringToPopup = this.props.isContained(elem);
        if (!elem || !this._isBlurringToPopup) {
            this.setOpen(false);
        }
    };

    handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        this.props.onFocus?.(e);
    };

    getError = (): IValidationError => {
        if (this._isBlurringToPopup || this.props.isContained(document.activeElement as HTMLElement)) {
            this._isBlurringToPopup = false;
            return null;
        }
        return this.props.error;
    };

    renderInput = ({ ref }: ReferenceChildrenProps) => {
        const InputComponent = this.props.inputComponent;
        return (
            <InputComponent {...getSharedInputProps(this.props)}
                            {...this.props.inputProps}
                            value={this.props.value}
                            ref={this._inputComponentRef}
                            outerRef={ref}
                            passRef={this._inputRef}
                            isActive={this.props.isVisible}
                            error={this.getError()}
                            onChange={this.handleChange}
                            onKeyDown={this.handleInputKeyDown}
                            onBlur={this.handleBlur}
                            onFocus={this.handleFocus}
                            onClick={this.handleClick}
                            onIconClick={this.handleIconClick}
                            icon={this.props.icon}
                            content={this.props.content}
                            selectOnFocus
            />
        );
    };

    renderCustomReference = ({ ref }: ReferenceChildrenProps) => {
        return this.props.customReferenceElement({
            ref,
            onClick: this.handleIconClick,
            onKeyDown: this.handleInputKeyDown
        });
    };

    render = () => {
        const PickerPopup = this.props.pickerPopup;

        return (
            <Popover
                reference={!this.props.inputComponent && this.props.customReferenceElement ? this.renderCustomReference : this.renderInput}
                isOpen={this.props.isVisible}
                passRef={this._popupRef}
                onKeyDown={this.handlePickerKeyDown}
                offsetX={this.props.popoverOffsetX ?? (this.props.isSharpLeft ? POPOVER_INPUT_SHARP_LEFT_OFFSET_X : POPOVER_INPUT_OFFSET_X)}
                offsetY={this.props.popoverOffsetY}>
                <PickerPopup onChange={this.handlePickerChange}
                             disableAutoFocus={this._disabledAutoFocus}
                             {...this.props.popupProps}/>
            </Popover>
        );
    };
}

export default withHideOnBlur(DatePickerBase);