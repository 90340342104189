import React, { useCallback, useState } from "react";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import { useTranslation } from "react-i18next";
import Field from "../../components/inputs/field";
import { Select } from "@components/inputs/select";
import Dialog from "../../components/dialog";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import dayjs from "dayjs";
import memoize from "../../utils/memoize";
import { getUtcDayjs } from "../../types/Date";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";

const getInitialYearSelectItems = memoize((): ISelectItem[] => {
    const thisYear = getUtcDayjs().get("year");
    const items: ISelectItem[] = [];

    for (let i = thisYear - 5; i < thisYear + 2; i++) {
        items.push({
            id: i,
            label: i.toString()
        });
    }

    return items;
});

export const InitialYearDialog: React.FunctionComponent<{
    initialYear: number;
    onConfirm: (initialYear: number) => void;
    onCancel: () => void;
}> = React.memo((props) => {
    const { initialYear, onConfirm } = props;
    const { t } = useTranslation(["Companies", "Common"]);
    const [year, setYear] = useState<number>(initialYear ?? dayjs().get("year"));
    const handleYearChange = useCallback((e: ISelectionChangeArgs) => {
        setYear(e.value as number);
    }, []);

    const handleConfirm = useCallback(() => {
        onConfirm(year);
    }, [onConfirm, year]);

    return (
            <Dialog title={t("Companies:Wizard.SetInitialYear")}
                    onConfirm={handleConfirm}
                    onClose={props.onCancel}
                    footer={<ConfirmationButtons onConfirm={handleConfirm}
                                                 onCancel={props.onCancel}
                                                 useWrapper={false}/>}
            >
                <FieldsWrapper>
                    <FieldsWrapper>
                        <Field label={t("Companies:Wizard.InitialYear")}
                               isRequired>
                            <Select
                                    onChange={handleYearChange}
                                    value={year}
                                    items={getInitialYearSelectItems()}
                            />
                        </Field>
                    </FieldsWrapper>
                </FieldsWrapper>
            </Dialog>
    );
});