import "./fonts.css";
import "simplebar/dist/simplebar.min.css";

import BusyIndicator from "@components/busyIndicator";
import { getRouteByEntityType } from "@odata/EntityTypes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, FeatureCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import React, { Component, lazy, Suspense } from "react";
import { RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ThemeProvider } from "styled-components/macro";

import administrationBundle from "./bundles/AdministrationBundle";
import agendaSettingsBundle from "./bundles/AgendaSettingsBundle";
import assetBundle from "./bundles/AssetBundle";
import auditBundle from "./bundles/AuditBundle";
import bankBundle from "./bundles/BankBundle";
import documentsBundle from "./bundles/DocumentsBundle";
import inboxBundle from "./bundles/InboxBundle";
import payrollBundle from "./bundles/PayrollBundle";
import reportsBundle from "./bundles/ReportsBundle";
import PageTitleSetter from "./components/breadCrumb/PageTitleSetter";
import ConfirmationDialogProvider from "./components/dialog/ConfirmationDialogProvider";
import { HotspotsProvider } from "./components/hotspots/HotspotsContext";
import MobileWarningDialog from "./components/mobileWarningDialog/MobileWarningDialog";
import PageLoaderProvider from "./components/pageLoader/pageLoaderContext";
import { AppContextProvider } from "./contexts/appContext/AppContext";
import { AppContext } from "./contexts/appContext/AppContext.types";
import AuthContextProvider from "./contexts/authContext/AuthContext";
import BackgroundJobsProvider from "./contexts/backgroundJobsContext/BackgroundJobsContext";
import { DomManipulatorProvider } from "./contexts/domManipulator/DomManipulatorProvider";
import PermissionContextProvider from "./contexts/permissionContext/PermissionContext";
import BackgroundJobsHistory from "./devtools/backgroundJobsHistory/BackgroundJobsHistory";
import { GlobalStyle } from "./global.style";
import { NotificationsProvider } from "./notifications/NotificationsContext";
import NotificationsRedirect from "./notifications/NotificationsRedirect";
import ODataProvider from "./odata/ODataProvider";
import AccountAssignment from "./pages/accountAssignment/AccountAssignment";
import BusinessPartner from "./pages/businessPartner/BusinessPartner";
import ChartOfAccounts from "./pages/chartOfAccounts";
import ChartOfAccountsTemplates from "./pages/chartOfAccountsTemplates";
import CompanyDashboard from "./pages/companies/CompanyDashboard";
import InitialAccountBalances from "./pages/companies/InitialAccountsBalance";
import ConnectIDokladScreen from "./pages/connectIDokladScreen/ConnectIDokladScreen";
import CustomerPortalPayment from "./pages/customerPortalPayment/CustomerPortalPayment";
import ElectronicSubmission from "./pages/electronicSubmission/ElectronicSubmission";
import ElectronicSubmissionAuditTrail from "./pages/electronicSubmission/ElectronicSubmissionAuditTrail";
import GoodbyeScreen from "./pages/goodbyeScreen/GoodbyeScreen";
import Home from "./pages/home";
import AgendaWorkOverview from "./pages/home/linkedPages/AgendaWorkOverview";
import FiscalYearCloseOverview from "./pages/home/linkedPages/FiscalYearCloseOverview";
import JournalEntriesLastMonth from "./pages/home/linkedPages/JournalEntriesLastMonth";
import PostedDocumentsLastMonth from "./pages/home/linkedPages/PostedDocumentsLastMonth";
import SwitchToCustomerPortal from "./pages/home/SwitchToCustomerPortal";
import Tickets from "./pages/tickets/Tickets";
import WebInvoice from "./pages/webInvoice/WebInvoice";
import {
    AUDIT_TRAIL,
    ROUTE_ACCOUNT_ASSIGNMENT,
    ROUTE_AGENDA_WORK_OVERVIEW,
    ROUTE_BACKGROUNDJOBS_HISTORY,
    ROUTE_BUSINESS_PARTNER,
    ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES,
    ROUTE_COMPANIES,
    ROUTE_CONNECT_WITH_IDOKLAD,
    ROUTE_CUSTOMER_PORTAL_PAYMENT,
    ROUTE_DATA_EXPORT,
    ROUTE_DATA_IMPORT,
    ROUTE_DEVTOOLS,
    ROUTE_ELECTRONIC_SUBMISSION,
    ROUTE_FISCAL_YEAR_CLOSE,
    ROUTE_FISCAL_YEAR_CLOSE_OVERVIEW,
    ROUTE_GOODBYE_SCREEN,
    ROUTE_HOME,
    ROUTE_INITIAL_ACCOUNT_BALANCES,
    ROUTE_JOURNAL_ENTRIES_LAST_MONTH,
    ROUTE_MENU,
    ROUTE_NOTIFICATION_REDIRECT,
    ROUTE_ORG_MENU,
    ROUTE_POSTED_DOCUMENTS_LAST_MONTH,
    ROUTE_SWITCH_TO_CUSTOMER_PORTAL,
    ROUTE_TICKETS,
    ROUTE_USER_SETTING,
    ROUTE_WEBINVOICE,
    ROUTE_WELCOME_SCREEN
} from "./routes";
import FormRoute from "./routes/FormRoute";
import { NotFoundRoute } from "./routes/NotFoundRoute";
import PrivateRoute from "./routes/PrivateRoute";
import { themes } from "./theme";
import Main from "./views/main";
import SmartNavDashboard from "./views/main/SmartNavDashboard";
import SubscriptionChecker from "./views/main/SubscriptionChecker";

const UserSetting = lazy(() => import("./pages/userSetting/UserSetting"));
const FiscalYearClose = lazy(() => import("./pages/fiscalYearClose/FiscalYearClose"));
const Router = lazy(() => import("./devtools/Router"));
const DataImport = lazy(() => import("./pages/dataImport/DataImport"));
const DataExport = lazy(() => import("./pages/dataExport/DataExport"));
const WelcomeScreen = lazy(() => import("./pages/welcomeScreen"));


interface IProps extends RouteComponentProps {
}

interface IState {
    oData?: OData;
    companyPermissions?: Record<string, Set<CompanyPermissionCode>>;
    generalPermissions?: Set<GeneralPermissionCode>;
}

class App extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            oData: null,
            companyPermissions: null,
            generalPermissions: null
        };
    }

    onODataLoaded = (oData: OData): void => {
        this.setState({ oData });
    };

    onPermissionsLoaded = (companyPermissions: Record<string, Set<CompanyPermissionCode>>, generalPermissions: Set<GeneralPermissionCode>): void => {
        this.setState({ companyPermissions, generalPermissions });
    };

    render() {
        const isMenu = this.props.location.pathname.includes(ROUTE_MENU);
        const key = isMenu ? "menu-anim-key" : "general-anim-key";

        return (
                <PageLoaderProvider>
                    <AuthContextProvider>
                        {/*don't change order of ContextProvider and ODataProvider!
                    ContextProviders needs to be first, to fetch current session and/or automatically login*/}
                        <AppContextProvider oData={this.state.oData} companyPermissions={this.state.companyPermissions}
                                            generalPermissions={this.state.generalPermissions}>
                            <ODataProvider onODataLoaded={this.onODataLoaded}>
                                <PermissionContextProvider onPermissionsLoaded={this.onPermissionsLoaded}>
                                    <DomManipulatorProvider>
                                        <AppContext.Consumer>
                                            {context => (
                                                    <ThemeProvider
                                                            theme={themes[context.getCurrentTheme() ?? "light"]}>
                                                        <GlobalStyle/>
                                                        <PageTitleSetter/>
                                                        <SubscriptionChecker/>
                                                        <MobileWarningDialog/>
                                                        <HotspotsProvider>
                                                            <NotificationsProvider>
                                                                <ConfirmationDialogProvider>
                                                                        <Switch>
                                                                            <PrivateRoute
                                                                                    path={`${ROUTE_WEBINVOICE}/:id`}
                                                                                    permission={null}
                                                                                    component={WebInvoice}/>
                                                                            <PrivateRoute
                                                                                    path={ROUTE_CONNECT_WITH_IDOKLAD}
                                                                                    permission={null}
                                                                                    component={ConnectIDokladScreen}/>
                                                                            <PrivateRoute
                                                                                    path={`${ROUTE_SWITCH_TO_CUSTOMER_PORTAL}/:id`}
                                                                                    permission={null}
                                                                                    component={SwitchToCustomerPortal}/>
                                                                            <PrivateRoute
                                                                                    path={`${ROUTE_NOTIFICATION_REDIRECT}/:id`}
                                                                                    permission={null}
                                                                                    component={NotificationsRedirect}/>
                                                                            <Main>
                                                                                <BackgroundJobsProvider>
                                                                                    <TransitionGroup
                                                                                            transitionname="menu-slide">
                                                                                        <CSSTransition key={key}
                                                                                                       classNames="menu-slide"
                                                                                                       timeout={500}>
                                                                                            <Suspense fallback={<BusyIndicator/>}>
                                                                                                <Switch
                                                                                                        location={this.props.location}>
                                                                                                    <PrivateRoute exact
                                                                                                                  permission={null}
                                                                                                                  path={ROUTE_HOME}
                                                                                                                  component={Home}/>
                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_MENU}
                                                                                                            permission={null}
                                                                                                            component={SmartNavDashboard}/>

                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_ORG_MENU}
                                                                                                            permission={null}
                                                                                                            component={SmartNavDashboard}/>


                                                                                                    <FormRoute
                                                                                                        path={getRouteByEntityType(EntityTypeName.Account)}
                                                                                                            entityType={EntityTypeName.Account}
                                                                                                            permission={CompanyPermissionCode.CustomerSettings}
                                                                                                            childCollection="Accounts"
                                                                                                            page={ChartOfAccounts}/>

                                                                                                    <FormRoute
                                                                                                            path={ROUTE_COMPANIES}
                                                                                                            entityType={EntityTypeName.Company}
                                                                                                            permission={null}
                                                                                                            page={CompanyDashboard}/>

                                                                                                    <FormRoute
                                                                                                            path={ROUTE_BACKGROUNDJOBS_HISTORY}
                                                                                                            entityType={EntityTypeName.BackgroundJob}
                                                                                                            permission={null}
                                                                                                            page={BackgroundJobsHistory}/>
                                                                                                    <FormRoute
                                                                                                            path={ROUTE_BUSINESS_PARTNER}
                                                                                                            permission={CompanyPermissionCode.CustomerSettings}
                                                                                                            entityType={EntityTypeName.BusinessPartner}
                                                                                                            page={BusinessPartner}/>

                                                                                                    <FormRoute
                                                                                                            path={ROUTE_CUSTOMER_PORTAL_PAYMENT}
                                                                                                            entityType={EntityTypeName.CustomerPortalPayment}
                                                                                                            permission={null}
                                                                                                            page={CustomerPortalPayment}/>

                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_GOODBYE_SCREEN}
                                                                                                            permission={null}
                                                                                                            component={GoodbyeScreen}/>

                                                                                                    <PrivateRoute exact
                                                                                                                  path={ROUTE_ELECTRONIC_SUBMISSION}
                                                                                                                  permission={CompanyPermissionCode.VatStatement}
                                                                                                                  component={ElectronicSubmission}/>
                                                                                                    {/*Todo: permission for audit trail and also page itself */}
                                                                                                    <PrivateRoute
                                                                                                            path={`${ROUTE_ELECTRONIC_SUBMISSION}/${AUDIT_TRAIL}`}
                                                                                                            permission={CompanyPermissionCode.Audit}
                                                                                                            component={ElectronicSubmissionAuditTrail}/>
                                                                                                    <PrivateRoute
                                                                                                            path={`${ROUTE_ELECTRONIC_SUBMISSION}/:year/:type?/:month?`}
                                                                                                            permission={null}
                                                                                                            component={ElectronicSubmission}/>

                                                                                                    <FormRoute
                                                                                                            path={ROUTE_TICKETS}
                                                                                                            entityType={EntityTypeName.Ticket}
                                                                                                            permission={null}
                                                                                                            page={Tickets}/>

                                                                                                    <FormRoute
                                                                                                            path={ROUTE_ACCOUNT_ASSIGNMENT}
                                                                                                            entityType={EntityTypeName.AccountAssignment}
                                                                                                            permission={GeneralPermissionCode.CommonSettingsManagement}
                                                                                                            page={AccountAssignment}/>
                                                                                                    <FormRoute
                                                                                                            path={`${ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES}`}
                                                                                                            childCollection="Accounts"
                                                                                                            permission={GeneralPermissionCode.CommonSettingsManagement}
                                                                                                            page={ChartOfAccountsTemplates}
                                                                                                            entityType={EntityTypeName.AccountTemplate}/>

                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_POSTED_DOCUMENTS_LAST_MONTH}
                                                                                                            permission={null}
                                                                                                            component={PostedDocumentsLastMonth}/>
                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_JOURNAL_ENTRIES_LAST_MONTH}
                                                                                                            permission={null}
                                                                                                            component={JournalEntriesLastMonth}/>
                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_AGENDA_WORK_OVERVIEW}
                                                                                                            permission={null}
                                                                                                            component={AgendaWorkOverview}/>
                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_FISCAL_YEAR_CLOSE_OVERVIEW}
                                                                                                            permission={null}
                                                                                                            component={FiscalYearCloseOverview}/>
                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_INITIAL_ACCOUNT_BALANCES}
                                                                                                            permission={null}
                                                                                                            component={InitialAccountBalances}/>

                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_WELCOME_SCREEN}
                                                                                                            permission={null}
                                                                                                            component={WelcomeScreen}/>
                                                                                                    <PrivateRoute
                                                                                                            path={`${ROUTE_DATA_IMPORT}/:system?`}
                                                                                                            permission={CompanyPermissionCode.CanImport}
                                                                                                            component={DataImport}/>
                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_DATA_EXPORT}
                                                                                                            permission={GeneralPermissionCode.CanExportDataFromTheSystem}
                                                                                                            component={DataExport}/>

                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_USER_SETTING}
                                                                                                            permission={null}
                                                                                                            component={UserSetting}/>

                                                                                                    <PrivateRoute
                                                                                                            path={`${ROUTE_FISCAL_YEAR_CLOSE}/:fiscalYear?/:task?`}
                                                                                                            permission={CompanyPermissionCode.FiscalYearClose}
                                                                                                            component={FiscalYearClose}/>

                                                                                                    {!context.isFeatureSwitchEnabled(FeatureCode.Payroll) ? null : payrollBundle()}

                                                                                                    {documentsBundle()}
                                                                                                    {inboxBundle()}
                                                                                                    {assetBundle()}
                                                                                                    {reportsBundle()}
                                                                                                    {bankBundle()}
                                                                                                    {administrationBundle()}
                                                                                                    {agendaSettingsBundle()}
                                                                                                    {auditBundle()}

                                                                                                    <PrivateRoute
                                                                                                            path={ROUTE_DEVTOOLS}
                                                                                                            permission={null}
                                                                                                            component={Router}/>

                                                                                                    <NotFoundRoute/>
                                                                                                </Switch>
                                                                                            </Suspense>
                                                                                        </CSSTransition>
                                                                                    </TransitionGroup>
                                                                                </BackgroundJobsProvider>
                                                                            </Main>
                                                                        </Switch>
                                                                </ConfirmationDialogProvider>
                                                            </NotificationsProvider>
                                                        </HotspotsProvider>
                                                    </ThemeProvider>
                                            )}
                                        </AppContext.Consumer>
                                    </DomManipulatorProvider>
                                </PermissionContextProvider>
                            </ODataProvider>
                        </AppContextProvider>
                    </AuthContextProvider>
                </PageLoaderProvider>
        );
    }
}

export default withRouter(App);

