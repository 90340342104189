import { getInfoValue } from "@components/smart/FieldInfo";
import { ISmartODataTableAPI } from "@components/smart/smartTable/SmartODataTableBase";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { IPaneBookmark, SplitLayout } from "@components/splitLayout";
import { IRowProps } from "@components/table/Rows";
import { WithOData, withOData } from "@odata/withOData";
import { cloneDeep } from "lodash";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import DraftTimestamp from "../components/draftTimeStamp";
import { Pane } from "../components/splitLayout/Pane";
import UnsavedChangesPrompt from "../components/unsavedChangesPrompt/UnsavedChangesPrompt";
import { NEW_ITEM_DETAIL } from "../constants";
import { AppContext, ContextEvents, IAppContext } from "../contexts/appContext/AppContext.types";
import { DomManipulatorProvider } from "../contexts/domManipulator/DomManipulatorProvider";
import { PageViewMode, PaneStatus, QueryParam, TableAddingRowType, TableViewMode } from "../enums";
import { TRecordAny, TRecordString } from "../global.types";
import { IAfterSaveEventArgs, ModelEvent } from "../model/Model";
import { StorageModel } from "../model/StorageModel";
import { TableStorage } from "../model/TableStorage";
import BindingContext, {
    areBindingContextsDifferent,
    createBindingContext,
    IEntity,
    TEntityKey
} from "../odata/BindingContext";
import { getQueryParameters, removeQueryParam } from "../routes/Routes.utils";
import { PropsWithTheme } from "../theme";
import memoizeOne from "../utils/memoizeOne";
import FileView from "../views/fileView/FileView";
import { getFiles } from "../views/fileView/FileViewUtils";
import { IFormDef, IProps as IFormProps } from "../views/formView/Form";
import { FormStorage } from "../views/formView/FormStorage";
import { ITableRefreshNeeded } from "../views/formView/FormView";
import { ITableViewBaseProps } from "../views/table/TableView";
import { ISplitPageTableDef } from "../views/table/TableView.utils";
import { ISplitPagePaneData } from "./Page";
import { DRAFT_BC_PREFIX, getCleanDraftKey, getPageViewMode, IDefinition } from "./PageUtils";

export type TRenderTableArgs = Omit<ITableViewBaseProps, "storage"> & { storage: TableStorage };

export interface ISplitPageProps<Definition> {
    t?: any;

    rootStorage?: StorageModel;
    renderTable: (args: TRenderTableArgs) => React.ReactElement;
    onTableStorageLoad?: (args?: any) => void;
    onFormStorageCreated?: (storage: FormStorage) => void;
    customTableStorage?: typeof TableStorage;
    hideBreadcrumbs?: boolean;
    formProps?: Partial<IFormProps>;
    tableViewProps?: any;
    pageViewMode?: PageViewMode;

    customData?: TRecordAny;
    hasPreview?: boolean;
    isInDialog?: boolean;
    // flag for child-parent split pages. Indicates table displays both parent and child and both are editable -
    // f.e. labels. In contraty ChartsOfAccounts are parent-child split page where parent is not editable
    isParentEditable?: boolean;

    // Indicator for parent-child split pages. Indicates the name of collection in parent entity type.
    childCollection?: string;

    masterData: ISplitPagePaneData;
    detailData: ISplitPagePaneData;

    parents?: IEntity[];
    parentEntity?: IEntity;

    usePrompt?: boolean;

    isSecondaryBookmarkActive?: boolean;

    onRowSelect?: (bc: BindingContext, search?: TRecordString) => void;
    onCloseDetail?: () => void;
    onAddDetail?: (type?: string) => void;
    onAddDetailSibling?: (bc: BindingContext) => void;
    onAfterSave?: (bc: BindingContext) => void;
    onChangeParent?: (key: TEntityKey) => void;
}

interface IProps<Definition> extends ISplitPageProps<Definition>, RouteComponentProps, WithOData, PropsWithTheme {

}

interface IState {
    paneStatus: PaneStatus[];
    highlightLastPane: boolean;
}

const defaultPaneStatus = [PaneStatus.Normal, PaneStatus.Normal, PaneStatus.Collapsed];
const viewFormOnlyPaneStatus = [PaneStatus.Collapsed, PaneStatus.Expanded, PaneStatus.Collapsed];

function isValidPaneStatus(status: string[]): boolean {
    if (status.length !== 3) {
        return false;
    }

    for (const char of status) {
        if (!Object.values(PaneStatus).includes(char as PaneStatus)) {
            return false;
        }
    }
    return true;
}

class SplitPageBase<Definition extends IDefinition> extends React.Component<IProps<Definition>, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    formStorage: FormStorage;
    tableStorage: TableStorage;

    // custom data assigned to form storage when row changed
    customData: TRecordAny;

    // helper variable to be used, to hold value for storage that does not yet exist
    _storageShouldBeDisabled: boolean;
    _formRef = React.createRef<any>();
    _tableRef = React.createRef<any>();
    _fileViewRef = React.createRef<any>();
    _draftTimeStampRef = React.createRef<any>();

    _formPaneRef = React.createRef<any>();
    _lastFormBusyState: boolean;

    getTableViewContext = memoizeOne(() => {
        if (!this.props.masterData.entitySet) {
            return undefined;
        }

        const entitySet = getInfoValue(this.props.masterData, "entitySet", {
            context: this.context
        });

        return createBindingContext(entitySet, this.props.oData.getMetadata());
    }, () => [this.props.masterData.entitySet]);

    private _lastDraftId: string;

    constructor(props: IProps<Definition>, context: IAppContext) {
        super(props);
        const TableStorageClass = this.props.customTableStorage ?? TableStorage;

        this.tableStorage = new TableStorageClass({
            ...this.getStorageProps(context),
            id: this.props.masterData.def.id,
            definition: this.props.masterData.def,
            refresh: async (refreshPage?: boolean) => {
                if (refreshPage) {
                    this.forceUpdate();
                } else {
                    if (this._tableRef.current?.beforeRefresh) {
                        await this._tableRef.current.beforeRefresh();
                    }
                    this._tableRef.current?.forceUpdate();
                }
            },
            // copy state in constructor instead of using it directly in the rest of the app
            // because browser history state is changed on any navigation
            initialHistoryState: props.history.location.state
        });

        this.state = {
            paneStatus: this.isTableVisible() ? defaultPaneStatus : viewFormOnlyPaneStatus,
            highlightLastPane: false
        };
    }

    componentDidMount(): void {
        this.initialLoad();
    }

    async componentDidUpdate(prevProps: Readonly<IProps<Definition>>, prevState: Readonly<IState>, snapshot?: any) {
        // the goal is to trigger the least refreshes as possible
        // !!!!!!! IMPORTANT !!!!!!!!!
        // NO FORM REFRESH OR UPDATE HERE
        // the ONLY ONE PLACE of refreshing the form is onAfterLoad in formView
        // the only exception is when chaining entity type, so we need to switch forms (or first load of course)
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!

        if (this.props.location.key !== prevProps.location.key
            && this.props.location.pathname === prevProps.location.pathname
            && this.props.location.search === prevProps.location.search
            && this.props.location.state !== prevProps.location.state) {
            // when clicked on link that navigates on the same page, state could've been changed
            // => update the state in current storage
            this.updateFormStorageInitialHistoryState();
        }

        if (!!getQueryParameters()[QueryParam.PaneStatus]) {
            const status = getQueryParameters()[QueryParam.PaneStatus].split("");
            if (isValidPaneStatus(status)) {
                this.setPaneStatus(status as unknown as PaneStatus[]);
            }
            removeQueryParam(this.props.history, QueryParam.PaneStatus, true);
        }

        // cases when entityset is changed: Typical case is when user change chartofaccount tab year ->
        // it changes from f.e: ChartOfAccounts(1) => ChartOfAccounts(4)
        const tableBc = this.getTableViewContext();
        if (!tableBc) {
            return;
        }

        if (!tableBc.isSame(this.tableStorage.data.bindingContext)) {
            // this is not the greatest solution but seems to be working, this is typically when user clicks table "secondary" tabs filter
            // we need to delete formstorage (as it has no use anyway) so we prevent useless render when changing to another tab..
            // this is due to the fact that we first time render second pane (condition detail.bc is fullfilled) so it is creating NOT updating (so
            // component did update won't work)
            delete this.formStorage;

            if (this.tableStorage.data.bindingContext.getRootParent().getPath(true) !== tableBc.getRootParent().getPath(true)) {
                this.tableStorage.clearFilters();
            }

            await this.loadTable(tableBc, false);
            this.forceUpdate();

            this.tableStorage.refresh();
            return;
        }

        const prevEntityType = prevProps.detailData.bc?.getEntityType();
        const currentEntityType = this.props.detailData.bc?.getEntityType();
        const hasEntityTypeChanged = prevEntityType && prevEntityType !== currentEntityType;

        // remove form storage if entity changed or detail data is empty, so it can be correctly initialized in renderAndLoadForm
        if (hasEntityTypeChanged || !this.props.detailData.bc) {
            this.formStorage = null;
        }

        const hasRowBcChanged = areBindingContextsDifferent(prevProps.detailData?.bc, this.props.detailData.bc);

        if (this.props.detailData.bc && this.props.detailData.draftId !== this._lastDraftId) {
            this._lastDraftId = this.props.detailData.draftId;
            this.renderAndLoadForm();
            this.tableStorage.storeSingleValue("rowBindingContext", createBindingContext(`${this.formStorage.data.definition.draftDef.draftEntitySet}(${this._lastDraftId})`, this.tableStorage.oData.metadata));
            this.tableStorage.refresh();
        } else if (this.props.detailData.bc && hasRowBcChanged) {
            this.renderAndLoadForm();
        }

        const rowBc = this.props.detailData.bc;
        const wasFormVisible = !!this.formStorage?.data?.bindingContext?.getKey();

        if (hasRowBcChanged) {
            this.tableStorage.storeSingleValue("rowBindingContext", rowBc);
            if (rowBc?.isNew() && this.tableStorage.data.tableViewMode !== TableViewMode.Draft && !getQueryParameters()[QueryParam.Drafts]) {
                const addingRowType = this.formStorage.getCustomData()?.isCopy ? TableAddingRowType.Copy : TableAddingRowType.New;
                this.tableStorage.storeSingleValue("addingRow", addingRowType);
            } else {
                this.tableStorage.storeSingleValue("addingRow", undefined);
                // for closing the right form (f.e. when canceling adding new stuff, or cancel button on the form)
                // we need to refresh the whole split page to hide the right form
                // keep in mind bot table and form are "shouldComponentUpdate -> false" so their content is not refreshed by
                // this call
                if (wasFormVisible && !rowBc) {
                    this.forceUpdate();
                }
            }

            this.tableStorage.refresh();
        }

        // Prevent invalid state - if form is not visible, first pane must not be collapsed
        if (!this.isFormVisible() && this.state.paneStatus?.[0] === PaneStatus.Collapsed) {
            this.setPaneStatus(defaultPaneStatus);
        }


        this.handlePageViewModeChange();
        this.updateTableStorageHistoryState();
    }

    handleRerenderNeeded = () => {
        this.forceUpdate();
    };

    updateFormStorageInitialHistoryState = () => {
        this.formStorage.initialHistoryState = this.props.history.location.state;
    };

    handlePageViewModeChange = async (): Promise<void> => {
        if (this.formStorage) {
            const lastReadOnly = this.formStorage.pageViewMode === PageViewMode.FormReadOnly;
            // restore original isReadOnly from the unchanged this.props.detailData.def
            const newReadOnly = getPageViewMode(this.props.pageViewMode) === PageViewMode.FormReadOnly;

            if (lastReadOnly !== newReadOnly) {
                if (this.formStorage) {
                    this.formStorage.pageViewMode = newReadOnly ? PageViewMode.FormReadOnly : PageViewMode.Default;

                    if (this.formStorage.initialHistoryState !== this.props.history.location.state) {
                        // in case user used "edit entity" button in read only form mode,
                        // initialHistoryState was cleared (FormView.renderButtonsByContext.onClick)
                        // but when user goes back (via browser) we need to restore it to the original browser state
                        // to get correct breadcrumbs
                        this.updateFormStorageInitialHistoryState();
                    }

                    if (lastReadOnly && !newReadOnly && !this.formStorage.data.metadata?.metadata) {
                        this.formStorage.setBusy(true);
                        await this.formStorage.loadDisabledFieldMetadata();
                        this.formStorage.setBusy(false);
                    }
                }
                this.forceUpdate();
                this.formStorage?.refresh();
            }
        }
    };

    updateTableStorageHistoryState = async () => {
        if (this.props.history.location.state === this.tableStorage.initialHistoryState) {
            return;
        }

        this.tableStorage.initialHistoryState = this.props.history.location.state;
        // await this.initialLoad();
        // await this.refreshTable();
    };

    getCurrentForm = () => {
        return this.formStorage?.data?.definition;
    };

    renderAndLoadForm = () => {
        if (!this.formStorage) {
            const { bc } = this.props.detailData;
            // if old form exists, show immediately busy indicator
            const showBusyImmediately = !!this.formStorage;
            this.createFormStorage(showBusyImmediately);
            // just for rendering empty form envelope
            // content is refresh after onAfterLoad via refresh
            this.forceUpdate(() => {
                if (bc === this.props.detailData.bc) {
                    this.loadForm(bc);
                }
            });
        } else {
            this.loadForm(this.props.detailData.bc);
        }
    };

    initialLoad = async () => {
        // do this first, before any awaits.
        // otherwise, the subsequent calls will trigger componentDidUpdate and call renderAndLoadForm again
        // => formStorage will be initialized two times resulting in two requests and form loaded events
        if (this.props.detailData.def) {
            // set _lastDraftId to prevent componentDidUpdate
            // from calling renderAndLoadForm twice
            this._lastDraftId = this.props.detailData.draftId;
            this.renderAndLoadForm();
        }

        await this.loadTable(this.props.detailData.bc);
        await this.props.onTableStorageLoad?.(this.tableStorage);
        this.forceUpdate();
    };

    loadTable = async (rowBindingContext: BindingContext, preserveInfos = true) => {
        await this.tableStorage.init({
            rowBindingContext,
            bindingContext: this.getTableViewContext(),
            definition: (this.props.masterData.def as ISplitPageTableDef),
            preserveInfos,
            useDrilldownFilters: !this.props.isInDialog,
            isInDialog: this.props.isInDialog
        });

        if (rowBindingContext?.isNew() && !this.props.detailData?.draftId) {
            this.tableStorage.storeSingleValue("addingRow", TableAddingRowType.New);
        }
    };

    loadForm = async (bc: BindingContext) => {
        if (bc) {
            const storage = this.formStorage;

            const initialData = storage.data?.customData?.initialData;

            if (this.customData) {
                storage.setCustomData(this.customData);
                delete this.customData;
            }

            storage.setCustomData({
                isCopy: !!initialData,
                isFirstRefreshAfterInit: true
            });

            await storage.init({
                bindingContext: bc,
                data: initialData
            });

            if (storage.data.bindingContext !== bc) {
                // storage is not loaded (it was re-initiated in the meantime)
                return;
            }

            if (initialData) {
                storage.data.origEntity = {};
            }

            delete storage.data?.customData?.["initialData"];

            storage.loaded = true;

            this._lastFormBusyState = this.formStorage?.isBusy();
        }
    };

    hasFiles = () => {
        const files = getFiles(this.formStorage);

        return files?.length > 0;
    };

    getStorageProps = (context: IAppContext) => {
        return {
            oData: this.props.oData,
            t: this.props.t,
            history: this.props.history,
            match: this.props.match,
            theme: this.props.theme,
            context: context
        };
    };

    createFormStorage = (showBusyImmediately = false) => {
        const _create = () => {
            // use clone in form storage - we can edit the form storage definition but stil keep original values
            const definition = cloneDeep(this.props.detailData.def);
            const isReadOnly = getPageViewMode(this.props.pageViewMode) === PageViewMode.FormReadOnly;
            const pageViewMode = isReadOnly ? PageViewMode.FormReadOnly : PageViewMode.Default;

            const storage = new FormStorage({
                ...this.getStorageProps(this.context),
                id: definition.id,
                definition,
                pageViewMode,
                refresh: (refreshPage?: boolean) => {
                    const _refreshRefs = () => {
                        this._formRef.current?.forceUpdate();
                        this._fileViewRef.current?.forceUpdate();
                        this._draftTimeStampRef.current?.forceUpdate();
                    };

                    if (!this.formStorage) {
                        // in case form was closed, nothing to refresh...
                        return;
                    }

                    const isFormBusy = this.formStorage.isBusy();

                    if (this._lastFormBusyState !== isFormBusy) {
                        this._lastFormBusyState = isFormBusy;
                        // re-render whole SplitPageBase to propagate busy state to the Form Pane Bubble
                        refreshPage = true;
                    }

                    if (storage.getCustomData()?.isFirstRefreshAfterInit || refreshPage) {
                        // FormViews should call refresh(true) in onAfterLoad, to ensure SplitPageBase is re-rendered
                        // => correct busy state on Pane Bubble can be propagated.
                        // But in case that for some reason won't happen, use isFirstRefresh safeguard so that SplitPageBase is re-rendered anyway
                        storage.setCustomData({ isFirstRefreshAfterInit: false });
                        this.forceUpdate(() => {
                            _refreshRefs();
                        });
                    } else if (!this._formRef.current) {
                        this.forceUpdate(() => {
                            _refreshRefs();
                        });
                    } else {
                        _refreshRefs();
                    }

                },
                initialHistoryState: this.props.history.location.state
            });

            if (showBusyImmediately) {
                storage.shouldDelayInitialBusy = false;
            }

            if (this._storageShouldBeDisabled) {
                storage.data.disabled = true;
            }

            this.props.onFormStorageCreated?.(storage);

            return storage;
        };

        this.formStorage = _create();
    };

    handleParentKeyChange = (id: TEntityKey) => {
        this.props.onChangeParent?.(id);
        this.context.getViewBreadcrumbs().items.pop();
    };

    handleRowSelect = (bindingContext: BindingContext, props: IRowProps, params: TRecordString, customData: TRecordAny) => {
        if (this.state.paneStatus[1] === PaneStatus.Collapsed && this.state.paneStatus[2] === PaneStatus.Collapsed) {
            this.setSplitToDefault();
        }
        this.context.eventEmitter.emit(ContextEvents.SelectedRowChanged);
        if (customData) {
            this.customData = customData;
        }
        const search = params ?? getQueryParameters();
        this.props.onRowSelect?.(bindingContext, search);
    };

    handleRemovedRows = (rows: BindingContext[]) => {
        let selectedRowBc = this.tableStorage.data.rowBindingContext;
        const draftDef = this.formStorage?.data.definition.draftDef;
        const isDraft = selectedRowBc?.getKey()?.toString()?.startsWith(DRAFT_BC_PREFIX);
        if (isDraft) {
            const key = getCleanDraftKey(selectedRowBc.getKey());
            selectedRowBc = createBindingContext(draftDef?.draftEntitySet, this.props.oData.getMetadata()).addKey(key);
        }
        if (rows.find(row => !areBindingContextsDifferent(row, selectedRowBc))) {
            this.props.onCloseDetail?.();
            // note: selectedRowBc.getEntitySet().getName() fails for removed ChoA->Account...
            // todo: can we maybe use just "isDraft" condition from above lines or move the event to TableViewWithDraft??
            if (draftDef?.draftEntitySet && (selectedRowBc.getEntitySet().getName() === draftDef?.draftEntitySet)) {
                this.context.eventEmitter.emit(ContextEvents.RecalculateDrafts);
            }
            // if the row has been removed, we want to remove it from current breadcrumbs as well
            this.context.setViewBreadcrumbs({
                items: this.context.getViewBreadcrumbs().items.slice(0, -1),
                lockable: false
            });
        }
    };

    handlePaneStatusChange = (paneStatus: PaneStatus[]) => {
        this.setPaneStatus(paneStatus);
    };

    setPaneStatus = (paneStatus: PaneStatus[]) => {
        this.setState({
            paneStatus: paneStatus
        });
    };


    isTableVisible = () => {
        if (this.props.pageViewMode) {
            return true;
        }

        const viewMode = getPageViewMode();
        return !viewMode || viewMode === PageViewMode.Default;
    };

    isFormVisible = () => {
        return !!this.props.detailData.bc;
    };

    setSplitToDefault = () => {
        if (this.state.paneStatus[0] !== PaneStatus.Normal || this.state.paneStatus[1] !== PaneStatus.Normal) {
            this.setPaneStatus(defaultPaneStatus);
        }
    };

    setAddingRowParent = (selectedRowBc: BindingContext): void => {
        if (!selectedRowBc) {
            if (this.formStorage) {
                this.formStorage.setCustomData({
                    Parent: null
                });
            }
            this.customData = null;

            return;
        }

        const tableApi = this.tableStorage.tableAPI as ISmartODataTableAPI;
        const rows = tableApi?.getState()?.rows ?? {};
        const parentRow = getRow(rows, selectedRowBc)?.customData.parent;

        if (this.formStorage) {
            this.formStorage.setCustomData({
                // if no parentRow, select row is the root parent,
                // => set it as the Parent
                Parent: parentRow?.id as BindingContext ?? selectedRowBc
            });

            // ensure that the custom data will remain in new form storage
            this.customData = this.formStorage.getCustomData();
        }
    };

    handleAdd = (type?: string) => {
        // used by CoA and Labels, this check can be changed as needed
        const shouldTryToAddSibling = this.tableStorage.data.bindingContext.isAnyPartCollection() || this.tableStorage.data?.definition?.childColumns;

        if (shouldTryToAddSibling) {
            // If no row is selected, create new first row. Otherwise, try to create sibling to the selected row.
            const selectedRowBc = this.tableStorage.data.rowBindingContext ?? null;

            this.setAddingRowParent(selectedRowBc);
        }

        this.setSplitToDefault();
        this.props.onAddDetail?.(type);
    };

    handleBeforeSave = () => {
        this.formStorage.setBusy(true);
    };

    handleSaveFail = () => {
        this.formStorage.setBusy(false);
    };

    handleAfterSave = (isNew: boolean, preventTableViewRefresh?: boolean) => {
        this.formStorage.setBusy(false);
        const { bindingContext } = this.formStorage.data;

        this.props.onAfterSave?.(bindingContext);
        this.tableStorage.storeSingleValue("rowBindingContext", bindingContext);
        const args: IAfterSaveEventArgs = {
            isNew,
            bindingContext
        };
        this.tableStorage.emitter.emit(ModelEvent.AfterSave, args);

        if (isNew || !preventTableViewRefresh) {
            this.refreshTable();
        }
    };

    handleCancel = () => {
        if (this.formStorage) {
            this.formStorage.loaded = false;
            this.props.onCloseDetail?.();

            this.context.setViewBreadcrumbs({
                items: this.context.getViewBreadcrumbs().items.slice(0, -1),
                lockable: false
            });
        }
    };

    tableCancelAdd = () => {
        // close storage when closing detail form, this prevents flicking when reopening form
        this.handleCancel();
    };

    refreshTable = (args: ITableRefreshNeeded = {}) => {
        if (!args.refreshRowOnly) {
            // in case we want to reload everything - table, tabs..
            if (!args.modelRefreshOnly) {
                this.tableStorage.updateUuid();
            }
            this.tableStorage.refresh();
        } else {
            // reload just one row of the table
            (this.tableStorage.tableAPI as any)?.reloadRow(args.rowBc ?? this.formStorage.data.bindingContext);
        }
    };

    handleTableRefreshNeeded = (args: ITableRefreshNeeded = {}) => {
        this.refreshTable(args);
    };

    handleOpenNextDraft = () => {
        const tableApi = this.tableStorage.tableAPI as ISmartODataTableAPI;
        const state = tableApi.getState();
        const storage = this.tableStorage;
        const draftDef = storage.data.definition.draftDef;
        const metadata = storage.oData.getMetadata();
        const draftId = getQueryParameters()[QueryParam.DraftId];

        if (draftId) {
            const draftBc = createBindingContext(draftDef.draftEntitySet, metadata).addKey(draftId);
            const currentIndex = state.rowsOrder.findIndex(order => order === draftBc.toString());
            const nextItem = state.rowsOrder[currentIndex + 1];
            if (nextItem) {
                const nextItemBc = createBindingContext(nextItem, metadata);
                const esName = storage.data.bindingContext.getEntitySet().getName();

                const bc = createBindingContext(esName, storage.oData.metadata).addKey(NEW_ITEM_DETAIL, true);
                this.props.onRowSelect?.(bc, {
                    [QueryParam.DraftId]: nextItemBc.getKey().toString(),
                    [QueryParam.Drafts]: "true"
                });

                this.handleTableRefreshNeeded();
            } else {
                this.handleAfterSave(true, false);
            }
        }
    }

    handleFormRefreshNeeded = async () => {
        await this.formStorage.reload();
    };

    handleFormDisabledChanged = (disabled: boolean) => {
        const currentStorage = this.formStorage;

        // can be called even before the form is opened => storage doesn't exist
        // => store in helper variable
        // DEV-16806 - set this flag always and don't delete it other then here
        // there are tables when we create form storages when row is clicked (multiple entity types)
        // so we need this flag to be set for every such form storage
        this._storageShouldBeDisabled = disabled;

        if (currentStorage) {
            currentStorage.data.disabled = disabled;
            currentStorage.refresh();
        }
    };

    renderTable = () => {
        return (
            this.props.renderTable({
                ref: this._tableRef,
                storage: this.tableStorage,
                rootStorage: this.props.rootStorage,
                formStorage: this.formStorage,
                onFormRefreshNeeded: this.handleFormRefreshNeeded,
                onFormDisabledChange: this.handleFormDisabledChanged,
                onAddingRowCancel: this.tableCancelAdd,
                onAdd: this.handleAdd,
                onRowSelect: this.handleRowSelect,
                onRemovedRows: this.handleRemovedRows,
                parents: this.props.parents,
                parentEntity: this.props.parentEntity,
                onParentKeyChange: this.handleParentKeyChange,
                ...this.props.tableViewProps
            })
        );
    };

    renderForm = () => {
        if (this.props.isSecondaryBookmarkActive) {
            return null;
        }

        if (this.formStorage) {
            const FormView = this.getCurrentForm().formControl;

            return (
                <DomManipulatorProvider>
                    {/* This is code for custom prompt, which was found very annoying and his service is no longer needed right now
                        Prompt stores value clicked on confirm dialog and so it is tailored to have one instance for one url. On url change it has to be unmounted
                    */}
                    {this.props.usePrompt &&
                        <UnsavedChangesPrompt key={this.props.location.pathname} storage={this.formStorage}/>
                    }
                    <FormView
                        ref={this._formRef}
                        title={this.getCurrentForm()?.title}
                        storage={this.formStorage}
                        pageViewMode={this.props.pageViewMode}
                        formProps={this.props.formProps}
                        onTableRefreshNeeded={this.handleTableRefreshNeeded}
                        onOpenNextDraft={this.handleOpenNextDraft}
                        onBeforeSave={this.handleBeforeSave}
                        onSaveFail={this.handleSaveFail}
                        onAfterSave={this.handleAfterSave}
                        onCancel={this.handleCancel}
                        onAdd={this.handleAdd}
                    />
                </DomManipulatorProvider>
            );
        }
        return null;
    };

    handleFilesUploaded = (isFirstUpload: boolean) => {
        if (isFirstUpload) {
            // refresh row to show attachment icon
            this.refreshTable({
                refreshRowOnly: true
            });
            // refresh hasFiles icon
            this.forceUpdate();
        }
    };

    handleNewFiles = () => {
        if (this.state.paneStatus?.[2] === PaneStatus.Collapsed) {
            // open last pane
            this.setPaneStatus([PaneStatus.Collapsed, PaneStatus.Normal, PaneStatus.Normal]);
        }

        if (this.state.highlightLastPane) {
            this.setState({
                highlightLastPane: false
            });
        }
    };

    handleDragEnter = () => {
        const isFileUploaderHidden = !!this._fileViewRef.current.state.selectedFile || this.state.paneStatus[2] === PaneStatus.Collapsed;

        if (isFileUploaderHidden) {
            this.setState({
                highlightLastPane: true
            });
        }
    };

    handleDragLeave = () => {
        if (this.state.highlightLastPane) {
            this.setState({
                highlightLastPane: false
            });
        }
    };


    getSecondaryBookmark = (): IPaneBookmark & { content: React.ReactElement } => {
        if (!this.formStorage?.loaded) {
            return null;
        }

        const bookmarkDef = getInfoValue(
            this.props.detailData?.def as IFormDef,
            "secondaryBookmark", {
                storage: this.formStorage
            });

        if (!bookmarkDef) {
            return null;
        }

        return {
            ...bookmarkDef,
            isActive: this.props.isSecondaryBookmarkActive
        };
    };

    handleBookmarkChange = (bookmarkId: string, isActive: boolean) => {
        const queryParams = getQueryParameters();

        if (isActive) {
            queryParams[QueryParam.SecondaryBookmarkActive] = "true";
        } else {
            delete queryParams[QueryParam.SecondaryBookmarkActive];
        }

        this.props.history.replace({
            pathname: this.props.history.location.pathname,
            search: new URLSearchParams(queryParams).toString()
        });
    };

    render() {
        const isFormReadOnly = this.formStorage?.isReadOnly;
        const hasFiles = this.hasFiles();
        const secondaryBookmark = this.getSecondaryBookmark();
        const hasDrafts = this.formStorage?.data.definition?.draftDef?.draftProperty;
        const hasThirdPane = this.props.hasPreview && (!isFormReadOnly || hasFiles);

        return (
            <SplitLayout onPaneStatusChanged={this.handlePaneStatusChange}
                         paneStatus={this.state.paneStatus}>
                <Pane visible={this.isTableVisible()} icon="List" width="50%">
                    {this.tableStorage.loaded && this.renderTable()}
                </Pane>
                <Pane visible={this.isFormVisible()} icon="Form" width="50%"
                      isLast={!hasThirdPane}
                      secondaryBookmark={secondaryBookmark}
                      onBookmarkChange={this.handleBookmarkChange}
                      paneAdditionalInfo={hasDrafts && !isFormReadOnly ?
                          <DraftTimestamp ref={this._draftTimeStampRef} storage={this.formStorage}/> : null}
                    // only show busy if third pane present, otherwise Splitter is not even visible
                    // TODO why do we render Splitter with bubble icon into DOM when thid pane is not present? can it get visible somehow?
                      isBusy={this.state.paneStatus[1] !== PaneStatus.Collapsed && hasThirdPane && this.formStorage && (this.formStorage?.isBusy() || !this.formStorage?.loaded || !this._formRef?.current)}
                      isBusyDelayed={!this.formStorage?.loaded}
                >
                    <>
                        {this.renderForm()}
                        {this.props.isSecondaryBookmarkActive && secondaryBookmark?.content}
                    </>
                </Pane>
                {hasThirdPane &&
                    <Pane visible={this.isFormVisible()} width="50%"
                          icon={hasFiles ? "DocumentFilled" : "Document"}
                          isLast={true}
                          isHighlighted={this.state.highlightLastPane}
                          showPaneGradient={hasFiles}
                          showSplitterGradient={hasFiles}>
                        <FileView formStorage={this.formStorage}
                                  isBusy={this.formStorage && (this.formStorage?.isBusy() || !this.formStorage?.loaded || !this._formRef?.current)}
                                  isVisible={this.state.paneStatus[2] !== PaneStatus.Collapsed}
                                  onNewFiles={this.handleNewFiles}
                                  onFilesUploaded={this.handleFilesUploaded}
                                  onDragEnter={this.handleDragEnter}
                                  onDragLeave={this.handleDragLeave}
                                  otherFileDropAreas={this.formStorage?.getCustomData()?.otherFileDropAreas}
                                  ref={this._fileViewRef}/>
                    </Pane>}
            </SplitLayout>
        );
    }
}

const wrapped = withRouter(withTheme(withOData(SplitPageBase)));
export { wrapped as SplitPageBase };