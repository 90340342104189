import { AlertPosition, IAlertProps } from "@components/alert/Alert";
import { IWithAlertSettings, WithAlert, withAlert } from "@components/alert/withAlert";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { getDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { CaretIcon, DownloadIcon, EditSimpleIcon, ExpandIcon, LockClosedIcon, LockOpenedIcon } from "@components/icon";
import { IInputOnBlurEvent, IInputOnChangeEvent } from "@components/inputs/input";
import { NumericInput } from "@components/inputs/numericInput/NumericInput";
import { ISelectionChangeArgs } from "@components/inputs/select/Select.types";
import Switch, { SwitchType } from "@components/inputs/switch/Switch";
import { isVisible } from "@components/smart/FieldInfo";
import { TooltipIconInfo } from "@components/tooltipIcon";
import { createBindingContext } from "@odata/BindingContext";
import { ODataError } from "@odata/Data.types";
import {
    EntitySetName,
    ICompanyVatReducedCoefficientEntity,
    IDocumentEntity,
    IElectronicSubmissionEntity
} from "@odata/GeneratedEntityTypes";
import {
    CurrencyCode,
    DocumentTypeCode,
    ElectronicSubmissionTypeCode,
    VatStatementFrequencyCode
} from "@odata/GeneratedEnums";
import { transformToODataString } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isDefined, isNotDefined } from "@utils/general";
import { capitalize } from "@utils/string";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BreadCrumbProvider } from "../../components/breadCrumb";
import BusyIndicator from "../../components/busyIndicator";
import { Button, ButtonGroup } from "../../components/button";
import Header from "../../components/header";
import Field from "../../components/inputs/field";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import { BasicSelect } from "../../components/inputs/select/BasicSelect";
import ReadOnlyList from "../../components/readOnlyList/ReadOnlyList";
import { SmartTable } from "../../components/smart/smartTable";
import Tabs from "../../components/tabs";
import { DASH_CHARACTER, EMPTY_VALUE } from "../../constants";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, IconSize, Status, TextAlign, ValueType } from "../../enums";
import { ValidationMessage } from "../../model/Validator.types";
import {
    ROUTE_VAT_CONTROL_STATEMENT_REPORT,
    ROUTE_VAT_STATEMENT_REPORT,
    ROUTE_VAT_VIES_STATEMENT_REPORT
} from "../../routes";
import TestIds from "../../testIds";
import CurrencyType, { formatCurrency } from "../../types/Currency";
import { getUtcDateBy } from "../../types/Date";
import customFetch from "../../utils/customFetch";
import memoize from "../../utils/memoize";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { StyledErrorWrapper } from "../../views/formView/FormView.styles";
import View from "../../views/View";
import { getCompanyCurrencyEntity } from "../companies/Company.utils";
import { VatReportProps } from "../reports/VatReport.utils";
import ClearingRatioDialog from "./ClearingRatioDialog";
import {
    createLockedVatStatementSubmission,
    createVatSubmissionDocuments,
    downloadSubmissionFile,
    ElectronicSubmissionNamespaces,
    getSubmissionFileUrl,
    getVatStatementFrequencyForSubmissionType,
    getVatStatementPeriod,
    isSubmissionLocked,
    lockSubmission,
    removeVatSubmissionDocuments,
    SubmissionStatus,
    TPossibleSubmissions
} from "./ElectronicSubmission.utils";
import {
    getLiabilityDocumentTableDef,
    getVatSubmissionClearingRatio,
    getVatSubmissionDocumentsData,
    getVatSubmissionPeriodName,
    isSubmissionLastPeriod,
    IVatSubmissionCountArgs,
    TVatSubmissionDocumentsData
} from "./VatSubmission.utils";
import {
    ClickableStyled,
    ClickableWrapper,
    CustomFormWrapper,
    StyledHeading,
    StyledParagraph,
    StyledVatSubmissionDetail,
    TableWrapperStyled
} from "./VatSubmissionDetail.styles";

interface IProps extends WithTranslation, WithOData, WithAlert, WithPromisedComponent {
    type: ElectronicSubmissionTypeCode;
    year: number;
    monthIndex: number;
    submission?: IElectronicSubmissionEntity;
    possibleSubmissions: TPossibleSubmissions;
    status?: SubmissionStatus;
    vatFrequency?: VatStatementFrequencyCode;
    onSubmissionChange?: (submission: IElectronicSubmissionEntity, isRemoved?: boolean) => Promise<boolean>;
}

enum VatPaymentType {
    VatLiability = "VatLiability",
    VatReceivable = "VatReceivable"
}

interface IState {
    busy: boolean;
    documents?: TVatSubmissionDocumentsData;
    amount?: number;
    amountError?: string;
    type?: VatPaymentType;
    coefficient?: ICompanyVatReducedCoefficientEntity;
}

class VatSubmissionDetail extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        busy: true,
        coefficient: null
    };

    getVatFrequency = memoize((): VatStatementFrequencyCode => {
        return getVatStatementFrequencyForSubmissionType({
            type: this.props.type,
            month: this.props.monthIndex,
            submission: this.props.submission,
            possibleSubmissions: this.props.possibleSubmissions
        });
    }, () => [this.props.type, this.props.monthIndex, this.props.submission, this.props.possibleSubmissions]);

    get subtitle() {
        const { year, monthIndex } = this.props;
        const d = getUtcDateBy(year, monthIndex);
        return capitalize(getVatSubmissionPeriodName(d, this.getVatFrequency()));
    }

    get vatSubmissionCountArgs() {
        const args: IVatSubmissionCountArgs = {};
        if (this.props.submission) {
            args.submission = this.props.submission;
        } else {
            const dateInPeriod = getUtcDateBy(this.props.year, this.props.monthIndex, 1);
            const { from, to } = getVatStatementPeriod(dateInPeriod, this.getVatFrequency());

            args.from = from.toDate();
            args.to = to.toDate();
        }
        return args;
    }

    get showClearingRatio(): boolean {
        return this.props.type === ElectronicSubmissionTypeCode.VatStatement && isSubmissionLastPeriod(this.props.vatFrequency, this.props.monthIndex);
    }

    get useVatReducedDeduction(): boolean {
        return this.showClearingRatio && isDefined(this.state.coefficient);
    }

    get isLocked(): boolean {
        return isSubmissionLocked(this.props.submission) || this.props.status === SubmissionStatus.Locked;
    }

    componentDidMount() {
        this.setBreadcrumbs();
        this.loadDocumentCounts();

        if (this.props.submission?.Id) {
            this.setDefaultStateFromSubmission();

            if (isSubmissionLocked(this.props.submission) && this.showClearingRatio) {
                this.loadClearingRatio();
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        this.setBreadcrumbs();

        if (this.props.year !== prevProps.year || this.props.monthIndex !== prevProps.monthIndex
            || this.props.submission !== prevProps.submission || this.props.type !== prevProps.type) {
            this.setAlert(null);
            this.loadDocumentCounts();
        }
        if (this.props.submission?.Id !== prevProps.submission?.Id || isSubmissionLocked(this.props.submission) !== isSubmissionLocked(prevProps.submission)) {
            this.setAlert(null);
            this.setDefaultStateFromSubmission();
        }
        if (this.props.monthIndex !== prevProps.monthIndex) {
            if (this.props.submission?.Id && isSubmissionLocked(this.props.submission) && this.showClearingRatio) {
                this.loadClearingRatio();
            } else {
                this.setState({ coefficient: null });
            }
        }
    }

    async loadDocumentCounts() {
        this.setState({ busy: true });
        const documents = await getVatSubmissionDocumentsData(this.vatSubmissionCountArgs, this.props.type, this.isLocked);
        this.setState({ busy: false, documents });
    }

    async loadClearingRatio(): Promise<void> {
        const { year } = this.props;
        const coefficient = await getVatSubmissionClearingRatio(this.props.oData, this.context, year + 1);
        this.setState({ coefficient });
    }

    getSubmissionTypeLabel = (type: ElectronicSubmissionTypeCode) => {
        return this.props.t(`ElectronicSubmission:VatSubmission.${type}`);
    };

    getSubmissionTypeLabelWithPeriod(type: ElectronicSubmissionTypeCode) {
        return `${this.getSubmissionTypeLabel(type)} (${this.subtitle})`;
    }

    // sets default state for vat submission field
    setDefaultStateFromSubmission() {
        const { VatStatementDocument, VatClearingDocument } = this.props.submission ?? {};
        const hasDocument = !!(VatClearingDocument?.Id || VatStatementDocument?.Id);
        const [type, amount] = hasDocument ? this.getStatementVatDocumentData()
            : (isSubmissionLocked(this.props.submission) ? this.getVatData() : [null, 0]);
        this.setState({ type, amount });
    }

    validateAmount(amount: number): string {
        let validation = null;
        if (isNotDefined(amount)) {
            validation = ValidationMessage.Required;
        }
        if (isNaN(amount)) {
            validation = ValidationMessage.NotANumber;
        }
        const isCashBasisCompany = isCashBasisAccountingCompany(this.context);
        if (isCashBasisCompany ? amount <= 0 : amount < 0) {
            validation = isCashBasisCompany ? ValidationMessage.PositiveNumber : ValidationMessage.PositiveNumberOrZero;
        }
        return validation ? this.props.t(`Common:${validation}`) : null;
    }

    setBreadcrumbs = () => {
        if (this.props.tReady) {
            this.context.setViewBreadcrumbs({
                items: [{
                    key: "detail",
                    title: this.getSubmissionTypeLabelWithPeriod(this.props.type)
                }],
                lockable: false
            });
        }
    };

    setAlert(key: string, status = Status.Success) {
        let alertProps: IAlertProps = null;
        if (key) {
            alertProps = {
                status,
                title: this.props.t(`Common:${status === Status.Success ? "Validation.SuccessTitle" : "Errors.ErrorHappened"}`),
                subTitle: this.props.t(key),
                isFullWidth: true
            };
            if (status === Status.Success) {
                alertProps.position = AlertPosition.CenteredBottom;
                alertProps.isFullWidth = false;
            }
        }
        this.props.setAlert(alertProps);
    }

    setAlertFromODataError(e: ODataError) {
        this.props.setAlert({
            ...getAlertFromError(e),
            action: null
        });
    }

    handleRemoveDocuments = async () => {
        const { submission, onSubmissionChange } = this.props;

        this.setState({ busy: true });

        try {
            const succ = await removeVatSubmissionDocuments(submission);

            if (succ) {
                await onSubmissionChange?.({
                    ...submission,
                    VatStatementDocument: null,
                    VatClearingDocument: null
                });
            } else {
                this.setAlert("ElectronicSubmission:Tax.ErrorRemoveDocument", Status.Error);
            }
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleGenerateDocument = async () => {
        const { submission, onSubmissionChange } = this.props;
        const { amount, type } = this.state;

        const error = this.validateAmount(amount);

        if (error) {
            this.setState({ amountError: error });
            return;
        }

        this.setState({ busy: true });

        try {
            const liability = (type === VatPaymentType.VatReceivable ? -1 : 1) * amount;
            const documents = await createVatSubmissionDocuments(submission, liability);
            let VatClearingDocument: IDocumentEntity = null;
            let VatStatementDocument: IDocumentEntity = null;

            if (documents?.length) {
                VatClearingDocument = documents.find(doc => doc.DocumentTypeCode === DocumentTypeCode.InternalDocument);
                VatStatementDocument = documents.find(doc => doc.Id !== VatClearingDocument?.Id);
            } else {
                // no document is allowed state, comment out error
                // this.setAlert("ElectronicSubmission:Tax.ErrorGenerateDocument", Status.Error);
            }

            await onSubmissionChange?.({
                ...submission,
                VatStatementDocument,
                VatClearingDocument
            });
            this.setAlert(null);
            this.setDefaultStateFromSubmission();
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleLockSubmission = async (isLocked: boolean): Promise<void> => {
        const { submission, year, monthIndex } = this.props;

        if (this.isLocked && !submission) {
            // fake lock, set correct error
            this.props.setAlert({
                status: Status.Error,
                title: this.props.t("Common:General.FormValidationErrorTitle"),
                subTitle: this.props.t("Error:ElectronicSubmissionCannotBeUnlockedWhenFollowedByLockedSubmission"),
            });
            return;
        }


        this.setState({ busy: true });

        try {
            const updatedSubmission = await (submission
                ? lockSubmission(this.props.oData, submission, isLocked)
                : createLockedVatStatementSubmission({
                    context: this.context,
                    oData: this.props.oData,
                    date: getUtcDateBy(year, monthIndex),
                    vatSubmissionType: this.props.type,
                    freq: this.getVatFrequency()
                }));

            if (updatedSubmission) {
                const willCloseDetail = await this.props.onSubmissionChange?.(updatedSubmission, !updatedSubmission?.Id);

                if (willCloseDetail) {
                    // no further action, component will be unmounted
                    return;
                }
                if (this.showClearingRatio) {
                    this.loadClearingRatio();
                }
            }

            this.setAlert(null);
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleFileDownload = async (type: ElectronicSubmissionTypeCode) => {
        try {
            await downloadSubmissionFile({
                context: this.context,
                type: type,
                submission: this.props.submission,
                generateType: "xml"
            });
            this.setAlert(`ElectronicSubmission:VatSubmission.${type}DownloadSuccess`);
        } catch (e) {
            this.setAlert("ElectronicSubmission:VatSubmission.DownloadError", Status.Error);
        }
    };

    handleTaxPortalRedirect = async (type: ElectronicSubmissionTypeCode) => {
        const url = getSubmissionFileUrl({
            context: this.context,
            type,
            submission: this.props.submission,
            generateType: "epo"
        });
        const res = await customFetch(url);

        if (res.ok) {
            const redirectUrl = await res.text();

            // open in new tab
            window.open(redirectUrl, "_blank");
        } else {
            this.setAlert("ElectronicSubmission:VatSubmission.DownloadError", Status.Error);
        }
    };

    getDocumentDrilldown(type: ElectronicSubmissionTypeCode): React.ReactNode {
        const { documents } = this.state;

        if (!documents) {
            return null;
        }

        const args = this.vatSubmissionCountArgs;
        const wholeDateRangeFilter = {
            DateStart: args.submission?.DatePeriodStart ?? args?.from,
            DateEnd: args.submission?.DatePeriodEnd ?? args?.to
        };

        let route: string;

        switch (type) {
            case ElectronicSubmissionTypeCode.VatStatement:
                route = ROUTE_VAT_STATEMENT_REPORT;
                break;
            case ElectronicSubmissionTypeCode.VatControlStatement:
                route = ROUTE_VAT_CONTROL_STATEMENT_REPORT;
                break;
            case ElectronicSubmissionTypeCode.VatVIESStatement:
                route = ROUTE_VAT_VIES_STATEMENT_REPORT;
                break;
        }

        const count = documents[type] ?? 0;
        const filters = {
            [VatReportProps.vatStatementPeriod]: wholeDateRangeFilter,
            [VatReportProps.unlockedElectronicSubmissionDocuments]: !this.isLocked
        };

        return !count ? DASH_CHARACTER :
            getDrillDownLink(`${count} ${this.props.t("Common:General.Document", { count })}`, {
                route,
                context: this.context,
                filters
            });
    }

    getVatData(): [VatPaymentType, number] {
        const { VatLiability } = this.props.submission;
        const { abs } = Math;
        const isLiability = VatLiability > 0;
        const type = isLiability ? VatPaymentType.VatLiability : VatPaymentType.VatReceivable;
        return [type, abs(VatLiability ?? 0)];
    }

    getStatementVatDocumentData(): [VatPaymentType, number, CurrencyCode] {
        const { VatStatementDocument } = this.props.submission;
        const { Amount, CurrencyCode: currency } = VatStatementDocument ?? {};
        const isLiability = VatStatementDocument.DocumentTypeCode === DocumentTypeCode.OtherLiability;
        const type = isLiability ? VatPaymentType.VatLiability : VatPaymentType.VatReceivable;
        return [type, (Amount ?? 0), (currency ?? CurrencyCode.CzechKoruna) as CurrencyCode];
    }

    renderLinks(type: ElectronicSubmissionTypeCode): React.ReactElement {
        return (
            <ClickableWrapper data-testid={TestIds.ElSubDetailLinks}>
                <ClickableStyled onClick={() => this.handleTaxPortalRedirect(type)} isLink>
                    {this.props.t("ElectronicSubmission:VatSubmission.MyTaxesLink")}
                    <ExpandIcon width={IconSize.XS} height={IconSize.XS}/>
                </ClickableStyled>
                <ClickableStyled onClick={() => this.handleFileDownload(type)} isLink>
                    {this.props.t("ElectronicSubmission:VatSubmission.DownloadXml")}
                    <DownloadIcon width={IconSize.XS} height={IconSize.XS}/>
                </ClickableStyled>
            </ClickableWrapper>
        );
    }

    renderTable = memoize((Ids: number[]) => {
        const tableDef = getLiabilityDocumentTableDef(this.context);
        const bindingContext = createBindingContext(EntitySetName.Documents, this.props.oData.metadata);
        const filterQuery = {
            query: `Id in (${transformToODataString(Ids, ValueType.Number)})`
        };

        return (
            <TableWrapperStyled>
                <Tabs data={[{ id: "Documents", title: this.props.t("ElectronicSubmission:Tax.Documents") }]}
                      selectedTabId={"Documents"}/>
                <SmartTable bindingContext={bindingContext}
                            initialSortBy={tableDef.initialSortBy}
                            columns={tableDef.columns.filter(col => isVisible({
                                info: col,
                                context: this.context
                            }))}
                            filter={filterQuery}
                            tableId={tableDef.id}
                            disableVirtualization
                            preventAutoReset={tableDef.preventAutoReset}
                            hideDrilldown
                            drilldown={null}/>
            </TableWrapperStyled>
        );
    }, (ids: number[]) => ids.join("-"));

    handleAmountBlur = (args: IInputOnBlurEvent) => {
        this.setState({ amountError: this.validateAmount(this.state.amount) });
    };

    handleAmountChange = (args: IInputOnChangeEvent<number>) => {
        this.setState({
            amount: args.value,
            amountError: null
        });
    };

    handleTypeChange = (args: ISelectionChangeArgs) => {
        if (args.triggerAdditionalTasks) {
            this.setState({ type: args.value as VatPaymentType });
        }
    };

    handleChangeRatio = async () => {
        const coefficient = await this.props.promisedComponent.openComponent<ICompanyVatReducedCoefficientEntity>((onFinish) => {
            return (
                <ClearingRatioDialog
                    initialCoefficient={this.state.coefficient}
                    context={this.context}
                    oData={this.props.oData}
                    onConfirm={onFinish}
                    onCancel={() => onFinish(null)}/>
            );
        });

        if (coefficient) {
            this.setState({ coefficient });

            if (!coefficient?.Id) {
                this.loadClearingRatio();
            }
        }
    };

    vatPaymentTypeItems = memoize(() => {
        return [VatPaymentType.VatLiability, VatPaymentType.VatReceivable].map(id => ({
            id, label: this.props.t(`ElectronicSubmission:VatSubmission.${id}`)
        }));
    });

    renderFields(isDisabled: boolean) {
        const { amountError, amount, type } = this.state;

        const [calcType, calcAmount] = this.getVatData();
        const currency = getCompanyCurrencyEntity(this.context);
        const calcOptions = {
            amount: formatCurrency(calcAmount, currency.Code)
        };

        return (
            <CustomFormWrapper data-testid={TestIds.FormView}>
                <StyledParagraph
                    data-testid={TestIds.CalculatedTax}>{this.props.t(`ElectronicSubmission:Tax.CalculatedTax_${calcType}`, calcOptions)}</StyledParagraph>
                <FieldsWrapper style={{ width: "100%", maxWidth: "100%" }}>
                    <Field width={BasicInputSizes.S}
                           name="custom-type"
                           label={this.props.t("ElectronicSubmission:Tax.TaxType")}>
                        <BasicSelect
                            inputIcon={<CaretIcon width={IconSize.M}/>}
                            isDisabled={isDisabled}
                            openOnClick
                            value={type}
                            onChange={this.handleTypeChange}
                            items={this.vatPaymentTypeItems()}
                        />
                    </Field>
                    <Field
                        width={BasicInputSizes.M}
                        name="custom-amount"
                        label={this.props.t("ElectronicSubmission:Tax.Tax")}
                        isRequired>
                        <NumericInput width={BasicInputSizes.M}
                                      isDisabled={isDisabled}
                                      error={amountError ? { message: amountError } : null}
                                      value={amount}
                                      textAlign={TextAlign.Right}
                                      unit={CurrencyType.getCurrencyUnit(getCompanyCurrency(this.context))}
                                      onBlur={this.handleAmountBlur}
                                      parserArgs={{ maximumFractionDigits: currency.MinorUnit }}
                                      onChange={this.handleAmountChange}/>
                    </Field>
                </FieldsWrapper>
            </CustomFormWrapper>
        );
    }

    renderClearingRatio() {
        const { coefficient } = this.state;

        return (
            <>
                <StyledHeading>{this.props.t("ElectronicSubmission:VatSubmission.ClearingRatio")}</StyledHeading>
                <StyledParagraph
                    data-testid={TestIds.CalculatedCoefficient}>{this.props.t("ElectronicSubmission:VatSubmission.ClearingInfo", { value: coefficient?.VatReducedCoefficient ?? EMPTY_VALUE })}</StyledParagraph>
                <ButtonGroup align={TextAlign.Right}>
                    <Button isTransparent onClick={this.handleChangeRatio} icon={<EditSimpleIcon/>}>
                        {this.props.t("ElectronicSubmission:VatSubmission.Change")}
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    renderVatStatementSubmissionDetails(): React.ReactElement {
        const { VatStatementDocument, VatClearingDocument, AreVatDocumentsCreated } = this.props.submission;
        const isCashBasisCompany = isCashBasisAccountingCompany(this.context);

        const isPeriodEmpty = !this.state.documents?.[ElectronicSubmissionTypeCode.VatStatement];
        const hasDocument = AreVatDocumentsCreated;
        const canGenerateDocument = !isPeriodEmpty && !hasDocument && this.validateAmount(this.state.amount) === null;
        const documentIds = [VatStatementDocument?.Id, VatClearingDocument?.Id].filter(id => !!id);

        return (
            <>
                <ReadOnlyList data={[
                    {
                        label: this.getSubmissionTypeLabel(ElectronicSubmissionTypeCode.VatStatement),
                        value: this.renderLinks(ElectronicSubmissionTypeCode.VatStatement)
                    }
                ]} withoutPadding/>

                <StyledHeading>{this.props.t("ElectronicSubmission:Tax.Title")}</StyledHeading>
                {this.renderFields(hasDocument || isPeriodEmpty)}
                <ButtonGroup align={TextAlign.Right}>
                    <Button onClick={this.handleRemoveDocuments}
                            isTransparent
                            isDisabled={!hasDocument}
                            hotspotId={"ElectronicSubmissionRemoveDocuments"}>
                        {this.props.t(`ElectronicSubmission:Tax.${isCashBasisCompany ? "RemoveDocument" : "CancelPosting"}`)}
                    </Button>
                    <Button onClick={this.handleGenerateDocument}
                            isDisabled={!canGenerateDocument}
                            hotspotId={"ElectronicSubmissionGenerateDocuments"}>
                        {this.props.t(`ElectronicSubmission:Tax.${isCashBasisCompany ? "CreateDocument" : "Post"}`)}
                    </Button>
                </ButtonGroup>
                {documentIds.length ? this.renderTable(documentIds) : null}
            </>
        );
    }

    renderOtherSubmissionDetails(type: ElectronicSubmissionTypeCode): React.ReactElement {
        return (
            <>
                <ReadOnlyList data={[
                    {
                        label: this.getSubmissionTypeLabel(type),
                        value: this.renderLinks(type)
                    }
                ]} withoutPadding/>
            </>
        );
    }

    renderLockedSubmissionDetails() {
        if (!this.props.submission) {
            return null;
        }

        return (<>
            {this.useVatReducedDeduction && this.renderClearingRatio()}
            <StyledHeading>{this.props.t("ElectronicSubmission:VatSubmission.TaxPortalDocuments")}</StyledHeading>
            <StyledParagraph>{this.props.t("ElectronicSubmission:VatSubmission.TaxPortalDocumentsDescription")}</StyledParagraph>
            {this.props.type === ElectronicSubmissionTypeCode.VatStatement
                ? this.renderVatStatementSubmissionDetails()
                : this.renderOtherSubmissionDetails(this.props.type)
            }
        </>);
    }

    renderSubmissionDetail() {
        const isLocked = this.isLocked;

        const iconSet = {
            on: LockClosedIcon,
            off: LockOpenedIcon
        };

        return (
            <>
                <StyledHeading>
                    {this.props.t(`ElectronicSubmission:VatSubmission.Label${this.props.type}`)}
                    <TooltipIconInfo style={{ marginLeft: "6px", top: "1px" }}>
                        {this.props.t("ElectronicSubmission:VatSubmission.Description")}
                    </TooltipIconInfo>
                </StyledHeading>

                <div data-testid={TestIds.ElSubDetailIncludedDocuments}>
                    {this.getDocumentDrilldown(this.props.type)}
                </div>

                <FieldsWrapper>
                    <Switch checked={isLocked}
                            label={this.props.t("ElectronicSubmission:VatSubmission.LockSwitchLabel")}
                            hotspotId={"ElectronicSubmissionVatSubmission"}
                            onChange={this.handleLockSubmission}
                            type={SwitchType.Icons}
                            iconSet={iconSet}
                            style={{ margin: "27px 0" }}/>
                </FieldsWrapper>

                {isLocked && this.renderLockedSubmissionDetails()}
            </>
        );
    }

    render() {
        if (!this.props.tReady) {
            return (<BusyIndicator/>);
        }

        return (<>
            <BreadCrumbProvider/>
            <View hotspotContextId={"vatSubmissionsDetail"}>
                <StyledVatSubmissionDetail>
                    <Header title={this.getSubmissionTypeLabel(this.props.type)}
                            subtitle={this.subtitle}/>
                    {this.props.alertProps?.status === Status.Error &&
                        (
                            <StyledErrorWrapper>
                                {this.props.alert}
                            </StyledErrorWrapper>
                        )
                    }
                    {this.renderSubmissionDetail()}
                </StyledVatSubmissionDetail>
            </View>
            {this.props.alertProps?.status === Status.Success && this.props.alert}
            {this.state.busy && <BusyIndicator/>}
        </>);
    }
}

const alertProps: IWithAlertSettings = {
    autoHide: true,
    position: AlertPosition.Default,
    isFullWidth: true
};

export default withAlert(alertProps)(withPromisedComponent(withTranslation(ElectronicSubmissionNamespaces)(withOData(VatSubmissionDetail))));