import { IGroupListItemDef } from "@components/configurationList";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportHierarchy, IReportVariantDef } from "@components/smart/smartTable";
import { ElectronicSubmissionTypeCode } from "@odata/GeneratedEnums";
import i18next from "i18next";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Sort } from "../../../enums";
import { TRecordType } from "../../../global.types";
import {
    vatPeriodParameterOnBeforeLoadCallback,
    vatStatementPeriodParamDef
} from "../customFilterComponents/VatPeriodParameter";
import {
    IReportTableDefinition,
    NumberAggregationFunction,
    ReportConfigGroup,
    TGetReportConfigListItemOverride,
    TimeAggregationFunction,
    TReportColumnOverrides,
    TReportConfigListItemOverride
} from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";
import {
    getUnlockedElectronicSubmissionDocumentsDef,
    getVatReportColumnOverrides,
    VatReportProps
} from "../VatReport.utils";

export enum VatControlStatementReportVariant {
    Evala = "-131"
}

export const VAT_CONTROL_STATEMENT_REPORT_PATH = "VatControlStatementReport";

export const getDefinition = (context: IAppContext): IReportTableDefinition => {
    const tableId = ReportId.VatControlStatement;
    const title = i18next.t("Reporting:VatControlStatement.Title");
    const path = VAT_CONTROL_STATEMENT_REPORT_PATH;
    const initialSortBy = [{ id: "Document_VatControlStatementSection", sort: Sort.Asc }];
    const parameters: string[] = [
        VatReportProps.vatStatementPeriod,
        VatReportProps.unlockedElectronicSubmissionDocuments
    ];

    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                VatReportProps.vatStatementPeriod,
                VatReportProps.unlockedElectronicSubmissionDocuments
            ],
            filterDefinition: {
                [VatReportProps.vatStatementPeriod]: vatStatementPeriodParamDef(),
                ...getUnlockedElectronicSubmissionDocumentsDef()
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }
    ];

    const onBeforeLoad = async (storage: ReportStorage) => {
        await vatPeriodParameterOnBeforeLoadCallback(storage, ElectronicSubmissionTypeCode.VatControlStatement);
    };

    const defaultReportVariants: TRecordType<IReportVariantDef> = {
        [VatControlStatementReportVariant.Evala]: {
            ReportHierarchy: {
                "Aggregate": true,
                "Groups": [
                    {
                        "ColumnAlias": "Document_VatControlStatementSection"
                    }
                ],
                "Columns": [
                    {
                        "ColumnAlias": "Document_NumberOurs"
                    },
                    {
                        "ColumnAlias": "Document_NumberTheirs"
                    },
                    {
                        "ColumnAlias": "Document_DateTaxableSupply",
                        "AggregationFunction": TimeAggregationFunction.Day
                    },
                    {
                        "ColumnAlias": "Document_DateVatDeduction",
                        "AggregationFunction": TimeAggregationFunction.Day
                    },
                    {
                        "ColumnAlias": "BusinessPartner_Name"
                    },
                    {
                        "ColumnAlias": "BusinessPartner_TaxNumber"
                    }
                ],
                "Aggregations": [
                    {
                        "ColumnAlias": "Document_StdAmountNet",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    },
                    {
                        "ColumnAlias": "Document_StdAmountVat",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    },
                    {
                        "ColumnAlias": "Document_1RdcAmountNet",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    },
                    {
                        "ColumnAlias": "Document_1RdcAmountVat",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    },
                    {
                        "ColumnAlias": "Document_2RdcAmountNet",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    },
                    {
                        "ColumnAlias": "Document_2RdcAmountVat",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    },
                    {
                        "ColumnAlias": "Document_NoVatAmountNet",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    },
                    {
                        "ColumnAlias": "Document_TotalAmount",
                        "AggregationFunction": NumberAggregationFunction.Sum
                    }
                ]
            }
        }
    };


    const configListItemsOverride: TGetReportConfigListItemOverride = (item: IGroupListItemDef): TReportConfigListItemOverride => {
        switch (true) {
            case item.id === "Document_VatControlStatementSection":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    group: ReportConfigGroup.Groups
                };
        }

        return item;
    };

    const columnOverrides: TReportColumnOverrides = {
        ...getVatReportColumnOverrides()
    };

    const defaultReportHierarchy: IReportHierarchy = defaultReportVariants[VatControlStatementReportVariant.Evala].ReportHierarchy;

    return {
        title, path, id: tableId, initialSortBy, filterBarDef, parameters, columnOverrides,
        onBeforeLoad, defaultReportHierarchy, defaultReportVariants, configListItemsOverride
    };
};
