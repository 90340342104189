import React from "react";
import { useTranslation } from "react-i18next";
import { IColumn, IRow } from "@components/table";
import { RowType, Status, TextAlign } from "../../../enums";
import SimpleTable from "../../../components/simpleTable/SimpleTable";
import { IFiscalYearEntity, ITaxDepreciationPolicyItemEntity } from "@odata/GeneratedEntityTypes";
import { formatCurrency } from "../../../types/Currency";
import { getUtcDayjs } from "../../../types/Date";
import dayjs from "dayjs";

export interface ITaxDepreciationPolicyItemEntityExtended extends ITaxDepreciationPolicyItemEntity {
    FiscalYear: IFiscalYearEntity;
}

interface IProps {
    header?: React.ReactElement;
    data: ITaxDepreciationPolicyItemEntityExtended[];
    FYs: IFiscalYearEntity[];
    currentFY: IFiscalYearEntity;
    putInUseFY: IFiscalYearEntity;
    disableBeforeDate: Date;
    currency: string;
}

export const TaxDepreciationPolicyTable: React.FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation(["FixedAsset"]);
    const columns: IColumn[] = [
        { id: "year", label: `${t("FixedAsset:DepreciationTable.Period")}:` },
        { id: "depreciation", label: `${t("FixedAsset:DepreciationTable.Depreciation")}:`, textAlign: TextAlign.Right },
        { id: "reduction", label: `${t("FixedAsset:DepreciationTable.ReductionRate")}:`, textAlign: TextAlign.Right },
        { id: "balance", label: `${t("FixedAsset:DepreciationTable.Balance")}:`, textAlign: TextAlign.Right },
        {
            id: "depreciated",
            label: `${t("FixedAsset:DepreciationTable.TaxDepreciated")}:`,
            textAlign: TextAlign.Right
        },
    ];

    const _createDayjs = (date: Date) => date && getUtcDayjs(date);

    const rows: IRow[] = [];
    const { currency, currentFY, disableBeforeDate, data } = props;
    const disabledDate = _createDayjs(disableBeforeDate);
    let showReductionRate = false;

    if (data?.length > 0) {
        // process data, prepare rows to be shown
        let id = 1;

        const _addInterruptionRow = (interruptedYears: IFiscalYearEntity[], hasNextRow?: boolean) => {
            const from = parseInt(interruptedYears[0].Number);
            const to = interruptedYears?.length > 1 ? parseInt(interruptedYears[interruptedYears.length - 1].Number) : null;
            // Adds row with info about interruption
            const key = !hasNextRow ? "InterruptedFrom" : (to ? "InterruptedBetween" : "InterruptedIn");
            rows.push({
                id: id++,
                values: {},
                type: RowType.Merged,
                isDivider: true,
                content: (<em>
                    {t(`FixedAsset:DepreciationTable.${key}`, { year: from, toYear: to })}
                </em>)
            });
        };

        const _addRegularRow = (item: ITaxDepreciationPolicyItemEntityExtended, FY: IFiscalYearEntity) => {
            const year = FY?.Number ?? "";
            rows.push({
                id: id++,
                values: {
                    year,
                    depreciation: formatCurrency(item.DepreciationExpense, currency),
                    reduction: `${item.ReductionRate} %`,
                    balance: formatCurrency(item.EndValue, currency),
                    depreciated: formatCurrency(item.AccumulatedDepreciation, currency)
                },
                type: RowType.Value,
                isDisabled: !!(FY?.DateStart && disabledDate?.isSameOrAfter(_createDayjs(FY?.DateStart), "day")),
                statusHighlight: year === currentFY?.Number ? Status.Success : null
            });
        };

        // creates fake FY before/after the given one (+/- 12 months)
        const _createFakeFY = (FY: IFiscalYearEntity, diff: number): IFiscalYearEntity => {
            const baseProp = diff > 0 ? "DateEnd" : "DateStart";
            const secondProp = diff > 0 ? "DateStart" : "DateEnd";
            const baseDate = getUtcDayjs(FY?.[baseProp]);
            return {
                [baseProp]: baseDate.add(diff, "year").toDate(),
                [secondProp]: baseDate.add(diff, "day").toDate(),
                Number: (parseInt(FY.Number) + diff).toString(),
            };
        };

        /**
         * Creates rows for the table with fake fiscal years, which covers all the depreciation rows. If depreciation starts
         * before the first fiscal year, we add row before, which starts 12 months before the first depreciation. Same at the end.
         * If there are no more fiscal years, we add fake FY by adding 12 months until the last depreciation is covered.
         * @param FYs
         * @param putInUseFY
         * @param firstDepFY
         * @param lastDep
         */
        const _createFYRows = (FYs: IFiscalYearEntity[], putInUseFY: IFiscalYearEntity, firstDepFY: IFiscalYearEntity, data: ITaxDepreciationPolicyItemEntityExtended[]): IFiscalYearEntity[] => {
            const ret: IFiscalYearEntity[] = [];

            const putInUseDate = _createDayjs(putInUseFY?.DateEnd);
            const lastDep = props.data[props.data.length - 1]?.DateDepreciation;
            // if it's interrupted in last FY, we don't know the last depreciation date, so we generate list of FYs to have same item count as depreciation data
            const FY_min_count = props.data.length;

            // start with real FYs without that one before the putInUseDate
            ret.push(...FYs.filter(fy => !putInUseDate || getUtcDayjs(fy.DateEnd).isSameOrAfter(putInUseDate, "month")));

            // create fake fiscal years before the first real one
            const firstDepDate = _createDayjs(firstDepFY?.DateEnd);
            const firstDate = putInUseDate && firstDepDate ? dayjs.min(putInUseDate, firstDepDate) : putInUseDate ?? firstDepDate;

            // until we have the first FY matching the first depreciation date
            let firstFY = ret[0];
            while (!getUtcDayjs(firstFY.DateEnd).isSameOrBefore(firstDate, "month")) {
                // create fake FY before the actual one
                firstFY = _createFakeFY(firstFY, -1);
                // put it to the start of the array, so it's the first one processed next iteration
                ret.unshift(firstFY);
            }

            // create fake fiscal years after the last real one
            const lastDepDate = _createDayjs(lastDep);
            if (lastDepDate) {
                // if we know last depreciation date, create FYs until that date
                let lastFY = ret[ret.length - 1];
                while (!getUtcDayjs(lastFY?.DateEnd).isSameOrAfter(lastDepDate, "month")) {
                    lastFY = _createFakeFY(lastFY, 1);
                    ret.push(lastFY);
                }
            } else {
                // unless, we create fake FYs until we have enough of them
                while (ret.length < FY_min_count) {
                    const lastFY = ret[ret.length - 1];
                    ret.push(_createFakeFY(lastFY, 1));
                }
            }

            return ret;
        };

        const firstDepFY = props.data[0]?.FiscalYear;
        const allFYs = _createFYRows(props.FYs ?? [], props.putInUseFY, firstDepFY, props.data);

        let dataIdx = 0;
        let interruptionYears: IFiscalYearEntity[] = [];
        allFYs.forEach((item, idx) => {
            const dataItem = data[dataIdx];
            if (!dataItem) {
                // no more data to process
                return;
            }
            showReductionRate = showReductionRate || !!dataItem.ReductionRate;

            const { DateDepreciation } = dataItem;

            // if DateDepreciation is set, there is interruption between and the data will continue in some next FY
            if (!DateDepreciation || !getUtcDayjs(item.DateEnd).isSame(getUtcDayjs(DateDepreciation), "month")) {
                interruptionYears.push(item);
                return;
            }
            if (interruptionYears.length > 0) {
                // first matched data row after interruption -> add interruption row
                _addInterruptionRow(interruptionYears, true);
                interruptionYears = [];
            }
            // create regular row and move next
            _addRegularRow(dataItem, item);
            dataIdx++;
        });

        if (interruptionYears.length) {
            // Interrupted from ...
            _addInterruptionRow(interruptionYears);
        }
        // add all other rows as "plan" after last interruption row
        for (let i = dataIdx; i < data.length; i++) {
            const dataItem = data[i];
            _addRegularRow(dataItem, null);
        }
    }

    if (!showReductionRate) {
        // remove reduction rate column
        columns.splice(2, 1);
    }

    return (<>
        {props.header}
        <SimpleTable rows={rows} columns={columns} width={"100%"}/>
    </>);
};