import { ITabData } from "@components/tabs";
import { fetchData } from "@odata/OData.utils";
import { logger } from "@utils/log";

import { NEW_ITEM_DETAIL } from "../constants";
import { IAppContext } from "../contexts/appContext/AppContext.types";
import BindingContext, { IEntity, TEntityKey } from "../odata/BindingContext";
import { getCorrectPath } from "../routes";
import { ISplitPageTableDef } from "../views/table/TableView.utils";
import Page, { IProps } from "./Page";

export interface IParentPageProps extends IProps {
    isParentEditable: boolean;
}

export default class ParentChildPage<P extends IParentPageProps, S = {}> extends Page<P, S> {
    protected parents: IEntity[] = [];

    protected areParentsLoaded = false;
    private areParentsLoading = false;

    constructor(props: P, context: IAppContext) {
        super(props, context);

        this.handleChangeParent = this.handleChangeParent.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.loadParents();
    }

    async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<{}>) {
        super.componentDidUpdate(prevProps, prevState);
        await this.loadParents();
        this.redirectToFirstParent();
    }

    companyChange(id: number): void {
        this.areParentsLoaded = false;
        this.parents = [];
        super.companyChange(id);
    }


    loadParents = async () => {
        if (this.definition?.table?.parentDefinition && !this.areParentsLoaded && !this.areParentsLoading) {
            // prevent multiple load in case too many updates are fired
            this.areParentsLoading = true;
            await this.loadParentEntities();
            this.areParentsLoaded = true;
            this.areParentsLoading = false;
            this.redirectToFirstParent();
            this.forceUpdate();
        }
    };

    loadParentEntities = async () => {
        const parentDef = (this.masterData.def as ISplitPageTableDef).parentDefinition;
        const bc = this.getTableViewContextMemoized(parentDef.entitySet as string);

        let result: ITabData[];
        try {
            result = await fetchData(bc, this.props.oData, parentDef, this.context);
        } catch (error) {
            // todo: busy state & global error handling
            logger.error("error in fetchData", error);
            result = [];
        }

        this.parents = result;
    };

    redirectToFirstParent(): void {
        const parents = this.parents || [],
            activeId = this.getParentKey();

        if (parents.length) {
            if (!activeId) {
                const newUrl = getCorrectPath(this.props.match.path, {
                    ParentId: parents[0]?.Id
                });
                if (newUrl !== this.props.match.url) {
                    this.props.history.replace(newUrl);
                }
            }
            if (!parents.find((entity: IEntity) => entity.Id as string === activeId)) {
                // 404 - invalid EntityId in URL
                // todo: show error
            }
        }
    }

    handleRowSelect(bc: BindingContext): void {
        let parentKey, key;
        if (bc.isRoot() && this.props.childCollection) {
            parentKey = bc.getKey();
        } else {
            parentKey = bc.getParent()?.getKey();
            key = bc.getKey();
        }

        this.setUrl(key as string, parentKey as string);
    }

    getParentKey = (): string => {
        return (this.props.match.params as any)?.ParentId;
    };

    handleAddDetail(): void {
        const key = this.props.isParentEditable ? null : NEW_ITEM_DETAIL;
        const parentKey = this.props.isParentEditable ? NEW_ITEM_DETAIL : this.getParentKey();
        this.setUrl(key, parentKey);
    }

    handleCloseDetail(): void {
        this.setUrl(null, this.props.isParentEditable ? null : this.getParentKey());
    }

    getParentEntity = (): IEntity => {
        return this.parents?.find((entity: IEntity) => entity.Id.toString() === this.getParentKey());
    };

    getChildCollectionForEditableParent = () => {
        return this.props.isParentEditable && this.props.childCollection;
    };

    getMandatoryChildParentProps = () => {
        return {
            parents: this.parents,
            parentEntity: this.getParentEntity(),
            onChangeParent: this.handleChangeParent
        };
    };

    handleChangeParent(key?: TEntityKey): void {
        this.setUrl("", key?.toString(), {}, true);
    }

    handleAfterSave(bc: BindingContext): void {
        const currentKey = bc.getKey() as string;

        // if we have parent-child split page (childCollection prop) we have to differ two cases
        // if parent is not editable we know we are saving child
        // but if it is we have to decide if we are saving child or parent

        const isParentEditing = this.props.isParentEditable && bc.getPath(true) !== this.getChildCollectionForEditableParent();
        const parentKey = isParentEditing ? currentKey : this.getParentKey();
        const key = isParentEditing ? null : currentKey;

        this.setUrl(key, parentKey);
    }

    isReady(): boolean {
        const hasParentDefinition = this.definition?.table?.parentDefinition;
        return super.isReady() && (!hasParentDefinition || this.areParentsLoaded);
    }
}
