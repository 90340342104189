import { getWorkDate, getWorkDateCallback } from "@components/inputs/date/utils";
import { TValidatorFn } from "@components/smart/FieldInfo";
import { EntitySetName, EntityTypeName, InvoiceReceivedEntity } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { isCashBasisAccountingCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import { ValidationError } from "yup";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { ValidationErrorType, ValidatorType } from "../../../enums";
import { Model } from "../../../model/Model";
import { isNumberTheirsAndVatIdMandatory, SAVED_VATS_PATH } from "../../admin/vatRules/VatRules.utils";
import { addAssetDef, AssetTranslations } from "../../asset/fixedAsset/FixedAsset.utils";
import { addMinorAssetDef } from "../../asset/minorAsset/MinorAsset.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { commonDocumentTranslations } from "../Document.utils";
import {
    addAccountAssignmentTableDefs,
    addAmountsTableDefs,
    addBankAccountTableDefs,
    addBusinessPartnerFieldDefs,
    addBusinessPartnerTableDefs,
    addCommonDocumentDefs,
    addCorrectiveDocument,
    addPaymentOrderTableDefs
} from "../DocumentCommonDefs";
import {
    addDateGroupDateField,
    clearEmptyDateGroupDateFields,
    getDefinitions as getDocumentDefinitions
} from "../DocumentDef";
import { addAccrualsDef } from "../extensions/accruals/Accruals.utils";
import { addItemsSummaryDef } from "../extensions/itemsSummary/ItemsSummary.utils";
import { addProformaDef } from "../extensions/proforma/Proforma.utils";
import { addTimeResolutionDef } from "../extensions/timeResolution/TimeResolution.utils";
import InvoicesReceivedFormView from "./InvoicesReceivedFormView";


export const addDateIssued = (definition: IDefinition, definitionOnly?: boolean, isDefault?: boolean): void => {
    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        DateIssued: {}
    };

    definition.table.columnDefinition.DateIssued = { id: InvoiceReceivedEntity.DateIssued };
    definition.form.fieldDefinition.DateIssued = {
        id: InvoiceReceivedEntity.DateIssued,
        isRequired: true,
        defaultValue: () => (getWorkDate())
    };
    if (definition.table.massEditableDef) {
        definition.table.massEditableDef[InvoiceReceivedEntity.DateIssued] = {};
    }

    if (!definitionOnly) {
        addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateIssued);
    }

    if (isDefault) {
        const businessPartnerIndex = definition.table.columns.findIndex(column => column === InvoiceReceivedEntity.BusinessPartner);

        definition.table.filterBarDef[0].defaultFilters.splice(1, 0, InvoiceReceivedEntity.DateIssued);
        definition.table.columns.splice(businessPartnerIndex + 1, 0, InvoiceReceivedEntity.DateIssued);
    }
};

interface IAddNumberTheirsOptions {
    definitionOnly?: boolean;
    addVatValidation?: boolean;
}

const numberTheirsOnInvoiceReceivedValidator: TValidatorFn = (value, { storage }) => {
    if (isNumberTheirsAndVatIdMandatory({ storage }) && !value) {
        return new ValidationError(i18next.t("Document:Form.NumberTheirsValidation"), value, "", ValidationErrorType.Form);
    }
    return true;
};


export const addNumberTheirs = (definition: IDefinition, props?: IAddNumberTheirsOptions): void => {
    definition.table.columnDefinition[InvoiceReceivedEntity.NumberTheirs] = { id: InvoiceReceivedEntity.NumberTheirs };

    if (!props?.definitionOnly) {
        const numberOursIndex = definition.table.columns.findIndex(column => column === InvoiceReceivedEntity.NumberOurs);

        definition.table.columns.splice(numberOursIndex + 1, 0, InvoiceReceivedEntity.NumberTheirs);
    }
    if (definition.table.massEditableDef) {
        definition.table.massEditableDef[InvoiceReceivedEntity.NumberTheirs] = {};
    }
    definition.form.fieldDefinition[InvoiceReceivedEntity.NumberTheirs] = {
        id: InvoiceReceivedEntity.NumberTheirs,
        isRequired: isNumberTheirsAndVatIdMandatory,
        ...(props?.addVatValidation ? {
            validator: {
                type: ValidatorType.Custom,
                settings: {
                    customValidator: [{
                        validator: numberTheirsOnInvoiceReceivedValidator
                    }]
                }
            }
        } : {})
    };
    definition.form.groups.find(group => group.id === "document").rows[0].splice(0, 0, { id: InvoiceReceivedEntity.NumberTheirs });
    definition.table.filterBarDef[0].defaultFilters.splice(1, 0, InvoiceReceivedEntity.NumberTheirs);
    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        NumberTheirs: {}
    };
};

export const addDateReceived = (definition: IDefinition, isDefault?: boolean): void => {
    definition.table.columnDefinition[InvoiceReceivedEntity.DateReceived] = { id: InvoiceReceivedEntity.DateReceived };

    if (isDefault) {
        const businessPartnerIndex = definition.table.columns.findIndex(column => column === InvoiceReceivedEntity.BusinessPartner);

        definition.table.filterBarDef[0].defaultFilters.splice(2, 0, InvoiceReceivedEntity.DateReceived);
        definition.table.columns.splice(businessPartnerIndex + 1, 0, InvoiceReceivedEntity.DateReceived);
    }

    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        DateReceived: {}
    };

    if (definition.table.massEditableDef) {
        definition.table.massEditableDef[InvoiceReceivedEntity.DateReceived] = {};
    }
    definition.form.fieldDefinition[InvoiceReceivedEntity.DateReceived] = {
        id: InvoiceReceivedEntity.DateReceived,
        defaultValue: () => (getWorkDate())
    };

    addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateReceived);
    addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateDue);
};

export const addDateVATDeduction = (definition: IDefinition, context: IAppContext, definitionOnly?: boolean): void => {
    definition.table.columnDefinition[InvoiceReceivedEntity.DateVatDeduction] = {};
    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        DateVatDeduction: {}
    };

    definition.form.fieldDefinition[InvoiceReceivedEntity.DateVatDeduction] = {
        id: InvoiceReceivedEntity.DateVatDeduction,
        isRequired: true,
        defaultValue: () => (getWorkDate()),
        affectedFields: [
            { id: SAVED_VATS_PATH }
        ]
    };

    if (isVatRegisteredCompany(context) && !definitionOnly) {
        addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateVatDeduction);
    }
};

// DateTaxableSupply for AP documents
export const addDateTaxableSupplyForAP = (definition: IDefinition, hasAccountAssignment: boolean, context: IAppContext, isRequired?: boolean): void => {
    definition.table.columnDefinition.DateTaxableSupply = {};
    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        DateTaxableSupply: {}
    };

    // read only field with value mirroring DateAccountingTransaction
    definition.form.fieldDefinition.DateTaxableSupply = {
        defaultValue: !hasAccountAssignment ? getWorkDateCallback : definition.form.fieldDefinition.DateAccountingTransaction?.defaultValue ?? getWorkDateCallback,
        affectedFields: [
            { id: SAVED_VATS_PATH }
        ]
    };

    if (isRequired) {
        definition.form.fieldDefinition.DateTaxableSupply.isRequired = true;
    }

    if (isVatRegisteredCompany(context)) {
        addDateGroupDateField(definition.form, InvoiceReceivedEntity.DateTaxableSupply);
    }
};

export const enhanceReceivedDocDefinition = (
    definition: IDefinition,
    settings: {
        docType: DocumentTypeCode;
        transFile: string;
        isBusinessPartnerOptional?: boolean;
        hasAccountAssignment: boolean;
        withoutAccruals?: boolean;
        context: IAppContext;
    }
): void => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition
    };

    addCommonDocumentDefs(definition, settings.docType);
    addBankAccountTableDefs(definition);
    addBusinessPartnerTableDefs(definition);
    addAmountsTableDefs(definition);
    addNumberTheirs(definition, { addVatValidation: true });
    addDateReceived(definition, true);
    addDateVATDeduction(definition, settings.context);
    addDateIssued(definition);
    addBusinessPartnerFieldDefs(definition, settings.docType, settings.transFile, settings.isBusinessPartnerOptional);
    addAssetDef(definition);
    addMinorAssetDef(definition, settings.context);
    addDateTaxableSupplyForAP(definition, settings.hasAccountAssignment, settings.context);

    if (settings.hasAccountAssignment) {
        addAccountAssignmentTableDefs(definition);
        addTimeResolutionDef(definition);

        if (!settings.withoutAccruals) {
            addAccrualsDef(definition, { isExpense: true, addAction: true });
        }
    }
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = !isCashBasisAccountingCompany(context);

    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.InvoicesReceived,
        documentType: DocumentTypeCode.InvoiceReceived,
        tableId: `${EntityTypeName.InvoiceReceived}Table`,
        formId: `${EntityTypeName.InvoiceReceived}Form`,
        formControl: InvoicesReceivedFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("InvoicesReceived:Breadcrumbs.NewInvoice"),
                storage.data.entity?.NumberOurs && i18next.t("InvoicesReceived:Breadcrumbs.InvoiceWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InvoicesReceived],
        context
    }));

    enhanceReceivedDocDefinition(definition, {
        docType: DocumentTypeCode.InvoiceReceived,
        transFile: "InvoicesReceived",
        hasAccountAssignment,
        context
    });

    addCorrectiveDocument(definition, hasAccountAssignment);
    addPaymentOrderTableDefs(definition);
    addItemsSummaryDef(definition, context);
    addProformaDef(definition, hasAccountAssignment);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["InvoicesReceived", ...AssetTranslations, ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.InvoiceReceived, getDefinitions);