import ReadOnlyList from "@components/readOnlyList/ReadOnlyList";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { IPrEmployeeEntity } from "@odata/GeneratedEntityTypes";
import { getValue } from "@utils/general";
import React from "react";

import { SmartHeaderStyled } from "../../../views/formView/FormView.styles";
import TableView from "../../../views/table";
import { TableButtonsAction, TableButtonsActionType } from "../../../views/table/TableToolbar";
import { ITableViewBaseProps } from "../../../views/table/TableView";


interface IProps extends ITableViewBaseProps {
}

class TaxpayerDeclarationTableView extends TableView<IProps> {
    get shouldRenderBreadcrumbs(): boolean {
        return false;
    }

    get secondaryTitle(): string {
        return this.props.storage.t("TaxpayerDeclaration:Table.SecondaryTitle");
    }

    get headerData(): IReadOnlyListItem[] {
        const rootStorage = this.props.storage.getCustomData()?.rootStorage;
        const employee = rootStorage?.data.entity as IPrEmployeeEntity;

        return [{
            label: this.props.storage.t("TaxpayerDeclaration:Table.EmployeeFirstName"),
            value: employee?.FirstName
        }, {
            label: this.props.storage.t("TaxpayerDeclaration:Table.EmployeeLastName"),
            value: employee?.LastName
        }, {
            label: this.props.storage.t("TaxpayerDeclaration:Table.EmployeeNumber"),
            value: employee?.NumberOurs
        }];
    }

    getToolbarButtons(): TableButtonsActionType[] {
        return [
            TableButtonsAction.CSVExport,
            TableButtonsAction.XLSXExport,
            TableButtonsAction.PdfExport,
            TableButtonsAction.Sorting,
            TableButtonsAction.Settings
        ];
    }

    renderSmartHeader(): React.ReactElement {
        return (<>
            <ReadOnlyList title={this.secondaryTitle}
                          data={getValue(this.headerData ?? [])}
            />
            <SmartHeaderStyled
                    storage={this.props.storage}
                    title={this.props.storage.data.definition.title}
                    hotspotId={"tableHeader"}
                    shouldHideVariant={!!this.props.storage.data.predefinedFilters || this.props.storage.data.definition.preventStoreVariant}/>
        </>);
    }
}

export default TaxpayerDeclarationTableView;