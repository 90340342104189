import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { buildTreeFromAllItems } from "@components/inputs/select/SelectAPI";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { EntityTypeName, ILabelEntity, ILabelHierarchyEntity, LabelHierarchyEntity } from "@odata/GeneratedEntityTypes";
import { prepareQuery } from "@odata/OData.utils";
import { cloneDeep } from "lodash";
import React from "react";
import { DefaultTheme } from "styled-components";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { QueryParam } from "../../enums";
import { AwaitedArray, toTuple } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import { ROUTE_LABEL_HIERARCHIES } from "../../routes";
import { setBreadCrumbs } from "../../views/formView/Form.utils";
import { IContextInitArgs } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { hierarchyLoadFieldDefs } from "./LabelFormView";
import { getUsedLabels } from "./Labels.utils";
import {
    IExtendedLabelHierarchyEntity,
    labelColors,
    LabelHierarchyIsActivePath,
    LabelHierarchyParentPath
} from "./labelsHierarchyDef";

interface IProps extends IFormViewProps<IExtendedLabelHierarchyEntity>, WithConfirmationDialog {

}

class LabelsHierarchyFormView extends FormViewForExtend<IExtendedLabelHierarchyEntity, IProps> {
    static contextType = AppContext;

    _refScroll = React.createRef<HTMLElement>();

    getAdditionalLoadPromise = (args: IContextInitArgs) => {
        if (args.bindingContext.isNew()) {
            const query = prepareQuery({
                oData: this.props.storage.oData,
                bindingContext: args.bindingContext.removeKey(),
                fieldDefs: hierarchyLoadFieldDefs
            });
            return toTuple([query.fetchData<ILabelHierarchyEntity[]>()]);
        }

        return undefined;
    };

    // todo do we want to use this everywhere? could it be done generically on FormView?
    getAdditionalResults(): AwaitedArray<ReturnType<LabelsHierarchyFormView["getAdditionalLoadPromise"]>> {
        return this.props.storage.data.additionalResults;
    }

    getParentSelectInfo = (): IFieldInfo => {
        return this.props.storage.getInfo(this.props.storage.data.bindingContext.navigate(LabelHierarchyParentPath));
    };

    getUnusedColor = (): string => {
        const hierarchies = this.getAdditionalResults()[0];
        const usedColors = hierarchies.value.map((item) => item.Color);

        let unusedColor = labelColors[0];

        for (const color of labelColors) {
            if (usedColors.indexOf(color) === -1) {
                unusedColor = color;
                break;
            }
        }

        return unusedColor;
    };

    onAfterLoad = async () => {
        setBreadCrumbs(this.props.storage.getThis());

        const modelBc = this.props.storage.data.bindingContext;

        if (modelBc.isNew()) {
            // set as default color for hierarchy first unused from defined array
            const hierarchies = this.getAdditionalResults()[0].value;
            const unusedColor = this.getUnusedColor();

            this.props.storage.setValueByPath(LabelHierarchyEntity.Color, unusedColor);
            this.setDefaultParent();

            let items: ISelectItem[] = [];

            for (const hierarchy of hierarchies) {
                const labels = hierarchy.Labels.filter((label: ILabelEntity) => !label.Parent.Id);
                items.push({
                    label: hierarchy.Name,
                    // use Name as id instead of Id.
                    // we use both LabelHierarchies and Labels in one Select,
                    // => different entities, they could have same id and we need to differentiate between them.
                    // WARNING: this means we have to expect Name instead of Id in other handle functions.
                    id: hierarchy.Name,
                    additionalData: {
                        Id: hierarchy.Id,
                        Labels: labels,
                        // buildTreeFromAllItems expects children to be in Children
                        Children: labels,
                        Color: hierarchy.Color,
                        type: EntityTypeName.LabelHierarchy
                    }
                });

                for (const label of hierarchy.Labels) {
                    const labelHierarchy = { ...hierarchy };

                    items.push({
                        label: label.Name,
                        id: label.Id,
                        color: this.props.storage.theme[hierarchy.Color as keyof DefaultTheme],
                        additionalData: {
                            Parent: label.Parent.Id ? label.Parent : { Id: hierarchy.Name },
                            LabelHierarchy: labelHierarchy,
                            Children: label.Children,
                            IsActive: label.IsActive,
                            type: EntityTypeName.Label
                        }
                    });
                }
            }

            items = buildTreeFromAllItems(items, "Id");
            this.getParentSelectInfo().fieldSettings.items = items;
        }

        this.props.storage.refresh();
        return super.onAfterLoad();
    };

    // used here and in CoA to preselect parent based on the row that were selected when user clicked on "Add" button
    setDefaultParent = (): void => {
        const hierarchies = this.getAdditionalResults()[0].value;
        const defaultParent: BindingContext = this.props.storage.getCustomData().Parent;
        let hierarchy: ILabelHierarchyEntity;

        if (defaultParent) {
            let parentId: string | number;

            if (defaultParent.getPath(true) === LabelHierarchyEntity.Labels) {
                // Label - store its id, which is used as select item id
                parentId = defaultParent.getKey();
                hierarchy = hierarchies.find(hierarchy => hierarchy.Id === defaultParent.getRootParent().getKey());
            } else {
                // LabelHierarchy - store its Name, which is used as select item id
                hierarchy = hierarchies.find(h => h.Id === defaultParent.getKey());
                parentId = hierarchy.Name;
            }

            this.props.storage.setValueByPath(LabelHierarchyParentPath, parentId);
            this.props.storage.setValueByPath(LabelHierarchyEntity.Color, hierarchy.Color);
        }
    };

    handleParentChange = (e: ISmartFieldChange) => {
        if (e.triggerAdditionalTasks && e.bindingContext.getPath() === LabelHierarchyParentPath) {
            const isParentHierarchy = e.additionalData?.type === EntityTypeName.LabelHierarchy;
            let parentBc: BindingContext;
            let color: string;


            if (e.value === null) {
                parentBc = null;
                color = this.getUnusedColor();
            } else if (isParentHierarchy) {
                // label hierarchy
                parentBc = this.props.storage.data.bindingContext.removeKey().addKey(e.additionalData.Id);
                color = e.additionalData.Color;
            } else {
                // label
                const hierarchyBc = this.props.storage.data.bindingContext.removeKey().addKey(e.additionalData.LabelHierarchy.Id);

                parentBc = hierarchyBc.navigate(LabelHierarchyEntity.Labels).addKey(e.value as number);
                color = this.getAdditionalResults()[0].value.find(hierarchy => hierarchy.Id === e.additionalData.LabelHierarchy.Id).Color;
            }

            this.props.storage.setValueByPath(LabelHierarchyEntity.Color, color);
            // change Parent and rerender table to change position of the new row
            this.props.storage.setCustomData({
                Parent: parentBc
            });
            this.props.onTableRefreshNeeded({
                modelRefreshOnly: true
            });

            this.props.storage.refreshGroupByKey("mainGroup");
        }
    };

    handleChange = (e: ISmartFieldChange) => {
        this.handleParentChange(e);

        this.props.storage.handleChange(e);

        // triggerAdditionalTasks === true for select like components has same
        // meaning as triggerAdditionalTasks === undefined for the rest types
        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);
    };

    async onBeforeDelete(): Promise<boolean> {
        const labels = this.entity.Labels ?? [];

        this.props.storage.setCustomData({
            isDeleteButtonBusy: true
        });
        this.forceUpdate();

        let shouldConfirm = true;
        const isLabelUsed = await getUsedLabels(labels.map(label => label.Id));

        this.props.storage.setCustomData({
            isDeleteButtonBusy: false
        });
        this.forceUpdate();

        if (isLabelUsed.length > 0) {
            shouldConfirm = await this.props.confirmationDialog.open({
                content: this.props.storage.t("Labels:RemoveConfirmationText", { labels: labels.map(label => label.Name).join(", ") })
            });
        }

        return shouldConfirm;
    }

    save = async () => {
        this.props.onBeforeSave();

        const entity = cloneDeep(this.entity);
        const isHierarchy = !entity[LabelHierarchyParentPath];
        const isNew = this.props.storage.data.bindingContext.isNew();
        let bindingContext: BindingContext;
        let hierarchyId: number;

        if (isHierarchy) {
            if (isNew) {
                entity.Company = { Id: this.context.getCompany().Id };
            }
            bindingContext = this.props.storage.data.bindingContext;
        } else {
            const parentId = entity[LabelHierarchyParentPath];
            const parentSelectItems = this.getParentSelectInfo().fieldSettings.items;
            const parentItem = parentSelectItems.find(i => i.id === parentId);
            const isParentHierarchy = parentItem?.additionalData?.type === EntityTypeName.LabelHierarchy;

            hierarchyId = isParentHierarchy ? parentItem.additionalData.Id : parentItem.additionalData.LabelHierarchy.Id;
            bindingContext = this.props.storage.data.bindingContext.removeKey().addKey(hierarchyId).navigate(LabelHierarchyEntity.Labels).addKey(0, true);
            (entity as ILabelEntity).Parent = !isParentHierarchy && parentId ? { Id: parentId as number } : null;
            (entity as ILabelEntity).IsActive = entity[LabelHierarchyIsActivePath];
            delete entity.Color;
        }

        const result = await this.props.storage.save({
            successSubtitle: this.props.storage.t("Labels:Validation.Saved"),
            data: entity,
            bindingContext
        });

        if (!result) {
            this.props.onSaveFail();
        } else {
            if (isHierarchy) {
                setBreadCrumbs(this.props.storage.getThis());
                this.onAfterSave(isNew, false);
            } else {
                // custom handling for when Label is created from LabelHierarchy form,
                // redirect to Label form and manually refresh table
                const pathname = `${ROUTE_LABEL_HIERARCHIES}/${hierarchyId}/${LabelHierarchyEntity.Labels}/${result.data.Id}`;
                this.props.storage.history.push({ pathname, search: `${QueryParam.NewLabelSaved}=true` });
                this.props.onTableRefreshNeeded();
            }
        }
        this.forceUpdate();

        return result;
    };
}

export default withConfirmationDialog(withPermissionContext(LabelsHierarchyFormView));