import {
    IComplexFilter,
    isComplexFilter,
    isComplexFilterArr
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { ISelectItem, SelectGroups } from "@components/inputs/select/Select.types";
import { SwitchType } from "@components/inputs/switch/Switch";
import {
    getInfoValue,
    ifAll,
    ifAny,
    IFieldDef,
    IFieldInfoProperties,
    IGetValueArgs,
    isFieldDisabled,
    isRequired,
    isVisible,
    TInfoValue,
    TValidatorFn
} from "@components/smart/FieldInfo";
import {
    AccountDefFormatter,
    AccountSettingsAccountsSelectPath,
    countryDef,
    CurrencyDef,
    fourDigitsFormatter,
    getBalanceSheetLayoutAccount,
    getSavedAccountsDef,
    IPaymentRelatedEntityCustomData,
    SymbolConstantDef,
    SymbolSpecificDef,
    SymbolVariableDef
} from "@components/smart/GeneralFieldDefinition";
import { getCollapsedGroupId } from "@components/smart/Smart.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { fetchItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import { getBoundValue, mainBatchRequestId } from "@odata/Data.utils";
import { getDocumentTypeCodeFromEntityType } from "@odata/EntityTypes";
import {
    BankAccountEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    IAccountEntity,
    IBankAccountEntity,
    IBankEntity,
    IBankStatementEntity,
    IBankTransactionEntity,
    IBusinessPartnerBankAccountEntity,
    IBusinessPartnerEntity,
    ICashBoxCreateReceiptParameters,
    ICashBoxEntity,
    ICompanyBankAccountEntity,
    ICurrencyEntity,
    IDocumentEntity,
    IPrDeductionEntity,
    OdataActionName
} from "@odata/GeneratedEntityTypes";
import {
    ClearedStatusCode,
    CompanyPermissionCode,
    CountryCode,
    DocumentTypeCode,
    PaymentMethodCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import { getEnumEntities } from "@odata/GeneratedEnums.utils";
import { BatchRequest } from "@odata/OData";
import { createFilterString, ICreateFilterStringSettings, IFormatOptions } from "@odata/OData.utils";
import { getAgendaCountryCode } from "@pages/companies/Company.shared.utils";
import { IDocumentExtendedEntity } from "@pages/documents/DocumentInterfaces";
import {
    AbaGroupField,
    AccountNumberGroupField,
    createAccountFromIBAN,
    createBankAccountsItems,
    createIBANFromAccount,
    IAllBankAccount,
    ibanFormatter,
    ibanValidator,
    IHandleBlur,
    isBankAbaVisible,
    isBankAccountVisible,
    isBankCodeCountry,
    isBankCodeVisible,
    isIBANVisible
} from "@utils/BankUtils";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import customFetch, { getDefaultPostParams } from "@utils/customFetch";
import { compareDefined, compareDefinedArrays, forEachKey, getValue, isDefined, isNotDefined } from "@utils/general";
import { compareString, removeWhiteSpace, trimLeadingZeroes } from "@utils/string";
import i18next from "i18next";
import * as yup from "yup";
import { ValidationError } from "yup";

import { BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX, BANK_STATEMENTS, DASH_CHARACTER } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import {
    BankAccountType,
    BasicInputSizes,
    CacheStrategy,
    FastEntryInputSizes,
    FieldType,
    GroupedField,
    NavigationSource,
    ValidatorType
} from "../../../enums";
import { TValue } from "../../../global.types";
import { TableStorage } from "../../../model/TableStorage";
import { getCorrectValidationArgs } from "../../../model/Validator";
import { ValidationMessage } from "../../../model/Validator.types";
import BindingContext, { IEntity, TEntityKey } from "../../../odata/BindingContext";
import CurrencyType from "../../../types/Currency";
import NumberType from "../../../types/Number";
import { setGroupStatus } from "../../../views/formView/Form.utils";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { IChangedFilter } from "../../../views/table/TableView.utils";
import { generalAccountProps } from "../../accountAssignment/AccountAssignment.utils";
import {
    accountPartnerDepFields,
    hasBusinessPartner,
    setSavedContactItems
} from "../../businessPartner/BusinessPartner.utils";
import { getBasicCashBoxSelectDef } from "../../cashBoxes/cashReceipts/CashReceipts.utils";
import { getAccountUsageAcrossCharts } from "../../chartOfAccounts/ChartOfAccounts.utils";
import { getCompanyUsedCurrencyCodes } from "../../companies/Company.utils";
import { isIssued, isMatchingAccount, isReceived } from "../../documents/Document.utils";
import { TFieldDefinition, TFieldsDefinition } from "../../PageUtils";


export const BankAccountDependentFields = [{
    from: { id: "AccountNumber" },
    to: { id: "BankAccount/AccountNumber" }
}, {
    from: { id: "BankCode" },
    to: { id: "BankAccount/Bank" }
}, {
    from: { id: "AbaNumber" },
    to: { id: "BankAccount/AbaNumber" }
}, {
    from: { id: "IBAN" },
    to: { id: "BankAccount/IBAN" }
}, {
    from: { id: "SWIFT" },
    to: { id: "BankAccount/SWIFT" }
}, {
    from: { id: "PaymentServiceID" },
    to: { id: "BankAccount/PaymentServiceID" }
}, {
    from: { id: "PaymentServiceName" },
    to: { id: "BankAccount/PaymentServiceName" }
},
    {
        from: { id: "Country" },
        to: { id: "BankAccount/Country" }
    }
];

export const SAVED_ACCOUNTS_PATH = BindingContext.localContext("SavedAccounts");
export const CREATE_RECEIPT_PATH = "##CreateReceipt##";
export const BankAccountAdditionalProps = [
    { id: "BankAccount/PaymentServiceID" },
    { id: "BankAccount/PaymentServiceName" }
];

/**
 * Returns undefined if paymentMethod is not defined at all on the entity,
 * otherwise returns paymentMethod or null if paymentMethod is not set
 * @param args
 */
const getPaymentMethod = (args: IGetValueArgs): PaymentMethodCode => {
    const propName = "PaymentMethod";
    // forms without payment method has this field always visible (f.e. bank transaction)
    if (!args.storage.data.bindingContext.getEntityType().getProperty(propName)) {
        return undefined;
    }

    const methodBc = args.storage.data.bindingContext.navigate(propName);
    const hasPaymentMethodInfo = !!args.storage.getInfo(methodBc);
    if (!hasPaymentMethodInfo) {
        return undefined;
    }

    const method = getBoundValue({
        dataBindingContext: args.storage.data.bindingContext,
        data: args.storage.data.entity,
        bindingContext: methodBc
    });

    return (method?.Code as PaymentMethodCode) ?? null;
};

export const visibleForAllButCash = (args: IGetValueArgs): boolean => {
    const code = getPaymentMethod(args);
    return code !== PaymentMethodCode.Cash;
};

export const isBankAccountFieldVisible = (args: IGetValueArgs): boolean => {
    const code = getPaymentMethod(args);
    return code === undefined || code === PaymentMethodCode.WireTransfer;
};

export const isPaymentServiceFieldVisible = (args: IGetValueArgs, code?: PaymentMethodCode): boolean => {
    code = code || getPaymentMethod(args);
    const type = (args.storage.data.entity as IDocumentEntity)?.DocumentTypeCode as DocumentTypeCode;
    return code === PaymentMethodCode.PaymentService && isIssued(type);
};

const isSavedAccountsFieldVisible = (args: IGetValueArgs): boolean => {
    const code = getPaymentMethod(args);
    return code === undefined || code === PaymentMethodCode.WireTransfer || isPaymentServiceFieldVisible(args, code);
};

export const paymentDetailFields = [{ id: "SymbolVariable" }, { id: "SymbolSpecific" }, { id: "SymbolConstant" }, { id: "RemittanceInformation" }];

export const paymentMethodAffectedFields = [
    { id: "BankAccount/Country" },
    { id: "BankAccount/AccountNumber" },
    { id: "BankAccount/Bank" },
    { id: "BankAccount/IBAN" },
    { id: "BankAccount/SWIFT" },
    { id: "BankAccount/AbaNumber" }
    // ...specificBankFields,
    // { id: CREATE_RECEIPT_PATH },
    // { id: "CashBox" }
];

export const PaymentMethodFieldDef = {
    type: FieldType.ComboBox,
    cacheStrategy: CacheStrategy.EndOfTime,
    fieldSettings: {
        displayName: "Name"
    },
    defaultValue: PaymentMethodCode.WireTransfer,
    affectedFields: paymentMethodAffectedFields
};

export const BankAccountGroupCollapsedRowsDef = [[
    { id: "BankAccount/Country" }
], [
    { id: "BankAccount/AbaNumber" },
    { id: "BankAccount/AccountNumber" },
    { id: "BankAccount/Bank" },
    { id: "BankAccount/IBAN" },
    { id: "BankAccount/SWIFT" }
]];

export const getBankAccountGroupDef = (addPaymentDetails = false): IFormGroupDef => {
    const def = {
        id: "payment",
        title: i18next.t("Document:FormGroup.Payment"),
        rows: [[
            { id: "PaymentMethod" }, { id: CREATE_RECEIPT_PATH }, { id: "CashBox" }, { id: SAVED_ACCOUNTS_PATH }
        ]]
    };
    if (addPaymentDetails) {
        def.rows.push(paymentDetailFields);
    }
    return def;
};

export const handleCreateReceiptsChange = (e: ISmartFieldChange, storage: FormStorage): void => {
    if (e.bindingContext.getPath() === CREATE_RECEIPT_PATH) {
        onCreateReceiptChange(!!e.value, storage);
    }
};

const onCreateReceiptChange = (value: boolean, storage: FormStorage): void => {
    if (!value) {
        storage.setValueByPath("CashBox", null);
    } else {
        storage.setDefaultValueByPath("CashBox");
    }
};

const isCorrectBankAccountNumber = (number: string) => {
    const weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
    let sum = 0;
    for (let i = 1; i <= number.length; ++i) {
        sum += weights[weights.length - i] * parseInt(number[number.length - i]);
    }
    return (sum % 11) === 0;
};

export const accountNumberValidator: TValidatorFn = (value: TValue, { storage, bindingContext }) => {
    let errorKey: string;
    if (value && typeof value === "string") {
        const bankAccount = storage.getValue(bindingContext.getParent());
        const countryCode = bankAccount.CountryCode ?? bankAccount.Country?.Code;
        switch (countryCode) {
            case CountryCode.CzechRepublic:
                const matches = value.match(/^(([0-9]{1,6})-)?([0-9]{2,10})$/);
                if (!matches) {
                    errorKey = "WrongAccountNumber";
                } else if (!isCorrectBankAccountNumber(matches[3]) || (matches[2] && !isCorrectBankAccountNumber(matches[2]))) {
                    errorKey = "WrongAccountNumberChecksum";
                }
                break;
            default:
                // todo: enhance the check for other country bank account numbers
                if (!value.match(/^[0-9]+$/)) {
                    errorKey = "WrongAccountNumber";
                }
        }
    }
    return errorKey
        ? new ValidationError(i18next.t(`Common:General.${errorKey}`), value, bindingContext.getNavigationPath())
        : true;
};

export function getCustomerPaymentBankAccountsFieldBaseDef() {
    return {
        Country: { ...countryDef, isReadOnly: true, clearIfInvisible: false },
        AbaNumber: {
            isReadOnly: true,
            width: FastEntryInputSizes.M,
            isVisible: isBankAbaVisible,
            clearIfInvisible: false
        },
        AccountNumber: {
            isReadOnly: true,
            width: FastEntryInputSizes.M,
            isVisible: isBankAbaVisible,
            clearIfInvisible: false
        },
        IBAN: {
            isReadOnly: true,
            type: FieldType.Input,
            groupedField: GroupedField.MultiStart,
            width: BasicInputSizes.XL,
            isVisible: isIBANVisible,
            formatter: ibanFormatter,
            clearIfInvisible: false
        },
        SWIFT: {
            isReadOnly: true,
            width: FastEntryInputSizes.S,
            groupedField: GroupedField.MultiEnd,
            isVisible: isIBANVisible,
            clearIfInvisible: false
        }
    };
}

type TBankAccountField = "Country" | "AbaNumber" | "AccountNumber" | "Bank" | "IBAN" | "SWIFT";

export function getBankAccountsFieldBaseDef(): Record<TBankAccountField, TFieldDefinition> {
    return {
        Country: {
            ...countryDef,
            isRequired: true,
            affectedFields: [
                { id: "AccountNumber", navigateFromParent: true },
                { id: "Bank", navigateFromParent: true },
                { id: "IBAN", navigateFromParent: true },
                { id: "SWIFT", navigateFromParent: true },
                { id: "AbaNumber", navigateFromParent: true }],
            customizationData: {
                useForCustomization: false
            }
        },
        AbaNumber: {
            width: FastEntryInputSizes.M,
            groupedField: AbaGroupField,
            isRequired: true,
            isVisible: isBankAbaVisible,
            validator: {
                type: ValidatorType.String,
                settings: {
                    length: 9
                }
            },
            customizationData: {
                useForCustomization: false
            }
        },
        AccountNumber: {
            width: FastEntryInputSizes.M,
            isRequired: true,
            groupedField: AccountNumberGroupField,
            isVisible: isBankAccountVisible,
            validator: {
                type: ValidatorType.Custom,
                settings: {
                    customValidator: accountNumberValidator
                }
            },
            customizationData: {
                useForCustomization: false
            }
        },
        Bank: {
            type: FieldType.ComboBox,
            groupedField: GroupedField.MultiEnd,
            label: i18next.t("Common:Form.BankCode"),
            fieldSettings: {
                displayName: "Code",
                preloadItems: true,
                localDependentFields: [
                    { from: { id: "Code" }, to: { id: "BankCode" }, navigateFrom: NavigationSource.Parent },
                    { from: { id: "SWIFT" }, to: { id: "SWIFT" }, navigateFrom: NavigationSource.Parent }
                ]
            },
            columns: [
                {
                    id: "Code",
                    additionalProperties: [{ id: "/SWIFT" }, { id: "/LogoId" }]
                },
                { id: "Name" }
            ],
            customizationData: {
                useForCustomization: false
            },
            width: FastEntryInputSizes.S,
            isRequired: true,
            isVisible: isBankCodeVisible
        },
        IBAN: {
            type: FieldType.Input,
            groupedField: GroupedField.MultiStart,
            width: BasicInputSizes.XL,
            isVisible: isIBANVisible,
            parser: removeWhiteSpace,
            isRequired: true,
            formatter: ibanFormatter,
            validator: {
                type: ValidatorType.Custom,
                settings: {
                    customValidator: ibanValidator,
                    message: i18next.t("Common:General.WrongIBAN")
                }
            },
            customizationData: {
                useForCustomization: false
            }
        },
        SWIFT: {
            width: FastEntryInputSizes.S,
            groupedField: GroupedField.MultiEnd,
            isVisible: isIBANVisible,
            isRequired: true,
            customizationData: {
                useForCustomization: false
            },
            validator: {
                type: ValidatorType.Custom,
                settings: {
                    customSchema: (args: IGetValueArgs) => {
                        return yup.string().nullable()
                            .test("required", ValidationMessage.Required,
                                function(this: yup.TestContext, value: TValue) {
                                    // we need to use getCorrectValidationArgs to get correct binding context of items
                                    const innerArgs = getCorrectValidationArgs(args.storage, args.storage.getInfo(args.bindingContext), this.path);

                                    if (isVisible(innerArgs) && isRequired(innerArgs)) {
                                        return !!value;
                                    }

                                    return true;
                                })
                            .test("swift", i18next.t("Banks:Validation.WrongSWIFTFormat"),
                                function(this: yup.TestContext, value: TValue) {
                                    const swift = value as string;
                                    return !swift || (swift.length >= 8 && swift.length <= 11);
                                });
                    }
                }
            }
        }
    };
}

function isPaymentService({ storage }: IGetValueArgs): boolean {
    const paymentMethod = storage.getValueByPath("PaymentMethod", { useDirectValue: false });
    return paymentMethod === PaymentMethodCode.PaymentService;
}

interface BankAccountFieldsDef {
    addPaymentMethod: boolean;
    type: DocumentTypeCode;
    savedAccountLabel?: string;
    alwaysShowSymbolVariable?: boolean;
    areFieldsDisabled?: TInfoValue<boolean>;
    isVisible?: TInfoValue<boolean>;
}

function getSavedBankAccountAdditionalItem(isNew = true): ISelectItem {
    return {
        id: SelectionCode.Own,
        label: i18next.t(`Common:General.${isNew ? "New" : SelectionCode.Own}`),
        groupId: SelectGroups.Default
    };
}

export const isCreateReceiptFieldVisible = (args: IGetValueArgs): boolean => {
    const storage = args.storage as FormStorage<IDocumentExtendedEntity>;

    if (storage.data.entity.PaymentMethod?.Code !== PaymentMethodCode.Cash) {
        return false;
    }

    const entity = args.storage.getEntity<IDocumentEntity>();
    const transactionCurrency = entity.TransactionCurrency?.Code;

    const atLeastOneCashBoxAvailable = (args.storage as FormStorage).context.getCashBoxes(true).some(cashBox => {
        return cashBox.TransactionCurrencyCode === transactionCurrency;
    });

    return atLeastOneCashBoxAvailable;
};

export const getBankAccountFieldsDef = (args: BankAccountFieldsDef): TFieldsDefinition => {
    const _isIssued = isIssued(args.type);
    const _isReceivable = isReceived(args.type);

    const isFieldRequired = (args: IGetValueArgs): boolean => {
        const savedAccValue = args.storage.data.entity[SAVED_ACCOUNTS_PATH];
        return !_isReceivable || !!savedAccValue;
    };

    const _isBankAccountFieldVisible = (e: IGetValueArgs) => {
        if (args.isVisible) {
            return getValue(args.isVisible, e);
        }
        return args.addPaymentMethod ? isBankAccountFieldVisible(e) : true;
    };

    const { Country: BaseCountryDef, ...restBaseBankAccountDefs } = getBankAccountsFieldBaseDef();

    const isSpecificBankFieldVisible = (e: IGetValueArgs) => {
        if (args.alwaysShowSymbolVariable) {
            return true;
        }

        const country = e.storage.getValueByPath("BankAccount/Country", { useDirectValue: false });
        return _isBankAccountFieldVisible(e) && (country === CountryCode.CzechRepublic || isNotDefined(country));
    };


    const def: Record<string, IFieldInfoProperties> = {
        "BankAccount/Country": {
            ...BaseCountryDef,
            isVisible: _isBankAccountFieldVisible,
            isRequired: isFieldRequired,
            comparisonFunction: (entity: IDocumentEntity, origEntity: IDocumentEntity) => {
                return (_isEmptyBankAccount(entity.BankAccount) && _isEmptyBankAccount(origEntity.BankAccount)) ||
                    origEntity.BankAccount?.Country?.Code === entity.BankAccount?.Country?.Code;
            },
            isDisabled: args.areFieldsDisabled,
            affectedFields: paymentMethodAffectedFields
        },
        SymbolVariable: {
            ...SymbolVariableDef,
            isDisabled: args.areFieldsDisabled,
            isVisible: isSpecificBankFieldVisible
        },
        SymbolSpecific: {
            ...SymbolSpecificDef,
            isDisabled: args.areFieldsDisabled,
            isVisible: isSpecificBankFieldVisible
        },
        SymbolConstant: {
            ...SymbolConstantDef,
            isDisabled: args.areFieldsDisabled,
            isVisible: isSpecificBankFieldVisible
        },
        RemittanceInformation: {
            isDisabled: args.areFieldsDisabled,
            isVisible: isBankAccountFieldVisible
        }
    };

    forEachKey(restBaseBankAccountDefs, (key) => {
        def[`BankAccount/${key}`] = {
            ...restBaseBankAccountDefs[key],
            isVisible: ifAll(_isBankAccountFieldVisible, restBaseBankAccountDefs[key].isVisible),
            isRequired: isFieldRequired,
            isDisabled: args.areFieldsDisabled
        };
    });

    const _savedAccDef = getSavedAccountsDef();
    const _savedAccounts = {
        label: args.savedAccountLabel ?? i18next.t("Common:Form.SavedAccounts"),
        width: _isIssued ? BasicInputSizes.XXL : BasicInputSizes.L,
        ..._savedAccDef,
        fieldSettings: {
            ..._savedAccDef.fieldSettings,
            additionalItems: [getSavedBankAccountAdditionalItem()],
            noRecordText: isReceived(args.type) ? i18next.t("Common:Select.NoRecord") : undefined,
            localDependentFields: BankAccountDependentFields,
            shouldDisplayAdditionalColumns: true,
            showTabularHeader: false
        },
        creatingTitle: i18next.t("Common:General.NewAccount"),
        collapsedRows: BankAccountGroupCollapsedRowsDef,
        isVisible: isSavedAccountsFieldVisible,
        isDisabled: args.areFieldsDisabled
    };

    if (_isIssued) {
        _savedAccounts.columns = [{
            id: "Account"
        }, {
            id: "CurrencyCode"
        }, {
            id: "Name"
        }];
        _savedAccounts.isRequired = isPaymentService;
    }

    def[SAVED_ACCOUNTS_PATH] = _savedAccounts;

    if (args.addPaymentMethod) {
        const basicCashBoxSelectDef = getBasicCashBoxSelectDef();
        def.PaymentMethod = {
            ...PaymentMethodFieldDef,
            fieldSettings: {
                ...PaymentMethodFieldDef.fieldSettings,
                ...(_isIssued ? {
                    transformFetchedItems: (items: ISelectItem[], args: IGetValueArgs): ISelectItem[] => {
                        // remove payment service if there are no company account of that type
                        const companyBankAccounts = args.storage.context.getCompanyBankAccounts(true);
                        const hasPaymentServiceAccount = companyBankAccounts.find(account => !!account.PaymentServiceID);
                        if (!hasPaymentServiceAccount) {
                            return items.filter(item => item.id !== PaymentMethodCode.PaymentService);
                        }
                        return items;
                    }
                } : {})
            },
            customizationData: {
                dependents: [CREATE_RECEIPT_PATH, "CashBox"]
            }
        };

        def[CREATE_RECEIPT_PATH] = {
            type: FieldType.Switch,
            label: i18next.t("Document:Form.CreateReceipt"),
            fieldSettings: {
                type: SwitchType.YesNo
            },
            isDisabled: (args) => {
                return !args.storage.context.getCompanyPermissions().has(CompanyPermissionCode.CashBox);
            },
            defaultValue: (args) => {
                // only set true for new entity
                return args.bindingContext.isNew() && args.storage.context.getCompanyPermissions().has(CompanyPermissionCode.CashBox);
            },
            isVisible: (args) => {
                const storage = args.storage as FormStorage<IDocumentExtendedEntity>;
                return isCreateReceiptFieldVisible(args) && storage.data.entity[DocumentEntity.ClearedStatus]?.Code !== ClearedStatusCode.Cleared
                    // should be hidden in saved entity with changed draft also
                    && storage.data.origEntity.ClearedStatus?.Code !== ClearedStatusCode.Cleared;
            },
            affectedFields: [
                { id: "CashBox" }
            ],
            customizationData: {
                useForCustomization: true
            },
            useForComparison: false
        };
        def["CashBox"] = {
            ...basicCashBoxSelectDef,
            isVisible: (args) => {
                return isCreateReceiptFieldVisible(args) && !!args.storage.getValueByPath(CREATE_RECEIPT_PATH);
            },
            isReadOnly: (args) => {
                const storage = args.storage as FormStorage<IDocumentExtendedEntity>;

                return storage.data.entity[DocumentEntity.ClearedStatus]?.Code === ClearedStatusCode.Cleared;
            },
            isRequired: true,
            fieldSettings: {
                ...basicCashBoxSelectDef.fieldSettings,
                itemsForRender: (items: ISelectItem[], args: IGetValueArgs): ISelectItem[] => {
                    const entity = args.storage.getEntity<IDocumentEntity>();
                    const transactionCurrency = entity.TransactionCurrency?.Code;

                    return items.map(item => {
                        const editedItem = { ...item };
                        const additionalData = item.additionalData as ICashBoxEntity;

                        editedItem.isDisabled = !!transactionCurrency && additionalData.TransactionCurrencyCode !== transactionCurrency;

                        return editedItem;
                    });
                }
            },
            customizationData: {
                useForCustomization: false
            }
        };
    }

    return def;
};

export const addCreateReceiptRequest = (storage: FormStorage, batch: BatchRequest, cashBoxId: number): void => {
    if (!cashBoxId) {
        return;
    }
    const wrapper = batch.getEntitySetWrapper(EntitySetName.CashBoxes);

    wrapper.action(OdataActionName.CashBoxCreateReceipt, cashBoxId, {
        Document: { "@odata.id": storage.data.bindingContext.isNew() ? `$${mainBatchRequestId}` : storage.data.bindingContext.toString() }
    } as ICashBoxCreateReceiptParameters);
};

export const NEW_ACCOUNT_CHILD_ITEM = "new";

export interface IBankAccountsCustomData extends IFormStorageDefaultCustomData {
    bankAccounts?: IBusinessPartnerBankAccountEntity[];
    allBankAccounts?: IBankAccountEntity[];
}

const getFullAccountSettingsAccountNumber = (numberPrefix: number, numberSuffix: string) => {
    return `${numberPrefix}${numberSuffix}`;
};

export function hasRelatedEntity(args: IGetValueArgs): boolean {
    return (args.storage as FormStorage<unknown, IPaymentRelatedEntityCustomData>).getCustomData().hasRelatedEntity;
}

export type TEntityWithAccount = ICompanyBankAccountEntity | ICashBoxEntity;
// check if the account is already used in another BankAccount/Cashbox
const isAccountUsedInOtherEntity = (storage: FormStorage<unknown, IPaymentRelatedEntityCustomData>, account: IAccountEntity, accountPrefix: number): boolean => {
    const currentAccount = storage.data.entity as TEntityWithAccount;
    const entities = storage.getCustomData().parentData as TEntityWithAccount[];
    const usedInEntity = entities.find(entity => isMatchingAccount({
        Name: entity.BalanceSheetAccountName,
        Number: getFullAccountSettingsAccountNumber(accountPrefix, entity.BalanceSheetAccountNumberSuffix)
    }, account, true));

    return usedInEntity && usedInEntity.Id !== currentAccount.Id;
};

export const getAccountSettingsSelectDef = (accountPrefix: number): IFieldInfoProperties => {
    const accountDef = generalAccountProps();

    return {
        ...accountDef,
        width: BasicInputSizes.L,
        label: i18next.t("Banks:Accounts.Account"),
        filter: {
            select: (args) => {
                let filter = "";

                const originalFilter = getInfoValue(accountDef?.filter, "select", args);

                if (originalFilter) {
                    filter = originalFilter;
                }

                if (filter) {
                    filter += " AND ";
                }

                filter += `startswith(Number,'${accountPrefix}') AND Number NE '${accountPrefix}'`;

                return filter;
            }
        },
        defaultValue: NEW_ACCOUNT_CHILD_ITEM,
        cacheStrategy: CacheStrategy.None,
        fieldSettings: {
            ...accountDef.fieldSettings,
            additionalProperties: [
                ...(accountDef.fieldSettings?.additionalProperties ?? []),
                { id: "Currency" }
            ],
            // use Number instead of Id, so that we can build initialItem without fetching all items
            idName: "Number",
            localDependentFields: null,
            transformFetchedItems: (items: ISelectItem[], args: IGetValueArgs): ISelectItem[] => {
                // filter out accounts that are already used in other bank account/cashbox
                return items.filter(item => !isAccountUsedInOtherEntity(args.storage as FormStorage, item.additionalData as TEntityWithAccount, accountPrefix));
            },
            additionalItems: [
                {
                    id: NEW_ACCOUNT_CHILD_ITEM,
                    label: i18next.t("Banks:Accounts.NewAccountItem")

                }
            ]
        },
        affectedFields: [
            { id: "BalanceSheetAccountNumberSuffix" },
            { id: "BalanceSheetAccountName" },
            { id: "InitialBalance" }
        ]
    };
};

const isUsingExistingAccount = (args: IGetValueArgs) => {
    const selectedAccount = args.storage.getValueByPath(AccountSettingsAccountsSelectPath);

    return selectedAccount && selectedAccount !== NEW_ACCOUNT_CHILD_ITEM;
};

export const getAccountRelatedAccountSettingsFieldsDef = (accountPrefix: number): TFieldsDefinition => {
    const BalanceSheetAccountNumberSuffixDef = getBalanceSheetLayoutAccount(accountPrefix);

    return {
        [AccountSettingsAccountsSelectPath]: {
            ...getAccountSettingsSelectDef(accountPrefix),
            // map to BE, so that the field isn't disabled when it isn't supposed to
            backendPath: "BalanceSheetAccountName",
            useForComparison: false,
            collapsedRows: [[
                { id: "BalanceSheetAccountNumberSuffix" },
                { id: "BalanceSheetAccountName" }
            ]]
        },
        BalanceSheetAccountNumberSuffix: {
            ...BalanceSheetAccountNumberSuffixDef,
            isReadOnly: ifAny(isUsingExistingAccount)
        },
        BalanceSheetAccountName: {
            label: i18next.t("Banks:Accounts.AccountName"),
            groupedField: GroupedField.MultiEnd,
            isRequired: true,
            isReadOnly: ifAny(isUsingExistingAccount)
        }
    };
};

export const getAccountSettingsFormGroup = (): IFormGroupDef => {
    return {
        id: "accountingSettings",
        title: i18next.t("Banks:Accounts.AccountingSettings"),
        rows: [
            [
                { id: AccountSettingsAccountsSelectPath }
            ]
        ]
    };
};

const isTransactionCurrencyInCompanyCurrency = (args: IGetValueArgs): boolean => {
    const transactionCurrency = args.storage.getValueByPath("TransactionCurrency") as ICurrencyEntity;

    return transactionCurrency?.Code === getCompanyCurrency(args.context);
};

export const initialBalanceParser: (val: string, args?: IFormatOptions) => TValue = (val) => {
    return NumberType.parse(val, false, { maximumFractionDigits: 2 })?.toString();
};

export const getInitialBalanceCurrencyAffectedFields = (): IFieldDef[] => {
    return [
        { id: "InitialBalance" },
        { id: "InitialTransactionBalance" },
        { id: BindingContext.localContext("ExchangeRate") }
    ];
};

export const getInitialBalanceFieldsDef = (): TFieldsDefinition => {
    return {
        TransactionCurrency: {
            ...CurrencyDef,
            isReadOnly: ifAny(hasRelatedEntity,
                (args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<TEntityWithAccount, IPaymentRelatedEntityCustomData>;
                    const usage = storage.getCustomData().accountUsage;

                    return usage?.AvailableAccountedEntityCurrencyCodes.length === 1;
                }
            ),
            width: BasicInputSizes.L,
            affectedFields: getInitialBalanceCurrencyAffectedFields(),
            fieldSettings: {
                ...CurrencyDef.fieldSettings,
                itemsForRender: (items: ISelectItem[], args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<TEntityWithAccount, IPaymentRelatedEntityCustomData>;
                    const usage = storage.getCustomData().accountUsage;

                    if (usage?.AvailableAccountedEntityCurrencyCodes.length > 0) {
                        const availableCurrencyCodes = usage.AvailableAccountedEntityCurrencyCodes;

                        return items.filter(item => availableCurrencyCodes.includes(item.id.toString()));
                    } else {
                        return items;
                    }
                }
            }
        },
        "InitialBalance": {
            width: BasicInputSizes.M,
            type: FieldType.NumberInput,
            isRequired: true,
            defaultValue: 0,
            parser: initialBalanceParser,
            fieldSettings: {
                unit: args => CurrencyType.getCurrencyUnit(getCompanyCurrency(args.context))
            },
            isReadOnly: isUsingExistingAccount,
            isVisible: (args: IGetValueArgs) => {
                return isCashBasisAccountingCompany(args.storage.context);

            },
            affectedFields: [
                { id: BindingContext.localContext("ExchangeRate") }
            ]
        },
        "InitialTransactionBalance": {
            width: BasicInputSizes.M,
            type: FieldType.NumberInput,
            isRequired: true,
            defaultValue: 0,
            parser: initialBalanceParser,
            fieldSettings: {
                unit: (args: IGetValueArgs) => {
                    const transactionCurrency = args.storage.getValueByPath("TransactionCurrency") as ICurrencyEntity;

                    return transactionCurrency?.Code ? CurrencyType.getCurrencyUnit(transactionCurrency.Code) : "";
                }
            },
            isVisible: (args: IGetValueArgs) => {
                return isCashBasisAccountingCompany(args.storage.context) && !isTransactionCurrencyInCompanyCurrency(args);
            },
            isReadOnly: isUsingExistingAccount,
            affectedFields: [
                { id: BindingContext.localContext("ExchangeRate") }
            ]
        },
        [BindingContext.localContext("ExchangeRate")]: {
            width: BasicInputSizes.S,
            label: i18next.t("Banks:Form.ExchangeRate"),
            isReadOnly: true,
            isVisible: (args: IGetValueArgs) => {
                return isCashBasisAccountingCompany(args.storage.context) && !isTransactionCurrencyInCompanyCurrency(args);

            },
            formatter: (val, args: IGetValueArgs) => {
                const initialBalance = args.storage.getValueByPath("InitialBalance") as number;
                const initialTransactionBalance = args.storage.getValueByPath("InitialTransactionBalance") as number;

                if (isDefined(initialBalance) && isDefined(initialTransactionBalance)) {
                    const exchangeRate = initialBalance / initialTransactionBalance;

                    if (isNaN(exchangeRate) || exchangeRate === 0) {
                        return DASH_CHARACTER;
                    }

                    return fourDigitsFormatter(exchangeRate);
                }
                return DASH_CHARACTER;
            }
        }
    };
};

export const getInitialBalanceFormGroup = (): IFormGroupDef => {
    return {
        id: "initialBalance",
        rows: [
            [
                { id: "TransactionCurrency" }
            ],
            [
                { id: "InitialBalance" },
                { id: "InitialTransactionBalance" },
                { id: BindingContext.localContext("ExchangeRate") }
            ]
        ]
    };
};

export const initAccountSettings = (storage: FormStorage, accountPrefix: number): void => {
    if (storage.data.bindingContext.isNew()) {
        setGroupStatus(getCollapsedGroupId({ id: AccountSettingsAccountsSelectPath }), storage, true);
    } else {
        const fieldInfo = storage.getInfo(storage.data.bindingContext.navigate(AccountSettingsAccountsSelectPath));
        const origAccount = storage.data.entity as TEntityWithAccount;
        const account: IAccountEntity = {
            Number: getFullAccountSettingsAccountNumber(accountPrefix, origAccount.BalanceSheetAccountNumberSuffix),
            Name: origAccount.BalanceSheetAccountName,
            Currency: storage.data.entity.TransactionCurrency
        };
        const label = AccountDefFormatter(account.Number, {
            item: account,
            entity: account,
            storage
        });

        fieldInfo.fieldSettings.initialItems = [
            {
                id: account.Number,
                label,
                additionalData: account
            }
        ];

        storage.setValueByPath(AccountSettingsAccountsSelectPath, account.Number);
    }
};

export const handleAccountSettingsSelectChange = (event: ISmartFieldChange, storage: FormStorage<TEntityWithAccount, IPaymentRelatedEntityCustomData>): void => {
    if (event.bindingContext.getPath() !== AccountSettingsAccountsSelectPath || !event.triggerAdditionalTasks) {
        return;
    }

    const isCreating = event.value === NEW_ACCOUNT_CHILD_ITEM;
    const AccountSettingsCollapsedGroupId = getCollapsedGroupId({ id: AccountSettingsAccountsSelectPath });

    if (isCreating) {
        setGroupStatus(AccountSettingsCollapsedGroupId, storage, true);
        storage.clearValueByPath("BalanceSheetAccountNumberSuffix");
        storage.clearValueByPath("BalanceSheetAccountName");
        storage.setCustomData({ accountUsage: null });
        storage.refresh();
    } else {
        const account: IAccountEntity = event.additionalData;

        setGroupStatus(AccountSettingsCollapsedGroupId, storage, false);
        storage.clearAndSetValueByPath("BalanceSheetAccountNumberSuffix", account.Number.slice(BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX.toString().length));
        storage.clearAndSetValueByPath("BalanceSheetAccountName", account.Name);

        getAccountUsageAcrossCharts(account.Number).then((usage) => {
            // set currency to one of the available currencies, or keep the current one if it can be used
            const availableCurrencyCodes = usage.AvailableAccountedEntityCurrencyCodes.length > 0 ? usage.AvailableAccountedEntityCurrencyCodes : getCompanyUsedCurrencyCodes(storage.context as IAppContext);
            const entity = storage.getEntity<TEntityWithAccount>();
            const currentTransactionCurrencyCode = entity.TransactionCurrencyCode ?? entity.TransactionCurrency?.Code;

            if (!availableCurrencyCodes.includes(currentTransactionCurrencyCode)) {
                // set whole currency object, not just Code
                // the select items doesn't have to be loaded and we need currency name for the select item description
                const currencyCode = availableCurrencyCodes[0];
                const currencies = getEnumEntities(EntityTypeName.Currency);
                const currency = currencies.find(currency => currency.Code === currencyCode);
                storage.setValueByPath("TransactionCurrency", currency);
            }

            storage.setCustomData({ accountUsage: usage });
            storage.refresh();
        });

    }

    storage.refresh();
};

const handleBalanceSheetAccountChange = (event: ISmartFieldChange, storage: FormStorage): void => {
    setGroupStatus(getCollapsedGroupId({ id: AccountSettingsAccountsSelectPath }), storage, true);
    storage.clearAndSetValueByPath(AccountSettingsAccountsSelectPath, NEW_ACCOUNT_CHILD_ITEM);
    storage.refresh();
};

export const handleBalanceSheetAccountNumberSuffixChange = (event: ISmartFieldChange, storage: FormStorage): void => {
    if (event.bindingContext.getPath() === "BalanceSheetAccountNumberSuffix") {
        handleBalanceSheetAccountChange(event, storage);
    }
};

export const handleBalanceSheetAccountNameChange = (event: ISmartFieldChange, storage: FormStorage): void => {
    if (event.bindingContext.getPath() === "BalanceSheetAccountName") {
        handleBalanceSheetAccountChange(event, storage);
    }
};

export const getAccountSettingsFieldsChange = (event: ISmartFieldChange, storage: FormStorage): void => {
    handleAccountSettingsSelectChange(event, storage);
    handleBalanceSheetAccountNumberSuffixChange(event, storage);
    handleBalanceSheetAccountNameChange(event, storage);
};

export const applyAccountSettingsOnBeforeSave = (entity: TEntityWithAccount, context: IAppContext): void => {
    if (entity.TransactionCurrency.Code === getCompanyCurrency(context)) {
        entity.InitialTransactionBalance = entity.InitialBalance;
    }
};

export type TBankAccountRelatedEntity = IBankTransactionEntity | IDocumentExtendedEntity | IPrDeductionEntity;

export interface IBankAccountArgs {
    storage: FormStorage<TBankAccountRelatedEntity, IBankAccountsCustomData>;
    type?: DocumentTypeCode;
    isEntityWithoutBP?: boolean;
    businessPartner?: IBusinessPartnerEntity;
    account?: IBankAccountEntity;
    // true for call in onAfterLoad
    isInit?: boolean;
    changePartner?: boolean;
    businessPartnerPath?: string;
}

export const prepareSavedAccounts = (args: IBankAccountArgs): void => {
    const bankAccounts = isReceived(args.type) ? args.businessPartner?.BankAccounts : args.storage.context.getCompanyBankAccounts(true);
    args.storage.setCustomData({ bankAccounts });

    appendAdditionalItemsToSavedBankAccounts(args);
    setSavedBankAccountItems(args.storage, bankAccounts as IBankAccountEntity[]);
    setSavedBankAccount(args, bankAccounts as IBankAccountEntity[]);
};

export const prepareAllSavedAccounts = (args: IBankAccountArgs): void => {
    const accounts = args.storage.getCustomData().allBankAccounts;

    args.storage.setCustomData({ bankAccounts: args.businessPartner?.BankAccounts || [] });

    appendAdditionalItemsToSavedBankAccounts(args);
    setSavedBankAccountItems(args.storage, accounts, true);

    if (isSavedAccountsFieldVisible({ storage: args.storage })) {
        setSavedBankAccount(args);
    }
};

export const updateAllSavedAccountsOnPartnerChange = (args: IBankAccountArgs): void => {
    // called when user changes business partner (direct change to existing) or blurs after bp field changes
    // which can result to creating new business partner or empty business partner
    // STEPS:
    // 1. If business partner has this account select it (BE AWARE THAT EVEN IF SELECT HAS ALL ACCOUNTS FROM ALL BUSINESS PARTNERS WE STILL NEED TO CHECK IT TO ACCOUNTS ONLY SELECTED BUSINESS PARTNER HAS)
    // 2. If business partner does not contain this acc, and there is some account selected, select OWN
    // 3. Change the label of the select, based whether there is business partner or not
    // 4. Change the group status (to switch between creating and not creating)
    args.storage.setCustomData({ bankAccounts: args.businessPartner?.BankAccounts || [] });
    if (isReceived(args.type)) {
        const acc = findCurrentBankAccount(args.storage, false);
        const bc = args.storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH);

        if (acc) {
            // 1.
            args.storage.clearAndSetValue(bc, acc.Id);
        } else {
            // 2.
            const isEmpty = hasEmptyBankAccount(args.storage);
            if (!isEmpty) {
                args.storage.clearAndSetValue(bc, SelectionCode.Own);
            }
        }

        // 3.
        appendAdditionalItemsToSavedBankAccounts(args);
        const isNew = !!(args.businessPartner?.Name || args.businessPartner?.LegalNumber);
        const saVal = args.storage.getValueByPath(SAVED_ACCOUNTS_PATH);
        // 4.
        setBankAccountGroupStatus(args, isNew && saVal === SelectionCode.Own);

        refreshBankGroup(args.storage);
    }
};

// used for both BankAccounts and CashBoxes
export const getIsDefaultSwitchDef = (isCashBoxDef?: boolean): TFieldDefinition => {
    return {
        type: FieldType.Switch,
        fieldSettings: {
            type: SwitchType.YesNo
        },
        defaultValue: (args: IGetValueArgs) => {
            const items = !isCashBoxDef ? args.storage.context.getCompanyBankAccounts() : args.storage.context.getCashBoxes();
            return items?.length === 0;
        },
        isDisabled: (args: IGetValueArgs) => {
            const items = !isCashBoxDef ? args.storage.context.getCompanyBankAccounts() : args.storage.context.getCashBoxes();
            const isEmpty = items?.length === 0;

            const isOrigDefault = (args.storage as FormStorage).getOrigEntity<TEntityWithAccount>().IsDefault;
            // we either disable default button if there is no other bank acc or
            // bank account can't be "undefaulted" by itself also inactive accounts can't be changed to default
            return isEmpty || (!args.storage.data.bindingContext.isNew() && (isOrigDefault || !args.storage.getEntity<TEntityWithAccount>().IsActive));
        }
    };
};

const hasOwnBankAccount = (args: IBankAccountArgs) => {
    if (args.isEntityWithoutBP) {
        return false;
    }

    const hasPartner = hasBusinessPartner(args.storage, args.businessPartner, args.businessPartnerPath);

    const bpPath = args.businessPartnerPath ?? "BusinessPartner";
    // for required business partner we display always "New"
    const bpInfo = args.storage.getInfo(args.storage.data.bindingContext.navigate(`${bpPath}/Name`));
    const isRequired = bpInfo?.isRequired;

    const canCreatePartner = canPartnerBeCreated(args);

    // display Own when there is no partner bank account using (f.e. InvoiceIssued) or there is no partner selected (and is not required) -> Bank transaction
    // otherwise display New --> InvoiceReceived
    return !canCreatePartner || (!isReceived(args.type) || (!isRequired && !hasPartner));
};

export const appendAdditionalItemsToSavedBankAccounts = (args: IBankAccountArgs): void => {
    const { storage } = args;
    const bc = storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH);
    const info = storage.getInfo(bc);
    if (!info) {
        return;
    }
    const isPaymentService = isPaymentServiceFieldVisible({ storage });
    if (!isPaymentService) {
        const displayOwn = hasOwnBankAccount(args);
        info.fieldSettings.additionalItems = [getSavedBankAccountAdditionalItem(!displayOwn)];
    } else {
        info.fieldSettings.additionalItems = [];
        const value = storage.getValue(bc);
        if (value === SelectionCode.Own) {
            storage.clearValue(bc);
        }
    }
};

export const setSavedBankAccountItems = (storage: FormStorage, accounts: IBankAccountEntity[] = [], hasBusinessPartnerCol?: boolean): void => {
    const info = storage.getInfo(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH));
    if (info) {
        const isPaymentService = isPaymentServiceFieldVisible({ storage });
        const types = isPaymentService ? [BankAccountType.Service]
            : [BankAccountType.Account, BankAccountType.ABA, BankAccountType.IBANOnly];
        info.fieldSettings.items = createBankAccountsItems({
            bankAccounts: accounts,
            hasBusinessPartnerCol,
            allowedTypes: types,
            addCurrency: true
        });
    }
};

const _isEmptyBankAccount = (bankAccount: IBankAccountEntity): boolean => {
    const bankAccountProps: (keyof IBankAccountEntity)[] = [
        "AccountNumber", "BankCode", "IBAN", "AbaNumber", "SWIFT", "PaymentServiceID", "PaymentServiceName"
    ];
    return (!bankAccount || bankAccountProps.every(propName => !bankAccount[propName]));
};

export const hasEmptyBankAccount = (storage: FormStorage): boolean => {
    return _isEmptyBankAccount(storage.data.entity.BankAccount);
};

// TODO: recreate to more general function which can be used by non document entities ()without BP etc.)
export const setSavedBankAccount = (args: IBankAccountArgs, accounts?: ICompanyBankAccountEntity[]) => {
    const receivable = isReceived(args.type);
    const account = args.account || findCurrentBankAccount(args.storage, receivable);
    const bc = args.storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH);
    const info = args.storage.getInfo(bc);

    let isEmpty = hasEmptyBankAccount(args.storage);
    if (!isEmpty) {
        const item = info?.fieldSettings?.items?.find(i => i.id === account?.Id || compareBankAccount(i.additionalData, account));
        if (!item) {
            args.storage.clearAndSetValue(bc, info?.fieldSettings?.additionalItems?.length ? SelectionCode.Own : null);
            setBankAccountGroupStatus(args, true, true);
        } else {
            args.storage.clearAndSetValue(bc, item.id);
            if (args.changePartner && item) {
                args.storage.processDependentField(accountPartnerDepFields, item.additionalData, bc);
                setSavedContactItems(args.storage, item.additionalData?.BusinessPartner?.Contacts);
            }
        }
    } else {
        const hasOwn = info.fieldSettings?.additionalItems?.find(item => item.id === SelectionCode.Own);
        const hasEmptyItem = !!info.fieldSettings?.noRecordText;

        if (!receivable && args.isInit) {
            // set to company default account
            const isNew = args.storage.data.bindingContext.isNew();
            const defaultAcc = accounts?.find(acc => acc.IsDefault);
            // if account has service setup skipp it as it won't be shown in select
            if (isNew && defaultAcc && !defaultAcc.PaymentServiceID) {
                args.storage.clearAndSetValue(bc, defaultAcc.Id);
                args.storage.processDependentField(BankAccountDependentFields, defaultAcc);
            } else if (hasOwn) {
                args.storage.clearAndSetValue(bc, SelectionCode.Own);
            } else {
                args.storage.clearValue(bc);
            }

            return;
        }

        // check whether there is any field picked outside of country
        const bankAccount = args.storage.data.entity.BankAccount;
        isEmpty = (!bankAccount || (!bankAccount.AccountNumber && !bankAccount.BankCode && !bankAccount.IBAN && !bankAccount.AbaNumber && !bankAccount.SWIFT));
        if (isEmpty && (hasEmptyItem || !hasOwn)) {
            args.storage.clearValue(bc);
        } else {
            args.storage.clearAndSetValue(bc, SelectionCode.Own);
        }

        // handling edge case when there is account set to business partner's deleted account
        if (!isEmpty && receivable && args.isInit) {
            const hasOwnPartner = hasOwnBankAccount(args);
            if (!hasOwnPartner) {
                setGroupStatus(getCollapsedGroupId({ id: SAVED_ACCOUNTS_PATH }), args.storage, true);
            }
        }
    }

    if (!args.isInit) {
        setBankAccountGroupStatus(args, !isEmpty && !account?.Id);
    }

    return args.storage.getValue(bc);
};

export const findCurrentBankAccount = (storage: FormStorage<TBankAccountRelatedEntity, IBankAccountsCustomData>, useAllBanks?: boolean): IBankAccountEntity => {
    const currentBankAccount = storage.data.entity.BankAccount;
    // const storage: FormStorage<unknown, IBankAccountsCustomData> = args.storage;
    // serch among all bank accounts (f.e. for transactions) BUT not when saving (we crate separate request for BUSINESS PARTNER
    // in case partner doesn't contains such account
    const allBankAccounts = useAllBanks ? storage.getCustomData().allBankAccounts : null;
    const accounts = allBankAccounts || (storage.getCustomData().bankAccounts || []);
    return accounts.find((bankAccount: IBankAccountEntity) => compareBankAccount(currentBankAccount, bankAccount));
};

const compareBankAccount = (ba1: IBankAccountEntity, ba2: IBankAccountEntity) => {
    if (!ba1 || !ba2) {
        return false;
    }

    if (ba1.PaymentServiceID || ba2.PaymentServiceID) {
        return ba1.PaymentServiceID === ba2.PaymentServiceID && ba1.PaymentServiceName === ba2.PaymentServiceName;
    }

    if (compareDefinedArrays([trimLeadingZeroes(ba1.AccountNumber), trimLeadingZeroes(ba1.BankCode)], [trimLeadingZeroes(ba2.AccountNumber), trimLeadingZeroes(ba2.BankCode)])) {
        return true;
    }

    if (compareDefined(removeWhiteSpace(ba1.IBAN), removeWhiteSpace(ba2.IBAN))) {
        return true;
    }

    return compareDefinedArrays([trimLeadingZeroes(ba1.AccountNumber), trimLeadingZeroes(ba1.AbaNumber)], [trimLeadingZeroes(ba2.AccountNumber), trimLeadingZeroes(ba2.AbaNumber)]);
};


export const deleteEmptyBankAccount = (data: IEntity): void => {
    if (_isEmptyBankAccount(data.BankAccount)) {
        data.BankAccount = {};
    }
};

const setBankAccountGroupStatus = (args: IBankAccountArgs, isCreating: boolean, forceExpand?: boolean) => {
    if (!args.isEntityWithoutBP && !hasOwnBankAccount(args)) {
        setGroupStatus(getCollapsedGroupId({ id: SAVED_ACCOUNTS_PATH }), args.storage, isCreating, forceExpand);
    } else {
        // For issued documents, we are working with company bank accounts -> bankAccount group is never marked
        // as "New" (isCreating), but if the bank account is custom, we forceExpand the group
        setGroupStatus(getCollapsedGroupId({ id: SAVED_ACCOUNTS_PATH }), args.storage, false, forceExpand);
    }

    args.storage.refreshGroupByKey("payment");
};


export const addBusinessPartnerBankAccountRequest = (storage: FormStorage, isNewBusinessPartner: boolean, batch: BatchRequest): boolean => {
    if (isNewBusinessPartner || findCurrentBankAccount(storage) || hasEmptyBankAccount(storage)) {
        return false;
    }

    // Clear hidden fields according to visibility of document account fields
    const bankAccountBc = storage.data.bindingContext.navigate("BankAccount");
    const newBankAccount = storage.clearHiddenFields(storage.data.entity.BankAccount, bankAccountBc) as IBusinessPartnerBankAccountEntity;
    if (!newBankAccount.Bank) {
        // todo: this might be done automatically by clearHiddenFields...
        newBankAccount.BankCode = null;
    }
    // enum references cannot be saved as object (would have to be transformed to odata reference) and CountryCode is enough,
    // either use prepareBatch that would transform them correctly or delete those if Code reference is present
    delete newBankAccount.Country;
    delete newBankAccount.Bank;
    delete newBankAccount.Id;
    delete newBankAccount.DateCreated;
    delete newBankAccount.DateLastModified;
    // Put to the end
    newBankAccount.Order = 0;

    batch.fromPath(`BusinessPartners(${storage.data.entity.BusinessPartner.BusinessPartner.Id})/BankAccounts`).create(newBankAccount);

    return true;
};

export const changeCountryByIBAN = (storage: FormStorage, rootBc: BindingContext): void => {
    const countryBc = rootBc.navigate("Country");
    const originalCountry = storage.getValue(countryBc, { useDirectValue: false });

    const IBAN = storage.getValue(rootBc.navigate("IBAN"));
    const ibanCountry = IBAN?.substring(0, 2)?.toUpperCase();
    if (ibanCountry !== originalCountry) {
        const countries = storage.getInfo(countryBc)?.fieldSettings?.items;
        const newCountry = countries?.find(c => c.id === ibanCountry);
        if (newCountry) {
            storage.clearAndSetValue(countryBc, newCountry.additionalData);
            storage.clearAndSetValue(rootBc.navigate("CountryCode"), newCountry.additionalData.Code);
        }
    }
};

/**
 * Sets bankValue as it would be set in the select (triggers dependent fields processing)
 * @param storage
 * @param bankAccountBc
 * @param bankCode
 */
export const setBankValue = (storage: FormStorage, bankAccountBc: BindingContext, bankCode: string): void => {
    if (bankCode) {
        const bankBc = bankAccountBc.navigate("Bank");
        const bankCodeItem = storage.getInfo(bankBc)?.fieldSettings?.items?.find((bankItem) => {
            const bank = bankItem.additionalData as IBankEntity;
            return bank.Code === bankCode;
        });
        if (bankCodeItem) {
            storage.handleChange({
                value: bankCodeItem.id,
                bindingContext: bankBc,
                additionalData: bankCodeItem.additionalData,
                triggerAdditionalTasks: true
            });
        }
    }
};

const handleIBANChange = (args: IBankAccountArgs, e: ISmartFieldChange) => {
    const storage = args.storage;
    const path = e.bindingContext.getNavigationPath(true);

    if (path === "BankAccounts/IBAN" || path === "BankAccount/IBAN" || path === "IBAN") {
        const parBc = e.bindingContext.getParent();


        const IBAN = storage.getValue(parBc.navigate("IBAN"));

        const ibanCountry = IBAN?.substring(0, 2);
        const countryBc = parBc.navigate("Country");
        const country = storage.getValue(countryBc, { useDirectValue: false });

        if (isBankCodeCountry(ibanCountry) || isBankCodeCountry(country)) {
            const IBAN = storage.getValue(parBc.navigate("IBAN"));
            const acc = createAccountFromIBAN(IBAN);
            const accBc = parBc.navigate("AccountNumber");
            const accountNumber = trimLeadingZeroes(acc?.account);
            storage.clearAndSetValue(accBc, accountNumber);
            if (accountNumber) {
                storage.validateFieldSync(accBc);
            }
            setBankValue(storage, parBc, acc?.bank);
        }
    }
};

export const tryToCreateIBAN = (storage: FormStorage, bc: BindingContext): void => {
    const parBc = bc.getParent();
    const country = storage.getValue(parBc.navigate("Country"), { useDirectValue: false });

    if (isBankCodeCountry(country)) {
        const accNumber = storage.getValue(parBc.navigate("AccountNumber"));

        const bankBc = parBc.navigate("Bank");
        const bankCode = storage.getValue(bankBc)?.Code;

        const IBAN = createIBANFromAccount(accNumber, bankCode, country);
        const IbanBc = parBc.navigate("IBAN");
        storage.clearAndSetValue(IbanBc, IBAN);
        if (IBAN) {
            storage.validateFieldSync(IbanBc);
        }
    }
};

export async function tryToCreateSwift(storage: FormStorage<IDocumentEntity>): Promise<void> {
    const { entity } = storage.data;
    const bankBc = storage.data.bindingContext.navigate(`${DocumentEntity.BankAccount}/${BankAccountEntity.Bank}`);
    const swiftBc = storage.data.bindingContext.navigate(`${DocumentEntity.BankAccount}/${BankAccountEntity.SWIFT}`);
    const info = storage.getInfo(bankBc);
    const bankItems = await fetchItemsByInfo(storage, info);
    const bank = bankItems.find(item => item.id === entity.BankAccount?.BankCode);
    storage.setValue(swiftBc, bank.additionalData.SWIFT);
}

export const handleAccOrBankChange = (storage: FormStorage, e: ISmartFieldChange): void => {
    const path = e.bindingContext.getNavigationPath(true);
    const prefixes = ["BankAccounts/", "BankAccount/", ""];
    const _checkPath = (path: string, shouldMatch: string) => prefixes.some(pref => `${pref}${shouldMatch}` === path);

    if (_checkPath(path, "AccountNumber") || (_checkPath(path, "Bank") && e.triggerAdditionalTasks)) {
        tryToCreateIBAN(storage, e.bindingContext);
    }
};

export const handleBankFieldChangeWithoutSavedAccount = (args: IBankAccountArgs, e: ISmartFieldChange): void => {
    handleBankCountryChange(args, e);
    handleAccOrBankChange(args.storage, e);
    handleIBANChange(args, e);
};

const isCreating = (args: IBankAccountArgs, e: ISmartFieldChange) => {
    return e.value === SelectionCode.Own && canPartnerBeCreated(args);
};

const canPartnerBeCreated = (args: IBankAccountArgs) => {
    // placeholder for cases (which may come) when we don't want to allow BP creation
    return true;
};

export const handleBankFieldChange = (args: IBankAccountArgs, e: ISmartFieldChange): void => {
    handleSavedAccountChange(args, e);
    handleBankFieldChangeWithoutSavedAccount(args, e);

    const path = e.bindingContext.getPath();
    const parentBc = e.bindingContext.getParent();
    const isBankAccountField = isBankAccount(parentBc);
    const isBankCodeLiveChange = path === "Bank" && !e.triggerAdditionalTasks;
    const isCountry = path === "Country";

    if (isBankAccountField && !isBankCodeLiveChange && !isCountry) {
        const val = setSavedBankAccount(args);
        const _isCreating = val === SelectionCode.Own && canPartnerBeCreated(args);
        const isClearing = isNotDefined(val);
        if (isClearing) {
            clearBankFields(args.storage, parentBc);
        }

        setBankAccountGroupStatus(args, _isCreating);
        appendAdditionalItemsToSavedBankAccounts(args);
        refreshBankGroup(args.storage);
    }
};

export const handlePaymentMethodChange = (args: IBankAccountArgs, e: ISmartFieldChange): void => {
    const path = e.bindingContext.getPath();
    if (path === "PaymentMethod") {
        const { storage, type } = args;

        if (e.value === PaymentMethodCode.WireTransfer
            || (isIssued(type) && e.value === PaymentMethodCode.PaymentService)) {
            setDefaultCountry(storage);
            prepareSavedAccounts(args);
        } else if (e.value === PaymentMethodCode.Cash) {
            if ((storage.data.entity as IDocumentEntity)[DocumentEntity.ClearedStatus]?.Code !== ClearedStatusCode.Cleared) {
                const createReceiptBc = args.storage.data.bindingContext.navigate(CREATE_RECEIPT_PATH);

                if (!isFieldDisabled(args.storage.getInfo(createReceiptBc), args.storage, createReceiptBc)) {
                    args.storage.setValue(createReceiptBc, true);
                    onCreateReceiptChange(true, args.storage);
                }
            }
        }
        refreshBankGroup(args.storage);
    }
};

export function isBankAccount(bindingContext: BindingContext): boolean {
    let entityType = bindingContext.getEntityType();
    let isBankAccount = false;
    while (entityType && !isBankAccount) {
        isBankAccount = [EntityTypeName.BankAccount, EntityTypeName.DocumentBankAccount, EntityTypeName.PrEmployeeBankAccount]
            .includes(entityType.getName() as EntityTypeName);
        entityType = entityType.getBaseType();
    }
    return isBankAccount;
}

const setDefaultCountry = (storage: FormStorage) => {
    storage.clearAndSetValueByPath("BankAccount/Country", getAgendaCountryCode(storage.context));
};

const handleBankCountryChange = (args: IBankAccountArgs, e: ISmartFieldChange) => {
    const parentBc = e.bindingContext.getParent();
    const { storage } = args;

    if (isBankAccount(parentBc) && e.bindingContext.getPath() === "Country" && e.triggerAdditionalTasks) {
        clearBankFields(storage, parentBc);
        if (e.bindingContext.getNavigationPath(true) === "BankAccount/Country") {
            const bc = storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH);
            const val = storage.getValue(bc);
            // if there is any account selected (not "own", "default", ...) we set "own" as new value

            if (Number.isInteger(val)) {
                storage.clearAndSetValue(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH), SelectionCode.Own);
                setBankAccountGroupStatus(args, true);
            }
        }

        refreshBankGroup(storage);
    }
};

export const clearBankFields = (storage: FormStorage, rootBc: BindingContext): void => {
    const _clear = (bc: BindingContext) => {
        storage.clearAndSetValue(bc, null);
        storage.clearAdditionalFieldData(bc);
    };

    // we need to set null not undefined so BE can properly clear stuff
    _clear(rootBc.navigate(BankAccountEntity.BankCode));
    _clear(rootBc.navigate(BankAccountEntity.Bank));
    _clear(rootBc.navigate(BankAccountEntity.AccountNumber));
    _clear(rootBc.navigate(BankAccountEntity.AbaNumber));
    _clear(rootBc.navigate(BankAccountEntity.IBAN));
    _clear(rootBc.navigate(BankAccountEntity.SWIFT));
    _clear(rootBc.navigate(BankAccountEntity.PaymentServiceID));
    _clear(rootBc.navigate(BankAccountEntity.PaymentServiceName));
};

const handleSavedAccountChange = (args: IBankAccountArgs, e: ISmartFieldChange) => {
    if (e.bindingContext.getNavigationPath(true) === SAVED_ACCOUNTS_PATH && e.triggerAdditionalTasks) {
        const isNew = e.value === SelectionCode.Own;
        const _isCreating = isCreating(args, e);
        const isClearing = isNotDefined(e.value);

        // DEV-11719 we removed country from dependent fields so clicking on "new" doesn't erase current selection
        // so we have to set it up manually
        if (!isNew && e.value) {
            const country = e.additionalData?.CountryCode;
            if (country) {
                args.storage.clearAndSetValueByPath("BankAccount/Country", country);
            }
        }

        if (isNew) {
            const currentCountry = args.storage.getValueByPath("BankAccount/Country", { useDirectValue: false });
            if (!currentCountry) {
                setDefaultCountry(args.storage);
            }
        }

        if (isClearing) {
            clearBankFields(args.storage, args.storage.data.bindingContext.navigate("BankAccount"));
            setDefaultCountry(args.storage);
            args.storage.setGroupStatus({
                isExpanded: false,
                isCreating: false
            }, getCollapsedGroupId({ id: SAVED_ACCOUNTS_PATH }));
        } else {
            setBankAccountGroupStatus(args, _isCreating, isNew);
        }

        refreshBankGroup(args.storage);
    }
};

const refreshBankGroup = (storage: FormStorage): void => {
    storage.addActiveGroupByKey("payment");
};

export const getBankBlurArgs = (storage: FormStorage, bc: BindingContext): IHandleBlur => {
    return {
        storage: storage,
        bindingContext: bc,
        root: bc.getParent()
    };
};

export const loadAllBankAccounts = async (storage: FormStorage<TBankAccountRelatedEntity, IBankAccountsCustomData>): Promise<void> => {
    const accounts: IAllBankAccount[] = [];

    if (!storage.getCustomData().allBankAccounts) {
        const partners = await storage.oData.getEntitySetWrapper(EntitySetName.BusinessPartners).query()
            .expand("BankAccounts")
            .expand("Contacts")
            .expand("BillingAddress", q => {
                q.expand("Country");
            })
            .expand("VatStatus")
            .fetchData<IBusinessPartnerEntity[]>();

        for (const partner of partners?.value || []) {
            for (const acc of partner.BankAccounts || []) {
                accounts.push({
                    BusinessPartner: partner,
                    ...acc
                });
            }
        }

        accounts.sort((ba1: IBankAccountEntity, ba2: IBankAccountEntity) => {
            if (ba1.AccountNumber && ba2.AccountNumber) {
                return compareString(ba1.AccountNumber, ba2.AccountNumber);
            } else {
                return compareString(ba1.IBAN, ba2.IBAN);
            }
        });

        storage.setCustomData({ allBankAccounts: accounts });
    }
};

/**
 * Transforms CompanyBankAccount select items (with IsActive flag in additionalData) in a way that inactive
 * accounts are removed, except the currently selected account, which is left as disabled, so it's correctly
 * rendered in select.
 * ToDo: we may remove this in the future and make it in more generic way for all possible selects
 * @param items
 * @param currentAccountId
 */
export function removeInactiveAccounts(items: ISelectItem[], currentAccountId?: TEntityKey): ISelectItem[] {
    return items.reduce((retValue, currentItem) => {
        const isSelected = currentAccountId && currentItem.id === currentAccountId;
        if (currentItem.additionalData.IsActive || isSelected) {
            currentItem.isDisabled = !currentItem.additionalData.IsActive;
            retValue.push(currentItem);
        }
        return retValue;
    }, []);
}

/**
 * If SymbolVariable is not changed by user, update its value based on the templateString;
 * @param storage
 * @param newValue either NumberOurs or NumberTheirs. Only numeric part of the string is used.
 * @param originalValue value before the change.
 * */
export const createAndSetSymbolVariable = (storage: FormStorage, originalValue: string, newValue: string): void => {
    const _createFrom = (n: string) => {
        const ret = n?.replace(/\D/g, "");
        return ret !== "" ? ret : undefined;
    };

    const symbolIsNotSet = !storage.data.entity?.SymbolVariable;
    const symbolMatchesOriginalValue = storage.data.entity?.SymbolVariable === _createFrom(originalValue);
    if ((symbolIsNotSet || symbolMatchesOriginalValue) && storage.data.entity?.PaymentMethod?.Code === PaymentMethodCode.WireTransfer) {
        storage.clearAndSetValue(storage.data.bindingContext.navigate("SymbolVariable"), _createFrom(newValue));
    }
};

export const setVariableSymbolFromNumberOurs = (storage: FormStorage, origNumberOurs: string, newNumberOurs: string): void => {
    const documentType = getDocumentTypeCodeFromEntityType(storage.data.bindingContext.getEntityType().getName() as EntityTypeName);

    if (isReceived(documentType)) {
        // for received invoices, VariableSymbol is tied to NumberTheirs and is handled in ReceivedDocumentBaseFormView
        return;
    }

    // if numberOurs is set, we may create variable symbol from it in some cases:
    // - variable symbol should not be set or it should match previous number ours
    if (newNumberOurs) {
        createAndSetSymbolVariable(storage, origNumberOurs, newNumberOurs);
    }
};

export const buildComplexBankAccountFilterFromBankAccountString = (changedFilter: IChangedFilter, storage: TableStorage, settings?: ICreateFilterStringSettings): string => {
    const getFilterString = (prop: keyof IBankAccountEntity, originalValue: string | IComplexFilter<string>, bankAccountString: string): string => {
        const isComplex = isComplexFilter(originalValue);
        // is called for either BankAccount navigation or BankAccount/AccountNumber property
        const bc = changedFilter.bindingContext.isValidNavigation(prop) ? changedFilter.bindingContext.navigate(prop) : changedFilter.bindingContext.getParent().navigate(prop);

        return createFilterString({
                ...changedFilter,
                bindingContext: bc,
                value: !isComplex ? bankAccountString : [{
                    ...originalValue,
                    value: bankAccountString
                }]
            },
            settings
        );
    };

    const applyFilter = (originalValue: string | IComplexFilter<string>) => {
        const isComplex = isComplexFilter(originalValue);
        const bankAccountString = removeWhiteSpace(!isComplex ? originalValue : originalValue.value);

        const containsSeparator = bankAccountString.includes("/");
        if (!containsSeparator) {
            const possibleProps: (keyof IBankAccountEntity)[] = ["AccountNumber", "AbaNumber", "PaymentServiceID", "IBAN"];

            return storage.joinFilters(possibleProps.map((prop) => {
                return getFilterString(prop, originalValue, bankAccountString);
            }), "OR");
        } else {
            const [prefix, suffix] = bankAccountString.split("/");
            const basicAccountFilter = `(${storage.joinFilters([getFilterString("AccountNumber", originalValue, prefix), getFilterString("BankCode", originalValue, suffix)], "AND")})`;
            const abaAccountFilter = `(${storage.joinFilters([getFilterString("AbaNumber", originalValue, prefix), getFilterString("AccountNumber", originalValue, suffix)], "AND")})`;

            return storage.joinFilters([basicAccountFilter, abaAccountFilter], "OR");
        }
    };

    if (isComplexFilterArr(changedFilter.value)) {
        return storage.joinFilters((changedFilter.value as IComplexFilter[]).map((val) => {
            return applyFilter(val as IComplexFilter<string>);
        }), "AND");
    } else {
        return applyFilter(changedFilter.value as string);
    }
};

export const checkRelatedEntities = async (storage: FormStorage<any, IPaymentRelatedEntityCustomData>, addTransactions = false) => {
    let hasRelatedEntities = false;

    const transactionCondition = addTransactions ? ` AND Transactions/$count gt 0` : ``;

    if (!storage.data.bindingContext.isNew()) {
        try {
            const bankAccountId = storage.data.bindingContext.getKey();
            const query = storage.oData.getEntitySetWrapper(EntitySetName.BankStatements).query();
            query.filter(`BankAccount/Id eq ${bankAccountId}${transactionCondition}`)
                .top(1);

            const result = await query.fetchData<IBankStatementEntity[]>();

            hasRelatedEntities = !!result.value?.length;
        } catch (e) {
            // fall silently
        }
    }

    storage.setCustomData({ hasRelatedEntity: hasRelatedEntities });
};

export const refreshStatementsAPI = async (bankAccountId: number): Promise<Response> => {
    const url = `${BANK_STATEMENTS}/SyncBankTransactions/${bankAccountId}`;
    return await customFetch(url, {
        ...getDefaultPostParams()
    });
};