import { handleRefHandlers, isDefined } from "@utils/general";
import { getTextWidthByElement } from "@utils/string";
import { debounce } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { TextAlign } from "../../enums";
import TestIds from "../../testIds";
import MoreItems from "../moreItems/MoreItems";
import Token, { ITokenProps, StyledTokenizer } from "./";
import { AfterTokensText, StyledTokenizerOuterWrapper } from "./Tokenizer.styles";
import { MAX_SHORT_TOKEN_WIDTH, MIN_LONG_TOKEN_WIDTH, TOKEN_MARGIN_RIGHT } from "./Tokenizer.utils";

interface IProps extends WithTranslation, WithDomManipulator {
    tokens: ITokenProps[];
    maxVisibleTokens?: number;
    maxWidth?: string; // default is "fit-content"
    customMoreTokensText?: (hiddenItemsCount: number) => string;
    isWrappable?: boolean;
    showAll?: boolean;
    /** By default, Tokenizer with all tokens visible is rendered in overflow tooltip.
     * This provides option to use custom content. */
    customTooltipContent?: React.ReactNode;
    /** Use to render custom token */
    tokenRenderer?: (tokenDef: ITokenProps, index: number) => React.ReactNode;
    onClick?: (tokenId: string) => void;
    passRef?: React.Ref<HTMLDivElement>;
    ref?: React.RefObject<Tokenizer>;
    className?: string;
    showMoreTokensTooltip?: boolean;
    trailingText?: string; // to show text after tokens, calculates space for it
    extraSpace?: number; // additional space to be kept for additional (absolutely positioned) content
}

interface IDomUpdateArgs {
    tokens: HTMLDivElement[];
    maxTokenWidth: number;
    lastVisibleTokenRightPos: number;
    hiddenTokensCount: number;
    extraSpace: number;
}

interface IState {
    hiddenTokensCount: number;
}

/**
 * Tokenizer component
 *  - isWrappable: if true, tokens will wrap to new line if there is not enough space.
 *          Available space will be calculated based on parent element or maxWidth property.
 *  - maxWidth: maximum width of the tokenizer, if not set, it will be calculated based on parent element
 *  - maxVisibleTokens: maximum number of tokens to be visible, rest will be hidden in MoreItems
 *  - showAll: if true, all tokens will be visible, regardless of maxVisibleTokens or maxWidth
 */

/** Container for tokens. Handles their rendering, selection and responsiveness */
class Tokenizer extends React.PureComponent<IProps, IState> {
    _wrapperRef = React.createRef<HTMLDivElement>();
    _tokenizerRef = React.createRef<HTMLDivElement>();
    _refMoreItems = React.createRef<HTMLDivElement>();
    _refAfterTokenText = React.createRef<HTMLDivElement>();

    resizeObserver: ResizeObserver;

    constructor(props: IProps) {
        super(props);

        this.state = {
            hiddenTokensCount: 0
        };

        this.resizeObserver = new ResizeObserver(this.handleResize);
    }

    componentDidMount(): void {
        this.correctItems();
        this.resizeObserver.observe(this._wrapperRef.current);
        this.resizeObserver.observe(this._wrapperRef.current?.parentElement);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        const _joined = (tokens: ITokenProps[]) => tokens.map(t => t.title ?? "").join("");

        if (_joined(this.props.tokens) !== _joined(prevProps.tokens) || this.props.trailingText !== prevProps.trailingText ||
                this.props.showAll !== prevProps.showAll || this.props.extraSpace !== prevProps.extraSpace ||
                this.props.maxVisibleTokens !== prevProps.maxVisibleTokens || this.props.maxWidth !== prevProps.maxWidth) {
            this.correctItems();
        }
    }

    componentWillUnmount() {
        this.handleResize.cancel();
    }

    handleResize = debounce((entries: readonly ResizeObserverEntry[]) => {
        /**
         * MultiSelect might be hidden on page load (e.g. in filters) but could have some value,
         * which should be shortened with MoreItemsMark - we need to recalculate when multiselect
         * become visible. It also could be useful if we will have flexible multiselect.
         **/
        this.correctItems();
    }, 100);

    // known problem for the current version (to adhere to the rules of DOMOrchestrator)
    // if width of the longer item has been once cut to MAX_ITEM_WIDTH,
    // it won't get resized to bigger one, even if there would be enough space
    correctItems = () => {
        // cases when select is hidden f.e. collapsible content
        if (!this._wrapperRef.current?.offsetWidth || !this._tokenizerRef.current || this.props.isWrappable) {
            if (this._refMoreItems.current) {
                this.props.domManipulatorOrchestrator.registerCallback(
                    () => {
                        return this._refMoreItems.current.style.display === "block";
                    },
                    (isMoreTextVisible) => {
                        if (isMoreTextVisible) {
                            this._refMoreItems.current.style.display = "none";
                        }
                    },
                    [this._refMoreItems]
                );
            }
        }

        this.props.domManipulatorOrchestrator.registerCallback(
            () => {
                const tokens = this._tokenizerRef.current.children as unknown as HTMLDivElement[];

                if (this.props.isWrappable) {
                    return {
                        tokens,
                        hiddenTokensCount: 0,
                        lastVisibleTokenRightPos: undefined,
                        maxTokenWidth: undefined,
                        extraSpace: 0
                    };
                }

                let hiddenTokensCount = 0;
                // extra space to be kept for additional (absolutely positioned) content, which should be deducted from parent element available space
                const extraSpace = this.props.trailingText ? getTextWidthByElement(this.props.trailingText, this._refAfterTokenText.current) : 0;
                const SPACE_FOR_MORE_ITEMS = 15; // MoreItems sign (+N) will be rendered in the extra space, or we let 15px for it if there is no extra space
                const keepAdditionalSpace = extraSpace + Math.max(this.props.extraSpace ?? 0, SPACE_FOR_MORE_ITEMS);
                // wrapper - for size calculations
                const wrapper = this._wrapperRef.current;

                let availableWidth: number;

                if (!this.props.showAll && this.props.maxWidth) {
                    // predefined width
                    availableWidth = parseInt(this.props.maxWidth);
                } else {
                    // available width is calculated based on parent element
                    const parentEl = wrapper.parentElement;
                    const style = window.getComputedStyle(parentEl);
                    availableWidth = parentEl.offsetWidth - parseFloat(style.paddingLeft) - parseFloat(style.paddingRight) - (keepAdditionalSpace ? keepAdditionalSpace + TOKEN_MARGIN_RIGHT : 0);
                }
                availableWidth = Math.round(Math.max(availableWidth, 0));

                if (availableWidth === 0) {
                    return {
                        tokens,
                        hiddenTokensCount: tokens.length,
                        lastVisibleTokenRightPos: undefined,
                        maxTokenWidth: undefined,
                        extraSpace
                    };
                }

                let lastVisibleTokenRightPos = 0;
                let allFit = true;
                let widthSum = 0;
                const _doesTokenFit = (rightPos: number, index: number) => {
                    return rightPos <= (availableWidth + 1) && (!this.props.maxVisibleTokens || index < this.props.maxVisibleTokens);
                };

                // go through all items full length (scrollWidth) to find out whether they all fit
                for (let i = 0; i < tokens.length; i++) {
                    const item = tokens[i];

                    // use scrollWidth => add +2 for borders manually
                    const size = (item.scrollWidth + 2) + this.getTokenMargin(i);

                    widthSum += size;

                    if (!_doesTokenFit(widthSum, i)) {
                        allFit = false;
                        break;
                    }
                }

                let max = tokens.length === 1 || allFit ? availableWidth : Math.min(availableWidth, MAX_SHORT_TOKEN_WIDTH);

                if (tokens.length && !allFit && availableWidth < MIN_LONG_TOKEN_WIDTH) {
                    console.warn(`Tokenizer: available width (${availableWidth}) is less than MIN_LONG_TOKEN_WIDTH, tokens will be cut off`);
                    max = Math.max(availableWidth, MIN_LONG_TOKEN_WIDTH); // minimum width for token, which is shortened
                }

                widthSum = 0;

                // go through all items after "max" width was set based on allFit condition
                // to find out which items don't fit
                for (let i = 0; i < tokens.length; i++) {
                    const item = tokens[i];
                    const size = Math.min(item.offsetWidth, max) + this.getTokenMargin(i);
                    // first token is always visible
                    const isFirst = i === 0;

                    widthSum += size;

                    if (!isFirst && !_doesTokenFit(widthSum, i)) {
                        hiddenTokensCount += 1;
                    } else {
                        lastVisibleTokenRightPos += size;
                    }
                }

                return {
                    tokens,
                    extraSpace,
                    hiddenTokensCount,
                    lastVisibleTokenRightPos: this.props.showAll ? availableWidth : lastVisibleTokenRightPos,
                    maxTokenWidth: max
                };
            },
            (data: IDomUpdateArgs) => {
                const { showAll, isWrappable } = this.props;

                for (let i = 0; i < data.tokens.length; i++) {
                    const item = data.tokens[i];
                    // every tokens gets max-width
                    item.style.maxWidth = isDefined(data.maxTokenWidth) ? `${data.maxTokenWidth}px` : "fit-content";

                    // use visibility to hide overflowing tokens,
                    // we need them to occupy space for the calculations
                    item.style.visibility = showAll || isWrappable || i < (data.tokens.length - data.hiddenTokensCount) ? "visible" : "hidden";
                }

                this._tokenizerRef.current.style.overflow = showAll ? "hidden" : "visible";
                this._tokenizerRef.current.style.maxWidth = isDefined(data.lastVisibleTokenRightPos) ? `${data.lastVisibleTokenRightPos}px` : "fit-content";

                const selectedTokenIndex = this.selectedTokenIndex;
                // scroll to the active item or to the right as much as possible
                if (isWrappable) {
                    // no scrolling for wrapped tokens
                } else if (selectedTokenIndex !== -1) {
                    this.scrollToIndex(selectedTokenIndex, TextAlign.Left);
                } else if (showAll) {
                    // when focused and no item is selected, we scroll to rightmost item, so
                    // user can see newly added (appended) tokens
                    this.scrollToIndex(data.tokens.length - 1, TextAlign.Right);
                } else {
                    // if there is no focus, left most token should be visible first
                    this.scrollToIndex(0, TextAlign.Left);
                }

                if (this._refMoreItems.current) {
                    this._refMoreItems.current.style.position = "absolute";
                    this._refMoreItems.current.style.left = `${data.lastVisibleTokenRightPos}px`;
                    this._refMoreItems.current.style.display = !showAll && data.hiddenTokensCount > 0 ? "block" : "none";
                }

                if (this._refAfterTokenText.current) {
                    this._refAfterTokenText.current.style.left = data.tokens?.length ? `${data.lastVisibleTokenRightPos + (this._refMoreItems.current?.offsetWidth ?? 0) + TOKEN_MARGIN_RIGHT}px` : "0";
                    this._refAfterTokenText.current.style.display = !showAll ? "block" : "none";
                }

                if (this._wrapperRef.current) {
                    const moreItemsSize = data.hiddenTokensCount > 0 ? getTextWidthByElement(this.props.customMoreTokensText?.(data.hiddenTokensCount) ?? `+${data.hiddenTokensCount}`, this._refMoreItems.current) : 0;
                    this._wrapperRef.current.style.paddingRight = showAll || isWrappable ? "0" : `${data.extraSpace + moreItemsSize}px`;
                }

                if (this.state.hiddenTokensCount !== data.hiddenTokensCount) {
                    this.setState({ hiddenTokensCount: data.hiddenTokensCount });
                }
            },
            [this._tokenizerRef, this._wrapperRef, this._refAfterTokenText]
        );
    };

    getTokenMargin = (index: number): number => {
        return index < this.props.tokens.length - 1 ? TOKEN_MARGIN_RIGHT : 0;
    };

    /**
     * Scrolls to token with index idx
     * @param idx
     * @param alignment - new alignment or 'nearest' to calculate nearest edge
     * @param forceAlignment
     */
    scrollToIndex(idx: number, alignment: TextAlign | "nearest" = TextAlign.Left, forceAlignment = false): void {
        const item = this._wrapperRef.current.childNodes[0].childNodes[idx] as HTMLElement;
        if (item) {
            const itemLeft = item.offsetLeft;
            const itemRight = itemLeft + item.offsetWidth;
            const viewportLeft = this._tokenizerRef.current.scrollLeft;
            const viewportRight = viewportLeft + this._tokenizerRef.current.offsetWidth;

            const isFullyVisible = itemLeft > viewportLeft && itemRight < viewportRight;

            if (alignment === "nearest") {
                alignment = Math.abs(itemLeft - viewportLeft) > Math.abs(itemRight - viewportRight)
                    ? TextAlign.Right
                    : TextAlign.Left;
            }
            if (!isFullyVisible || forceAlignment) {
                this._tokenizerRef.current.scrollLeft = (alignment === TextAlign.Right)
                    ? itemRight - this._tokenizerRef.current.offsetWidth + 1
                    : itemLeft;
            }
        }
    }

    get selectedTokenIndex(): number {
        return this.props.tokens.findIndex(token => token.isSelected);
    }

    renderMoreItemsText = () => {
        if (this.props.isWrappable) {
            return null;
        }

        // ignore selected token style inside tooltip
        let tokens = this.props.tokens.map(token => ({
            ...token,
            isSelected: false
        }));

        if (this.state.hiddenTokensCount) {
            tokens = tokens.slice(-1 * this.state.hiddenTokensCount);
        }

        return <MoreItems
                passRef={this._refMoreItems}
                showTooltip={!!this.props.showMoreTokensTooltip}
                count={this.props.showAll ? 0 : this.state.hiddenTokensCount}
                tooltipContent={this.props.customTooltipContent ??
                        <WrappedTokenizer isWrappable maxWidth={"300px"} tokens={tokens}/>}
        />;
    };

    handleTokenizerRef = (ref: HTMLDivElement) => {
        handleRefHandlers(ref, this._tokenizerRef, this.props.passRef);
    };

    renderTokens = (): React.ReactNode => {
        return this.props.tokens.map((tokenDef: ITokenProps, index: number) => {
            if (this.props.tokenRenderer) {
                return this.props.tokenRenderer(tokenDef, index);
            }

            return (
                <Token key={index}
                       onClick={this.props.onClick}
                       {...tokenDef}/>
            );
        });
    };

    render() {
        return (
            <StyledTokenizerOuterWrapper
                data-testid={TestIds.Tokenizer}
                ref={this._wrapperRef}
                className={this.props.className}
            >
                <StyledTokenizer
                    ref={this.handleTokenizerRef}
                    isWrappable={this.props.isWrappable}>
                    {this.renderTokens()}
                </StyledTokenizer>
                {this.renderMoreItemsText()}
                <AfterTokensText ref={this._refAfterTokenText}
                                 data-testid={TestIds.AfterTokensText}>{this.props.trailingText}</AfterTokensText>
            </StyledTokenizerOuterWrapper>
        );
    }
}

export { Tokenizer as TokenizerClean };

// extra constant instead of export default, so that Tokenizer can use internally another Tokenizer
const WrappedTokenizer = withDomManipulator(withTranslation(["Components"], { withRef: true })(Tokenizer));

export default WrappedTokenizer;