import {
    EntitySetName,
    IPrEmploymentEntity,
    IPrEmploymentExtraSalaryEntity,
    IPrEmploymentSalaryComponentEntity,
    IPrEmploymentTemplateEntity,
    IPrEmploymentTemplateExtraSalaryEntity,
    IPrEmploymentTemplateSalaryComponentEntity,
    IPrExtraSalaryEntity,
    IPrExtraSalaryLegislativeValueEntity,
    IPrPayrollSettingEntity,
    IPrSalaryComponentEntity,
    OdataActionName,
    PrEmploymentEntity,
    PrEmploymentTemplateEntity,
    PrEmploymentTemplateExtraSalaryEntity,
    PrEmploymentTemplateExtraSalaryTemporalCurrentEntity,
    PrEmploymentTemplateExtraSalaryTemporalEntity,
    PrEmploymentTemplateSalaryComponentEntity,
    PrEmploymentTemplateSalaryComponentTemporalCurrentEntity,
    PrEmploymentTemplateSalaryComponentTemporalEntity,
    PrEmploymentTemplateTemporalCurrentEntity,
    PrEmploymentTemporalCurrentEntity,
    PrExtraSalaryEntity,
    PrExtraSalaryLegislativeValueEntity,
    PrExtraSalaryTemporalEntity,
    PrPayrollSettingEntity,
    PrPayrollSettingTemporalCurrentEntity,
    PrSalaryComponentEntity,
    PrSalaryComponentTemporalEntity
} from "@odata/GeneratedEntityTypes";
import { BatchRequest, isBatchResultOk, OData, ODataQueryBuilder, Query } from "@odata/OData";
import { PrBaseTypeCode, PrEntityValueSourceCode } from "@odata/GeneratedEnums";
import { FormStorage } from "../../../views/formView/FormStorage";
import { createLegislativeExtraSalaries } from "../extraSalary/ExtraSalary.utils";
import {
    createNewTemporalLineItem,
    getDefaultTemporalDateValidFrom,
    getDefaultTemporalDateValidTo,
    groupAndOptimizeRanges,
    replaceSyncedValueWithHistoryValues,
    TTemporal
} from "@odata/TemporalUtils";
import { ODataQueryResult } from "@odata/ODataParser";
import BindingContext, { createBindingContext, IEntity } from "../../../odata/BindingContext";
import { IGetValueArgs, TTemporalDialogSettings } from "@components/smart/FieldInfo";
import i18next from "i18next";
import { addNewLineItem } from "@components/smart/smartFastEntryList";
import { formatDateToDateTimeOffsetString } from "@components/inputs/date/utils";
import { getUtcDate } from "../../../types/Date";

export enum DialogType {
    SalaryComponent = "SalaryComponent",
    ExtraSalary = "ExtraSalary"
}

export const EmploymentTranslationFiles = ["Employment", "SalaryComponent", "ExtraSalary", "NumberRange", "Common"];

export const LegislativeExtraSalaryLocalPath = BindingContext.localContext("LegislativeExtraSalaries");
export const ExtraSalaryTogglePath = BindingContext.localContext("LegislativeExtraSalariesToggle");

export const ExtraSalaryGrpId = PrEmploymentTemplateEntity.ExtraSalaries;
export const SalaryComponentGrpId = PrEmploymentTemplateEntity.SalaryComponents;

// needs to be in callback, so that translation is loaded
export const getExtraSalaryTemporalDialogSettings = (): TTemporalDialogSettings => {
    return {
        dialogTitle: i18next.t("ExtraSalary:Form.PlanExtraSalaryTitle"),
        columns: [
            PrEmploymentTemplateExtraSalaryTemporalEntity.Name,
            PrEmploymentTemplateExtraSalaryTemporalEntity.ComputationType,
            PrEmploymentTemplateExtraSalaryTemporalEntity.Value
        ],
        extractHistory: getExtraSalaryItemHistory
    };
};

// needs to be in callback, so that translation is loaded
export const getSalaryComponentTemporalDialogSettings = (): TTemporalDialogSettings => {
    return {
        dialogTitle: i18next.t("SalaryComponent:Form.PlanSalaryComponentTitle"),
        columns: [
            PrEmploymentTemplateSalaryComponentTemporalEntity.Name,
            PrEmploymentTemplateSalaryComponentTemporalEntity.Type,
            PrEmploymentTemplateSalaryComponentTemporalEntity.Amount
        ],
        extractHistory: getSalaryComponentItemHistory
    };
};

export function isLegislativeExtraSalaryItem(salaryItem: IPrEmploymentExtraSalaryEntity): boolean {
    const value = salaryItem?.Default?.BaseTypeCode ?? salaryItem?.Template?.Default?.BaseTypeCode;
    return value === PrBaseTypeCode.Legislative;
}

export function isLegislativeExtraSalary({ storage, bindingContext, item }: IGetValueArgs): boolean {
    if (!item) {
        item = storage.getValue(bindingContext);
    }
    // universal check for both PrEmploymentTemplateSalaryItem and PrEmploymentSalaryItem
    const employmentItem = (item as unknown as IPrEmploymentExtraSalaryEntity);
    return isLegislativeExtraSalaryItem(employmentItem);
}

export type TEmploymentEntity = IPrEmploymentEntity | IPrEmploymentTemplateEntity;
export type TEmploymentLineItemProp = PrEmploymentEntity.ExtraSalaries | PrEmploymentEntity.SalaryComponents;
export type TEmploymentTemplateLineItemProp =
    PrEmploymentTemplateEntity.ExtraSalaries
    | PrEmploymentTemplateEntity.SalaryComponents;

export type TSalaryComponentLineItem = IPrEmploymentTemplateSalaryComponentEntity | IPrEmploymentSalaryComponentEntity;
export type TExtraSalaryLineItem = IPrEmploymentTemplateExtraSalaryEntity | IPrEmploymentExtraSalaryEntity;

/**
 * Method transforms ValueSourceCode used on template to SourceCode, which should be set to EmploymentEntity when
 * template is applied to it.
 * @param code
 */
function getEmploymentSourceCodeFromTemplate(code: string): PrEntityValueSourceCode {
    switch (code) {
        case PrEntityValueSourceCode.Entity:
            return PrEntityValueSourceCode.Template;
        case PrEntityValueSourceCode.Default:
            return PrEntityValueSourceCode.TemplateDefault;
        default:
            return (code as PrEntityValueSourceCode) ?? PrEntityValueSourceCode.Template;
    }
}

/**
 * Returns new sourceCode for newly created line item
 * @param code as string, so we don't need to cast it when called
 * @param isFromTemplate
 */
function getSourceCode(code: string, isFromTemplate: boolean): PrEntityValueSourceCode {
    if (!isFromTemplate) {
        return PrEntityValueSourceCode.Default;
    }
    return getEmploymentSourceCodeFromTemplate(code as PrEntityValueSourceCode);
}

export function createNewSalaryComponentItemFromDefault(defaultSalaryComponent: IPrSalaryComponentEntity, template?: IPrEmploymentTemplateSalaryComponentEntity): TSalaryComponentLineItem {
    const entity = template ?? defaultSalaryComponent;
    // change type so we are able to get "*SourceCode" with fallback, because it's not defined on IPrSalaryComponent
    const {
        CurrentTemporalPropertyBag
    } = (entity as unknown as IPrEmploymentTemplateSalaryComponentEntity);
    const isFromTemplate = !!template;

    const newItem: TSalaryComponentLineItem = createNewTemporalLineItem(getSalaryComponentTemporalDialogSettings(), {
        Default: defaultSalaryComponent?.Id ? { ...defaultSalaryComponent } : null,
        CurrentTemporalPropertyBag: {
            ...CurrentTemporalPropertyBag,
            AmountSourceCode: getSourceCode(CurrentTemporalPropertyBag.AmountSourceCode, isFromTemplate),
            NameSourceCode: getSourceCode(CurrentTemporalPropertyBag.NameSourceCode, isFromTemplate),
            TypeSourceCode: getSourceCode(CurrentTemporalPropertyBag.TypeSourceCode, isFromTemplate)
        }
    });
    if (template) {
        const { Default, ...newItemWithoutDefault } = newItem;
        return {
            Template: { ...template },
            ...newItemWithoutDefault
        } as IPrEmploymentSalaryComponentEntity;
    }
    return newItem as IPrEmploymentTemplateSalaryComponentEntity;
}

export function createNewExtraSalaryItemFromDefault(defaultExtraSalary: IPrExtraSalaryEntity, template?: IPrEmploymentTemplateExtraSalaryEntity, legislativeValues?: IPrExtraSalaryLegislativeValueEntity[]): TExtraSalaryLineItem {
    const entity = template ?? defaultExtraSalary;
    // change type so we are able to get "*SourceCode" with fallback, because it's not defined on IPrExtraSalary
    const { CurrentTemporalPropertyBag } = (entity as unknown as IPrEmploymentTemplateExtraSalaryEntity);
    const isFromTemplate = !!template;
    let defaultRange = null;

    if (defaultExtraSalary.BaseTypeCode === PrBaseTypeCode.Legislative) {
        const {
            DateValidFrom,
            DateValidTo
        } = legislativeValues.find(value => value.ExtraSalaryTypeCode === defaultExtraSalary.TypeCode);
        defaultRange = { DateValidFrom, DateValidTo };
    }

    const newItem: TExtraSalaryLineItem = createNewTemporalLineItem(getExtraSalaryTemporalDialogSettings(), {
        Default: defaultExtraSalary?.Id ? { ...defaultExtraSalary } : null,
        CurrentTemporalPropertyBag: {
            ...CurrentTemporalPropertyBag,
            ComputationTypeSourceCode: getSourceCode(CurrentTemporalPropertyBag.ComputationTypeSourceCode, isFromTemplate),
            ValueSourceCode: getSourceCode(CurrentTemporalPropertyBag.ValueSourceCode, isFromTemplate),
            NameSourceCode: getSourceCode(CurrentTemporalPropertyBag.NameSourceCode, isFromTemplate)
        }
    }, defaultRange);
    if (template) {
        const { Default, ...newItemWithoutDefault } = newItem;
        return {
            Template: { ...template },
            ...newItemWithoutDefault
        } as IPrEmploymentExtraSalaryEntity;
    }
    return newItem as IPrEmploymentTemplateExtraSalaryEntity;
}

export async function getEmploymentGeneralSettings(oData: OData, withHistory = false): Promise<IPrPayrollSettingEntity> {
    try {
        const query = oData.getEntitySetWrapper(EntitySetName.PrPayrollSettings).query()
            .expand(PrPayrollSettingEntity.CurrentTemporalPropertyBag, (q) => {
                q.select(
                    PrPayrollSettingTemporalCurrentEntity.SickDays,
                    PrPayrollSettingTemporalCurrentEntity.LeaveDays
                );
            });

        if (withHistory) {
            query.expand(PrPayrollSettingEntity.TemporalPropertyBag);
        }

        const settings = (await query.fetchData<IPrPayrollSettingEntity[]>()).value;

        return settings[0];
    } catch (e) {
        // todo: handle error
        return {};
    }
}

export async function createDefaultLegislativeExtraSalaryItems(storage: FormStorage): Promise<void> {
    const items = await createLegislativeExtraSalaries(storage, getExtraSalaryTemporalDialogSettings());

    items.forEach(item => {
        addNewLineItem(storage, PrEmploymentTemplateEntity.ExtraSalaries, PrEmploymentTemplateEntity.ExtraSalaries, [], item);
    });
}

export async function initWithPayrollSettings(storage: FormStorage): Promise<void> {
    const settings = await getEmploymentGeneralSettings(storage.oData);

    const { SickDays, LeaveDays } = settings?.CurrentTemporalPropertyBag ?? {};
    let { CurrentTemporalPropertyBag } = storage.data.entity;

    if (!CurrentTemporalPropertyBag) {
        CurrentTemporalPropertyBag = {};
    }

    // initiated with settings default
    CurrentTemporalPropertyBag.SickDays = SickDays;
    CurrentTemporalPropertyBag.SickDaysSourceCode = PrEntityValueSourceCode.Default;
    CurrentTemporalPropertyBag.LeaveDays = LeaveDays;
    CurrentTemporalPropertyBag.LeaveDaysSourceCode = PrEntityValueSourceCode.Default;

    storage.data.entity.CurrentTemporalPropertyBag = CurrentTemporalPropertyBag;
}

export async function initiateWithTemplate(storage: FormStorage<IPrEmploymentEntity>, templateId: string): Promise<void> {
    const batch: BatchRequest = storage.oData.batch();
    batch.beginAtomicityGroup("group1");

    // legislative values
    batch.getEntitySetWrapper(EntitySetName.PrExtraSalaryLegislativeValues).query()
        .select(
            PrExtraSalaryLegislativeValueEntity.ExtraSalaryTypeCode,
            PrExtraSalaryLegislativeValueEntity.DateValidFrom,
            PrExtraSalaryLegislativeValueEntity.DateValidTo
        );

    batch.getEntitySetWrapper(EntitySetName.PrEmploymentTemplates)
        .query(templateId)
        .expand(PrEmploymentTemplateEntity.CurrentTemporalPropertyBag, subQuery =>
            subQuery.expand(PrEmploymentTemplateTemporalCurrentEntity.GuaranteedSalaryDegree)
                .expand(PrEmploymentTemplateTemporalCurrentEntity.WorkingPattern)
        )
        .expand(PrEmploymentTemplateEntity.SalaryComponents, (subQuery) => {
            subQuery.expand(PrEmploymentTemplateSalaryComponentEntity.CurrentTemporalPropertyBag, (q2) =>
                    q2.expand(PrEmploymentTemplateSalaryComponentTemporalCurrentEntity.Type))
                .expand(PrEmploymentTemplateSalaryComponentEntity.Default);
        })
        .expand(PrEmploymentTemplateEntity.ExtraSalaries, (subQuery) => {
            subQuery
                .expand(PrEmploymentTemplateSalaryComponentEntity.CurrentTemporalPropertyBag, (q2) =>
                    q2.expand(PrEmploymentTemplateExtraSalaryTemporalCurrentEntity.ComputationType)
                )
                .expand(PrEmploymentTemplateSalaryComponentEntity.Default, q =>
                    q.select(PrExtraSalaryEntity.Id, PrExtraSalaryEntity.BaseTypeCode, PrExtraSalaryEntity.TypeCode)
                );
        });

    const results = await batch.execute();
    if (results.every(res => isBatchResultOk(res))) {

        const legislativeValues = (results[0].body as ODataQueryResult).value as IPrExtraSalaryLegislativeValueEntity[];
        const template = (results[1].body as ODataQueryResult).value as IPrEmploymentTemplateEntity;

        const { CurrentTemporalPropertyBag, SalaryComponents, ExtraSalaries } = template;

        const { entity } = storage.data;

        delete entity.ExtraSalaries;
        delete entity.SalaryComponents;
        ExtraSalaries?.forEach(tpl => {
            const item = createNewExtraSalaryItemFromDefault(tpl.Default, tpl, legislativeValues);
            addNewLineItem(storage, PrEmploymentEntity.ExtraSalaries, PrEmploymentEntity.ExtraSalaries, [], item);
        });
        SalaryComponents.forEach(tpl => {
            const item = createNewSalaryComponentItemFromDefault(tpl.Default, tpl);
            addNewLineItem(storage, PrEmploymentEntity.SalaryComponents, PrEmploymentEntity.SalaryComponents, [], item);
        });

        entity.CurrentTemporalPropertyBag = {
            ...CurrentTemporalPropertyBag,
            SickDaysSourceCode: getEmploymentSourceCodeFromTemplate(CurrentTemporalPropertyBag.SickDaysSourceCode),
            LeaveDaysSourceCode: getEmploymentSourceCodeFromTemplate(CurrentTemporalPropertyBag.LeaveDaysSourceCode),
            WorkingPatternSourceCode: getEmploymentSourceCodeFromTemplate(CurrentTemporalPropertyBag.WorkingPatternSourceCode),
            GuaranteedSalaryDegreeSourceCode: getEmploymentSourceCodeFromTemplate(CurrentTemporalPropertyBag.GuaranteedSalaryDegreeSourceCode)
        };

        const bc = storage.data.bindingContext
            .navigate(PrEmploymentEntity.CurrentTemporalPropertyBag)
            .navigate(PrEmploymentTemporalCurrentEntity.SickDays);
        const info = storage.getInfo(bc);
        const { temporalDialog } = info.fieldSettings;
        entity.TemporalPropertyBag = [{
            // props should not be defined -> we take it from template by default...
            DateValidFrom: getDefaultTemporalDateValidFrom(temporalDialog),
            DateValidTo: getDefaultTemporalDateValidTo(temporalDialog)
        }];

        storage.refresh();
    }
}


export function onBeforeSave(storage: FormStorage, entity: TEmploymentEntity): IEntity {
    // LegislativeExtraSalaries cannot be renamed...
    entity.ExtraSalaries?.forEach(salary => {
        if (isLegislativeExtraSalaryItem(salary)) {
            salary.TemporalPropertyBag?.forEach(item => {
                // it's not possible to change these values for legislative extra salaries
                delete item.Name;
                delete item.ComputationTypeCode;
                delete item.ComputationType;
            });
        }
    });

    return entity;
}

export async function removeTemplate(storage: FormStorage<IPrEmploymentEntity>): Promise<void> {
    storage.setBusy(true);

    const payload = {
        TargetDate: formatDateToDateTimeOffsetString(getUtcDate()), // todo: last non-editable date
        SynchronizeWithDefaultValues: false // todo: possible to be removed at all
    };

    await storage.oData.getEntitySetWrapper(EntitySetName.PrEmployments)
        .action(OdataActionName.PrEmploymentRemoveTemplate, storage.data.entity.Id, payload);

    await storage.reload({ preserveInfos: true, withoutBusy: true });

    storage.setBusy(false);
}

const expandSalaryComponent = <T extends Query = Query>(query: T): T =>
    query.expand(PrSalaryComponentEntity.TemporalPropertyBag, q =>
        q.expand(PrSalaryComponentTemporalEntity.Type));

const expandTemplateSalaryComponent = <T extends Query = Query>(query: T): T =>
    expandSalaryComponent(query)
        .expand(PrEmploymentTemplateSalaryComponentEntity.Default, q => expandSalaryComponent(q));

export async function getSalaryComponentItemHistory(storage: FormStorage, component: IEntity, data: TTemporal[]): Promise<TTemporal[]> {
    if (!data) {
        data = [];
    }

    const baseEntity = component.Template ?? component.Default;
    const hasTemplate = !!component.Template;

    if (!baseEntity) {
        // there are no default data, just return actual TemporalPropertyBag
        return data;
    }

    const { oData } = storage;

    let historyData: TTemporal[];

    let query: ODataQueryBuilder;
    if (hasTemplate) {
        const template = storage.data.entity.Template;
        const bc = createBindingContext(EntitySetName.PrEmploymentTemplates, oData.metadata).addKey(template)
                .navigate(PrEmploymentTemplateEntity.SalaryComponents).addKey(baseEntity);
        query = oData.fromPath(bc.getFullPath()).query();
        query = expandTemplateSalaryComponent(query);

        const result = await query.fetchData<IPrEmploymentSalaryComponentEntity>();
        const salaryComp = result.value;
        // extract additional NULL values from template from the Default
        historyData = (salaryComp.TemporalPropertyBag ?? [])
            .reduce((res, current) =>
                    res.concat(...replaceSyncedValueWithHistoryValues(current, salaryComp.Default.TemporalPropertyBag, PrEntityValueSourceCode.TemplateDefault)), []);
    } else {
        query = oData.getEntitySetWrapper(EntitySetName.PrSalaryComponents).query(baseEntity.Id);
        query = expandSalaryComponent(query);

        const result = await query.fetchData<IPrSalaryComponentEntity>();
        historyData = result.value.TemporalPropertyBag as TTemporal[];
    }

    return data.reduce((res: TTemporal[], item: TTemporal) =>
        res.concat(replaceSyncedValueWithHistoryValues(item, historyData, hasTemplate ? PrEntityValueSourceCode.Template : PrEntityValueSourceCode.Default)), []);
}

const expandExtraSalary = <T extends Query = Query>(query: T): T =>
    query.expand(PrExtraSalaryEntity.TemporalPropertyBag, q =>
        q.expand(PrExtraSalaryTemporalEntity.ComputationType));

const expandTemplateExtraSalary = <T extends Query = Query>(query: T): T =>
    expandExtraSalary(query)
        .expand(PrEmploymentTemplateExtraSalaryEntity.Default, q => expandExtraSalary(q));

export async function getExtraSalaryItemHistory(storage: FormStorage, salary: IEntity, data: TTemporal[]): Promise<TTemporal[]> {
    if (!data) {
        data = [];
    }

    const baseEntity = salary.Template ?? salary.Default;
    const hasTemplate = !!salary.Template;

    if (!baseEntity) {
        // there are no default data, just return actual TemporalPropertyBag
        return data;
    }

    const { oData } = storage;

    let historyData: TTemporal[];

    let query: ODataQueryBuilder;
    if (hasTemplate) {
        const template = storage.data.entity.Template;
        const bc = createBindingContext(EntitySetName.PrEmploymentTemplates, oData.metadata).addKey(template)
                .navigate(PrEmploymentTemplateEntity.ExtraSalaries).addKey(baseEntity);
        query = oData.fromPath(bc.getFullPath()).query();
        query = expandTemplateExtraSalary(query);

        const result = await query.fetchData<IPrEmploymentExtraSalaryEntity>();
        const extraSalary = result.value;
        // extract additional NULL values from template from the Default
        historyData = (extraSalary.TemporalPropertyBag ?? [])
            .reduce((res, current) =>
                    res.concat(...replaceSyncedValueWithHistoryValues(current, extraSalary.Default.TemporalPropertyBag, PrEntityValueSourceCode.TemplateDefault)), []);
    } else {
        query = oData.getEntitySetWrapper(EntitySetName.PrExtraSalaries).query(baseEntity.Id);
        query = expandExtraSalary(query);

        const result = await query.fetchData<IPrExtraSalaryEntity>();
        historyData = result.value.TemporalPropertyBag as TTemporal[];
    }

    return data.reduce((res: TTemporal[], item: TTemporal) =>
        res.concat(replaceSyncedValueWithHistoryValues(item, historyData, hasTemplate ? PrEntityValueSourceCode.Template : PrEntityValueSourceCode.Default)), []);
}

/**
 * Retusn function to extract history from template
 */
export function getExtractHistoryFromTemplateFn(propName: string) {
    return async function getHistoryFn(storage: FormStorage, entity: IEntity, data: TTemporal[]): Promise<TTemporal[]> {
        // todo: better condition
        const isExpandable = ["WorkingPattern", "GuaranteedSalaryDegree"].includes(propName);
        const hasGeneralSettings = ["LeaveDays", "SickDays"].includes(propName);

        if (!data) {
            data = [];
        }

        // First, if template is defined on the entity, try to expand data from template
        const id = entity.Template?.Id;
        if (id) {
            const query = storage.oData.getEntitySetWrapper(EntitySetName.PrEmploymentTemplates)
                    .query(id)
                    .expand(PrEmploymentTemplateEntity.TemporalPropertyBag, subQuery => {
                        if (isExpandable) {
                            subQuery.expand(propName);
                        }
                    });

            const result = await query.fetchData<IPrEmploymentTemplateEntity>();
            const historyData = groupAndOptimizeRanges(result.value.TemporalPropertyBag as TTemporal[], [propName], true);

            data = data.reduce((res: TTemporal[], current: TTemporal) =>
                    res.concat(...replaceSyncedValueWithHistoryValues(current, historyData, PrEntityValueSourceCode.Template)), []);
        }

        // Second, for globally configurable entities, we may expand additional null values from common settings
        if (hasGeneralSettings) {
            const settings = await getEmploymentGeneralSettings(storage.oData, true);
            const historyData = groupAndOptimizeRanges(settings?.TemporalPropertyBag as TTemporal[], [propName], true);

            data = data.reduce((res: TTemporal[], current: TTemporal) =>
                    res.concat(...replaceSyncedValueWithHistoryValues(current, historyData, PrEntityValueSourceCode.Default)), []);
        }

        return data;
    };
}
