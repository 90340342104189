import { IEntity, TEntityKey } from "@odata/BindingContext";
import {
    CompanyEntity,
    EntitySetName,
    INotificationEntity,
    INotificationSettingEntity,
    INotificationTypeEntity,
    INotificationTypeGroupEntity,
    IUserNotificationEntity,
    NotificationEntity,
    NotificationSettingEntity,
    UserNotificationEntity
} from "@odata/GeneratedEntityTypes";
import { NotificationTypeCode } from "@odata/GeneratedEnums";
import { IBatchResult, OData } from "@odata/OData";
import { ODataQueryResult } from "@odata/ODataParser";
import { DefaultTheme } from "styled-components/macro";

import { IAppContext } from "../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../contexts/appContext/AppContext.utils";
import { TRecordAny } from "../global.types";
import {
    ROUTE_BANK_ACCOUNTS,
    ROUTE_BANK_TRANSACTIONS,
    ROUTE_INBOX,
    ROUTE_INBOX_CUSTOMER_APPROVAL,
    ROUTE_TICKETS
} from "../routes";
import DateType, { getUtcDate } from "../types/Date";
import memoize from "../utils/memoize";

export const FAVICON_SIZE = 16;

export const redrawFavicon = async (notificationsCount = 0, theme: DefaultTheme): Promise<void> => {
    const favicons = document.getElementsByClassName("favicon") as HTMLCollectionOf<HTMLLinkElement>;
    const canvas = await drawFaviconImage(notificationsCount, "favicon/bright/favicon-32x32.png", theme);

    for (const favicon of favicons) {
        favicon.href = canvas.toDataURL("image/png");
    }
};

export const drawFaviconImage = async (notificationsCount = 0, originalFavicon: string, theme: DefaultTheme): Promise<HTMLCanvasElement> => {
    return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const origFavicon = document.createElement("img");

        canvas.width = FAVICON_SIZE;
        canvas.height = FAVICON_SIZE;

        const context = canvas.getContext("2d");

        origFavicon.src = originalFavicon;

        origFavicon.onload = () => {
            // Draw Original Favicon as Background
            context.drawImage(origFavicon, 0, 0, FAVICON_SIZE, FAVICON_SIZE);

            if (notificationsCount) {
                // Draw Notification Circle
                context.beginPath();
                context.arc(canvas.width - FAVICON_SIZE / 3, FAVICON_SIZE / 3, FAVICON_SIZE / 3, 0, 2 * Math.PI);
                context.fillStyle = theme.C_SEM_el_bad;
                context.fill();

                // Draw Notification Number
                context.font = "10px Lato";
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillStyle = theme.C_BTN_4L_emph_text;
                context.fillText(notificationsCount.toString(), canvas.width - FAVICON_SIZE / 3, FAVICON_SIZE / 3);
            }

            resolve(canvas);
        };
    });
};

export const getNotificationArguments = (notification: INotificationEntity): IEntity & {
    ErrorCode?: string;
    ErrorParameters?: Record<string, unknown>;
    ErrorDetail?: TRecordAny | string;
} => {
    const args = (notification.TextArgument && JSON.parse(notification.TextArgument)) ?? {};

    // date ErrorParameters should start with "date"
    if (args.ErrorParameters) {
        for (const key of Object.keys(args.ErrorParameters)) {
            if (key.startsWith("date")) {
                args.ErrorParameters[key] = DateType.format(getUtcDate(args.ErrorParameters[key] as string));
            }
        }
    }

    const sharedArgs = {
        ...args,
        ErrorCode: args.ErrorCode,
        ErrorParameters: args.ErrorParameters,
        ErrorDetail: args.ErrorDetail
    } as (IEntity & {
        ErrorCode?: string;
        ErrorParameters?: Record<string, unknown>;
        ErrorDetail?: TRecordAny | string;
    });


    switch (notification.NotificationTypeCode) {
        case NotificationTypeCode.InboxDocumentReceivment:
            return {
                ...sharedArgs,
                InboxMessages: args.InboxMessages as number
            };
        case NotificationTypeCode.DataBoxMessageReceivment:
            return {
                ...sharedArgs,
                Sender: args.Sender as string,
                MessageId: args.MessageId as number,
                Subject: args.Subject as string
            };
        case NotificationTypeCode.NewTicketCreated:
        case NotificationTypeCode.NewTicketFromCustomerPortalCreated:
        case NotificationTypeCode.NewTicketMessageFromAnAccountantReceivement:
        case NotificationTypeCode.NewTicketMessageFromACustomerReceivement:
            return {
                ...sharedArgs,
                TicketName: args.TicketName as string,
                TicketId: args.TicketId as number,
                Subject: args.Subject as string,
                Sender: args.Sender as string,
            };
        case NotificationTypeCode.SystemUpdate:
        case NotificationTypeCode.SatisfactionSurvey:
            return {};
        case NotificationTypeCode.BankStatementImportError:
        case NotificationTypeCode.BankAPIExpirationReminder:
            return {
                ...sharedArgs,
                CompanyBankAccountName: args.CompanyBankAccountName as string
            };
    }

    return args;
};

export const getNotificationUrl = (notification: INotificationEntity): string => {
    const args = getNotificationArguments(notification);
    let url;

    switch (notification.NotificationType.Code) {
        case NotificationTypeCode.BankAPIExpirationReminder:
            return addCompanyIdToUrl(ROUTE_BANK_ACCOUNTS, notification.Company?.Id);
        case NotificationTypeCode.InboxDocumentReceivment:
            return addCompanyIdToUrl(ROUTE_INBOX, notification.Company?.Id);
        case NotificationTypeCode.InboxDocumentApprovalCanceled:
        case NotificationTypeCode.InboxDocumentApprovalResumed:
        case NotificationTypeCode.DocumentForApprovalReceivement:
            return addCompanyIdToUrl(ROUTE_INBOX_CUSTOMER_APPROVAL, notification.Company?.Id);
        case NotificationTypeCode.DataBoxMessageReceivment:
            url = "https://www.mojedatovaschranka.cz/";

            return url;
        case NotificationTypeCode.NewTicketCreated:
        case NotificationTypeCode.NewTicketFromCustomerPortalCreated:
        case NotificationTypeCode.NewTicketMessageFromAnAccountantReceivement:
        case NotificationTypeCode.NewTicketMessageFromACustomerReceivement:
            url = ROUTE_TICKETS;

            if (args?.TicketId) {
                url += `/${args.TicketId}`;
            }

            return addCompanyIdToUrl(url, notification.Company?.Id);
        case NotificationTypeCode.BankStatementImportError:
        case NotificationTypeCode.BankTransactionsMatchMultipleError:
            url = ROUTE_BANK_TRANSACTIONS;

            return addCompanyIdToUrl(url, notification.Company?.Id);
        case NotificationTypeCode.SatisfactionSurvey:
            return "";
        case NotificationTypeCode.SystemUpdate:
            return "";
    }

    return "";
};

function fetchNotifications(oData: OData, key?: TEntityKey): Promise<ODataQueryResult<IUserNotificationEntity | IUserNotificationEntity[]>> {
    return oData.getEntitySetWrapper(EntitySetName.Notifications).query(key)
        .expand(UserNotificationEntity.Notification,
            (q) => q.expand(NotificationEntity.NotificationType)
                .expand(NotificationEntity.Company, qq => qq.expand(CompanyEntity.Logo)))
        .fetchData();
}

export const fetchNotificationsMemoized = memoize((oData: OData, key?: TEntityKey) =>
    fetchNotifications(oData, key), (oData, key) => key);

export interface INotificationsState {
    notificationSettings: INotificationSettingEntity;
    notificationTypeGroups: INotificationTypeGroupEntity[];
    notificationTypes: INotificationTypeEntity[];
}

export const fetchNotificationsSettings = async (context: IAppContext, oData: OData): Promise<INotificationsState> => {
    const batch = oData.batch();

    batch.beginAtomicityGroup("group1");

    const settingsId = context.getData().userSettings.NotificationSetting.Id.toString();
    batch.getEntitySetWrapper(EntitySetName.NotificationSettings).query(settingsId).expand(NotificationSettingEntity.TypeSettings);
    batch.getEntitySetWrapper(EntitySetName.NotificationTypeGroups).query();
    batch.getEntitySetWrapper(EntitySetName.NotificationTypes).query();

    const results = await batch.execute() as IBatchResult<ODataQueryResult>[];

    const notificationSettings = results[0].body.value as INotificationSettingEntity;
    const notificationTypeGroups = results[1].body.value as INotificationTypeGroupEntity[];
    const notificationTypes = results[2].body.value as INotificationTypeEntity[];

    const notificationsState: INotificationsState = {
        notificationSettings,
        notificationTypeGroups,
        notificationTypes
    };

    return notificationsState;
};