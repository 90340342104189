import {
    Condition,
    ConditionType,
    PredefinedFilter
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { isSameMonth } from "@components/inputs/date/utils";
import { EMPTY_DASH } from "@components/readOnlyList/ReadOnlyList";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { TFormTable } from "@components/smart/smartFormGroup/SmartFormGroup";
import { fetchReportTableData, IFetchReportTableDataArgs } from "@components/smart/smartTable/SmartReportTable.utils";
import { ICellValueObject } from "@components/table";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import {
    CompanyEntity,
    CompanyVatReducedCoefficientEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    ICompanyEntity,
    ICompanyVatReducedCoefficientEntity,
    IDocumentEntity,
    IElectronicSubmissionEntity
} from "@odata/GeneratedEntityTypes";
import {
    ClearedStatusCode,
    DocumentTypeCode,
    ElectronicSubmissionTypeCode,
    PostedStatusCode,
    VatStatementFrequencyCode,
    VatStatementStatusCode
} from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import { IFormatOptions } from "@odata/OData.utils";
import {
    VAT_CONTROL_STATEMENT_REPORT_PATH
} from "@pages/reports/vatControlStatementReport/VatControlStatementReportDef";
import { VatReportProps } from "@pages/reports/VatReport.utils";
import { VAT_STATEMENT_REPORT_PATH } from "@pages/reports/vatStatementReport/VatStatementReportDef";
import { VAT_VIES_STATEMENT_REPORT_PATH } from "@pages/reports/vatVIESStatementReport/VatVIESStatementReportDef";
import { isAccountAssignmentCompanyValue, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { Dayjs } from "dayjs";
import i18next from "i18next";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { ReportTableRowType, Sort } from "../../enums";
import { TValue } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import DateType, { DATE_MIN, DateFormat, getUtcDayjs } from "../../types/Date";
import { VatStatementPeriod } from "../companies/Company.utils";
import { getStatusFilterId } from "../reports/CommonDefs";
import { IReportData, ReportColumnType } from "../reports/Report.utils";

export interface IVatSubmissionCountArgs {
    submission?: IElectronicSubmissionEntity;
    from?: Date;
    to?: Date;
    drillDown?: boolean;
}

interface IGetDocumentJournalFilterDef {
    id: string;
    value: TValue;
    type: ReportColumnType;
}

export function getDocumentJournalStatusFilter(statusType: EntityTypeName.VatStatementStatus | EntityTypeName.ClearedStatus | EntityTypeName.PostedStatus, values: (ClearedStatusCode | VatStatementStatusCode | PostedStatusCode)[]): IGetDocumentJournalFilterDef {
    return {
        id: BindingContext.localContext("DocumentStatus"),
        value: [{
            type: ConditionType.Included,
            filter: PredefinedFilter.Value,
            condition: Condition.Equals,
            value: values.map(code => getStatusFilterId(statusType, code))
        }],
        type: ReportColumnType.String
    };
}

export type TVatSubmissionDocumentsData = Partial<Record<ElectronicSubmissionTypeCode, number>>;

export async function getVatSubmissionDocumentsData(args: IVatSubmissionCountArgs, type: ElectronicSubmissionTypeCode, isLocked: boolean): Promise<TVatSubmissionDocumentsData> {
    let path: string;

    switch (type) {
        case ElectronicSubmissionTypeCode.VatStatement:
            path = VAT_STATEMENT_REPORT_PATH;
            break;
        case ElectronicSubmissionTypeCode.VatControlStatement:
            path = VAT_CONTROL_STATEMENT_REPORT_PATH;
            break;
        case ElectronicSubmissionTypeCode.VatVIESStatement:
            path = VAT_VIES_STATEMENT_REPORT_PATH;
            break;
    }

    const fetchArgs: IFetchReportTableDataArgs = {
        path,
        settings: {
            [BindingContext.cleanLocalContext(VatReportProps.vatStatementPeriod)]: {
                DateStart: args.submission?.DatePeriodStart ?? args?.from,
                DateEnd: args.submission?.DatePeriodEnd ?? args?.to
            },
            [BindingContext.cleanLocalContext(VatReportProps.unlockedElectronicSubmissionDocuments)]: !isLocked
        },
        reportHierarchy: {
            Aggregate: false,
            Columns: [
                { ColumnAlias: "Document_NumberOurs" }
            ],
            Groups: [],
            Aggregations: []
        }
    };

    const response = await fetchReportTableData(fetchArgs);


    const retVal: TVatSubmissionDocumentsData = {};

    if (response?.ok) {
        const data = (await response.json()) as IReportData;
        const uniqueIds = new Set<string>();

        (data.Rows ?? []).forEach((row) => {
            if (row.Type === ReportTableRowType.Value) {
                // one document can be in multiple rows, so we need to only count unique ids
                uniqueIds.add(row.Value.Document_NumberOurs as string);
            }
        });

        retVal[type] = uniqueIds.size;
    }

    return retVal;
}

export async function getVatSubmissionClearingRatio(oData: OData, context: IAppContext, year: number): Promise<ICompanyVatReducedCoefficientEntity> {
    const companyId = context.getCompanyId();
    const query = oData.getEntitySetWrapper(EntitySetName.Companies)
        .query(companyId)
        .select(CompanyEntity.UseVatReducedDeduction, CompanyEntity.Id)
        .expand(CompanyEntity.VatReducedCoefficients, q =>
            q.filter(`${CompanyVatReducedCoefficientEntity.Year} eq ${year}`).select(CompanyVatReducedCoefficientEntity.VatReducedCoefficient, CompanyVatReducedCoefficientEntity.Id));

    const result = await query.fetchData<ICompanyEntity>();
    const company = result.value;

    if (company.UseVatReducedDeduction) {
        return company.VatReducedCoefficients?.[0] ?? {
            Year: year,
            VatReducedCoefficient: 0
        };
    }
    return null;
}

export async function changeVatSubmissionClearingRatio(oData: OData, context: IAppContext, coeff: ICompanyVatReducedCoefficientEntity): Promise<void> {
    const companyId = context.getCompanyId();

    await oData.getEntitySetWrapper(EntitySetName.Companies).update(companyId, {
        [`${CompanyEntity.VatReducedCoefficients}@odata.delta`]: [coeff]
    });
}

export const getLiabilityDocumentTableDef = (context: IAppContext): TFormTable => {
    return {
        id: `LiabilityDocumentTable`,
        entitySet: EntitySetName.Documents,
        initialSortBy: [
            { id: "DateAccountingTransaction", sort: Sort.Asc }
        ],
        columns: [
            {
                id: DocumentEntity.DateAccountingTransaction,
                label: i18next.t("ElectronicSubmission:Tax.Date"),
                isVisible: isAccountAssignmentCompanyValue
            },
            {
                id: DocumentEntity.DateCbaDocument,
                label: i18next.t("ElectronicSubmission:Tax.Date"),
                isVisible: (args: IGetValueArgs) => isCashBasisAccountingCompany(args.context)
            },
            {

                id: DocumentEntity.DocumentType,
                fieldSettings: {
                    displayName: "Name"
                },
                label: i18next.t("ElectronicSubmission:Tax.DocumentType")
            },
            {
                id: DocumentEntity.NumberOurs,
                label: i18next.t("ElectronicSubmission:Tax.Document"),
                formatter: (val: TValue, args?: IFormatOptions<IDocumentEntity, IDocumentEntity>) => {
                    const { item } = args;
                    const { Id, NumberOurs } = item ?? {};

                    return Id ? getTableIntentLink(NumberOurs, {
                        route: `${getRouteByDocumentType(item.DocumentTypeCode as DocumentTypeCode)}/${Id}`,
                        context
                    }) : null;
                }
            },
            {
                id: DocumentEntity.Amount,
                label: i18next.t("ElectronicSubmission:Tax.Amount")
            }
        ]
    };
};

function isVatStatementPeriod(value: any): value is VatStatementPeriod {
    return value?.from && value?.to;
}

export function getVatSubmissionFrequencyFromPeriod(period: IElectronicSubmissionEntity | VatStatementPeriod): VatStatementFrequencyCode {
    let from: Date, to: Date;
    if (isVatStatementPeriod(period)) {
        from = period.from?.toDate();
        to = period.to?.toDate();
    } else {
        from = period.DatePeriodStart;
        to = period.DatePeriodEnd;
    }
    return isSameMonth(from, to) ? VatStatementFrequencyCode.Monthly : VatStatementFrequencyCode.Quarterly;
}

export function getVatSubmissionPeriodSortValue(date: Date | Dayjs, frequency: VatStatementFrequencyCode): TValue {
    if (!frequency || !date) {
        return DATE_MIN;
    }
    return getUtcDayjs(date).startOf(frequency === VatStatementFrequencyCode.Monthly ? "month" : "quarter").toDate();
}

export function getVatSubmissionPeriodTableCell(date: Date | Dayjs, frequency: VatStatementFrequencyCode, numeric?: boolean): ICellValueObject {
    if (!frequency || !date) {
        return { value: EMPTY_DASH, tooltip: EMPTY_DASH };
    }
    let value: string;
    const periodDate = getUtcDayjs(date);
    switch (frequency) {
        case VatStatementFrequencyCode.Quarterly:
            const quarter = periodDate.quarter();
            const year = periodDate.get("year");
            value = numeric ? `${periodDate.get("year")}/Q${periodDate.quarter()}`
                : i18next.t("ElectronicSubmission:VatSubmission.Quarter", { count: quarter, year, ordinal: true });
            break;
        case VatStatementFrequencyCode.Monthly:
            value = numeric ? periodDate.format("M/YY") : DateType.format(periodDate, DateFormat.monthAndYear);
            break;
    }
    return { value, tooltip: value };
}

export function getVatSubmissionPeriodName(date: Date | Dayjs, frequency: VatStatementFrequencyCode, numeric?: boolean): string {
    return getVatSubmissionPeriodTableCell(date, frequency, numeric).value as string;
}

export function isSubmissionLastPeriod(vatFrequency: VatStatementFrequencyCode, monthIndex: number): boolean {
    return vatFrequency === VatStatementFrequencyCode.Monthly ? monthIndex === 11 : monthIndex === 9;
}