import { getSubmissionDetailRoute } from "@odata/EntityTypes";
import { IElectronicSubmissionEntity } from "@odata/GeneratedEntityTypes";
import { ElectronicSubmissionTypeCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import { getSortedFYs } from "@pages/fiscalYear/FiscalYear.utils";
import { isDefined, sortCompareFn } from "@utils/general";
import memoizeOne from "@utils/memoizeOne";
import { Dayjs } from "dayjs";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import BusyIndicator from "../../components/busyIndicator";
import { Pane, SplitLayout } from "../../components/splitLayout";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { PaneStatus } from "../../enums";
import { ROUTE_ELECTRONIC_SUBMISSION } from "../../routes";
import { getUtcDateBy } from "../../types/Date";
import {
    ElectronicSubmissionNamespaces,
    fetchPossibleSubmissions,
    fetchSubmissionData,
    getDefaultVatFrequency,
    getPossibleSubmission,
    getSubmissionId,
    getSubmissionIdBy,
    getSubmissionInfoRowsForYear,
    IGetSubmissionInfoRowsForYearArgs,
    ISubmissionOverviewRow,
    SubmissionStatus,
    TPossibleSubmissions
} from "./ElectronicSubmission.utils";
import ElectronicSubmissionOverview from "./ElectronicSubmissionOverview";
import VatSubmissionDetail from "./VatSubmissionDetail";

interface IRouteProps {
    year: string;
    type: string;
    month?: string;
}

interface IProps extends WithTranslation, WithOData, RouteComponentProps<IRouteProps> {
}

interface IState {
    busy: boolean;
    paneStatus: PaneStatus[];
    // existing locked submissions
    submissions: Map<string, IElectronicSubmissionEntity>;
    // for selected year, list of all periods that can show unlocked submission in the UI
    possibleSubmissions: TPossibleSubmissions;
    firstVatPeriodDate?: Dayjs;
    lastVatPeriodDate?: Dayjs;
    year: number;
}

const defaultPaneStatus = [PaneStatus.Expanded, PaneStatus.Normal];
const detailOpenedPaneStatus = [PaneStatus.Normal, PaneStatus.Normal];

class ElectronicSubmission extends React.Component<IProps, IState> {
    static contextType = AppContext;

    constructor(props: IProps) {
        super(props);

        this.state = {
            busy: true,
            paneStatus: this.isDetailOpened ? detailOpenedPaneStatus : defaultPaneStatus,
            submissions: null,
            possibleSubmissions: null,
            lastVatPeriodDate: null,
            firstVatPeriodDate: null,
            year: null
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (!this.isDetailOpened && isDefined(prevProps.match.params.month)) {
            this.setPaneStatus(defaultPaneStatus);
        }
    }

    get vatFrequency() {
        return getDefaultVatFrequency(this.state.submissions, this.state.year, this.context);
    }

    get monthIndex() {
        const { month } = this.props.match.params;
        return month ? parseInt(month) - 1 : null;
    }

    get type() {
        return this.props.match.params.type as ElectronicSubmissionTypeCode;
    }

    get isDetailOpened() {
        return isDefined(this.props.match.params.month);
    }

    init = async (): Promise<void> => {
        const appContext = this.context as IAppContext;
        const presetYear = this.props.match.params?.year ? parseInt(this.props.match.params.year) : null;

        const fiscalYears = getSortedFYs(appContext);

        // redirect to not found when non-existing fiscal year is passed in url
        if (presetYear && !fiscalYears.find(fiscalYear => fiscalYear.DateStart.getFullYear() === presetYear || fiscalYear.DateEnd.getFullYear() === presetYear)) {
            this.props.history.replace(ROUTE_ELECTRONIC_SUBMISSION);
        }

        const {
            firstVatPeriodDate,
            lastVatPeriodDate,
            submissions,
            possibleSubmissions,
            year
        } = await fetchSubmissionData({
            context: appContext,
            oData: this.props.oData,
            year: presetYear
        });

        this.setState({
            submissions,
            firstVatPeriodDate,
            lastVatPeriodDate,
            possibleSubmissions,
            year,
            busy: false
        });
    };

    setPaneStatus(paneStatus: PaneStatus[]) {
        this.setState({ paneStatus: [...paneStatus] });
    }

    handlePaneStatusChange = (paneStatus: PaneStatus[]): void => {
        this.setPaneStatus(paneStatus);
    };

    handleSelectYear = async (year: number): Promise<void> => {
        this.props.history.replace(getSubmissionDetailRoute(year));

        const possibleSubmissions = await fetchPossibleSubmissions(year);

        this.setState({
            year,
            possibleSubmissions
        });
    };

    handleSelectDetail = (type: ElectronicSubmissionTypeCode, month: number) => {
        this.props.history.replace(getSubmissionDetailRoute(this.state.year, type, month + 1));
        this.setPaneStatus(detailOpenedPaneStatus);
    };

    handleSubmissionChange = async (submission: IElectronicSubmissionEntity, isRemoved = false): Promise<boolean> => {
        const key = getSubmissionId(submission);
        const current = this.state.submissions.get(key) ?? {};
        const updatedSubmission = {
            ...current,
            ...submission
        };
        const updatedSubmissions = new Map(this.state.submissions);

        if (isRemoved) {
            updatedSubmissions.delete(key);
        } else {
            updatedSubmissions.set(key, updatedSubmission);
        }

        const result = await fetchSubmissionData({
            context: this.context,
            oData: this.props.oData,
            year: this.state.year
        });

        this.setState({
            submissions: result.submissions,
            firstVatPeriodDate: result.firstVatPeriodDate,
            lastVatPeriodDate: result.lastVatPeriodDate,
            possibleSubmissions: result.possibleSubmissions,
            year: result.year
        }, () => {
            // when quarterly submission unlocked,
            // it could break into monthly possible submissions
            // => we need to redirect to the first possible month if there isn't possible submission for the current month
            if (isRemoved) {
                const typeCode = submission.ElectronicSubmissionTypeCode as ElectronicSubmissionTypeCode;
                const possibleSubmission = getPossibleSubmission(result.possibleSubmissions, typeCode, submission.DatePeriodStart.getMonth());

                if (!possibleSubmission) {
                    const possibleSubmissions = result.possibleSubmissions[typeCode];

                    possibleSubmissions.sort((a, b) => sortCompareFn(a.DateStart, b.DateStart));

                    const firstPossibleMonth = possibleSubmissions[0].DateStart.getMonth();

                    this.handleSelectDetail(typeCode, firstPossibleMonth);
                }
            }
        });

        return false;
    };

    renderDetailView(overviewRows: ISubmissionOverviewRow[]) {
        const submission: IElectronicSubmissionEntity = this.state.submissions.get(getSubmissionIdBy(this.type, getUtcDateBy(this.state.year, this.monthIndex, 1)));
        const overViewRow: ISubmissionOverviewRow = overviewRows?.find(row => row.month === this.monthIndex);
        const status = overViewRow?.[this.type]?.status;

        if (!overViewRow || [SubmissionStatus.Empty, SubmissionStatus.NonExisting].includes(status) || !Object.values(ElectronicSubmissionTypeCode).includes(this.type)) {
            // wrong month or type, close form
            this.props.history.replace(ROUTE_ELECTRONIC_SUBMISSION);
            return null;
        }

        return (
            <VatSubmissionDetail year={this.state.year}
                                 type={this.type}
                                 monthIndex={this.monthIndex}
                                 submission={submission}
                                 possibleSubmissions={this.state.possibleSubmissions}
                                 vatFrequency={this.vatFrequency}
                                 onSubmissionChange={this.handleSubmissionChange}
                                 status={status}
            />
        );
    }

    getSubmissionInfoParams = memoizeOne((): IGetSubmissionInfoRowsForYearArgs => {
        return {
            year: this.state.year,
            submissions: this.state.submissions,
            firstVatPeriodDate: this.state.firstVatPeriodDate,
            lastVatPeriodDate: this.state.lastVatPeriodDate,
            context: this.context,
            possibleSubmissions: this.state.possibleSubmissions
        };
    }, () => [
        this.state.year, this.state.submissions, this.state.firstVatPeriodDate,
        this.state.lastVatPeriodDate, this.context, this.state.possibleSubmissions
    ]);

    render() {
        if (this.state.busy || !this.props.tReady || !this.state.year) {
            return (<BusyIndicator/>);
        }

        const overviewRows = getSubmissionInfoRowsForYear(this.getSubmissionInfoParams());

        return (<>
            <SplitLayout onPaneStatusChanged={this.handlePaneStatusChange}
                         paneStatus={this.state.paneStatus}>
                <Pane visible icon="List" width="50%">
                    <ElectronicSubmissionOverview year={this.state.year}
                                                  month={this.monthIndex}
                                                  selectedType={this.type}
                                                  initialHistoryState={this.props.history?.location?.state}
                                                  rows={overviewRows}
                                                  onSelectYear={this.handleSelectYear}
                                                  onSelectDetail={this.handleSelectDetail}/>
                </Pane>
                <Pane visible={this.isDetailOpened} icon="Form" width="50%">
                    {this.isDetailOpened && this.renderDetailView(overviewRows)}
                </Pane>
            </SplitLayout>
        </>);
    }
}

export default withOData(withRouter(withTranslation(ElectronicSubmissionNamespaces)(ElectronicSubmission)));
