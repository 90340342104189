import React, { useContext } from "react";

import { ConfirmationDialogContext, WithConfirmationDialogContext } from "./ConfirmationDialogProvider";

export interface WithConfirmationDialog {
    confirmationDialog: WithConfirmationDialogContext
}

export const withConfirmationDialog = <P extends WithConfirmationDialog>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithConfirmationDialog>> => {
    return React.forwardRef((props: Omit<P, keyof WithConfirmationDialog>, ref) => {
        const confDialogContext = useConfirmationDialog();

        return (
            <Component confirmationDialog={confDialogContext}
                       ref={ref}
                       {...props as P}/>
        );
    }) as any;
};

export function useConfirmationDialog() {
    return useContext(ConfirmationDialogContext);
}