import { getOneFetch, isAbortException } from "@utils/oneFetch";
import { anyPartStartsWithAccentsInsensitive } from "@utils/string";
import { saveAs } from "file-saver";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { withTheme } from "styled-components/macro";

import { AUDIT_API_URL } from "../../../constants";
import { AppContext, IAppContext } from "../../../contexts/appContext/AppContext.types";
import { IconSize } from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import { PropsWithTheme } from "../../../theme";
import DateType, { getUtcDate } from "../../../types/Date";
import ExcelExport, { ExportType } from "../../../utils/ExcelExport";
import TableToolbar, { TableButtonsAction, TableButtonsActionType } from "../../../views/table/TableToolbar";
import { AuditTrailChangeType, AuditTrailTableColumn, IAuditEntity } from "../../auditTrail/AuditTrail.utils";
import { SearchIcon } from "../../icon";
import { ICellValueObject, IColumn, IRow, IRowValues, TCellValue, TId } from "../../table";
import { IRowProps } from "../../table/Rows";
import { ISmartTableCommonProps } from "./SmartTable";
import { getRowFromArray, TFormatterFn } from "./SmartTable.utils";
import { SmartTableBase } from "./SmartTableBase";

export interface IAuditTableColumn extends IColumn {
    formatter?: TFormatterFn;
    originalId?: keyof IAuditEntity;
}

interface IProps extends Omit<ISmartTableCommonProps, "onAfterTableLoad">, PropsWithTheme, WithTranslation {
    path: string;
    filterValues: TRecordAny;
    onRowSelect?: (id: TId, props: IRowProps) => void;
    onAfterTableLoad?: (versions: IAuditEntity[]) => void;
}

interface IState {
    loaded: boolean;
    rows: IRow[];
}

const oneFetch = getOneFetch();

class SmartAuditTable extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    state: IState = {
        loaded: false,
        rows: []
    };

    componentDidMount() {
        if (this.props.path) {
            this.init();
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.path !== prevProps.path) {
            this.init();
        }
    }

    getContext = () => {
        return this.context as IAppContext;
    };

    getCurrentCompanyId = () => {
        return this.getContext().getCompany().Id;
    };


    init = async () => {
        try {
            const response = await oneFetch.fetch(`${AUDIT_API_URL}/${this.props.path}`);

            if (!response.ok) {
                this.setState({
                    loaded: true,
                    rows: []
                });

                return;
            }

            const result = await response.json() as IAuditEntity[];

            this.props.onAfterTableLoad(result);

            this.setState({
                loaded: true,
                rows: this.prepareRows(result)
            });
        } catch (e) {
            if (isAbortException(e)) {
                return;
            } else {
                throw e;
            }
        }
    };

    prepareRows = (rows: IAuditEntity[]): IRow[] => {
        let hasUnusedChange = false;

        return [...rows].reverse().map((row, index) => {
            const values: IRowValues = {};
            const columns = this.getColumns();
            const isLast = index === 0;
            const canRenderSearchIcon = hasUnusedChange;

            hasUnusedChange = row.Type !== AuditTrailChangeType.Attachment || hasUnusedChange;

            for (const column of columns) {
                const columnId = column.id as keyof IAuditEntity;
                const value = row[column.originalId ?? columnId];

                values[columnId] = column.formatter ? column.formatter(value, {
                    entity: row,
                    customArgs: { isLast }
                }) as string : value as TCellValue;
            }

            return {
                id: row["ChangedOn"],
                values,
                customData: {
                    entity: row
                },
                isDisabled: isLast,
                drilldown: () => {
                    if (isLast || row.Type === AuditTrailChangeType.Attachment || !canRenderSearchIcon) {
                        return null;
                    }

                    return (
                        <SearchIcon width={IconSize.S} height={IconSize.S}
                                    preventHover={isLast}/>
                    );
                }
            };
        });
    };


    filterRows = () => {
        const shouldBeFiltered = Object.keys(this.props.filterValues).length > 0;
        if (shouldBeFiltered) {
            const columns = this.getColumns();
            return (this.state.rows || []).filter(row => {
                let isFit = true;
                for (const column of columns) {
                    const id = column.id;
                    const value = row.values[id] as string;
                    const filterValue = this.props.filterValues[id];
                    if (filterValue && !anyPartStartsWithAccentsInsensitive(value, filterValue)) {
                        isFit = false;
                        break;
                    }
                }

                return isFit;
            });
        }

        return this.state.rows;
    };

    getColumns = (): IAuditTableColumn[] => {
        return [
            {
                id: AuditTrailTableColumn.ChangedOnDate,
                originalId: "ChangedOn",
                label: this.props.t("Audit:Columns.Date"),
                formatter: (val: TValue) => {
                    return DateType.localFormat(getUtcDate(val as string));
                }
            },
            {
                id: AuditTrailTableColumn.ChangedOnTime,
                originalId: "ChangedOn",
                label: this.props.t("Audit:Columns.Time"),
                formatter: (val: TValue) => {
                    return DateType.localFormat(getUtcDate(val as string), DateType.defaultTimeFormat);
                }
            },
            {
                id: AuditTrailTableColumn.AuthorName,
                label: this.props.t("Audit:Columns.Author")
            },
            {
                id: AuditTrailTableColumn.Type,
                label: this.props.t("Audit:Columns.Type"),
                formatter: (val: TValue, args) => {
                    let value: TCellValue = this.props.t(`Audit:Type:${val}`).toString();
                    const isLast = args.customArgs?.isLast;

                    if (isLast) {
                        value += ` (${this.props.t("Audit:LastChange")})`;
                    }

                    if (val === AuditTrailChangeType.Attachment) {
                        const entity = args.entity as IAuditEntity;
                        const description = ` (${this.props.t(`Audit:Attachment.${entity.Operation}`)} ${entity.CurrentValue})`;
                        value = {
                            tooltip: `${value}${description}`,
                            value: (
                                <>
                                    {value}
                                    <span style={{ color: isLast ? null : this.props.theme.C_ACT_thick_line }}>
                                        {description}
                                    </span>
                                </>
                            )
                        };
                    }

                    return value;
                }
            }
        ];
    };

    exportTable = async (exportType: ExportType) => {
        const columns = this.getColumns();
        const rows = this.filterRows().map(row => row.values)
            .map(row => {
                for (const column of columns) {
                    const val = row[column.id];

                    if (typeof val === "object") {
                        row[column.id] = (val as ICellValueObject)?.tooltip as string ?? "";
                    }
                }

                return row;
            });

        const file = await ExcelExport.export({
            columns,
            rows,
            tableName: this.props.tableId,
            type: exportType
        });

        saveAs(file);
    };

    handleTableButtonClick = (key: TableButtonsActionType) => {
        switch (key) {
            case TableButtonsAction.XLSXExport:
                this.exportTable(ExportType.XLSX);
                break;
            case TableButtonsAction.CSVExport:
                this.exportTable(ExportType.CSV);
                break;
        }
    };

    handleRowSelect = (id: TId, props: IRowProps): void => {
        const row = getRowFromArray(this.state.rows, id);

        if (!row.isDisabled && (row.customData.entity as IAuditEntity).Type !== AuditTrailChangeType.Attachment) {
            this.props.onRowSelect(id, props);
        }
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }
        const columns = this.getColumns();
        const rows = this.filterRows();

        return (
            <>
                <TableToolbar
                    isDisabled={!this.state.loaded}
                    visibleButtons={[
                        TableButtonsAction.CSVExport,
                        TableButtonsAction.XLSXExport
                    ]}
                    onClick={this.handleTableButtonClick}>
                </TableToolbar>
                <SmartTableBase {...this.props}
                                onRowSelect={this.handleRowSelect}
                                disableSort
                                rows={rows}
                                rowCount={rows.length}
                                tableId={this.props.tableId}
                                columns={columns.map(column => ({
                                    ...column,
                                    // solve mismatch between width (number) in IColumn and width (string) in IFieldInfo
                                    width: column.width ? `${column.width}px` : undefined,
                                    bindingContext: null
                                }))}
                                loaded={this.state.loaded}/>
            </>
        );
    }
}

export default withTranslation(["Audit", "Components"])(withTheme(SmartAuditTable));