import { ISelectItem, SelectGroups } from "@components/inputs/select/Select.types";
import { SwitchType } from "@components/inputs/switch/Switch";
import { EMPTY_DASH } from "@components/readOnlyList/ReadOnlyList";
import { IGetValueArgs, TGetValueFn } from "@components/smart/FieldInfo";
import { CurrencyDef, getDocumentItemVatDef, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import BindingContext, { createPath, IEntity } from "@odata/BindingContext";
import {
    AccountingEntity,
    EntitySetName,
    EntityTypeName,
    IAccountAssignmentEntity,
    ICompanyEntity,
    IItemTemplateAccountAssignmentEntity,
    IItemTemplateEntity,
    ItemTemplateAccountAssignmentEntity,
    ItemTemplateAccountAssignmentSelectionEntity,
    ItemTemplateCbaTaxImpactEntity,
    ItemTemplateEntity,
    ItemTemplateVatClassificationEntity,
    ItemTemplateVatClassificationSelectionEntity,
    UnitOfMeasureEntity
} from "@odata/GeneratedEntityTypes";
import {
    AccountingCode,
    CbaCategoryTaxImpactCode,
    CurrencyCode,
    SelectionCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import {
    getAccountComparisonFunction,
    getAccountFormatter
} from "@pages/accountAssignment/AccountAssignment.utils.shared";
import { flattenAccountsDef } from "@pages/accountAssignment/AccountAssignmentDef";
import {
    getCbaTaxImpactSelectItems,
    ItemTemplatesTranslationFiles,
    TItemTemplateStorage
} from "@pages/admin/itemTemplates/ItemTemplates.utils";
import ItemTemplatesFormView from "@pages/admin/itemTemplates/ItemTemplatesFormView";
import { getNonTaxAccountFilter, isProportionalDeduction } from "@pages/admin/vatRules/VatRules.utils";
import { getAssetAcquisitionLabel, getPartialImpactLabel } from "@pages/cashBasisAccounting/CashBasisAccounting.utils";
import { getCurrencyUnit, lineItemsTriggerProps, readOnlyCurrencyItemFormatter } from "@pages/documents/Document.utils";
import { ItemQuantityDef, UnitOfMeasureDef } from "@pages/documents/DocumentCommonDefs";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import {
    BasicInputSizes,
    FastEntryInputSizes,
    FieldType,
    GroupedField,
    NavigationSource,
    Sort,
    ValidatorType
} from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";

export const recalculateTriggerProps = lineItemsTriggerProps.filter(prop => !prop.startsWith("Unit")) as ItemTemplateEntity[];

export const CbaTaxImpactLocalPath = BindingContext.localContext("TaxImpact");
export const CbaTaxPercentagePath = createPath(ItemTemplateEntity.CbaTaxImpact, ItemTemplateCbaTaxImpactEntity.TaxPercentage);
export const CbaTaxImpactPath = createPath(ItemTemplateEntity.CbaTaxImpact, ItemTemplateCbaTaxImpactEntity.TaxImpact);

export const AASelectionCodePath = createPath(ItemTemplateEntity.AccountAssignmentSelection, ItemTemplateAccountAssignmentSelectionEntity.SelectionCode);
export const AccAssCreditAccountPath = BindingContext.localContext("CreditAccount");
export const AccAssDebitAccountPath = BindingContext.localContext("DebitAccount");
export const AccAssNamePath = createPath(ItemTemplateEntity.AccountAssignmentSelection, ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.Name);
export const AccAssShortNamePath = createPath(ItemTemplateEntity.AccountAssignmentSelection, ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.ShortName);

export const selectionAccountPaths = [
    createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.CreditAccountName),
    createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.CreditAccountNumber),
    createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.DebitAccountName),
    createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.DebitAccountNumber)
];

export const VatProportionalDeductionPath = createPath(ItemTemplateEntity.VatClassificationSelection, ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.VatProportionalDeduction);
export const VatDeductionTypeCodePath = createPath(ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.VatDeductionTypeCode);
export const VatDeductionTypePath = createPath(ItemTemplateEntity.VatClassificationSelection, ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.VatDeductionType);
export const NonTaxAccountPath = BindingContext.localContext("NonTaxAccount");

export const vatClassificationAccountPaths = [
    createPath(ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.NonTaxAccountName),
    createPath(ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.NonTaxAccountNumber),
];

function accountEntityGetter(entity: IItemTemplateEntity, storage?: Model): IItemTemplateAccountAssignmentEntity {
    const assignment: TRecordAny = { ...entity.AccountAssignmentSelection?.AccountAssignment };
    if (storage) {
        const assignmentBc = storage.data.bindingContext.navigate(ItemTemplateEntity.AccountAssignmentSelection);
        selectionAccountPaths.forEach(path => {
            const bc = assignmentBc.navigate(path);
            const data = storage.getTemporalData(bc);
            if (data?.value) {
                assignment[bc.getPath()] = data.value;
            }
        });
    }
    return assignment;
}

function vatNonTaxAccountEntityGetter(entity: IItemTemplateEntity, storage?: Model): IEntity {
    const vatClassification: TRecordAny = { ...entity.VatClassificationSelection?.VatClassification };
    if (storage) {
        const vatClassBc = storage.data.bindingContext.navigate(ItemTemplateEntity.VatClassificationSelection);
        vatClassificationAccountPaths.forEach(path => {
            const bc = vatClassBc.navigate(path);
            const data = storage.getTemporalData(bc);
            if (data?.value) {
                vatClassification[bc.getPath()] = data.value;
            }
        });
    }
    return vatClassification;
}

const isGroupedFieldEndWhenNotVatRegistered: TGetValueFn<GroupedField> = (args) => {
    return isVatRegistered(args) ? null : GroupedField.WrappedMultiEnd;
};

const isVatRegistered: TGetValueFn<boolean> = (args) => {
    return args.storage.data.entity?.VatStatus?.Code === VatStatusCode.VATRegistered;
};

const isPartialTaxImpact: TGetValueFn<boolean> = (args) => {
    // including temporal value
    const taxImpact = args.storage.getValueByPath(CbaTaxImpactLocalPath);
    return taxImpact === CbaCategoryTaxImpactCode.Partial;
};

const isCashBasisAccounting: TGetValueFn<boolean> = (args) => {
    return args.storage.data.entity?.Accounting?.Code === AccountingCode.CashBasisAccounting;
};

const hasAccountAssignment: TGetValueFn<boolean> = (args) => {
    return args.storage.data.entity?.Accounting?.Code === AccountingCode.AccountingForBusiness;
};

const hasOwnAASelection: TGetValueFn<boolean> = (args) => {
    const selection = args.storage.getValueByPath(AASelectionCodePath);
    return selection === SelectionCode.Own;
};

const hasProportionalDeduction: TGetValueFn<boolean> = (args) => {
    const bc = args.storage.data.bindingContext
            .navigate(ItemTemplateEntity.VatClassificationSelection)
            .navigate(VatDeductionTypeCodePath);
    const deductionTypeCode = args.storage.getValue(bc);
    return isProportionalDeduction(deductionTypeCode);
};

export const additionalAssignmentSelectionCodes = [SelectionCode.None, SelectionCode.Default, SelectionCode.Own];
export const additionalVatClassificationSelectionCodes = [SelectionCode.None, SelectionCode.Default];

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            ItemTemplateEntity.Description,
            ItemTemplateEntity.Accounting,
            ItemTemplateEntity.VatStatus,
            ItemTemplateEntity.TransactionCurrency,
            AccAssShortNamePath,
            VatDeductionTypePath,
            CbaTaxImpactPath
        ],
        filterDefinition: {
            [ItemTemplateEntity.Description]: {},
            ...withDisplayName(ItemTemplateEntity.Accounting),
            ...withDisplayName(ItemTemplateEntity.VatStatus),
            ...withDisplayName(CbaTaxImpactPath, {
                description: i18next.t("ItemTemplates:Table.CbaTaxImpact").toString()
            }),
            [ItemTemplateEntity.AlwaysOffer]: {
                width: BasicInputSizes.M,
            },
            [ItemTemplateEntity.Quantity]: {},
            ...withDisplayName(ItemTemplateEntity.UnitOfMeasure),
            ...withDisplayName(ItemTemplateEntity.Vat),
            [ItemTemplateEntity.TransactionAmount]: {},
            [ItemTemplateEntity.TransactionAmountNet]: {},
            [ItemTemplateEntity.TransactionAmountVat]: {},
            ...withDisplayName(ItemTemplateEntity.TransactionCurrency),
            [AccAssShortNamePath]: {},
            ...withDisplayName(VatDeductionTypePath, {
                label: i18next.t("ItemTemplates:Table.VatDeductionType")
            })
        },
        isValueHelp: true,
        allowCustomFilters: true
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        id: `${EntityTypeName.ItemTemplate}Table`,
        columns: [
            ItemTemplateEntity.Description,
            ItemTemplateEntity.Accounting,
            ItemTemplateEntity.VatStatus,
            ItemTemplateEntity.TransactionCurrency,
            AccAssShortNamePath,
            CbaTaxImpactPath,
            VatDeductionTypePath,
        ],
        columnDefinition: {
            [ItemTemplateEntity.Description]: {},
            ...withDisplayName(ItemTemplateEntity.Accounting),
            ...withDisplayName(ItemTemplateEntity.VatStatus),
            ...withDisplayName(ItemTemplateEntity.TransactionCurrency),
            [ItemTemplateEntity.AlwaysOffer]: {},
            [ItemTemplateEntity.Quantity]: {},
            [ItemTemplateEntity.UnitOfMeasure]: {
                fieldSettings: {
                    displayName: UnitOfMeasureEntity.ShortName
                }
            },
            ...withDisplayName(ItemTemplateEntity.Vat),
            [ItemTemplateEntity.TransactionAmount]: {},
            [ItemTemplateEntity.TransactionAmountNet]: {},
            [ItemTemplateEntity.TransactionAmountVat]: {},
            [AccAssShortNamePath]: {
                label: i18next.t("ItemTemplates:Table.AccountAssignment"),
                formatter: (val: string, args: IFormatOptions): string => {
                    if (!val) {
                        return EMPTY_DASH;
                    }
                    return val;
                }
            },
            [CbaTaxImpactPath]: {
                label: i18next.t("ItemTemplates:Table.CbaTaxImpact"),
                formatter: (val: string, { entity }: IFormatOptions): string => {
                    const Name = val && getEnumDisplayValue(EntityTypeName.CbaCategoryTaxImpact, val);
                    if (val === CbaCategoryTaxImpactCode.Partial && entity.CbaTaxImpact?.TaxPercentage) {
                        return getPartialImpactLabel(Name, entity.CbaTaxImpact?.TaxPercentage);
                    }
                    if (val === CbaCategoryTaxImpactCode.Nontax && entity.CbaTaxImpact?.IsAssetAcquisition) {
                        return getAssetAcquisitionLabel(Name);
                    }
                    return Name ?? EMPTY_DASH;
                },
                additionalProperties: [
                    { id: `/${createPath(ItemTemplateEntity.CbaTaxImpact, ItemTemplateCbaTaxImpactEntity.TaxPercentage)}` },
                    { id: `/${createPath(ItemTemplateEntity.CbaTaxImpact, ItemTemplateCbaTaxImpactEntity.IsAssetAcquisition)}` },
                ]
            },
            [VatDeductionTypePath]: {
                label: i18next.t("ItemTemplates:Table.VatDeductionType"),
                formatter: (val: string, args: IFormatOptions): string => {
                    if (!val) {
                        return EMPTY_DASH;
                    }
                    return getEnumDisplayValue(EntityTypeName.VatDeductionType, val);
                }
            }
        },
        title: i18next.t("ItemTemplates:Title"),
        initialSortBy: [{ id: "Description", sort: Sort.Asc }]
    };

    const currDef = cloneDeep(CurrencyDef);
    delete currDef.filter;
    currDef.defaultValue = CurrencyCode.CzechKoruna;

    const amountDef = {
        width: FastEntryInputSizes.S,
        formatter: readOnlyCurrencyItemFormatter,
        fieldSettings: {
            unit: getCurrencyUnit
        }
    };

    const vatDef = getDocumentItemVatDef();
    delete vatDef.defaultValue;

    const form: IFormDef = {
        id: `${EntityTypeName.ItemTemplate}Form`,
        translationFiles: getDefinitions.translationFiles,
        formControl: ItemTemplatesFormView,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("ItemTemplates:NewItem"), storage.data.entity?.Description),
        fieldDefinition: {
            [ItemTemplateEntity.Description]: {
                isRequired: true,
                width: BasicInputSizes.XL,
                validator: {
                    type: ValidatorType.String,
                    settings: {
                        min: 3
                    }
                },
            },
            [ItemTemplateEntity.TransactionCurrency]: {
                ...currDef,
                affectedFields: [
                    { id: ItemTemplateEntity.TransactionAmount },
                    { id: ItemTemplateEntity.TransactionAmountNet },
                    { id: ItemTemplateEntity.TransactionAmountVat },
                ],
            },
            [ItemTemplateEntity.VatStatus]: {
                type: FieldType.ComboBox,
                isRequired: true,
                defaultValue: VatStatusCode.VATRegistered,
                fieldSettings: {
                    items: getEnumSelectItems(EntityTypeName.VatStatus).filter(item => item.id !== VatStatusCode.IdentifiedPerson)
                }
            },
            [ItemTemplateEntity.Accounting]: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.L,
                isRequired: true,
                defaultValue: AccountingCode.AccountingForBusiness,
                fieldSettings: {
                    displayName: AccountingEntity.Name
                }
            },
            [ItemTemplateEntity.AlwaysOffer]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo,
                },
                defaultValue: true,
                affectedFields: [{ id: ItemTemplateEntity.AllowedCompanies }],
                customizationData: {
                    dependents: [ItemTemplateEntity.AllowedCompanies]
                }
            },
            [ItemTemplateEntity.AllowedCompanies]: {
                type: FieldType.MultiSelect,
                isRequired: true,
                isCollectionField: true,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    keyPath: "Id",
                    items: [],
                    itemsForRender: (origItems, args: IGetValueArgs): ISelectItem[] => {
                        const companies = args.storage.context.getData().companies;
                        const { Accounting, VatStatus } = args.storage.data.entity as IItemTemplateEntity;
                        return companies?.filter(company => {
                            // todo: should we filter out companies according to current VatStatus or anytime VatStatus to support suggesting values in different times?
                            return company.AccountingCode === Accounting?.Code && company.VatStatuses.find(vatStatus => vatStatus.VatStatusCode === VatStatus?.Code);
                        }).map(({ Name, Id }: ICompanyEntity): ISelectItem => ({
                            label: Name,
                            id: Id,
                        }));
                    }
                },
                isVisible: (args: IGetValueArgs) => {
                    return !args.storage.data.entity?.AlwaysOffer;
                }
            },
            // line item values
            [ItemTemplateEntity.Quantity]: {
                ...ItemQuantityDef,
                groupedField: GroupedField.WrappedMultiStart,
            },
            [ItemTemplateEntity.UnitOfMeasure]: { ...UnitOfMeasureDef },
            [ItemTemplateEntity.Vat]: {
                ...vatDef,
                isVisible: isVatRegistered
            },
            [ItemTemplateEntity.TransactionAmountNet]: {
                ...amountDef,
                isVisible: isVatRegistered
            },
            [ItemTemplateEntity.TransactionAmountVat]: {
                ...amountDef,
                isVisible: isVatRegistered
            },
            [ItemTemplateEntity.TransactionAmount]: { ...amountDef },
            // Category
            [CbaTaxImpactLocalPath]: {
                type: FieldType.ComboBox,
                label: i18next.t("ItemTemplates:Form.CbaTaxImpact"),
                isVisible: isCashBasisAccounting,
                groupedField: isGroupedFieldEndWhenNotVatRegistered,
                fieldSettings: {
                    noRecordText: EMPTY_DASH,
                    itemsFactory: (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        return Promise.resolve(getCbaTaxImpactSelectItems());
                    }
                },
                formatter: (val: TValue, { entity }: IFormatOptions): string => {
                    const item = getCbaTaxImpactSelectItems().find(item => item.id === val);
                    if (val === CbaCategoryTaxImpactCode.Partial && entity.CbaTaxImpact?.TaxPercentage) {
                        return getPartialImpactLabel(item.label, entity.CbaTaxImpact?.TaxPercentage);
                    }
                    return item?.label ?? EMPTY_DASH;
                },
                additionalProperties: [
                    { id: createPath(ItemTemplateEntity.CbaTaxImpact, ItemTemplateCbaTaxImpactEntity.TaxImpactCode) },
                    { id: createPath(ItemTemplateEntity.CbaTaxImpact, ItemTemplateCbaTaxImpactEntity.IsAssetAcquisition) },
                ]
            },
            [CbaTaxPercentagePath]: {
                type: FieldType.NumberInput,
                fieldSettings: {
                    unit: "%",
                    showSteppers: true,
                    min: 0,
                    max: 100
                },
                defaultValue: 100,
                validator: {
                    type: ValidatorType.Number,
                    settings: {
                        min: 0,
                        max: 100
                    }
                },
                customizationData: { useForCustomization: false },
                isConfirmable: true,
                isVisible: isPartialTaxImpact
            },
            // AccountAssignmentSelection
            [ItemTemplateEntity.AccountAssignmentSelection]: {
                type: FieldType.ComboBox,
                isVisible: hasAccountAssignment,
                groupedField: isGroupedFieldEndWhenNotVatRegistered,
                fieldSettings: {
                    noRecordText: EMPTY_DASH,
                    additionalItems: additionalAssignmentSelectionCodes.map((code) => ({
                        label: i18next.t(`ItemTemplates:Selection.${code}`),
                        id: code,
                        groupId: SelectGroups.Default,
                        additionalData: {
                            SelectionCode: code
                        }
                    })),
                    itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        // account assignments should be preloaded by formView in storage customData
                        const assignments = (args.storage as TItemTemplateStorage).getCustomData().AccountAssignments;
                        return assignments?.map(({
                                                    Id,
                                                    ShortName,
                                                    Name,
                                                    CreditAccountName,
                                                    CreditAccountNumber,
                                                    DebitAccountName,
                                                    DebitAccountNumber
                                                }: IAccountAssignmentEntity): ISelectItem => ({
                            id: Id,
                            label: `${ShortName} - ${Name}`,
                            additionalData: {
                                CreditAccountName,
                                CreditAccountNumber,
                                DebitAccountName,
                                DebitAccountNumber,
                                Name, ShortName,
                                SelectionCode: SelectionCode.Copy
                            }
                        }));
                    },
                    localDependentFields: [
                        {
                            from: { id: "CreditAccountName" },
                            to: { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.CreditAccountName) },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: "CreditAccountNumber" },
                            to: { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.CreditAccountNumber) },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: "DebitAccountName" },
                            to: { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.DebitAccountName) },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: "DebitAccountNumber" },
                            to: { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.DebitAccountNumber) },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: "Name" },
                            to: { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.Name) },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: "ShortName" },
                            to: { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.ShortName) },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: "SelectionCode" },
                            to: { id: ItemTemplateAccountAssignmentSelectionEntity.SelectionCode },
                            navigateFrom: NavigationSource.Itself
                        },
                    ]
                },
                formatter: (val, { item, storage }) => {
                    const assignment = storage.getValueByPath(createPath(ItemTemplateEntity.AccountAssignmentSelection, ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment), { skipTemporaryValue: true });
                    const code = storage.getValueByPath(AASelectionCodePath, { skipTemporaryValue: true });
                    if (code === SelectionCode.Copy) {
                        return `${assignment?.ShortName} - ${assignment?.Name}`;
                    }
                    // none, default, own
                    const selectionName = i18next.t(`ItemTemplates:Selection.${code}`);
                    if (code === SelectionCode.Own) {
                        return `${assignment?.ShortName} (${selectionName})`;
                    }
                    return val ? selectionName : EMPTY_DASH;
                },
                additionalProperties: [
                    ...selectionAccountPaths.map(id => ({ id })),
                    { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.Name), },
                    { id: createPath(ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment, ItemTemplateAccountAssignmentEntity.ShortName), },
                    { id: ItemTemplateAccountAssignmentSelectionEntity.SelectionCode },
                ]
            },
            [AccAssNamePath]: {
                width: BasicInputSizes.XL,
                isRequired: hasOwnAASelection,
                useForValidation: true,
                customizationData: {
                    useForCustomization: false
                },
                isConfirmable: true
            },
            [AccAssCreditAccountPath]: {
                label: i18next.t("ItemTemplates:Form.CreditAccount"),
                ...flattenAccountsDef,
                isRequired: hasOwnAASelection,
                comparisonFunction: getAccountComparisonFunction("CreditAccount", accountEntityGetter),
                formatter: getAccountFormatter("CreditAccount", accountEntityGetter),
                useForValidation: true,
                customizationData: {
                    useForCustomization: false
                },
                isConfirmable: true
            },
            [AccAssDebitAccountPath]: {
                label: i18next.t("ItemTemplates:Form.DebitAccount"),
                ...flattenAccountsDef,
                isRequired: hasOwnAASelection,
                comparisonFunction: getAccountComparisonFunction("DebitAccount", accountEntityGetter),
                formatter: getAccountFormatter("DebitAccount", accountEntityGetter),
                useForValidation: true,
                customizationData: {
                    useForCustomization: false
                },
                isConfirmable: true
            },

            // VatClassificationSelection
            [ItemTemplateEntity.VatClassificationSelection]: {
                type: FieldType.ComboBox,
                isVisible: isVatRegistered,
                groupedField: GroupedField.WrappedMultiEnd,
                fieldSettings: {
                    noRecordText: EMPTY_DASH,
                    additionalItems: additionalVatClassificationSelectionCodes.map((code) => ({
                        label: i18next.t(`ItemTemplates:VatSelection.${code}`),
                        id: code,
                        groupId: SelectGroups.Default,
                        additionalData: {
                            SelectionCode: code
                        }
                    })),
                    itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        return getEnumSelectItems(EntityTypeName.VatDeductionType)
                                .map(item => ({
                                    ...item,
                                    additionalData: {
                                        ...item.additionalData,
                                        VatDeductionTypeCode: item.id,
                                        SelectionCode: SelectionCode.Own
                                    }
                                }));
                    },
                    getCustomTabularData(val: TValue, args: IFormatOptions): string[] {
                        if (isProportionalDeduction(val)) {
                            const { storage, bindingContext } = args;
                            const bc = bindingContext.navigate(createPath(ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.VatProportionalDeduction));
                            const percentage = storage.getValue(bc);

                            return [`${percentage}%`];
                        }
                        return null;
                    },
                    localDependentFields: [
                        {
                            from: { id: "VatDeductionTypeCode" },
                            to: { id: VatDeductionTypeCodePath },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: "SelectionCode" },
                            to: { id: ItemTemplateVatClassificationSelectionEntity.SelectionCode },
                            navigateFrom: NavigationSource.Itself
                        }
                    ]
                },
                additionalProperties: [
                    ...vatClassificationAccountPaths.map(id => ({ id })),
                    { id: createPath(ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.VatDeductionTypeCode) },
                    { id: createPath(ItemTemplateVatClassificationSelectionEntity.VatClassification, ItemTemplateVatClassificationEntity.VatProportionalDeduction) },
                    { id: ItemTemplateVatClassificationSelectionEntity.SelectionCode },
                ]
            },
            [VatProportionalDeductionPath]: {
                type: FieldType.NumberInput,
                isRequired: hasProportionalDeduction,
                fieldSettings: {
                    unit: "%",
                    showSteppers: true,
                    min: 0,
                    max: 100
                },
                validator: {
                    type: ValidatorType.Number,
                    settings: {
                        min: 0,
                        max: 100
                    }
                },
                useForValidation: true,
                customizationData: {
                    useForCustomization: false
                },
                isConfirmable: true
            },
            [NonTaxAccountPath]: {
                label: i18next.t("ItemTemplates:Form.NonTaxAccount"),
                ...flattenAccountsDef,
                filter: {
                    select: getNonTaxAccountFilter()
                },
                isRequired: hasProportionalDeduction,
                comparisonFunction: getAccountComparisonFunction("NonTaxAccount", vatNonTaxAccountEntityGetter),
                formatter: getAccountFormatter("NonTaxAccount", vatNonTaxAccountEntityGetter),
                useForValidation: true,
                customizationData: {
                    useForCustomization: false
                },
                isConfirmable: true
            },
        },
        groups: [{
            id: "general",
            rows: [
                [{ id: ItemTemplateEntity.Description }]
            ]
        }, {
            id: "conditions",
            title: i18next.t("ItemTemplates:Form.Conditions"),
            rows: [
                [{ id: ItemTemplateEntity.Accounting }, { id: ItemTemplateEntity.VatStatus }, { id: ItemTemplateEntity.TransactionCurrency }],
                [{ id: ItemTemplateEntity.AlwaysOffer }, { id: ItemTemplateEntity.AllowedCompanies }]
            ]
        }, {
            id: "defaultValues",
            title: i18next.t("ItemTemplates:Form.DefaultValues"),
            rows: [[
                { id: ItemTemplateEntity.Quantity },
                { id: ItemTemplateEntity.UnitOfMeasure },
                { id: ItemTemplateEntity.Vat },
                { id: ItemTemplateEntity.TransactionAmountNet },
                { id: ItemTemplateEntity.TransactionAmountVat },
                { id: ItemTemplateEntity.TransactionAmount },
                { id: ItemTemplateEntity.AccountAssignmentSelection },
                { id: CbaTaxImpactLocalPath },
                { id: ItemTemplateEntity.VatClassificationSelection },
            ]]
        }]
    };

    return {
        entitySet: EntitySetName.ItemTemplates,
        table,
        form
    };
};

getDefinitions.translationFiles = ItemTemplatesTranslationFiles;
setDefByEntityType(EntityTypeName.ItemTemplate, getDefinitions);