import { ICachedFieldState, IStorageSmartFieldValues } from "@odata/Data.utils";
import { getFieldInfo, IFieldInfo } from "@odata/FieldInfo.utils";
import { isDefined } from "@utils/general";
import React, { ComponentType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext, IAppContext } from "../../../contexts/appContext/AppContext.types";
import { FieldType, LabelStatus } from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import { IAuditTrailData, Model } from "../../../model/Model";
import { IValidationError } from "../../../model/Validator.types";
import BindingContext from "../../../odata/BindingContext";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IMultiFieldSelectChange } from "../../inputs/businessPartnerSelect";
import { ICheckboxChange } from "../../inputs/checkbox";
import { ICheckboxGroupChange } from "../../inputs/checkboxGroup/CheckboxGroup";
import { ISmartCheckboxGroupChangeEvent } from "../../inputs/checkboxGroup/SmartCheckboxGroup";
import Field from "../../inputs/field";
import { IInputOnBlurEvent, IInputOnChangeEvent, IInputRowsChangeEvent, ISharedInputProps } from "../../inputs/input";
import { IMultiSelectionChangeArgs } from "../../inputs/select/MultiSelect";
import { TCellValue } from "../../table";
import { TooltipIconInfo } from "../../tooltipIcon";
import { StyledTooltipContent } from "../../tooltipIcon/TooltipIcon.styles";
import { getInfoValue, IFieldDef, IFieldInfoProperties } from "../FieldInfo";
import { ISmartRadioGroupChange } from "../smartRadioButtonGroup/SmartRadioButtonGroup";
import { TFieldValue } from "../smartTable/SmartTable.utils";
import { IValueHelperChangeArgs } from "../smartValueHelper";
import {
    getControlData,
    getValueHelperChangeParams,
    IGetControlDataRetValue,
    ISmartFieldEvents
} from "./SmartFieldUtils";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";

export interface ISmartFieldTempDataActionArgs {
    bindingContext: BindingContext;
    groupId?: string;
}

export interface ISmartFieldChange {
    value?: TValue;
    parsedValue?: TValue;
    selectedItems?: ISelectItem[];
    currentValue?: string;
    groupId?: string;

    type?: FieldType;
    bindingContext: BindingContext;
    triggerAdditionalTasks?: boolean;
    additionalData?: TRecordAny;
}

export interface ISmartFieldWidthChange {
    width: string;
    bindingContext: BindingContext;
}

export interface ISmartFieldHeightChange {
    height: string;
    bindingContext: BindingContext;
}

export interface ISmartFieldBlur extends IInputOnBlurEvent {
    groupId?: string;
    bindingContext: BindingContext;
    isCreating?: boolean;
}

export interface ISmartFieldValidationError {
    groupId?: string;
    data: IValidationError;
    bindingContext: BindingContext;
}

export interface ISmartFieldProps {
    bindingContext: BindingContext;
    storage: Model;
    uuid?: string;
    /** Use if SmartField is defined with custom Model or to override definition stored in storage fieldsInfo
     * for example if you want to use multiple SmartFields for same property with different formatter */
    fieldDef?: IFieldInfoProperties;

    fieldProps?: ISharedInputProps & {
        labelStatus?: LabelStatus;
        hasPadding?: boolean;
        tooltip?: string;
        isRequired?: boolean;
    };

    className?: string;
    style?: React.CSSProperties;

    showLabelDescription?: boolean;
    useWidthWhenReadOnly?: boolean;

    fastEntryListId?: string;

    onChange?: (args: ISmartFieldChange) => void;
    onTemporalChange?: (args: ISmartFieldChange) => void;
    onCancel?: (args: ISmartFieldTempDataActionArgs) => void;
    onConfirm?: (args: ISmartFieldTempDataActionArgs) => void;

    // callback when field state has changed (become visible/hidden, become required or is not required anymore)
    onFieldStateChange?: (bc: BindingContext, state: ICachedFieldState, prevState: ICachedFieldState) => void;

    onBlur?: (args: ISmartFieldBlur) => void;
    /** ResponsiveInput can cause width change event */
    onWidthChange?: (args: ISmartFieldWidthChange) => void;
    onHeightChange?: (args: ISmartFieldHeightChange) => void;

    // Method called to customize render
    customRenderWrapper?: (field: React.ReactNode, info: IFieldInfo, data: IGetControlDataRetValue) => React.ReactNode;
    // adds extra content to Field before Input
    extraFieldContent?: React.ReactElement;

    // otherwise doesn't work for typescript, because of withTranslation HOC
    ref?: React.Ref<React.ReactElement>;
}

interface ISmartFieldState {
    fieldInfo: IFieldInfo;
}

export interface ISmartFieldRenderProps {
    info?: IFieldInfo;
    formattedValue?: TCellValue | Promise<TCellValue> | TFieldValue;
    parentProps?: ISmartFieldProps;
    storageData?: IStorageSmartFieldValues;
    auditTrailData?: IAuditTrailData;
    onRowsChange?: ({ numRows, newHeight }: IInputRowsChangeEvent) => void;
    onBlur?: (args: IInputOnBlurEvent) => void;
    error?: IValidationError;
    name?: string;
    width?: string;
    value?: any;
    checked?: boolean;
    currentValue?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    isSharpLeft?: boolean;
    isSharpRight?: boolean;
    isVisible?: boolean;
    labelStatus?: LabelStatus;
    showChange?: boolean;
    // could be any of the change events handled by SmartField (input, select...)
    onChange?: (args: unknown) => void;
}

class SmartField extends React.Component<ISmartFieldProps & WithTranslation, ISmartFieldState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    state = {
        fieldInfo: null as IFieldInfo
    };

    _isMounted: boolean;
    _currentFieldState: ICachedFieldState;
    uuid: string;

    constructor(props: ISmartFieldProps & WithTranslation, context: IAppContext) {
        super(props, context);

        // automatically register SmartField on model
        this.props.storage.addRef(this, this.props.bindingContext);
    }

    cacheCurrentFieldStateAndTriggerEvents(triggerEvents: boolean) {
        const { storage, bindingContext } = this.props;

        const savedState = storage.getAdditionalFieldData(bindingContext, "currentFieldState");
        const savedStateKeys: (keyof ICachedFieldState)[] = ["isRequired", "isVisible"];
        if (savedState && savedStateKeys.some((key) => isDefined(savedState[key]) && savedState[key] !== this._currentFieldState[key])) {
            this.props.onFieldStateChange?.(bindingContext, this._currentFieldState, savedState);
        }

        storage.setAdditionalFieldData(bindingContext, "currentFieldState", { ...this._currentFieldState }, false);
    }


    componentDidMount() {
        this._isMounted = true;
        this.loadInfo();
        this.cacheCurrentFieldStateAndTriggerEvents(false);
    }

    componentDidUpdate(prevProps: ISmartFieldProps, prevState: ISmartFieldState): void {
        this.loadInfo();
        this.cacheCurrentFieldStateAndTriggerEvents(true);
        if (!prevProps.bindingContext.isSame(this.props.bindingContext)) {
            this.props.storage?.removeRef(this, prevProps.bindingContext);
            this.props.storage.addRef(this, this.props.bindingContext);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.storage?.removeRef(this, this.props.bindingContext);
    }

    getInfo = () => {
        let fieldInfo = this.props.storage.getInfo(this.props.bindingContext);

        if (this.props.fieldDef) {
            fieldInfo = { ...fieldInfo, ...this.props.fieldDef };
        }

        return fieldInfo;
    };

    loadInfo = async () => {
        if (this.getInfo()) {
            return;
        }

        const info = await getFieldInfo({
            bindingContext: this.props.bindingContext,
            context: this.context,
            fieldDef: this.props.fieldDef as IFieldDef
        });

        if (info) {
            this.props.storage.data.fieldsInfo[this.props.bindingContext.getFullPath(true)] = info;
            this.forceUpdate();
        }
    };

    handleBlur = (args: IInputOnBlurEvent) => {
        this.props.onBlur?.({
            ...args,
            bindingContext: this.props.bindingContext
        });
    };

    handleFocus = () => {
        // do nothing
    };

    handleInputChange = (data: IInputOnChangeEvent) => {
        this.handleFieldChange({
            value: data.value,
            type: this.getControlType(),
            bindingContext: this.props.bindingContext
        });
    };

    handleColorChange = (value: string) => {
        this.handleFieldChange({
            value,
            type: this.getControlType(),
            bindingContext: this.props.bindingContext
        });
    };

    handleCheckboxChange = (args: ICheckboxChange) => {
        this.handleFieldChange({
            value: args.value,
            type: this.getControlType(),
            bindingContext: this.props.bindingContext
        });
    };

    handleSwitchChange = (checked: boolean) => {
        this.handleFieldChange({
            value: checked,
            parsedValue: checked,
            type: this.getInfo().type,
            bindingContext: this.props.bindingContext
        });
    };

    handleConfirm = () => {
        this.props.onConfirm?.({
            bindingContext: this.props.bindingContext
        });
    };

    handleCancel = () => {
        this.props.onCancel?.({
            bindingContext: this.props.bindingContext
        });
    };

    handleSelectChange = (args: ISelectionChangeArgs | IMultiFieldSelectChange | IMultiSelectionChangeArgs) => {
        this.handleFieldChange({
            value: args.value as TValue,
            selectedItems: (args as IMultiSelectionChangeArgs).selectedItems,
            additionalData: args.additionalData,
            triggerAdditionalTasks: args.triggerAdditionalTasks,
            type: this.getControlType(),
            bindingContext: this.props.bindingContext
        });
    };

    handleValueHelperChange = (args: IValueHelperChangeArgs) => {
        this.handleFieldChange(getValueHelperChangeParams(this.props.bindingContext, this.getControlType(), args));
    };

    handleSegmentedButtonChange = (key: string) => {
        this.handleFieldChange({
            value: key,
            type: FieldType.SegmentedButton,
            bindingContext: this.props.bindingContext,
            // to trigger correctEnumValue in FormStorage.handleChange
            triggerAdditionalTasks: true
        });
    };

    handleOnDateChange = (e: IInputOnChangeEvent) => {
        this.handleFieldChange({
            value: e.value,
            parsedValue: e.value,
            type: this.getControlType(),
            bindingContext: this.props.bindingContext,
            triggerAdditionalTasks: e.triggerAdditionalTasks
        });
    };

    handleFileChange = (files: File[]) => {
        this.handleFieldChange({
            value: files,
            type: FieldType.FileInput,
            bindingContext: this.props.bindingContext
        });
    }

    handleWidthChange = (width: string) => {
        this.props.onWidthChange?.({
            bindingContext: this.props.bindingContext,
            width
        });
    };

    handleRowsChange = ({ newHeight }: IInputRowsChangeEvent) => {
        this.props.onHeightChange?.({
            bindingContext: this.props.bindingContext,
            height: newHeight
        });
    };

    handleCheckboxGroupChange = (args: ICheckboxGroupChange) => {
        this.handleFieldChange({
            bindingContext: this.props.bindingContext,
            value: args.values,
            // FormStorage.storeMultiValue expects this
            triggerAdditionalTasks: true
        });
    };

    handleRadioButtonChange = (args: ISmartRadioGroupChange) => {
        this.handleFieldChange({
            bindingContext: this.props.bindingContext,
            value: args.value
        });
    };

    handleSmartCheckboxGroupChange = (args: ISmartCheckboxGroupChangeEvent) => {
        this.handleFieldChange({
            bindingContext: args.bindingContext,
            value: args.value
        });
    };

    handleFieldChange = (args: ISmartFieldChange) => {
        const isConfirmable = this.getInfo()?.isConfirmable;

        if (isConfirmable) {
            this.props.onTemporalChange?.(args);
        } else {
            (this.props.storage as FormStorage).handleFirstChange?.();
            this.props.onChange(args);
        }
    };

    getDisabledMessage = (): string => {
        // currently, we don't want to show automatic disabled message https://solitea-cz.atlassian.net/browse/DEV-18205
        return "";
    };

    getControlType = () => {
        return this.getInfo()?.type;
    };

    render() {
        const info = this.getInfo();
        if (!info) {
            return null;
        }

        const events: ISmartFieldEvents = {
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
            onDateChange: this.handleOnDateChange,
            onFileChange: this.handleFileChange,
            onSegmentedButtonChange: this.handleSegmentedButtonChange,
            onWidthChange: this.handleWidthChange,
            onInputChange: this.handleInputChange,
            onCheckboxChange: this.handleCheckboxChange,
            onColorChange: this.handleColorChange,
            onSwitchChange: this.handleSwitchChange,
            onCheckboxGroupChange: this.handleCheckboxGroupChange,
            onSmartRadioButtonChange: this.handleRadioButtonChange,
            onSmartCheckboxGroupChange: this.handleSmartCheckboxGroupChange,
            onRowsChange: this.handleRowsChange,
            onSelectChange: this.handleSelectChange,
            onValueHelperChange: this.handleValueHelperChange,
            onCancel: this.handleCancel,
            onConfirm: this.handleConfirm,
            onChange: this.props.onChange
        };

        const { storage, bindingContext } = this.props;
        let { fieldProps } = this.props;
        const controlData = getControlData({
            bc: bindingContext,
            storage,
            type: this.getControlType(),
            context: this.context,
            fieldDef: this.props.fieldDef,
            events,
            fastEntryListId: this.props.fastEntryListId
        });

        const { isRequired, isVisible } = controlData.storageData ?? {};
        this._currentFieldState = { isRequired, isVisible };

        if (!controlData?.storageData.isVisible) {
            return null;
        }

        let fieldElement;
        const auditTrailData = fieldProps?.auditTrailData ?? controlData.storageData.additionalFieldData?.auditTrailData;

        if (this.getControlType() !== FieldType.Custom) {
            const tooltip = fieldProps?.tooltip ?? (info?.tooltip && getInfoValue(info, "tooltip", {
                storage
            }));
            const extraFieldContent = getInfoValue(info, "extraFieldContent", {
                storage, info, bindingContext
            }) || this.props.extraFieldContent;
            const extraFieldContentAfter = getInfoValue(info, "extraFieldContentAfter", {
                storage, info, bindingContext
            });

            const tooltipAfter = getInfoValue(info, "tooltipAfter", { storage });
            const labelStatus = getInfoValue(info, "labelStatus", { storage });

            // Currently, we show sharpLeft input for fields with extra content before, usually text,
            // but we may need to customize this in fieldSettings in the future?
            fieldProps = {
                ...fieldProps,
                isSharpLeft: fieldProps?.isSharpLeft || !!extraFieldContent,
                isSynchronized: controlData.props.isSynchronized
            };

            //@ts-ignore
            const Control = <controlData.Control
                {...controlData.props}
                {...fieldProps}
                key={controlData.props?.key}
            />;

            // the same thing could be done for checkbox/radiobutton HERE instead of in field
            fieldElement = (
                <Field
                    {...fieldProps}
                    className={this.props.className}
                    isFullRow={controlData.props.isFullRow}
                    style={this.props.style}
                    auditTrailData={auditTrailData}
                    key={this.uuid}
                    name={controlData.props.name}
                    isValueHelp={controlData.isValueHelp}
                    useWidthWhenReadOnly={this.props.useWidthWhenReadOnly}
                    tooltip={tooltip}
                    disabledMessage={controlData.props.disabledText ?? this.getDisabledMessage()}
                    textAlign={fieldProps?.textAlign ?? controlData.props.textAlign}
                    isRequired={fieldProps?.isRequired ?? controlData.props.isRequired}
                    isDisabled={fieldProps?.isDisabled ?? controlData.props.isDisabled}
                    isReadOnly={fieldProps?.isReadOnly ?? controlData.props.isReadOnly}

                    type={this.getControlType()}
                    label={info?.fieldSettings?.checkBoxFieldLabel ?? info?.label}
                    description={(this.props.showLabelDescription || info?.showLabelDescription) && info?.description as string}
                    width={fieldProps?.width ?? controlData.props.width}
                    labelStatus={fieldProps?.labelStatus ?? labelStatus}>
                    {extraFieldContent}
                    {Control}
                    {extraFieldContentAfter}
                    {tooltipAfter && <TooltipIconInfo style={{ marginLeft: "5px" }}>
                        <StyledTooltipContent>{tooltipAfter}</StyledTooltipContent>
                    </TooltipIconInfo>}
                </Field>
            );
        }

        if (info.render) {
            fieldElement = info.render({
                storage: this.props.storage as Model,
                fieldElement,
                events,
                props: {
                    parentProps: this.props,
                    info,
                    storageData: controlData.storageData,
                    ...controlData.props,
                    ...this.props.fieldProps,
                    auditTrailData: auditTrailData
                }
            });
        }

        return this.props.customRenderWrapper ? this.props.customRenderWrapper(fieldElement, info, controlData) : fieldElement;
    }
}

const HOC = withTranslation(["Common", "Components"], { withRef: true })(SmartField) as ComponentType<ISmartFieldProps>;
HOC.displayName = "SmartFieldWithTranslation";

export default HOC;
