import React from "react";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { getCorrectPath, ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES } from "../../routes";
import { createBindingContext, IEntity, TEntityKey } from "@odata/BindingContext";
import SmartHierarchyTable from "../../components/smart/smartTable/SmartHierarchyTable";
import { StyledTableTabs, TableWrapper } from "../../views/table/TableView.styles";
import TableView, { ITableViewBaseProps, ITableViewBaseState } from "../../views/table/TableView";
import { BreadCrumbProvider } from "../../components/breadCrumb/index";
import { TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar";
import memoizeOne from "../../utils/memoizeOne";
import { TFunction } from "i18next";
import { RowAction, Status, ToolbarItemType } from "../../enums";
import { ITabData } from "@components/tabs";
import { getFiscalYearStatus } from "../fiscalYear/FiscalYearDef";
import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import {
    accountsIsRowWithoutAction,
    accountsRowsFactory,
    loadUnRemovableAccounts,
    rewriteChartOfAccountsWithTemplate
} from "./ChartOfAccounts.utils";
import View from "../../views/View";
import { IRow, TId } from "@components/table";
import { getOneFetch } from "@utils/oneFetch";
import { DASH_CHARACTER } from "../../constants";
import { getUniqName } from "../chartOfAccountsTemplates/ChartOfAccountsTemplates.utils";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { AlertPosition } from "@components/alert/Alert";
import NewTemplateDialog from "./NewTemplateDialog";
import { EntitySetName, IChartOfAccountsEntity } from "@odata/GeneratedEntityTypes";
import { FiscalYearStatusCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { IToolbarItem } from "@components/toolbar";
import SmartIconSelect from "../../components/smart/smartSelect/SmartIconSelect";
import { TemplateIcon } from "@components/icon";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { ISelectionChangeArgs, SelectGroups } from "@components/inputs/select/Select.types";

interface IState extends ITableViewBaseState {
    forceReload?: boolean;
    busy?: boolean;
    unRemovableAccounts?: number[];
    newTemplateName: string;
    showNewTemplateDialog: boolean;
    showInitialBalancesDialog: boolean;
}

interface IProps extends ITableViewBaseProps, WithAlert, WithPermissionContext {
}

enum ActionItems {
    EDIT = "edit",
    CLONE = "clone"
}

const getActionItems = memoizeOne((t: TFunction) => {
    return [{
        id: ActionItems.CLONE,
        label: t("ChartsOfAccounts:Table.Clone"),
        iconName: "Add",
        groupId: SelectGroups.Action
    }, {
        id: ActionItems.EDIT,
        label: t("ChartsOfAccounts:Table.Edit"),
        iconName: "Edit",
        groupId: SelectGroups.Action
    }];
});


class ChartOfAccountsTableView extends TableView<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    oneFetch = getOneFetch();

    constructor(props: IProps) {
        super(props);

        this.state = {
            ...this.state,
            forceReload: false,
            busy: false,
            unRemovableAccounts: null,
            newTemplateName: "",
            showNewTemplateDialog: false,
            showInitialBalancesDialog: false
        };
    }

    loadUnRemovableAccounts = memoizeOne(
            async () => {
                const unRemovableAccounts = await loadUnRemovableAccounts(this.props.storage, this.props.parentEntity?.Id);
                this.setState({ unRemovableAccounts });
            }, () => [this.props.parentEntity?.Id]);

    getContext = () => {
        return this.context as IAppContext;
    };

    getCurrentCompanyId = () => {
        return this.getContext().getCompany().Id;
    };

    componentDidMount() {
        super.componentDidMount();
        this.loadUnRemovableAccounts();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        this.loadUnRemovableAccounts();
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        this.oneFetch.abort();
    }

    handleTabChange = (id: TEntityKey): void => {
        this.handleToolbarCancel();
        this.props.onParentKeyChange?.(id);
    };

    handleTemplateChange = async (args: ISelectionChangeArgs): Promise<void> => {
        if (!args.triggerAdditionalTasks) {
            return;
        }
        switch (args.value) {
            case ActionItems.EDIT:
                this.setTemplateListUrl();
                break;
            case ActionItems.CLONE:
                const parent = this.props.parentEntity;
                const newTemplateName = await getUniqName(this.props.storage.oData, parent.Name);
                this.setState({ newTemplateName, showNewTemplateDialog: true });
                this.forceUpdate();
                break;

            default:
                // selected template from dropdown replaces all accounts of current CoA
                if (typeof args.value === "number") {
                    this.replaceAccounts(args.value);
                }
        }
    };

    setTemplateListUrl = (newTemplateId?: TEntityKey, created = false): void => {
        this.context.setViewBreadcrumbs({
            items: [],
            lockable: false
        });
        // todo: how we generally handle routes
        const pathname = getCorrectPath(`${ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES}/:ParentId`, {
            ParentId: newTemplateId?.toString()
        });
        const back = window.location.pathname;
        this.props.storage.history.push(pathname, { customData: { created }, back });
    };

    getCurrentCoAKey = (): string => {
        return this.props.parentEntity?.Id.toString();
    };

    getTemplatesBindingContext = memoizeOne(() => {
        return createBindingContext(EntitySetName.ChartOfAccountsTemplates, this.props.storage.oData.getMetadata());
    });

    getTemplatesSelectFieldInfo = memoizeOne((): IFieldInfo => ({
        bindingContext: this.getTemplatesBindingContext(),
        id: "",
        fieldSettings: {
            displayName: "Name",
            itemsForRender: (items) => {
                return this.canReplaceAccounts ? items : [];
            }
        },
        columns: [{ id: "Name" }]
    }), () => [this.canReplaceAccounts]);

    replaceAccounts = async (templateId: TEntityKey) => {
        this.setState({ busy: true });
        this.forceUpdate();

        const chartOfAccount = this.props.parentEntity;
        // we keep the current CoA name, there is no ui to change it during replacement

        const duplicates = await rewriteChartOfAccountsWithTemplate(chartOfAccount.Id, chartOfAccount.Name, templateId as string);

        if (duplicates.length) {
            const duplicateNames = duplicates.map((duplicate: IEntity) => `${duplicate.Account.Number} ${DASH_CHARACTER} ${duplicate.Account.Name}`).join(", ");
            this.props.setAlert({
                status: Status.Warning,
                title: this.props.storage.t("ChartsOfAccounts:Table.DuplicateAccountsWarning", { duplicates: duplicateNames })
            });
        } else if (duplicates.error) {
            const validationMessage = duplicates.error?.validationMessages?.[0];
            this.props.setAlert({
                status: Status.Error,
                title: this.props.storage.t("Common:Errors.ErrorHappened"),
                subTitle: validationMessage ? this.props.storage.t(`Error:${validationMessage.code}`, { currency: validationMessage.messageParameters?.currency }) : null
            });
        }

        // todo: we receive all the new data in response of the fetch, how we can pass them to smartHierarchyTable to render them directly without another fetch?
        const forceReload = !this.state.forceReload;
        this.setState({ forceReload, busy: false });
        this.forceUpdate();
    };

    getTabData = (items: IChartOfAccountsEntity[]): { data: ITabData[], additionalData: ITabData[] } => {
        const additionalData: ITabData[] = [];
        const data: ITabData[] = [];

        items.forEach((item: IEntity) => {
            const isClosed = item.FiscalYear?.StatusCode === FiscalYearStatusCode.Closed;

            const newItem = {
                id: item.Id.toString(),
                hotspotId: isClosed ? "chartOfAccountsClosedTab" : `CoATab-${item.Id.toString()}`,
                title: item.FiscalYear?.Number ?? item.Name,
                tooltip: item.FiscalYear?.StatusCode === FiscalYearStatusCode.Active ? this.props.storage.t("ChartsOfAccounts:Active") : this.props.storage.t("ChartsOfAccounts:Future"),
                status: getFiscalYearStatus(item.FiscalYear?.StatusCode)
            };

            isClosed ? additionalData.push(newItem) : data.push(newItem);
        });
        additionalData.reverse();
        return { data, additionalData };
    };

    getToolbarButtons(): TableButtonsActionType[] {
        const buttons = super.getToolbarButtons();

        if (!this.canAddOrRemoveAccount) {
            return buttons.filter(b => TableButtonsAction.Add !== b && TableButtonsAction.Remove !== b);
        }

        return buttons;
    }

    get canAddOrRemoveAccount() {
        return this.props.parentEntity.FiscalYear.StatusCode !== FiscalYearStatusCode.Closed;
    }

    get canReplaceAccounts() {
        return this.props.parentEntity.FiscalYear.StatusCode === FiscalYearStatusCode.NotUsed;
    }

    getTemplateSelectDef = (): IToolbarItem => {
        return {
            id: "templateSelect",
            itemType: ToolbarItemType.Custom,
            render: () => {
                return (
                        <SmartIconSelect key={"templateSelect"}
                                         hotspotId={"templateSelect"}
                                         title={this.props.storage.t("ChartsOfAccounts:Table.TemplatesActionTitle")}
                                         bindingContext={this.getTemplatesBindingContext()}
                                         fieldInfo={this.getTemplatesSelectFieldInfo()}
                                         isDisabled={!!this.props.storage.data.addingRow}
                                         headerText={this.props.storage.t("ChartsOfAccounts:Table.Templates")}
                                         icon={<TemplateIcon/>}
                                         additionalItems={getActionItems(this.props.storage.t)}
                                         onChange={this.handleTemplateChange}
                                         dontDisplayNoDataFound={true} // https://solitea-cz.atlassian.net/browse/DEV-13849
                                         showSearchBoxInMenu={false} // https://solitea-cz.atlassian.net/browse/DEV-13849
                        />
                );
            }
        };
    };

    isRowWithoutAction = (rowId: TId, action: RowAction, row: IRow): boolean => {
        return accountsIsRowWithoutAction(rowId, action, row, this.state.unRemovableAccounts, this.props.storage);
    };

    rowsFactory = (rows: IRow[]): IRow[] => {
        return accountsRowsFactory(rows, this.props.storage, this.state.unRemovableAccounts, this.getRowActionType());
    };

    handleNewTemplate = (): void => {
        this.getTemplatesSelectFieldInfo().fieldSettings.items = null;
        this.handleCloseTemplateDialog();
    };

    handleCloseTemplateDialog = (): void => {
        this.setState({ showNewTemplateDialog: false });
        this.forceUpdate();
    };

    customToolbarContent = () => {
        const items: IToolbarItem[] = [];

        if (this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.CommonSettingsManagement)) {
            items.push(this.getTemplateSelectDef());
        }

        return items;
    };

    setAlert = (name: string): void => {
        this.props.setAlert({
            status: Status.Success,
            title: this.props.storage.t("Common:Validation.SuccessTitle"),
            subTitle: this.props.storage.t("ChartsOfAccounts:Validation.TemplateWasCreated", { name })
        });
    };

    closeInitialBalancesDialog = (): void => {
        this.setState({
            showInitialBalancesDialog: false
        });
        this.forceUpdate();
    };

    render() {
        const { data, additionalData } = this.getTabData(this.props.parents);
        return (
            <>
                <BreadCrumbProvider removeLast={!!this.props.storage.data.rowBindingContext}/>
                <View hotspotContextId={this.props.storage.id}>
                    <SmartHeaderStyled
                        storage={this.props.storage}
                        title={this.props.storage.data.definition.title}/>
                    {!!this.props.parentEntity && !!this.props.storage.data.bindingContext && this.props.storage.data.visibleFilters && (
                        <>
                            {this.renderFilterBar()}
                            <StyledTableTabs
                                onChange={this.handleTabChange}
                                data={data}
                                isDisabled={this.isAddingNew}
                                additionalData={additionalData}
                                additionalTabPrefix={this.props.storage.t("ChartsOfAccounts:History")}
                                selectedTabId={this.getCurrentCoAKey()}/>
                            {this.renderToolbar()}
                        </>
                    )}

                    <TableWrapper>
                        <SmartHierarchyTable  {...this.getTableSharedProps()}
                                              rowsFactory={this.rowsFactory}
                                              forceReload={this.state.forceReload}
                                              loadAll/>
                    </TableWrapper>
                    {this.state.showNewTemplateDialog &&
                        <NewTemplateDialog
                            newTemplateName={this.state.newTemplateName}
                            onSave={this.handleNewTemplate}
                            onClose={this.handleCloseTemplateDialog}
                            parent={this.props.parentEntity}
                            setAlert={this.setAlert}
                        />
                    }
                    {this.renderDefaultDialogs()}
                    {this.props.alert}
                    {this.state.busy && <BusyIndicator isDelayed/>}
                </View>
            </>
        );
    }
}

export default withPermissionContext(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom,
    style: {
        width: "500px"
    }
})(ChartOfAccountsTableView));
