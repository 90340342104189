import { composeRefHandlers, doesElementContainsElement, getFocusableElements, isObjectEmpty } from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import KeyboardShortcutsManager from "@utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import { capitalize } from "@utils/string";
import { debounce } from "lodash";
import { Resizable } from "re-resizable"; // TODO: External library used just here, wait for some time, if they really want this removed, for now, resizing is just disabled
import React from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { WithTranslation, withTranslation } from "react-i18next";

import { PortalRootElementProvider } from "../../contexts/portalRootElement/PortalRootElementProvider";
import { IconSize } from "../../enums";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import memoizeOne from "../../utils/memoizeOne";
import { WithBusyIndicator, withBusyIndicator } from "../busyIndicator/withBusyIndicator";
import { ButtonGroup, IconButton } from "../button";
import CustomResizeObserver from "../customResizeObserver";
import { ArrowIcon, CloseIcon, ReorderSmallIcon, ResizeIcon } from "../icon";
import {
    Body,
    DragIconWrapper,
    EditableWindowCloseIcon,
    Footer,
    Header,
    HeaderCustomContent,
    StyledDialog,
    Title
} from "./Dialog.styles";
import ModalBackdrop from "./ModalBackdrop";

// context available inside dialog, so that its children can for example close it easily or access its scrollbar
export const DialogContext = React.createContext<IDialogContext>(undefined);

export interface IDialogContext {
    scrollRef: React.RefObject<HTMLDivElement>;
    bodyRef: React.RefObject<HTMLDivElement>;
    footerGroupRef: React.RefObject<HTMLDivElement>;
    close: () => void;
    setBusy: (busy: boolean) => void;
    isBusy: boolean;
}

export interface IDialogProps {
    // onClose is required so that Dialog can always be closed by "Esc" key
    onClose: () => void;
    // onConfirm is required, so that Dialog can be confirmed by alt+s,
    // set null if the Dialog is not confirmable (e.g. for isEditableWindow)
    onConfirm: () => void;
    onAfterOpen?: () => void;
    width?: string;
    height?: string;
    minWidth?: string;
    minHeight?: string;
    /** Use if you want to use custom scroll in the content */
    disableScroll?: boolean;
    isEditableWindow?: boolean;
    isOpaqueBusyIndicator?: boolean;
    /** Renders back icon into the headerContent part. Fires onBack on click. */
    hasBackIcon?: boolean;
    onBack?: () => void;
    headerContent?: React.ReactElement;
    title?: string;
    /** Don't apply padding in body section to allow custom padding handling. */
    removePadding?: boolean;
    withoutHeader?: boolean;

    aretateWidth?: boolean;
    /** When we need the Dialog to have fixed height from the start. E.g. with PdfViewer inside. Best combined with height='100%' */
    aretateHeight?: boolean;
    bodyRef?: React.Ref<HTMLDivElement>;
    /** For special dialogs that are supposed to be fully centered */
    ignoreShellPadding?: boolean;
    className?: string;
    testid?: string;
}

interface IProps extends IDialogProps, WithBusyIndicator, WithTranslation {
    footer?: React.ReactElement;
    // don't render button group,
    // parent component will probably render into footer more complex structure and custom button group
    footerWithoutButtonGroup?: boolean;
    footerJustify?: React.CSSProperties["justifyContent"];
    // sometimes, we want to render the dialogs footer from inside its children
    // this prop ensures that the footer wrapper will be rendered,
    // and the children can render the footer inside it via the DialogContext and React.createPortal
    renderFooterForPortal?: boolean;
    isConfirmation?: boolean;
}

interface IState {
    x: number;
    y: number;
    // when first rendered, the dialog is supposed to be centered in the browser windowed
    // once it was dragged, the position should stay fixed
    // !! change in specification !!
    // we want dialog to be fixed by default, to prevent it from jumping when its size changes
    isInitial: boolean;
}

class Dialog extends React.PureComponent<IProps, IState> {
    // store active element before opening the dialog
    // restore it when the dialog is closed
    // TODO this doesn't work if the element is rerendered
    _lastActiveElement: HTMLElement;
    _dialogRef = React.createRef<Resizable>();
    _draggableRef = React.createRef<Draggable>();
    _bodyRef = React.createRef<HTMLDivElement>();
    _footerGroupRef = React.createRef<HTMLDivElement>();
    _dragHandleRef = React.createRef<HTMLDivElement>();
    _closeIcon = React.createRef<HTMLButtonElement>();
    _isSimulatedDrag = false;
    _ignoreResizeEvent = false;
    _unsubscribeKeyboardShortcuts: () => void;

    static defaultProps = {
        isEditableWindow: false
    };

    state = {
        x: 0,
        y: 0,
        isInitial: true
    };

    constructor(props: IProps) {
        super(props);

        if (props.isOpaqueBusyIndicator) {
            this.props.updateBusyIndicatorSettings({
                isOpaque: true
            });
        }
    }

    componentDidMount(): void {
        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_S],
            callback: this.handleKeyboardShortcut,
            isPrioritized: true
        });

        // if the Dialog is busy, the content can still be loading
        // => wait for it to load
        if (!this.props.busy) {
            this.onAfterOpen();
        }

        if (this.props.aretateWidth) {
            this.aretate("width");
        }

        if (this.props.aretateHeight) {
            this.aretate("height");
        }

        if (this.isDraggable) {
            window.addEventListener("resize", this.simulateDragEventDebounced);
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.state.isInitial && prevProps.busy && !this.props.busy) {
            this.onAfterOpen();
        }
        if (this.props.aretateWidth !== prevProps.aretateWidth) {
            this.aretate("width");
        }
        if (this.props.aretateHeight !== prevProps.aretateHeight) {
            this.aretate("height");
        }
    }

    componentWillUnmount() {
        this._unsubscribeKeyboardShortcuts();
        this.onAfterClose();

        if (this.isDraggable) {
            window.removeEventListener("resize", this.simulateDragEventDebounced);
        }
    }

    get isDraggable(): boolean {
        return !this.props.isEditableWindow;
    }

    aretate(prop: "width" | "height") {
        if (this._dialogRef.current) {
            const capitalized = capitalize(prop) as ("Height" | "Width");
            const shouldAretate = this.props[`aretate${capitalized}`];
            this._dialogRef.current.resizable.style[prop] = shouldAretate ? `${this._dialogRef.current.resizable[`offset${capitalized}`]}px` : null;
        }
    }

    getDialogsFocusableElements = () => {
        // we can't store getFocusableElements results in private variable
        // becasue elements rendered in dialog can change during its lifetime!
        if (!this._dialogRef.current?.resizable) {
            return [];
        }
        const elements = getFocusableElements(this._dialogRef.current.resizable);
        return Array.from(elements);
    };

    onAfterOpen = () => {
        // wait for items to be rendered (e.g. SmartField is kinda async)
        setTimeout(() => {
            const focusableElements = this.getDialogsFocusableElements();
            this._lastActiveElement = document.activeElement as HTMLElement;

            this.props.onAfterOpen?.();
            // only auto focus on input
            if (focusableElements[0]?.tagName === "INPUT") {
                this.focusFocusable(0);
            } else { // otherwise, focus the whole dialog, so that the esc key works for closing
                this._dialogRef.current?.resizable.focus();
            }

            if (this.isDraggable) {
                this.fixateCenteredDialog();
            }
        }, 0);
    };

    onAfterClose = () => {
        /* When dialog is closed by EnterKey, we need to set focus lazily not to trigger
            the "Enter" key event on this._lastActiveElement */
        setTimeout(() => {
            // restore previous focus on dialog close
            this._lastActiveElement?.focus();
        }, 0);
    };

    // in case the focus got somehow out of the dialog (or on the dialog parent element)
    // we need to trap it back inside the dialog
    isElementInElements = (element: HTMLElement, elements: Element[]) => {
        return elements.includes(element);
    };

    focusFocusable = (index: number) => {
        const focusableElements = this.getDialogsFocusableElements();

        (focusableElements[index] as HTMLElement)?.focus();
    };

    handleKeyboardShortcut = (shortcut: KeyboardShortcut, event: KeyboardEvent): boolean => {
        if (shortcut === KeyboardShortcut.ALT_S) {
            if (this.props.onConfirm) {
                this.props.onConfirm();
                return true;
            }
        }

        return false;
    };

    handleTabForward = (event: React.KeyboardEvent) => {
        const focusableElements = this.getDialogsFocusableElements();

        if (document.activeElement === focusableElements[focusableElements.length - 1]
            || !this.isElementInElements(document.activeElement as HTMLElement, focusableElements)) {
            event.preventDefault();
            this.focusFocusable(0);
        }
    };

    handleTabBackward = (event: React.KeyboardEvent) => {
        const focusableElements = this.getDialogsFocusableElements();

        if (document.activeElement === focusableElements[0]
            || !this.isElementInElements(document.activeElement as HTMLElement, focusableElements)) {
            event.preventDefault();
            this.focusFocusable(focusableElements.length - 1);
        }
    };

    handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.defaultPrevented) {
            // Do not trigger action if it's handled inside the dialog
            return;
        }
        switch (event.key) {
            case KeyName.Enter: {
                event.stopPropagation();
                this.props.onConfirm?.();
                break;
            }
            case KeyName.Tab: {
                // only handle focus inside dialog,
                // it can be outside, e.g. in date picker
                if (doesElementContainsElement(this._dialogRef.current?.resizable, document.activeElement) || this._dialogRef.current?.resizable === document.activeElement || document.body === document.activeElement) {
                    event.stopPropagation();
                    // prevent focus from getting outside of dialog
                    if (event.shiftKey) {
                        this.handleTabBackward(event);
                    } else {
                        this.handleTabForward(event);
                    }
                }
                break;
            }
        }
    };

    handleCloseDialogClick = () => {
        this.props.onClose?.();
    };

    fixateCenteredDialog = () => {
        if (!this._dialogRef.current) {
            return;
        }

        const dialogElement = this._dialogRef.current.resizable;
        const parentStyle = getComputedStyle((dialogElement as HTMLElement).parentElement);
        const element = (dialogElement as HTMLElement);

        this.setState({
            x: element.getBoundingClientRect().x - parseInt(parentStyle.paddingLeft) - parseInt(parentStyle.left),
            y: element.getBoundingClientRect().y - parseInt(parentStyle.paddingTop),
            isInitial: false
        });
    };

    handleDragStart = (e: DraggableEvent) => {
        this._isSimulatedDrag = false;
    };

    handleDrag = (e: DraggableEvent, ui: DraggableData) => {
        this.setState((state) => (
            {
                x: state.x + ui.deltaX,
                y: state.y + ui.deltaY
            }
        ));
    };

    handleResizableResizeStart = () => {
        this._ignoreResizeEvent = true;
    };

    handleResizableResizeStop = () => {
        this._ignoreResizeEvent = false;
    };

    handleContentResize = () => {
        // only handle resize caused by Dialog content,
        // not caused by user manually resizing the Resizable component
        if (this._ignoreResizeEvent) {
            return;
        }

        // prevent Dialog from getting out of bounds when its content gets too big
        this.simulateDragEvent();
    };

    // to prevent it from being out of bounds when browser window or its content is resized
    simulateDragEvent = () => {
        if (!this._dragHandleRef.current) {
            return;
        }

        this._isSimulatedDrag = true;

        const triggerMouseEvent = (element: HTMLElement | Document, eventType: string) => {
            const mouseEvent = new Event(eventType, { "bubbles": true, "cancelable": true });
            element.dispatchEvent(mouseEvent);
        };

        setTimeout(() => {
            triggerMouseEvent(this._dragHandleRef.current, "mouseover");
            triggerMouseEvent(this._dragHandleRef.current, "mousedown");

            setTimeout(() => {
                triggerMouseEvent(window.document, "mousemove");
                triggerMouseEvent(this._dragHandleRef.current, "mouseup");
            });
        });

        // triggerMouseEvent(this._dragHandleRef.current, "click");
    };

    // force the Draggable library to recalculate dialog position
    simulateDragEventDebounced = debounce(() => {
        this.simulateDragEvent();
    }, 100);

    renderBackIcon = () => {
        return (
            <IconButton title={this.props.t("Common:General.Back")}
                        onClick={this.props.onBack}
                        isDecorative>
                <ArrowIcon width={IconSize.M}/>
            </IconButton>
        );
    };

    getDialogContext = memoizeOne(
        (): IDialogContext => {
            return {
                scrollRef: this._bodyRef,
                bodyRef: this._bodyRef,
                footerGroupRef: this._footerGroupRef,
                close: this.props.onClose,
                setBusy: this.props.setBusy,
                isBusy: this.props.busy
            };
        }
    );

    renderDragHandler = (): React.ReactElement => {
        return (
            <DragIconWrapper className="dragHandle"
                             ref={this._dragHandleRef}>
                <ReorderSmallIcon width={IconSize.M} height={IconSize.M}/>
            </DragIconWrapper>
        );
    };

    render() {
        const enableResize: any = {};

        /*if (this.props.resizable) {
            enableResize["bottomRight"] = true;
        }*/

        /*tabindex with positive value is used to prevent focus from getting out of the dialog*/
        /*with tabindex 0, user could tab from browser URL bar into page DOM, outside of the dialog*/
        const passProps = {
            role: "dialog",
            "aria-modal": true,
            tabIndex: 1,
            _draggable: this.isDraggable,
            _isEditableWindow: this.props.isEditableWindow,
            _minWidth: this.props.minWidth,
            _minHeight: this.props.minHeight
        };

        const defaultSize = this.props.isEditableWindow ? "100%" : "auto";

        const shouldRenderHeader = !this.props.withoutHeader && !this.props.isEditableWindow && (!this.props.isConfirmation || this.props.title);
        let dialog = (
            <Resizable as={StyledDialog}
                       className={this.props.className}
                       enable={isObjectEmpty(enableResize) ? false : enableResize}
                       onResizeStart={this.handleResizableResizeStart}
                       onResizeStop={this.handleResizableResizeStop}
                       handleComponent={{
                           bottomRight: <ResizeIcon cursor="se-resize"/>
                       }}
                       handleStyles={{
                           bottomRight: {
                               width: IconSize.M,
                               height: IconSize.M,
                               right: 0,
                               bottom: 0
                           }
                       }}
                       defaultSize={{
                           width: this.props.width ?? defaultSize,
                           height: this.props.height ?? defaultSize
                       }}
                       ref={this._dialogRef}
                       {...passProps}
                       data-testid={this.props.testid ?? TestIds.Dialog}
            >
                <CustomResizeObserver onResize={this.handleContentResize}/>
                {this.props.busyIndicator}
                {shouldRenderHeader &&
                    <Header
                        data-testid={TestIds.DialogHeader}>
                        {this.renderDragHandler()}
                        {(this.props.headerContent || this.props.hasBackIcon) &&
                            <HeaderCustomContent>
                                {this.props.hasBackIcon && this.renderBackIcon()}
                                {this.props.headerContent}
                            </HeaderCustomContent>
                        }
                        <Title aria-label={this.props.title}
                               data-testid={TestIds.DialogTitle}>{this.props.title}</Title>
                    </Header>
                }
                {!shouldRenderHeader && this.isDraggable &&
                    this.renderDragHandler()
                }
                {/*problem with horizontal scrolling and SimpleBar (see BigDialog demo when uncommented)*/}
                {/*<ScrollBar primary*/}
                {/*           style={{ overflowX: "hidden" }}*/}
                {/*>*/}
                <Body data-testid={TestIds.DialogBody} ref={composeRefHandlers(this._bodyRef, this.props.bodyRef)}
                      disableScroll={this.props.disableScroll}
                      isConfirmation={this.props.isConfirmation}
                      _isEditableWindow={this.props.isEditableWindow}
                      withoutPadding={this.props.removePadding}>
                    {this.props.children}
                </Body>
                {/*</ScrollBar>*/}
                {(this.props.footer || this.props.renderFooterForPortal) &&
                    <Footer data-testid={TestIds.DialogFooter} justify={this.props.footerJustify}>
                        {!this.props.footerWithoutButtonGroup ?
                            <ButtonGroup ref={this._footerGroupRef}>{this.props.footer}</ButtonGroup> :
                            this.props.footer
                        }
                    </Footer>
                }
                {/*Close icon should be last in the dialog to gets focus as last*/}
                {this.props.isEditableWindow && <EditableWindowCloseIcon passRef={this._closeIcon}
                                                                         isDecorative
                                                                         isLight
                                                                         title={this.props.t("Common:General.Close")}
                                                                         testid={TestIds.EditableWindowCloseIcon}
                                                                         onClick={this.handleCloseDialogClick}>
                    <CloseIcon isLightHover width={IconSize.M}/>
                </EditableWindowCloseIcon>}
            </Resizable>
        );

        if (this.isDraggable) {
            dialog = (
                <Draggable handle=".dragHandle"
                           onStart={this.handleDragStart}
                           onDrag={this.handleDrag}
                           ref={this._draggableRef}
                           bounds={"parent"}
                           position={{
                               x: this.state.x,
                               y: this.state.y
                           }}
                >
                    {dialog}
                </Draggable>
            );
        }

        return (
            <DialogContext.Provider value={this.getDialogContext()}>
                <ModalBackdrop isBlurred={this.props.isEditableWindow}
                               isCentered={this.state.isInitial}
                               ignoreShellPadding={this.props.ignoreShellPadding}
                               onKeyDown={this.handleKeyDown}
                               onClose={this.props.onClose}>
                    <PortalRootElementProvider useGlobalRoot>
                        {dialog}
                    </PortalRootElementProvider>
                </ModalBackdrop>
            </DialogContext.Provider>
        );
    }
}

export default withBusyIndicator({ passBusyIndicator: true })(withTranslation(["Common"])(Dialog));