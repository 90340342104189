import React from "react";
import { FormStorage } from "../../../views/formView/FormStorage";
import Dialog from "../../../components/dialog";
import BusyIndicator from "../../../components/busyIndicator";
import { Button } from "../../../components/button";
import {
    IAccountingDepreciationPolicyItemEntity,
    IAssetEntity,
    IFiscalYearEntity
} from "@odata/GeneratedEntityTypes";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import { AssetStatusCode, DepreciationPolicyTypeCode, DepreciationTypeCode } from "@odata/GeneratedEnums";
import { TRecordAny } from "../../../global.types";
import { ITaxDepreciationPolicyItemEntityExtended, TaxDepreciationPolicyTable } from "./TaxDepreciationPolicyTable";
import { AccountingDepreciationPolicyTable } from "./AccountingDepreciationPolicyTable";
import { getAssetCurrency, isImportedAsset } from "./FixedAssetDef";
import ReadOnlyList from "../../../components/readOnlyList/ReadOnlyList";
import DateType, { getUtcDayjs } from "../../../types/Date";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { ASSET_API_URL } from "../../../constants";
import { fetchAccountingDepreciationPolicyItems } from "../Asset.utils";
import {
    getFiscalYearByChartOfAccount,
    getFiscalYearByDate,
    getNewestInactiveFY,
    getOldestActiveFY,
    getSortedFYs
} from "../../fiscalYear/FiscalYear.utils";
import { IEntity } from "@odata/BindingContext";
import { sortCompareFn } from "@utils/general";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { parseDateFields } from "@odata/Data.utils";


interface IProps {
    storage: FormStorage;
    type: DepreciationPolicyTypeCode;
    onClose: () => void;
}

interface IState {
    loaded: boolean;
    data?: TRecordAny;
}

interface IFetchDeprecationPolicyData {
    Id?: number;
    AssetValue: number;
    DateFrom: string;
    // Accounting deprecation policy
    DepreciationTypeCode?: DepreciationTypeCode;
    UsefulLife?: number;
    // Tax deprecation policy
    CoefficientCode?: string;
    ReductionRate?: number;
    DateInterruptedFrom?: string;
    // ImportedAsset fields
    IsImportedAsset?: boolean;
    ImportedAssetPrice?: number;
    RemainingAccountingPrice?: number;
    RemainingTaxPrice?: number;
    RemainingTaxYears?: number;
    IsImproved?: boolean;
}

export default class PolicyDeprecationDialog extends React.Component<IProps, IState> {
    // default state
    state: IState = {
        loaded: false
    };

    get dialogTitle(): string {
        return this.props.storage.t(`FixedAsset:Form.${this.isTaxRelated ? "TaxDepreciationPolicy" : "AccountingDepreciationPolicy"}`);
    }

    get isTaxRelated(): boolean {
        return this.props.type === DepreciationPolicyTypeCode.TaxDepreciation;
    }

    componentDidMount() {
        if (this.props.storage.isDirty()) {
            // shows data according to latest changes in the form
            this.fetchRestData();
        } else if (this.isTaxRelated) {
            // uses data from entity (data are loaded with the main form
            this.setDataFromEntity();
        } else if (!isCashBasisAccountingCompany(this.props.storage.context)) {
            // load data for AccountingDepreciationPolicy/Items from oData endpoint
            this.fetchAccountingDepreciationItems();
        }
    }

    enhanceTaxDepreciationPolicyItems(items: ITaxDepreciationPolicyItemEntityExtended[]): ITaxDepreciationPolicyItemEntityExtended[] {
        const { storage } = this.props;

        return items.reduce((filteredItems, item, idx) => {
            if (item.IsForReportOnly !== true) {
                filteredItems.push({
                    ...item,
                    FiscalYear: item.DateDepreciation && getFiscalYearByDate(storage.context, item.DateDepreciation)
                });
            }
            return filteredItems;
        }, []);
    }

    async fetchRestData(): Promise<void> {
        const { storage } = this.props;
        const entity = storage.data.entity as IAssetEntity;
        const isImported = isImportedAsset({ storage });

        const data: IFetchDeprecationPolicyData = {
            AssetValue: entity.CalculatedPrice ?? 0,
            DateFrom: formatDateToDateString(entity.DateFirstPutInUse),
            DateInterruptedFrom: formatDateToDateString(entity.TaxDepreciationPolicy.DateInterruptedFrom),
            IsImportedAsset: isImported
        };
        if (entity.Status?.Code !== AssetStatusCode.Created) {
            data.Id = entity.Id;
        }
        if (isImported) {
            data.ImportedAssetPrice = entity.ImportedAssetPrice;
        }
        if (this.isTaxRelated) {
            data.CoefficientCode = entity.TaxDepreciationPolicy.Coefficient?.Code;
            data.ReductionRate = entity.TaxDepreciationPolicy.ReductionRate ?? 0;
            if (isImported) {
                data.RemainingTaxPrice = entity.RemainingTaxPrice;
                data.RemainingTaxYears = entity.RemainingTaxYears ?? 0;
                data.IsImproved = !!entity.IsImproved;
            }
        } else {
            data.DepreciationTypeCode = entity.AccountingDepreciationPolicy?.DepreciationType?.Code as DepreciationTypeCode;
            data.UsefulLife = entity.AccountingDepreciationPolicy?.UsefulLife;
            if (isImported) {
                data.RemainingAccountingPrice = entity.RemainingAccountingPrice;
            }
        }

        const result = await customFetch(`${ASSET_API_URL}/policy/${this.props.type}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify(data)
        });

        if (result.ok) {
            let data = await result.json();
            parseDateFields(data);

            data = data.sort((a: ITaxDepreciationPolicyItemEntityExtended, b: ITaxDepreciationPolicyItemEntityExtended) =>
                sortCompareFn(a.DateCalculatedFor, b.DateCalculatedFor));

            if (this.isTaxRelated) {
                data = this.enhanceTaxDepreciationPolicyItems(data);
            }

            this.setState({
                loaded: true,
                data
            });
        } else {
            // todo: how to handle errors??
            this.props.onClose();
        }
    }

    async fetchAccountingDepreciationItems(): Promise<void> {
        const data = await fetchAccountingDepreciationPolicyItems(this.props.storage);

        if (data) {
            this.setState({
                loaded: true,
                data
            });
        } else {
            // todo: how to handle errors??
            this.props.onClose();
        }
    }

    setDataFromEntity(): void {
        const { storage } = this.props;
        const entity = storage.data.entity as IAssetEntity;
        const items = ((this.isTaxRelated ? entity.TaxDepreciationPolicy.Items : entity.AccountingDepreciationPolicy.Items) ?? []) as ITaxDepreciationPolicyItemEntityExtended[];
        const data = this.enhanceTaxDepreciationPolicyItems(items)
            .sort((a: IEntity, b: IEntity) => sortCompareFn(parseInt(a.Order), parseInt(b.Order)));

        this.setState({
            loaded: true,
            data
        });
    }

    getHeaderData = (): IReadOnlyListItem[] => {
        const { storage } = this.props;
        const { data } = this.state;

        const first = data[0];
        const last = data[data.length - 1];

        const headerData: IReadOnlyListItem[] = [];
        if (first?.DateDepreciation) {
            headerData.push({
                label: storage.t("FixedAsset:DepreciationTable.FirstDepreciation"),
                value: DateType.format(getUtcDayjs(first.DateDepreciation))
            });
        }
        if (last?.DateDepreciation) {
            headerData.push({
                label: storage.t("FixedAsset:DepreciationTable.LastDepreciation"),
                value: DateType.format(getUtcDayjs(last.DateDepreciation))
            });
        }
        if (!this.isTaxRelated) {
            const md = (storage.data.entity as IAssetEntity).DepreciationAccount?.Number;
            const d = (storage.data.entity as IAssetEntity).AccumulatedDepreciationsAccount?.Number;
            const shortName = md && d && `${md}/${d}`;
            if (shortName) {
                headerData.push({
                    label: storage.t("FixedAsset:DepreciationTable.AccountAssignment"),
                    value: shortName
                });
            }
        }
        return headerData;
    };

    renderConfirmationButtons(): React.ReactElement {
        return (
            <Button onClick={this.props.onClose}>
                {this.props.storage.t("Common:General.Close")}
            </Button>
        );
    }

    renderContent(): React.ReactElement {
        const { storage } = this.props;
        const { context, data } = this.props.storage;
        const asset = data.entity as IAssetEntity;
        const currency = getAssetCurrency(asset, context);
        const openedFYStartDate = getNewestInactiveFY(context)?.DateEnd;

        if (this.isTaxRelated) {
            const FYs = getSortedFYs(context);
            const currentFY = getFiscalYearByDate(context, getUtcDayjs());
            let putInUseFY: IFiscalYearEntity;
            if (isImportedAsset({ storage })) {
                putInUseFY = asset.ChartOfAccounts?.Id ? getFiscalYearByChartOfAccount(context, asset.ChartOfAccounts.Id) : getOldestActiveFY(context);
            } else {
                putInUseFY = (asset.DateFirstPutInUse ? getFiscalYearByDate(context, asset.DateFirstPutInUse) : null);
            }
            return (
                <TaxDepreciationPolicyTable header={<ReadOnlyList data={this.getHeaderData()}/>}
                                            currency={currency}
                                            FYs={FYs}
                                            putInUseFY={putInUseFY}
                                            currentFY={currentFY}
                                            disableBeforeDate={openedFYStartDate}
                                            data={this.state.data as ITaxDepreciationPolicyItemEntityExtended[]}/>
            );
        }

        return (
            <AccountingDepreciationPolicyTable header={<ReadOnlyList data={this.getHeaderData()}/>}
                                               currency={currency}
                                               disableBeforeDate={openedFYStartDate}
                                               data={this.state.data as IAccountingDepreciationPolicyItemEntity[]}/>
        );
    }

    render() {
        return (
            <Dialog title={this.dialogTitle}
                    onClose={this.props.onClose}
                    onConfirm={null}
                    footer={this.renderConfirmationButtons()}
            >
                {this.state.loaded ? this.renderContent() : <BusyIndicator/>}
            </Dialog>
        );
    }
}