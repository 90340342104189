import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import { getDefByEntityType } from "@pages/getDefByEntityType";
import { IPageParams } from "@pages/Page";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import i18next from "i18next";
import React, { ComponentType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { FieldType, FormMode } from "../../enums";
import { TRecordAny } from "../../global.types";
import { TableStorage } from "../../model/TableStorage";
import BindingContext, { createBindingContext, getBindingContext } from "../../odata/BindingContext";
import { AUDIT_TRAIL } from "../../routes";
import { PropsWithTheme } from "../../theme";
import { getEntitySetFromDefinition } from "../../views/formView/Form.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import { SmartFilterBarStyled, TableWrapper } from "../../views/table/TableView.styles";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import View from "../../views/View";
import { BreadCrumbProvider } from "../breadCrumb/BreadCrumbProvider";
import { ISmartFieldChange } from "../smart/smartField/SmartField";
import { FilterBarGroup, IFilterGroupDef } from "../smart/smartFilterBar/SmartFilterBar.types";
import SmartAuditTable from "../smart/smartTable/SmartAuditTable";
import { TId } from "../table";
import { AuditTrailTableColumn, IAuditEntity } from "./AuditTrail.utils";
import AuditTrailDialog from "./AuditTrailDialog";

interface IProps {
    entityType?: string;
    pageIsLoaded?: boolean;
}

interface IState {
    isOpened: boolean;
    selectedRowId: string;
    versions: IAuditEntity[];
    filterValues: TRecordAny;
}

export const ChangedOnDatePath = BindingContext.localContext(AuditTrailTableColumn.ChangedOnDate);
export const ChangedOnTimePath = BindingContext.localContext(AuditTrailTableColumn.ChangedOnTime);
export const AuthorNamePath = BindingContext.localContext(AuditTrailTableColumn.AuthorName);
export const TypePath = BindingContext.localContext(AuditTrailTableColumn.Type);

export const getTableDefinition = (): ISplitPageTableDef => {
    const filterBarDef: IFilterGroupDef[] = [{
        id: FilterBarGroup.Filters,
        allowCustomFilters: false,
        createQuery: false,
        icon: "Filter",
        title: i18next.t("Common:General.Filters"),
        defaultFilters: [ChangedOnDatePath, ChangedOnTimePath, AuthorNamePath, TypePath],
        filterDefinition: {
            [ChangedOnDatePath]: {
                label: i18next.t("Audit:Columns.Date"),
                type: FieldType.Input
            },
            [ChangedOnTimePath]: {
                label: i18next.t("Audit:Columns.Time"),
                type: FieldType.Input
            },
            [AuthorNamePath]: {
                label: i18next.t("Audit:Columns.Author"),
                type: FieldType.Input
            },
            [TypePath]: {
                label: i18next.t("Audit:Columns.Type"),
                type: FieldType.Input
            }
        }
    }];

    return {
        filterBarDef,
        id: "AuditTrailSpecialTable"
    };
};

type AuditTrailViewProps = IProps & RouteComponentProps<{}>;

class AuditTrailView extends React.Component<AuditTrailViewProps & WithTranslation & WithOData & PropsWithTheme, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    private defFn: IGetDefinition;
    private definition: IDefinition;
    private isTranslationLoaded = false;
    readonly formStorage: FormStorage;
    readonly tableStorage: TableStorage;

    entityType: string;

    constructor(props: AuditTrailViewProps & WithTranslation & WithOData & PropsWithTheme, context: IAppContext) {
        super(props);

        const initProps = {
            oData: this.props.oData,
            theme: this.props.theme,
            context,
            t: this.props.t
        };

        this.formStorage = new FormStorage({
            id: "form",
            ...initProps
        });

        this.tableStorage = new TableStorage({
            id: "table",
            ...initProps
        });

        this.state = {
            isOpened: false,
            filterValues: {},
            versions: [],
            selectedRowId: null
        };

        this.entityType = this.props.entityType;
        // in some cases with dynamic entity type we can use trick that correct entity set should be in :parentId
        // typical use case is cashBox receipt
        if (!this.entityType) {
            const es = (this.props.match.params as any).ParentId;
            const bc = createBindingContext(es, this.props.oData.getMetadata());
            this.entityType = bc.getEntityType().getName();
        }
    }

    loadDef = async () => {
        this.defFn = getDefByEntityType(this.entityType as EntityTypeName);
        const translation = this.defFn.translationFiles;

        if (translation) {
            await i18next.loadNamespaces(translation);
        }
        this.isTranslationLoaded = true;
    };

    async componentDidMount() {
        await this.loadDef();
        this.load();
    }

    async componentDidUpdate() {
        await this.loadDef();
    }

    setBreadCrumbs = () => {
        const text = this.definition.form.getItemBreadCrumbText?.(this.formStorage);

        this.context.setViewBreadcrumbs({
            items: [
                {
                    key: "item",
                    link: this.props.location.pathname.replace(`/${AUDIT_TRAIL}`, ""),
                    title: text
                },
                {
                    key: "auditTrail",
                    title: this.props.t("Audit:AuditTrail")
                }
            ],
            lockable: false
        });
    };

    load = async () => {
        if (this.props.tReady && !this.definition && this.isTranslationLoaded) {
            this.definition = this.defFn(this.context);

            const bc = this.getBindingContext(this.getEntitySet());

            await this.formStorage.init({
                definition: this.definition.form,
                bindingContext: bc,
                formMode: FormMode.AuditTrail
            });

            this.setBreadCrumbs();

            await this.tableStorage.init({
                definition: getTableDefinition(),
                bindingContext: bc
            });

            this.forceUpdate();
        }
    };

    getParentKey = () => {
        return (this.props.match.params as IPageParams)?.ParentId;
    };

    getEntitySet = () => {
        return getEntitySetFromDefinition(this.definition, this.context, this.getParentKey());
    };

    getId = () => {
        return (this.props.match.params as IPageParams)?.Id;
    };

    getBindingContext = (entitySet: string) => {
        return getBindingContext(`${entitySet}(${this.getId()})`, this.props.oData.getMetadata());
    };

    handleRowSelect = (id: TId) => {
        this.setState({
            selectedRowId: id.toString(),
            isOpened: true
        });
    };

    handleTableLoad = (versions: IAuditEntity[]): void => {
        this.setState({
            versions
        });
    };

    handleDialogClose = () => {
        // set breadCrumbs again, they could have been changed by the opened form view
        this.setBreadCrumbs();
        this.setState({
            isOpened: false
        });
    };

    handleFilterChange = (args?: ISmartFieldChange) => {
        this.tableStorage.handleChange(args);

        this.tableStorage.refreshFields();
        const changedFilters = this.tableStorage.getChangedFilters().changedFields;
        const filters: TRecordAny = {};
        for (const filter of changedFilters) {
            const id = BindingContext.cleanLocalContext(filter.info.id);
            filters[id] = filter.value;
        }

        this.setState({
            filterValues: filters
        });
    };

    handleClearFilter = () => {
        const filtersChanged = this.tableStorage.clearFilters();

        if (filtersChanged) {
            this.setState({
                filterValues: {}
            });
            this.forceUpdate();
        }
    };

    render() {
        const id = this.getId();
        if (!this.definition) {
            this.load();
            return null;
        }

        if (!this.formStorage.loaded) {
            return null;
        }

        return (
            <>
                <BreadCrumbProvider back={this.props.location.pathname.replace(`/${AUDIT_TRAIL}`, "")}/>
                <View hotspotContextId={"auditTrailView"}>
                    <SmartHeaderStyled title={this.props.t("Audit:AuditTrail")}
                                       shouldHideVariant={true}
                                       storage={this.formStorage}
                    />
                    <SmartFilterBarStyled
                        tableId="Audit"
                        onFilterChange={this.handleFilterChange}
                        storage={this.tableStorage}
                        onClearFilter={this.handleClearFilter}
                    />
                    <TableWrapper>
                        <SmartAuditTable tableId={"AuditTrail"}
                                         filterValues={this.state.filterValues}
                                         path={`${this.entityType}/${id}`}
                                         onRowSelect={this.handleRowSelect}
                                         onAfterTableLoad={this.handleTableLoad}/>
                    </TableWrapper>
                    {this.state.isOpened &&
                        <AuditTrailDialog
                            entityType={this.entityType}
                            onClose={this.handleDialogClose}
                            definition={this.definition}
                            defaultVersionId={this.state.selectedRowId}
                            versions={this.state.versions}
                            id={this.getId()}
                            bindingContext={this.getBindingContext(this.getEntitySet())}
                            t={this.props.t}/>
                    }
                </View>
            </>
        );
    }
}


export default withRouter<AuditTrailViewProps, ComponentType<AuditTrailViewProps>>(withTranslation(["Common", "Audit"])(withTheme(withOData(AuditTrailView))));