import { CustomerPortal } from "@pages/home/CustomerPortal.utils";
import { getValue } from "@utils/general";
import * as History from "history";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import {
    AppContext,
    ContextEvents,
    IBreadcrumb,
    IBreadcrumbItem,
    ILockAlert
} from "../../contexts/appContext/AppContext.types";
import { IconSize } from "../../enums";
import { ROUTE_COMPANIES, ROUTE_HOME } from "../../routes";
import TestIds from "../../testIds";
import { ExtendedShellContent } from "../../views/main/Shell.types";
import { WithAlert, withAlert } from "../alert/withAlert";
import { ButtonGroup, IconButton } from "../button";
import { THistoryLocation } from "../drillDown/DrillDown.utils";
import { ArrowIcon, LockClosedIcon, LockOpenedIcon } from "../icon";
import { IMenuSelected } from "../navigation";
import { BreadCrumb } from "./BreadCrumb";
import { BreadCrumbWrapper, TooltipIconCopyLinkBreadcrumb } from "./BreadCrumb.styles";
import { BreadCrumbItem } from "./BreadCrumbItem";
import { StyledArrowButton } from "./BreadCrumbItem.styles";

interface IProps extends WithTranslation, WithAlert, RouteComponentProps {
    className?: string;
    locked?: boolean;
    canBeUnlocked?: boolean;
    back?: THistoryBack;
    removeLast?: boolean;
    isFullscreen?: boolean;
    style?: React.CSSProperties;

    customBreadCrumbs?: ICustomBreadCrumb[];
}

export interface ICustomBreadCrumb {
    key: string;
    label: string;
    to: History.LocationDescriptor;
}

// either url or callback that will push new url into the history.state
export type THistoryBack = false | string | THistoryLocation | (() => void);

class BreadCrumbProvider extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    ignoreNextViewBreadcrumbsChange = false;

    componentDidMount() {
        this.context.eventEmitter.on(ContextEvents.ViewBreadcrumbsChanged, this.handleViewBreadcrumbsChange);
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.ViewBreadcrumbsChanged, this.handleViewBreadcrumbsChange);
    }

    handleViewBreadcrumbsChange = () => {
        if (!this.ignoreNextViewBreadcrumbsChange) {
            this.forceUpdate();
        }

        this.ignoreNextViewBreadcrumbsChange = false;
    };

    onMenuItemClick = (id?: string) => {
        if (id?.startsWith?.("Settings.")) {
            this.context.openExtendedShell(ExtendedShellContent.Settings);
            return;
        }

        // don't rerender second time, when the change is caused from here
        this.ignoreNextViewBreadcrumbsChange = true;
        // remove bread crumbs from views
        this.context.setViewBreadcrumbs({
            items: [],
            lockable: false
        });
    };

    handleLockClick = (event: React.MouseEvent) => {
        const viewBreadcrumbs: IBreadcrumb = this.context.getViewBreadcrumbs();

        if (viewBreadcrumbs.onLockClick) {
            viewBreadcrumbs.onLockClick(event);
        }
    };

    getBreadCrumbs = (viewBreadcrumbs: IBreadcrumb) => {
        const breadCrumbs = [];
        let selectedMenu: IMenuSelected = this.context.getSelectedMenu();
        let customBreadCrumbs = this.props.customBreadCrumbs;
        let showMenuItemBreadcrumb = true;

        if (this.context.getAddingNewCompany()) {
            selectedMenu = selectedMenu ?? {
                group: null,
                item: null
            };
            customBreadCrumbs = customBreadCrumbs ?? [];
            customBreadCrumbs.unshift({
                label: this.props.t("Companies:Wizard.Title"),
                to: ROUTE_COMPANIES,
                key: "companyWizard"
            });
            customBreadCrumbs.unshift({
                label: this.props.t("Companies:Title"),
                to: ROUTE_COMPANIES,
                key: "companies"
            });
        }

        const customerPortalBreadcrumb = CustomerPortal.isActive ? (
            <BreadCrumbItem key={"home"}
                            to={ROUTE_HOME}>{this.context.getCompany().Name}</BreadCrumbItem>
        ) : null;

        if (selectedMenu) {
            if (!customBreadCrumbs?.length && selectedMenu.group) {
                breadCrumbs.push(
                    customerPortalBreadcrumb ??
                    <BreadCrumbItem key={selectedMenu.group.key}
                                    onClick={this.onMenuItemClick.bind(this, selectedMenu.group.key)}
                                    to={selectedMenu.group.url}>{selectedMenu.group.title.toUpperCase()}</BreadCrumbItem>
                );
            }

            if (customBreadCrumbs) {
                let index = 0;
                for (const customBreadCrumb of customBreadCrumbs) {
                    // first custom breadcrumb is expected to be group
                    if (index === 0) {
                        breadCrumbs.push(
                            customerPortalBreadcrumb ??
                            <BreadCrumbItem key={customBreadCrumb.key}
                                            onClick={this.onMenuItemClick.bind(this, customBreadCrumb.key)}
                                            to={customBreadCrumb.to}>{this.props.t(customBreadCrumb.label).toUpperCase()}</BreadCrumbItem>
                        );
                    } else {
                        breadCrumbs.push(
                            <BreadCrumbItem key={customBreadCrumb.key}
                                            to={customBreadCrumb.to}>{this.props.t(customBreadCrumb.label)}</BreadCrumbItem>
                        );
                    }
                    index += 1;
                }

                if (viewBreadcrumbs?.items.length > 0) {
                    // prevent showing e.g. "faktury prijate" in form view breadcrumbs during drilldown intent navigation
                    showMenuItemBreadcrumb = false;
                }
            }

            if ((showMenuItemBreadcrumb || this.context.getAddingNewCompany()) && selectedMenu.item) {
                // last item is not link clickable as it does nothing anyway
                const isLast = !viewBreadcrumbs || !viewBreadcrumbs.items || viewBreadcrumbs.items.length === 0;
                if (isLast) {
                    breadCrumbs.push(
                        <BreadCrumbItem
                            key={selectedMenu.item.key}>{getValue(selectedMenu.item.title)}</BreadCrumbItem>
                    );
                } else {
                    breadCrumbs.push(
                        <BreadCrumbItem key={selectedMenu.item.key} onClick={this.onMenuItemClick}
                                        to={selectedMenu.item.url}>{this.props.t(getValue(selectedMenu.item.title))}</BreadCrumbItem>
                    );
                }
            }
        }

        if (viewBreadcrumbs) {
            viewBreadcrumbs.items.forEach((breadCrumbItem: IBreadcrumbItem) => {
                breadCrumbs.push(
                    <BreadCrumbItem key={breadCrumbItem.key}
                                    to={breadCrumbItem.link}>{this.props.t(breadCrumbItem.title)}</BreadCrumbItem>
                );
            });
        }

        return breadCrumbs;
    };

    renderLock = (isLockDisabled: boolean) => {
        const Icon = this.props.locked ? LockClosedIcon : LockOpenedIcon;
        const title = this.props.locked ? this.props.canBeUnlocked ? this.props.t("Common:General.Unlock") : this.props.t("Components:Table.LockPermissionLack") : this.props.t("Common:General.Lock");
        const viewBreadcrumbs = this.context.getViewBreadcrumbs();
        const lockAlert = viewBreadcrumbs.lockAlert as ILockAlert;

        return (
            <IconButton title={title} key={"Lock"}
                        onClick={this.handleLockClick}
                        isDisabled={isLockDisabled || !this.props.canBeUnlocked}
                        alert={lockAlert?.alert}
                        alertPopperOptions={lockAlert?.popperOptions}
                        isDecorative>
                <Icon width={IconSize.XS}/>
            </IconButton>
        );
    };

    renderCopyLink = () => {
        return (
            // tmp solution until we make proper links with state stored to BE
            <TooltipIconCopyLinkBreadcrumb link={window.location.href}/>
        );
    };

    renderBackLink = () => {
        if (this.props.back === false) {
            return null;
        }
        const addingNewCompany = (this.context.getAddingNewCompany() && this.props.location.pathname !== ROUTE_COMPANIES);
        const shouldShowBack = this.props.back || CustomerPortal.isActive || addingNewCompany;
        if (!shouldShowBack) {
            return null;
        }
        const isLink = this.props.back !== "function";
        let link = isLink ? this.props.back : null;
        const onClick = isLink ? null : this.props.back;

        if (!link) {
            if (addingNewCompany) {
                link = ROUTE_COMPANIES;
            } else if (CustomerPortal.isActive) {
                link = ROUTE_HOME;
            }
        }

        return (
            <StyledArrowButton title={this.props.i18n.t("Common:General.Back")}
                               isTransparent
                               isDecorative
                               dontAddCompanyIdToLink
                               link={link as THistoryLocation}
                               onClick={onClick as unknown as (() => void)}>
                <ArrowIcon width={IconSize.S}/>
            </StyledArrowButton>
        );
    };

    render = () => {
        const viewBreadCrumbs = { ...this.context.getViewBreadcrumbs() } as IBreadcrumb;
        const lockable = getValue(viewBreadCrumbs.lockable) && !this.props.removeLast;
        const isLockDisabled = getValue(viewBreadCrumbs.isLockDisabled);

        if (this.props.removeLast) {
            viewBreadCrumbs.items = viewBreadCrumbs.items.slice(0, -1);
        }

        return (
            <BreadCrumbWrapper data-testid={TestIds.Breadcrumb}
                               isFullscreen={this.props.isFullscreen}>
                {this.renderBackLink()}
                <BreadCrumb className={this.props.className}>
                    {this.getBreadCrumbs(viewBreadCrumbs)}
                </BreadCrumb>
                <ButtonGroup>
                    {lockable && this.renderLock(isLockDisabled)}
                    {this.renderCopyLink()}
                </ButtonGroup>
            </BreadCrumbWrapper>
        );
    };
}


const BreadCrumbProviderExtended = withRouter(withTranslation(["Common", "Components", "Companies"])(withAlert({ autoHide: true })(BreadCrumbProvider)));

export { BreadCrumbProviderExtended as BreadCrumbProvider };