import React from "react";
import { ExtendedShell, IExtendedShellPropsForExtend } from "../views/main/ExtendedShell";
import Notifications, { INotification } from "./Notifications";
import { NotificationsHeader, StyledBackButton } from "./Notifications.styles";
import { ArrowIcon } from "@components/icon";
import { WithTranslation, withTranslation } from "react-i18next";
import { themes } from "../theme";
import { ThemeProvider } from "styled-components/macro";
import Header from "../components/header";
import { IconSize } from "../enums";
import NotificationsSettings from "./notificationsSettings/NotificationsSettings";
import { fetchNotificationsSettings, INotificationsState } from "./Notification.utils";
import { WithOData, withOData } from "@odata/withOData";
import { AppContext } from "../contexts/appContext/AppContext.types";
import TestIds from "../testIds";

export interface IProps extends IExtendedShellPropsForExtend, WithOData {
    notifications?: INotification[];
    onlyShowUnread?: boolean;
    onShouldShowReadChange?: (shouldShowRead: boolean) => void;
    onMarkAllAsRead?: () => void;
    onIsUnreadChange?: (id: string, isUnread: boolean) => void;
    onNotificationClick?: (id: string) => void;
}

interface IState {
    isLoading?: boolean;
    isSettingsMode: boolean;
}

class NotificationsExtendedShell extends React.PureComponent<IProps & WithTranslation, IState> {
    static contextType = AppContext;

    state: IState = {
        isLoading: true,
        isSettingsMode: false
    };

    initialNotificationSettingState: INotificationsState = null;

    componentDidMount() {
        this.init();
    }

    async init() {
        this.initialNotificationSettingState = await fetchNotificationsSettings(this.context, this.props.oData);
        this.setState({ isLoading: false });
    }

    handleOnlyShowUnreadChange = () => {
        this.props.onShouldShowReadChange?.(!this.props.onlyShowUnread);
    };

    openSettings = (): void => {
        this.setState({ isSettingsMode: true });
    };

    closeSettings = (): void => {
        this.setState({ isSettingsMode: false });
    };

    getOnlyShowUnreadIcon = (): string => {
        return this.props.onlyShowUnread ? "Visible" : "VisibleFilled";
    };

    get notifications() {
        return this.props.onlyShowUnread ? this.props.notifications.filter(n => n.isUnread) : this.props.notifications;
    }

    renderHeader = (): React.ReactElement => {
        return (
            <NotificationsHeader data-testid={TestIds.Header}>
                <Header
                    title={this.props.t("Common:ShellBar.Notifications")}
                    icons={!this.state.isSettingsMode ? [{
                        id: "Settings",
                        onClick: this.openSettings,
                        label: this.props.t("Components:Notifications.Settings"),
                        iconName: "Settings",
                        isLight: true
                    }, {
                        id: "showRead",
                        onClick: this.handleOnlyShowUnreadChange,
                        label: this.props.t(`Components:Notifications.${this.props.onlyShowUnread ? "ShowRead" : "ShowUnreadOnly"}`),
                        iconName: this.getOnlyShowUnreadIcon(),
                        isLight: true
                    }] : []}
                />
                {this.state.isSettingsMode && <StyledBackButton
                    title={this.props.t("Common:General.Back")}
                    isTransparent
                    isDecorative
                    isLight
                    key={"Back"}
                    onClick={this.closeSettings}>
                    <ArrowIcon width={IconSize.L} height={IconSize.L} />
                </StyledBackButton>}
            </NotificationsHeader>
        );
    };

    handleSettingsSave = (notificationSettings: INotificationsState): void => {
        this.initialNotificationSettingState = notificationSettings;
    };

    renderContent() {
        if (this.state.isSettingsMode) {
            return <NotificationsSettings
                onSave={this.handleSettingsSave}
                isLoading={this.state.isLoading}
                initialNotificationSettingState={this.initialNotificationSettingState} />;
        }

        return <Notifications notifications={this.notifications}
            onMarkAllAsRead={this.props.onMarkAllAsRead}
            onIsUnreadChange={this.props.onIsUnreadChange}
            onNotificationClick={this.props.onNotificationClick} />;
    }

    render() {
        return (
            <ThemeProvider theme={themes["light"]}>
                <ExtendedShell header={this.renderHeader()}
                    padding={"30px 30px 85px 41px"}
                    hideScrollbar={!this.notifications?.length && !this.state.isSettingsMode}
                    hotspotContextId={"notifications"}
                    {...this.props}>
                    {this.renderContent()}
                </ExtendedShell>
            </ThemeProvider>
        );
    }
}

export default withTranslation(["Components", "Common"])(withOData(NotificationsExtendedShell));