import { IAlertProps } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { Select } from "@components/inputs/select";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import { fourDigitsFormatter } from "@components/smart/GeneralFieldDefinition";
import { ICellValueObject } from "@components/table";
import { TEntityKey } from "@odata/BindingContext";
import { ODataError } from "@odata/Data.types";
import {
    AccountEntity,
    ChartOfAccountsTemplateEntity,
    CompanySettingEntity,
    EntitySetName,
    IAccountEntity,
    IChartOfAccountsEntity,
    IChartOfAccountsTemplateEntity,
    ICompanySettingEntity,
    ICurrencyUsedByCompanyEntity,
    IFiscalYearEntity,
    IInitialAccountBalanceEntity,
    InitialAccountBalanceEntity
} from "@odata/GeneratedEntityTypes";
import { CurrencyCode, FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { isNotDefined, isObjectEmpty, uuidv4 } from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import i18next, { TFunction } from "i18next";
import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Redirect, withRouter } from "react-router-dom";

import { BreadCrumbProvider } from "../../components/breadCrumb";
import BusyIndicator from "../../components/busyIndicator";
import { Button, ButtonGroup } from "../../components/button";
import { DatePicker } from "../../components/inputs/date";
import Field from "../../components/inputs/field";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import NumericInputWithStepper from "../../components/inputs/numericInput/NumericInput";
import RadioButtonGroup from "../../components/inputs/radioButtonGroup";
import Switch, { SwitchType } from "../../components/inputs/switch/Switch";
import ViewHeader from "../../components/smart/smartHeader/SmartHeader";
import {
    BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX,
    CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX,
    DASH_CHARACTER
} from "../../constants";
import { AppContext, IAppContext, IAppContextData } from "../../contexts/appContext/AppContext.types";
import {
    BasicInputSizes,
    LabelStatus,
    RadioButtonGroupLayout,
    Status,
    TextAlign,
    ValidationErrorType
} from "../../enums";
import { IValidationError } from "../../model/Validator.types";
import { ROUTE_COMPANIES, ROUTE_HOME } from "../../routes";
import TestIds from "../../testIds";
import CurrencyType, { formatCurrencyVariableDecimals } from "../../types/Currency";
import DateType, { getUtcDayjs } from "../../types/Date";
import NumberType, { currencyScaleFormatter, IParserArgs } from "../../types/Number";
import KeyboardShortcutsManager from "../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import memoize from "../../utils/memoize";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { StyledSuccessWrapper } from "../../views/formView/FormView.styles";
import View from "../../views/View";
import { AccountType } from "../chartOfAccounts/ChartOfAccounts.utils";
import { typeFormatter } from "../chartOfAccounts/ChartOfAccountsDef";
import { getSortedFYs } from "../fiscalYear/FiscalYear.utils";
import {
    AmountOverview,
    GridCell,
    HeaderCell,
    HeadingWrapper,
    InitialAccountBalanceGrid,
    InitialBalancesColumns,
    Separator,
    StickyHeader
} from "./InitialAccountBalance.styles";


interface IProps extends WithTranslation, WithOData, RouteComponentProps, WithAlert {
}

interface IState {
    oldestFiscalYearNotActive: boolean;
    date: Date;
    dateError: IValidationError;
    accounts: IAccountEntity[];
    errors: Record<string, { amount: IValidationError, transactionAmount: IValidationError }>;
    loading: boolean;
    balances: Record<string, IInitialAccountBalanceEntity & { hasRelatedEntity?: boolean }>;
    companySettingsId: TEntityKey;
    // true for balance with existing related CashBox/BankAccount
    balancesWithRelatedEntity: Record<string, boolean>;
}

class InitialAccountBalances extends React.Component<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        oldestFiscalYearNotActive: false,
        date: null,
        dateError: null,
        accounts: [],
        errors: {},
        loading: false,
        balances: {},
        companySettingsId: null,
        balancesWithRelatedEntity: {}
    };

    _refScroll = React.createRef<HTMLDivElement>();
    _gridRef = React.createRef<HTMLDivElement>();
    currencyItems: ISelectItem[];
    _unsubscribeKeyboardShortcuts: () => void;

    constructor(props: IProps) {
        super(props);

        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_S],
            callback: this.handleKeyboardShortcut
        });
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this._unsubscribeKeyboardShortcuts();
    }

    get isAddingNewCompany(): boolean {
        return this.context.getAddingNewCompany();
    }

    get fiscalYear(): IFiscalYearEntity {
        // use oldest fiscal year
        return getSortedFYs(this.context)[0];
    }

    load = async (): Promise<void> => {
        // only show initial balances if the oldest fiscal year is still active
        const oldestFiscalYear = this.fiscalYear;

        if (!this.isAddingNewCompany && oldestFiscalYear.StatusCode !== FiscalYearStatusCode.Active) {
            this.setState({
                oldestFiscalYearNotActive: true
            });

            return;
        }

        this.setState({ loading: true });

        if (this.props.alert) {
            this.props.setAlert(null);
        }

        const contextData = this.context.getData() as IAppContextData;

        const companySettingsRes = await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings)
            .query()
            .select(CompanySettingEntity.Id, CompanySettingEntity.DateInitialAccountBalances, CompanySettingEntity.InitialChartOfAccountsTemplateId)
            .expand(CompanySettingEntity.InitialAccountBalances)
            .top(1)
            .fetchData<ICompanySettingEntity[]>();

        const companySettings = (companySettingsRes.value as ICompanySettingEntity[])[0];

        // when creating new company - use ChartOfAccountsTemplates
        // when editing initial balances for already existing company (in dialog) - use the oldest active ChartsOfAccounts
        const entitySetName = !this.isAddingNewCompany ? EntitySetName.ChartsOfAccounts : EntitySetName.ChartOfAccountsTemplates;
        const id = !this.isAddingNewCompany ? oldestFiscalYear.ChartOfAccounts.Id : companySettings.InitialChartOfAccountsTemplateId || -1;

        const accountsRes = (await this.props.oData.getEntitySetWrapper(entitySetName)
            .query(id)
            .expand(ChartOfAccountsTemplateEntity.Accounts, q => {
                return q.filter(`not(startswith(${AccountEntity.Number}, '7'))`)
                    .select(AccountEntity.Id, AccountEntity.Number, AccountEntity.Name, AccountEntity.CategoryCode)
                    .expand(AccountEntity.Parent)
                    .expand(AccountEntity.Type)
                    .orderBy(AccountEntity.Number);
            })
            .fetchData<IChartOfAccountsTemplateEntity | IChartOfAccountsEntity>())?.value;

        const companySettingsId = companySettings.Id;
        const startDate = companySettings.DateInitialAccountBalances ?? oldestFiscalYear?.DateStart;

        const balances: Record<string, IInitialAccountBalanceEntity> = {};

        for (const balance of (companySettings.InitialAccountBalances ?? [])) {
            balances[balance.Number] = balance;
        }

        const balancesWithRelatedEntity: Record<string, boolean> = {};

        if (!this.isAddingNewCompany) {
            const bankAccounts = (this.context as IAppContext).getCompanyBankAccounts();
            const cashBoxes = (this.context as IAppContext).getCashBoxes();

            for (const bankAccount of bankAccounts) {
                const accNumber = `${BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX}${bankAccount.BalanceSheetAccountNumberSuffix}`;

                balancesWithRelatedEntity[accNumber] = true;

                if (!balances[accNumber]) {
                    balances[accNumber] = {
                        InitialBalance: 0,
                        InitialTransactionBalance: 0
                    };
                }

                balances[accNumber][InitialAccountBalanceEntity.TransactionCurrencyCode] = bankAccount.TransactionCurrencyCode;
            }

            for (const cashBox of cashBoxes) {
                const accNumber = `${CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX}${cashBox.BalanceSheetAccountNumberSuffix}`;

                balancesWithRelatedEntity[accNumber] = true;

                if (!balances[accNumber]) {
                    balances[accNumber] = {
                        InitialBalance: 0,
                        InitialTransactionBalance: 0
                    };
                }

                balances[accNumber][InitialAccountBalanceEntity.TransactionCurrencyCode] = cashBox.TransactionCurrencyCode;
            }
        }

        this.currencyItems = contextData?.currenciesUsedByCompany?.map((c: ICurrencyUsedByCompanyEntity) => ({
            id: c.FromCurrencyCode,
            label: c.FromCurrencyCode,
            additionalData: c.FromCurrency
        }));

        const accounts: IAccountEntity[] = accountsRes?.Accounts;

        for (const account of accounts) {
            if (!balances[account.Number]) {
                balances[account.Number] = {
                    InitialBalance: 0,
                    InitialTransactionBalance: 0
                };
            }
        }

        this.setState({
            loading: false,
            balances,
            accounts,
            date: startDate,
            companySettingsId,
            balancesWithRelatedEntity,
            dateError: null,
            errors: {}
        });
    };

    validateDate = (): boolean => {
        if (!DateType.isValid(this.state.date)) {
            this.setState({
                dateError: {
                    message: this.props.t("Common:Validation.NotADate"),
                    errorType: ValidationErrorType.TypeError
                }
            });
            return false;
        }

        return true;
    };

    validateAmount = (value: number, accNumber: string, isCzk: boolean, forceSetState: boolean): boolean => {
        const isValid = NumberType.isValid(value);

        if (!isValid || forceSetState) {
            this.setState((state) => {
                const errors = { ...state.errors };

                errors[accNumber] = {
                    ...errors[accNumber],
                    [isCzk ? "amount" : "transactionAmount"]: isValid ? null : {
                        message: this.props.t("Common:Validation.MustBeNumber"),
                        errorType: ValidationErrorType.TypeError
                    } as IValidationError
                };

                return { errors };
            });
        }

        return isValid;
    };

    validateAll = (): boolean => {
        let isValid = true;

        isValid = isValid && this.validateDate();
        const props: (keyof IInitialAccountBalanceEntity)[] = ["InitialBalance", "InitialTransactionBalance"];

        for (const [accNumber, balance] of Object.entries(this.state.balances)) {
            for (const prop of props) {
                if (isNotDefined(balance[prop])) {
                    continue;
                }

                isValid = isValid && this.validateAmount(balance[prop] as number, accNumber, prop === "InitialBalance", false);
            }

        }

        return isValid;
    };

    handleKeyboardShortcut = (shortcut: KeyboardShortcut, event: KeyboardEvent): boolean => {
        if (shortcut === KeyboardShortcut.ALT_S) {
            this.handleSave();
            return true;
        }

        return false;
    };

    handleAmountChange = (value: number, accNumber: string, isCzk: boolean, shouldValidate: boolean): void => {
        const balances = { ...this.state.balances };
        const accSettings = balances[accNumber] ?? {};
        const prop = isCzk ? "InitialBalance" : "InitialTransactionBalance";

        balances[accNumber] = {
            ...accSettings,
            [prop]: value
        };

        if (shouldValidate) {
            // validate both amount fields, change of one of them can remove backend validation error state
            const otherProp = isCzk ? "InitialTransactionBalance" : "InitialBalance";

            this.validateAmount(value, accNumber, isCzk, true);
            this.validateAmount(accSettings[otherProp], accNumber, !isCzk, true);
        } else {
            const errors = { ...this.state.errors };

            errors[accNumber] = {
                ...errors[accNumber],
                [isCzk ? "amount" : "transactionAmount"]: null
            };

            this.setState({ errors });
        }

        this.setState({ balances });
    };

    handleCurrencyChange = (val: string, accNumber: string): void => {
        const balances = { ...this.state.balances };
        const accSettings = balances[accNumber] ?? {};
        const errors = { ...this.state.errors };

        balances[accNumber] = { ...accSettings, TransactionCurrencyCode: val };

        if (val === getCompanyCurrency(this.context)) {
            balances[accNumber].InitialTransactionBalance = 0;
            errors[accNumber] = {
                ...errors[accNumber],
                transactionAmount: null
            };
        }

        this.setState({ balances, errors });
    };

    handleTypeChange = (val: string, accNumber: string): void => {
        const balances = { ...this.state.balances };
        const accSettings = balances[accNumber] ?? {};
        balances[accNumber] = { ...accSettings, IsDebit: val === "MD" };
        this.setState({ balances });
    };

    handleDateChange = (e: IInputOnChangeEvent<Date>) => {
        this.setState({ date: e.value });
    };

    handleDateBlur = (): void => {
        if (!this.validateDate()) {
            return;
        }

        // apparently, we want to check if the selected year is correct if used in CoA
        const isDateInFiscalYear = getUtcDayjs(this.state.date).isBetween(this.fiscalYear.DateStart, this.fiscalYear.DateEnd, "day", "[]");

        if (isDateInFiscalYear) {
            this.setState({
                dateError: null
            });
        } else {
            this.setState({
                dateError: {
                    message: this.props.t("Error:CompanySettingDateInitialAccountBalancesMustBeWithinFirstFiscalYear"),
                    errorType: ValidationErrorType.Form
                }
            });
        }
    };

    handleCancel = (): void => {
        this.load();
    };

    getFirstError = memoize(() => {
        return Object.entries(this.state.errors).find(([key, error]) => error.amount ?? error.transactionAmount);
    }, () => [this.state.errors]);

    hasErrors = (): boolean => {
        return !!this.state.dateError || !!this.getFirstError();
    };

    scrollTop = (): void => {
        if (this._refScroll.current) {
            this._refScroll.current.scrollTop = 0;
        }
    };

    scrollBottom = (): void => {
        if (this._refScroll.current) {
            this._refScroll.current.scrollTop = this._refScroll.current.scrollHeight;
        }
    };

    scrollToRow = (index: number): void => {
        this._gridRef.current?.children.item((index + 1) * InitialBalancesColumns)?.scrollIntoView();
    };

    handleSave = async (): Promise<void> => {
        const isValid = this.validateAll();

        if (!isValid || this.hasErrors()) {
            this.props.setAlert({
                status: Status.Error,
                title: i18next.t("Common:General.FormValidationErrorTitle"),
                subTitle: i18next.t("Common:General.FormValidationErrorSubTitle"),
                isFullWidth: true
            });
            this.scrollTop();
            return;
        }

        this.setState({ loading: true });
        const InitialAccountBalances = Object.entries(this.state.balances).map(([key, value]) => {
            const account = this.state.accounts.find(acc => key === acc.Number);
            const transactionCurrency = value.TransactionCurrencyCode ?? getCompanyCurrency(this.context);
            return {
                Id: value.Id,
                InitialBalance: value.InitialBalance ?? 0,
                InitialTransactionBalance: transactionCurrency === getCompanyCurrency(this.context) ? value.InitialBalance ?? 0 : (value.InitialTransactionBalance ?? 0),
                IsDebit: value.IsDebit ?? this.isDebitType(account.Type.Code as AccountType),
                Number: key,
                TransactionCurrencyCode: transactionCurrency,
                // remove if we manage to convert this page into regular form
                TemporaryGuid: uuidv4()
            };
        });

        let alert: IAlertProps;

        try {
            await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings).update(this.state.companySettingsId, {
                DateInitialAccountBalances: this.state.date,
                InitialAccountBalances
            });

            await this.load();

            alert = {
                status: Status.Success,
                title: i18next.t("Common:Validation.SuccessTitle"),
                subTitle: i18next.t("Companies:Wizard.InitialStateSaveSuccess"),
                isOneLiner: true,
                isFullWidth: true
            };

            this.scrollBottom();
        } catch (e) {
            const error = e as ODataError;

            alert = {
                isFullWidth: true,
                ...getAlertFromError(error)
            };

            // try to set error to the related fields
            for (const validationMessage of error._validationMessages) {
                const errEntity = validationMessage.entity;
                const relatedBalance = InitialAccountBalances.find(balance => balance.TemporaryGuid === errEntity.temporaryGuid);

                if (!relatedBalance) {
                    continue;
                }

                const error: IValidationError = {
                    message: validationMessage.message,
                    errorType: ValidationErrorType.Backend
                };

                this.setState({
                    errors: {
                        ...this.state.errors,
                        [relatedBalance.Number]: {
                            amount: error,
                            transactionAmount: error
                        }
                    }
                });
            }

            this.setState({ loading: false });
            this.scrollTop();
        }

        this.props.setAlert(alert);
    };

    isDebitType = (type: AccountType): boolean => {
        return [AccountType.Active, AccountType.Expense].includes(type);
    };

    getFormattedSum = (type: AccountType) => {
        const filteredAccounts = this.state.accounts.filter((acc: IAccountEntity) => acc.TypeCode === type);
        const sum = filteredAccounts.reduce((sum, acc) => {
            const balance = this.state.balances[acc.Number];
            const amount = balance?.InitialBalance || 0;
            if (balance && !isNaN(amount)) {
                if (balance.IsDebit === !this.isDebitType(type)) {
                    sum -= amount;
                } else {
                    sum += amount;
                }
            }
            return sum;
        }, 0);
        return formatCurrencyVariableDecimals(sum, getCompanyCurrency(this.context));
    };

    renderFooterButtons = (): React.ReactElement => {
        return (
            <ButtonGroup align={TextAlign.Right} style={{ marginTop: "30px" }}>
                <Button onClick={this.handleCancel} isTransparent>
                    {this.props.t("Common:General.Cancel")}
                </Button>
                <Button onClick={this.handleSave}>
                    {this.props.t("Common:General.Save")}
                </Button>
            </ButtonGroup>
        );
    };

    render() {
        if (this.state.oldestFiscalYearNotActive) {
            return (
                <Redirect to={{
                    pathname: ROUTE_HOME,
                    search: window.location.search
                }}/>
            );
        }

        if (!this.props.tReady) {
            return <BusyIndicator/>;
        }

        return (
            <>
                {this.state.loading && <BusyIndicator/>}
                <BreadCrumbProvider back={this.isAddingNewCompany ? ROUTE_COMPANIES : null}/>
                <View hotspotContextId={"initialAccountsBalance"}
                    // to support sticky header
                      style={{ height: "fit-content" }}
                      scrollProps={{
                          scrollableNodeProps: {
                              ref: this._refScroll
                          }
                      }}>
                    <StickyHeader _isInactiveCompany={this.isAddingNewCompany}>
                        <ViewHeader title={this.props.t("Companies:Wizard.InitialAccountBalances")}
                                    shouldHideVariant/>
                        <HeadingWrapper>
                            <span>{this.props.t("Companies:Wizard.InitialBalancesDate")}:</span>
                            <Field labelStatus={LabelStatus.Removed} name={"InitialBalancesDate"}>
                                <DatePicker value={this.state.date}
                                            onChange={this.handleDateChange}
                                            onBlur={this.handleDateBlur}
                                            error={this.state.dateError}
                                            minDate={this.fiscalYear.DateStart}
                                            maxDate={this.fiscalYear.DateEnd}
                                />
                            </Field>
                            <AmountOverview data-testid={TestIds.AmountOverview}>
                                <span><b>{this.props.t("Companies:Wizard.Active")}:</b>&nbsp;{this.getFormattedSum(AccountType.Active)}</span>
                                <Separator>|</Separator>
                                <span><b>{this.props.t("Companies:Wizard.Liability")}:</b>&nbsp;{this.getFormattedSum(AccountType.Liability)}</span>
                            </AmountOverview>
                            <AmountOverview data-testid={TestIds.AmountOverview}>
                                <span><b>{this.props.t("Companies:Wizard.Expense")}</b>:&nbsp;{this.getFormattedSum(AccountType.Expense)}</span>
                                <Separator>|</Separator>
                                <span><b>{this.props.t("Companies:Wizard.Income")}</b>:&nbsp;{this.getFormattedSum(AccountType.Income)}</span>
                            </AmountOverview>
                        </HeadingWrapper>
                    </StickyHeader>
                    {this.props.alertProps?.status && this.props.alertProps.status !== Status.Success ? this.props.alert : null}
                    <InitialAccountBalanceGrid ref={this._gridRef} data-testid={TestIds.InitialAccountBalancesGrid}>
                        <HeaderCell>{this.props.t("Companies:Wizard.Account")}</HeaderCell>
                        <HeaderCell>{this.props.t("Companies:Wizard.Type")}</HeaderCell>
                        <HeaderCell>{this.props.t("Companies:Wizard.InitialState")}</HeaderCell>
                        <HeaderCell/>
                        <HeaderCell/>
                        {this.state.accounts?.map((acc: IAccountEntity) => {
                            const accSetting = this.state.balances[acc.Number];
                            // currency cannot be changed for rows that are used in CashBox/BankAccount
                            const isCurrencyDisabled = this.state.balancesWithRelatedEntity[acc.Number];

                            return <GridRow
                                    key={acc.Number}
                                    acc={acc}
                                    t={this.props.t}
                                    currencyCode={accSetting?.TransactionCurrencyCode ?? getCompanyCurrency(this.context)}
                                    isCurrencyDisabled={isCurrencyDisabled}
                                    isDebit={accSetting?.IsDebit ?? this.isDebitType(acc.Type.Code as AccountType)}
                                    amount={this.state.balances[acc.Number]?.InitialBalance}
                                    transactionAmount={this.state.balances[acc.Number]?.InitialTransactionBalance}
                                    amountError={this.state.errors[acc.Number]?.amount}
                                    transactionAmountError={this.state.errors[acc.Number]?.transactionAmount}
                                    currencyItems={this.currencyItems}
                                    onTypeChange={this.handleTypeChange}
                                    onCurrencyChange={this.handleCurrencyChange}
                                    onAmountChange={this.handleAmountChange}/>;
                        })}
                        {/*try to make it look like form (correct bottom padding) even though we don't actually use Form here*/}
                        {/*TODO how to convert this page to form in the easiest way? but with sticky header, because this page is still supposed to be a bit special */}
                        <StyledSuccessWrapper>
                            {this.props.alertProps?.status === Status.Success ? this.props.alert : null}
                        </StyledSuccessWrapper>
                    </InitialAccountBalanceGrid>
                    {this.renderFooterButtons()}
                </View>
            </>
        );
    }
}

interface IGridRowProps {
    acc: IAccountEntity,
    amount: number,
    transactionAmount: number,
    isDebit: boolean,
    currencyCode: string,
    amountError?: IValidationError;
    transactionAmountError?: IValidationError;
    onAmountChange: (val: number, accNumber: string, czk: boolean, shouldValidate: boolean) => void,
    onTypeChange: (val: string, accNumber: string) => void,
    onCurrencyChange: (val: string, accNumber: string) => void,
    t: TFunction,
    currencyItems: ISelectItem[]
    isCurrencyDisabled?: boolean;
}

const radioButtonDef = [{
    id: "MD",
    label: "MD"
}, {
    id: "D",
    label: "D"
}];

const fieldWrapperStyles: React.CSSProperties = { flexWrap: "nowrap" };

class GridRow extends PureComponent<IGridRowProps> {
    static contextType = AppContext;

    parserArgs: IParserArgs = { maximumFractionDigits: 2 };

    handleChange = (e: IInputOnChangeEvent<number>): void => {
        this.props.onAmountChange(e.value, this.props.acc.Number, true, false);
    };

    handleTransctionAmountChange = (e: IInputOnChangeEvent<number>): void => {
        this.props.onAmountChange(e.value, this.props.acc.Number, false, false);
    };

    handleTypeChange = (val: string): void => {
        this.props.onTypeChange(val, this.props.acc.Number);
    };

    handleCurrencyChange = (args: ISelectionChangeArgs) => {
        this.props.onCurrencyChange(args.value as string, this.props.acc.Number);
    };

    handleSwitch = (isForeignCurrency: boolean) => {
        this.props.onCurrencyChange(isForeignCurrency ? CurrencyCode.Euro : getCompanyCurrency(this.context), this.props.acc.Number);
    };

    handleAmountBlur = () => {
        this.props.onAmountChange(this.props.amount, this.props.acc.Number, true, true);
    };

    handleTransactionAmountBlur = () => {
        this.props.onAmountChange(this.props.transactionAmount, this.props.acc.Number, false, true);
    };

    render() {
        const acc = this.props.acc;
        const rate = this.props.amount / this.props.transactionAmount;

        return <>
            <GridCell
                data-testid={TestIds.InitialAccountBalancesGridCell}>{!isObjectEmpty(acc.Parent) ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> : ""}{`${acc.Number} - ${acc.Name}`}</GridCell>
            <GridCell data-testid={TestIds.InitialAccountBalancesGridCell}>
                {(typeFormatter(acc.Type?.Name, { entity: acc }) as ICellValueObject).value}
            </GridCell>
            <GridCell data-testid={TestIds.InitialAccountBalancesGridCell}>
                <Field labelStatus={LabelStatus.Removed}
                       name={"amount"}>
                    <NumericInputWithStepper width={BasicInputSizes.M}
                                             name={acc.Number}
                                             value={this.props.amount}
                                             parserArgs={this.parserArgs}
                                             formatter={currencyScaleFormatter}
                                             error={this.props.amountError}
                                             onChange={this.handleChange}
                                             showSteppers={false}
                                             onBlur={this.handleAmountBlur}
                                             unit={CurrencyType.getCurrencyUnit(getCompanyCurrency(this.context))}
                    />
                </Field>
            </GridCell>
            <GridCell data-testid={TestIds.InitialAccountBalancesGridCell}>
                <RadioButtonGroup
                    checkedButton={this.props.isDebit ? "MD" : "D"}
                    definition={radioButtonDef}
                    onChange={this.handleTypeChange}
                    layout={RadioButtonGroupLayout.Row}/>
            </GridCell>
            <GridCell data-testid={TestIds.InitialAccountBalancesGridCell}>
                {acc.Number.startsWith("2") &&
                    <FieldsWrapper style={fieldWrapperStyles}>
                        <Field labelStatus={LabelStatus.Removed} name={acc.Number + "switch"}
                               isDisabled={this.props.isCurrencyDisabled}>
                            <Switch
                                    checked={this.props.currencyCode !== getCompanyCurrency(this.context)}
                                    isDisabled={this.props.isCurrencyDisabled}
                                    onChange={this.handleSwitch}
                                    label={this.props.t("Companies:Wizard.StateInForeignCurrency")}
                                    type={SwitchType.YesNo}/>
                        </Field>
                        {this.props.currencyCode !== getCompanyCurrency(this.context) && <>
                            <Field isSharpRight={true}
                                   labelStatus={LabelStatus.Removed}>
                                <NumericInputWithStepper width={BasicInputSizes.S}
                                                         name={acc.Number + "foreigncurrency"}
                                                         isSharpRight={true}
                                                         value={this.props.transactionAmount}
                                                         parserArgs={this.parserArgs}
                                                         formatter={currencyScaleFormatter}
                                                         error={this.props.transactionAmountError}
                                                         onBlur={this.handleTransactionAmountBlur}
                                                         onChange={this.handleTransctionAmountChange}
                                                         showSteppers={false}
                                />
                            </Field>
                            <Field isSharpLeft={true}
                                   labelStatus={LabelStatus.Removed}>
                                <Select
                                    name={acc.Number + "currency"}
                                    width={"90px"}
                                    isSharpLeft={true}
                                    value={this.props.currencyCode}
                                    items={this.props.currencyItems}
                                    isDisabled={this.props.isCurrencyDisabled}
                                    shouldDisplayAdditionalColumns={true}
                                    onChange={this.handleCurrencyChange}/>
                            </Field>
                            <Field labelStatus={LabelStatus.Removed}>
                                <span><b>{this.props.t("Companies:Wizard.Rate")}:</b>&nbsp;{isNaN(rate) || !isFinite(rate) ? DASH_CHARACTER : fourDigitsFormatter(rate)}</span>
                            </Field>
                        </>}
                    </FieldsWrapper>
                }
            </GridCell>
        </>;
    }
}

export default withAlert({
    autoHide: true
})(withRouter(withOData(withTranslation(["Common", "Companies"])(InitialAccountBalances))));