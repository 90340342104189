import React, { FocusEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BasicInputSizes, DatePickerView, IconSize } from "../../../enums";
import DateType, { DateFormat, DisplayFormat, getDateFormat } from "../../../types/Date";
import memoizeOne from "../../../utils/memoizeOne";
import { DateIcon } from "../../icon";
import { WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";
import {
    getSharedInputProps,
    IInputOnBlurEvent,
    IInputOnChangeEvent,
    IInputProps,
    InputWithErrorAndTooltip,
    ISharedInputProps
} from "../input";
import { withFormatter } from "../input/WithFormatter";
import DatePickerBase, { ICustomReferenceElement } from "./DatePickerBase";
import { DateRangeSpecialValue, TDisabledDateUnit } from "./popup/Calendar.utils";
import { DatePickerPopup, IProps as IDatePickerPopup } from "./popup/DatePickerPopup";
import { formatDateVariants, isMaxDay, isMinDay, longDateFormat } from "./utils";

export const DateInput = withFormatter<IInputProps & WithErrorAndTooltipProps, Date>({
    parser: (value, strict) => DateType.parse({ date: value, strictMode: strict }),
    formatter: DateType.format,
    // not defined values are valid when using for formatting -> formatted as empty string
    isValid: DateType.isValid,
    parseOnEnter: true
}, InputWithErrorAndTooltip);

export interface IPickerProps<Type = Date> extends ISharedInputProps {
    value?: Type;
    // if no value is set, this will be the focused date in picker popup when opened
    previewValue?: Date;
    onChange?: (e: IInputOnChangeEvent<Type>) => void;
    onBlur?: (args: IInputOnBlurEvent) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    format?: DateFormat | DisplayFormat;
    // DatePicker only props - makes sense also for others?
    defaultView?: DatePickerView;
    disableViewChange?: boolean;
    showSpecialValue?: DateRangeSpecialValue;
    minDate?: Date;
    maxDate?: Date;
    /** Content added to the end of the input. E.g. secondary warning icon */
    content?: React.ReactElement;
    isDateDisabled?: (date: Date, unit: TDisabledDateUnit) => boolean;
    workDate?: Date;
    className?: string;
    style?: React.CSSProperties;
}

class DatePicker extends React.Component<IPickerProps & WithTranslation> {
    static defaultProps: Partial<IPickerProps> = {
        format: DisplayFormat.DateMedium,
        width: BasicInputSizes.M
    };

    constructor(props: IPickerProps & WithTranslation) {
        super(props);

        this.formatter = this.formatter.bind(this);
    }

    get format(): string {
        // props.format is now ignored, since we changed from localized formats to user defined format in UserSetting
        const format = DateType.defaultDateFormat ?? this.props.format;

        return longDateFormat(getDateFormat(format));
    }

    get placeholder(): string {
        // TODO should we localize placeholder? How?
        return this.format?.toLowerCase();
    }

    formatter(value: Date): string {
        const formattedValue = DateType.format(value, this.format);

        if (this.props.showSpecialValue) {
            if (this.props.showSpecialValue === DateRangeSpecialValue.WithoutEnd && isMaxDay(this.props.value)) {
                return this.props.t("Common:Time.WithoutEnd");
            } else if (this.props.showSpecialValue === DateRangeSpecialValue.WithoutStart && isMinDay(this.props.value)) {
                return this.props.t("Common:Time.WithoutStart");
            }
        }

        return formattedValue;
    }

    parser = (value: string, strict: boolean): Date => {
        return DateType.parse({
            date: value,
            format: strict ? this.format : formatDateVariants(this.format),
            workDate: this.props.workDate
        });
    };

    isSame = (date1: Date, date2: Date): boolean => {
        if (date1 instanceof Date) {
            return DateType.isSameDay(date1, date2);
        }

        return date1 === date2;
    };

    get pickerPopup(): React.ComponentType<any> {
        return DatePickerPopup;
    }

    get icon() {
        return <DateIcon width={IconSize.M}/>;
    }

    get inputComponent(): React.ComponentType<IInputProps<Date>> {
        return DateInput;
    }

    get popoverOffsetX(): number {
        // to be overridden
        return null;
    }

    get popoverOffsetY(): number {
        // to be overridden
        return null;
    }

    getCustomReferenceElement = (args: ICustomReferenceElement): React.ReactNode => {
        // can be overridden in inherited class
        return null;
    };

    getPopupProps = memoizeOne((): Partial<IDatePickerPopup> => {
        return {
            value: this.props.value,
            defaultView: this.props.defaultView,
            disableViewChange: this.props.disableViewChange,
            showSpecialValue: this.props.showSpecialValue,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate,
            isDateDisabled: this.props.isDateDisabled
        };
    }, () => [this.props.value, this.props.defaultView, this.props.disableViewChange, this.props.showSpecialValue, this.props.minDate, this.props.maxDate]);

    getInputProps = memoizeOne(() => {
        return {
            formatter: !this.props.formatter ? this.formatter : (value: Date) => {
                // if custom formatter specified, give it the already pre-formatted value
                return this.props.formatter(this.formatter(value));
            },
            parser: this.parser,
            isSame: this.isSame
        };
    }, () => [this.formatter, this.parser, this.isSame, this.props.formatter]);

    render = () => {
        const popupProps = this.getPopupProps();
        const inputProps = this.getInputProps();

        return (
            <DatePickerBase {...getSharedInputProps(this.props)}
                            value={this.props.value}
                            popupProps={popupProps}
                            width={this.props.width}
                            onChange={this.props.onChange}
                            onBlur={this.props.onBlur}
                            onFocus={this.props.onFocus}
                            pickerPopup={this.pickerPopup}
                            inputComponent={this.inputComponent}
                            inputProps={inputProps}
                            customReferenceElement={this.getCustomReferenceElement}
                            popoverOffsetX={this.popoverOffsetX}
                            popoverOffsetY={this.popoverOffsetY}
                            icon={this.icon}
                            content={this.props.content}
                            placeholder={this.placeholder}
            />
        );
    };
}

const DatePickerExtended = withTranslation(["Components"])(DatePicker);

export { DatePickerExtended as DatePicker, DatePicker as DatePickerClean };