import { ifAny, IFieldDef, IGetValueArgs } from "@components/smart/FieldInfo";
import { isSystemEntity } from "@components/smart/GeneralFieldDefinition";
import { AccountEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { Sort } from "../../enums";
import { getDefinitions as chartOfAccountsDefinitions } from "../chartOfAccounts/ChartOfAccountsDef";
import { setDefByEntityType } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";
import ChartOfAccountsTemplatesFormView from "./ChartOfAccountsTemplatesFormView";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = cloneDeep(chartOfAccountsDefinitions(context, {
        tableId: `${EntityTypeName.ChartOfAccountsTemplate}Table`,
        formId: `${EntityTypeName.ChartOfAccountsTemplate}Form`,
        formControl: ChartOfAccountsTemplatesFormView,
        translationFiles: getDefinitions.translationFiles
    }));

    const removeProps = [AccountEntity.IsClosed, AccountEntity.IsActive] as string[];

    for (const prop of removeProps) {
        delete definition.table.filterBarDef[0].filterDefinition[prop];
        delete definition.table.columnDefinition[prop];
        delete definition.form.fieldDefinition[prop];
    }

    definition.table.columns =
        definition.table.columns.filter(item => !removeProps.includes(item));
    // We do not show title for chart of account templates, it's editable.
    definition.table.title = null;

    definition.table.filterBarDef[0].defaultFilters =
        definition.table.filterBarDef[0].defaultFilters.filter(item => !removeProps.includes(item));

    definition.table.parentDefinition = {
        sort: [{ id: "DateCreated", sort: Sort.Desc }],
        columns: [
            { id: "Name" },
            { id: "DateCreated" },
            { id: "DateLastModified" },
            { id: "AccountingCode" },
            { id: "CreatedBy/Name" },
            { id: "LastModifiedBy" }
        ],
        entitySet: EntitySetName.ChartOfAccountsTemplates
    };

    definition.form.groups[0].rows.forEach((row, idx) => {
        definition.form.groups[0].rows[idx] =
            row.filter(item => !removeProps.includes((item as IFieldDef).id));
    });
    // filter out empty rows
    definition.form.groups[0].rows = definition.form.groups[0].rows.filter(row => row.length);

    definition.form.translationFiles = ["ChartsOfAccountsTemplates"];
    const _baseReadOnly = definition.form.isReadOnly;
    definition.form.isReadOnly = ifAny(_baseReadOnly, isSystemEntity);

    definition.entitySet = (args: IGetValueArgs) => {
        let activeCOAId = args.data?.parentId;

        if (!activeCOAId) {
            // TODO how to show empty table without fake binding context
            activeCOAId = -1000;
        }

        return `${EntitySetName.ChartOfAccountsTemplates}(${activeCOAId})/Accounts`;
    };

    return definition;
};

getDefinitions.translationFiles = ["ChartsOfAccounts"];
setDefByEntityType(EntityTypeName.AccountTemplate, getDefinitions);