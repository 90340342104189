import { ISelectGroup, ISelectItem } from "@components/inputs/select/Select.types";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { IOrderBy } from "@odata/OData";
import { ICreateFilterStringSettings, IFormatOptions, IPrepareQuerySettings } from "@odata/OData.utils";
import { TTemporal } from "@odata/TemporalUtils";
import { TFieldDefinitionFactory } from "@pages/PageUtils";
import { isDefined, isNotDefined } from "@utils/general";
import { IOneFetch } from "@utils/oneFetch";
import i18next from "i18next";
import React from "react";
import { TestConfig, TestContext, ValidationError } from "yup";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import {
    CacheStrategy,
    FieldType,
    FieldVisibility,
    GroupedField,
    LabelStatus,
    ODataFilterFunction,
    RadioButtonGroupLayout,
    Status,
    TextAlign,
    ValidatorType,
    ValueType
} from "../../enums";
import { TRecordAny, TValue } from "../../global.types";
import { Model } from "../../model/Model";
import { IFilterQuery, TableStorage } from "../../model/TableStorage";
import { TValidatorSettings } from "../../model/Validator.types";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { DisplayFormat } from "../../types/Date";
import { FormStorage } from "../../views/formView/FormStorage";
import { IChangedFilter } from "../../views/table/TableView.utils";
import { IAlertProps } from "../alert/Alert";
import { DateRangeSpecialValue, TDisabledDateUnit } from "../inputs/date/popup/Calendar.utils";
import { IFormatterFns } from "../inputs/input/WithFormatter";
import { IRadioButtonDefinition } from "../inputs/radioButtonGroup/RadioButtonGroup";
import { ISwitchIconSet, SwitchType } from "../inputs/switch/Switch";
import { ISmartFieldRenderProps } from "./smartField/SmartField";
import { ISmartFieldEvents } from "./smartField/SmartFieldUtils";
import { IDependentFieldDef } from "./smartFormGroup/SmartFormGroup";
import { TExportFormatterFn, TFormatterFn } from "./smartTable/SmartTable.utils";

export type TValidatorMessageCallback = (args: Partial<TestConfig>) => string;
export type TValidatorFnResult = boolean | ValidationError;
export type TValidatorFn = (value: TValue, args: IGetValueArgs, testContext?: TestContext) => TValidatorFnResult | Promise<TValidatorFnResult>;
// other args is the original "info" object
export type TGetValueFn<type> = (args: IGetValueArgs, otherArgs?: TRecordAny) => type;
export type TInfoValue<type> = type | TGetValueFn<type>;

export interface IValidator {
    type: ValidatorType;
    settings?: TValidatorSettings;
}

export interface IFieldInfoFilter {
    /** Used as filter in oData request, when fetching select items */
    select?: TInfoValue<string>;

    /** Used as filter in oData request, when fetching data in table view.
     * Overrides default filter built by table view with custom filter query. */
    buildFilter?(item: IChangedFilter, settings: ICreateFilterStringSettings, storage: TableStorage): string | IFilterQuery;

    /** Used to transform filter value in reports in case we need different value of the
     * filter than the saved one. Similar behavior to buildFilter for oData */
    transformFilterValue?(value: TValue, info: IFieldInfo): TValue;

    /** Changes how the filter is build for null values.
     * False (default), expects the navigation value to be null, e.g. Items/any(x: x/Vat eq null)
     * True, expects the whole navigation to be empty, e.g. not(Labels/any())
     * */
    nullFilterMeansEmptyCollectionFilter?: boolean;
    /** Changes how filter is build for collections.
     * When false, collection filter is applied both as root filter and as filter in the expand of the collection.
     * When true, filter is only applied in root and not in the collection expand.
     * E.g.
     * false - $expand=Items($expand=LinkedDocument($filter=startswith(LinkedDocument/NumberOurs,'FAP2022000008'))&$filter=Items/any(x: startswith(x/LinkedDocument/NumberOurs,'FAP2022000008'))
     * true - $expand=Items($expand=LinkedDocument()&$filter=Items/any(x: startswith(x/LinkedDocument/NumberOurs,'FAP2022000008'))
     * */
    onlyApplyCollectionFilterOnRoot?: boolean;

    /**
     * When we use string filter, we may want to define different filter function (default is "StartsWith")
     */
    stringFilterFn?: ODataFilterFunction;

    /**
     * custom oData function applied on the column, e.g. "abs" or other supported mathematical function
     */
    oDataFn?: string;

    /** Used for composite value help filters like BankAccount which is made from AccountNumber and BankCode (and some other values...)
     * props should be relative to root binding context path.
     * groupByProperties are added to the query and used to create unique id for value help select items.
     * Use custom buildFilter method if you need some smart behavior for ConditionalDialog complex filter values.
     * */
    groupByProperties?: string[];
    /**
     * Used to define custom values (different from what is stored in "parsedValue" of TableStorage) for the read only values in FilterBar
     */
    customReadOnlyParsedValue?: TInfoValue<TValue>;
}

export interface IAffectedField {
    id: string;
    navigateFromParent?: boolean;
    reload?: boolean;
    // if set to true, validateField is gonna be called for this field
    revalidate?: boolean;
}

export interface ITableSettings {
    // needed for navigation properties to show correct value in table and excel export
    displayName?: string;
    hierarchy?: string;
    disableSort?: boolean;
}

export interface IFieldDef extends IFieldInfoProperties {
    id: string;
    factory?: TFieldDefinitionFactory;
    isCollectionField?: boolean;
}

export interface IInputSettings<Type = any> extends IFormatterFns<Type> {
    // time in ms, in which input sends normal onchange event but with triggerAdditionalChange flag
    debouncedWait?: number;
    placeholder?: TInfoValue<string>;
    unit?: TInfoValue<string>;
}

export interface ICustomizationData {
    // fields, which depends on the field. They are not used for customization, they are displayed always directly after
    // the field in order in this prop and cannot be customized
    dependents?: string[];
    useForCustomization?: TInfoValue<boolean>;
    // customization of groups -> group can be locked. In that case it's not possible to change its layout
    // (order of fields, add fields in it, etc...)
    isLocked?: boolean;
    // for some reason if the field is required for form (at least to be present but still available to be empty)
    isRequired?: TInfoValue<boolean>;
}

export interface ISmartEventData {
    storage: Model;
    bindingContext: BindingContext;
}

export interface ISelectSettings {
    // name of the column with display name to select components
    displayName?: string;
    // name of the property which is supposed to be used as 'id' in the items
    // by default, SmartSelect use key property of the current binding context (Id/Code)
    // currently used in Saldo.tsx to change id of items from Id to Number
    idName?: string;

    // static additional items
    additionalItems?: ISelectItem[];

    groups?: ISelectGroup[];

    // for multiselects and another M:N relations this field specifies path in collection to the ID of the item
    keyPath?: string;

    orderBy?: IOrderBy;
    // queryParams passed into Odata.query object (like charted=1)
    queryParams?: TInfoValue<Record<string, string>>;

    // defines custom entitySet from which the select items should be loaded
    // needed when we can't deduce the entitySet from the metadata
    entitySet?: TInfoValue<string>;
    isEnum?: boolean;

    // for cases entityset is defined and display name in entity and select's entity set is different -->
    // keep displayName correct with entity loaded and this display name is shown for select purposes
    entitySetDisplayName?: string;

    // default values for select type components
    items?: ISelectItem[];

    cacheFetchedItemsInFieldInfo?: boolean;
    itemsFactory?: (args: IGetValueArgs) => Promise<ISelectItem[]>;
    // itemsFactory for conditional dialog - we should have in separate property as itemsFactory is used for value help (same field definition)
    conditionalDialogItemsFactory?: (args: IGetValueArgs) => Promise<ISelectItem[]>;
    // itemsForRender is called on every render, can alter items shown by SmartSelect (e.g. filter out some unwanted items)
    itemsForRender?: (items: ISelectItem[], args: IGetValueArgs) => ISelectItem[];
    // transform items is called after fetch before the items are stored in fieldInfo
    transformFetchedItems?: (items: ISelectItem[], args: IGetValueArgs) => ISelectItem[];
    // endpoint for custom backend calls
    endpoint?: TInfoValue<string>;
    // called before items fetch,
    // very useful if the select is dependent on another data, and you need to load them before transformFetchedItems is called
    onBeforeRequest?: (args: IGetValueArgs) => Promise<void>;
    /** If true, select doesn't display no record found item when there is some nonexisting item typed */
    allowCreate?: boolean;
    /** Suffix for translation string for select for no selection values(we won't display empty field in such cases) */
    noRecordText?: string;
    dontDisplayNoDataFound?: boolean;
    // custom settings passed to prepareQuery when loading the smart select items
    querySettings?: IPrepareQuerySettings;
    // allows to specify search type -> Numbers, to get better search results for searching formatted/unformatted numbers
    // e.g. 1000 matches 1 000 etc...
    searchType?: ValueType;

    // fields affected by selection (usually field loaded for additional data)
    dependentFields?: IDependentFieldDef[];

    // dependent fields which are not requested from server but are searched in items additional data
    localDependentFields?: IDependentFieldDef[];

    // should we immediately change a value if user navigates in select by arrows?
    useAutoSelection?: boolean;
    // should we preselect items (highlight them and show the rest of the label selected in input,
    // so user can confirm it with tab
    useTypingForward?: boolean;

    // minimum characters to open basic select menu
    minCharsToOpenMenu?: number;

    // flag if tabular header should be displayed
    showTabularHeader?: boolean;

    // hide display all button for multi selects
    hideDisplayAll?: boolean;

    // for hierarchical multiselects check(uncheck) children(parents) based on checked hierarchy data
    shouldCheckParents?: boolean;
    shouldCheckChildren?: boolean;

    // for tabular selects, display additional (not display name) columns in input
    shouldDisplayAdditionalColumns?: boolean;

    // items for display information before original items are loaded
    initialItems?: ISelectItem[];

    // other properties to be retrieved by select and stored in select items, but not used as columns
    additionalProperties?: IFieldDef[];

    // in some cases current value is dynamically set based by item's values not just simple value (f.e. account assignment)
    // in such cases such value can be incorrect
    dontSetCurrentValueFromEntity?: boolean;

    // allow custom select handling in forms (custom icon, custom selection dialog
    onClick?: (e: React.MouseEvent, args: ISmartEventData) => void;
    onIconActivate?: (e: React.MouseEvent, args: ISmartEventData) => void;
    inputIcon?: React.ReactElement;

    // load items before open, in some cases it's better than handle default value manually
    preloadItems?: boolean;
    // Pass itemContent of the selected item into Input as contentBefore
    passItemContentToInput?: boolean;

    // there are case, where we want to display some custom tabular data for selected item,
    // which are not in the select menu. We can use this callback then.
    getCustomTabularData?: (val: TValue, args: IFormatOptions) => string[];

    trailingTextWithoutFocus?: TInfoValue<string>; // for smart label select (eg. "(Default)" after tokens)
    renderDefaultGroupWithoutCheckboxes?: boolean;
}

export enum AuditTrailFieldType {
    Difference = "Difference",
    NoDifference = "NoDifference",
    HoveredDifference = "HoveredDifference",
    HoveredNoDifference = "HoveredNoDifference"
}

export interface ISwitchSettings {
    type?: SwitchType;
    iconSet?: ISwitchIconSet;
}

export interface IFieldDefFn {
    storage: Model;
    fieldElement?: React.ReactElement;
    props?: ISmartFieldRenderProps;
    events?: ISmartFieldEvents;
}

export type TFieldDefFn = (args: IFieldDefFn) => React.ReactNode;


export interface IDateSettings {
    // if the picker doesn't have value assigned, preview value will be shown in the popup
    previewValue?: TInfoValue<Date>;
    // default date which is filled after user writes down only part of date (something like '0407')
    workDate?: TInfoValue<Date>;
    minDate?: TInfoValue<Date>;
    maxDate?: TInfoValue<Date>;
    showSpecialValue?: DateRangeSpecialValue;
    // custom callback to disable arbitrary values in the date popup
    isDateDisabled?: (date: Date, unit: TDisabledDateUnit) => boolean;
}

export interface ITokenFieldSettings {
    color?: string;
}

export interface IColorPickerSettings {
    colors?: string[];
}

export interface IReportFieldInfoSettings {
    /** Parameter is only for front end usage and will not be automatically send with the request */
    frontendOnly?: boolean;
    /** Id of the configuration group */
    groupId?: string;
    aggregationFunction?: string;
}

export interface IFilterSettings {
    /** Moves the filter field closer to the previous filter.
     * Only one use case now, if more were to come => crete filter groups. */
    groupWithPrevious?: boolean;
}

export interface IWriteLineSettings {
    isExtending?: boolean;
    maxLength?: number;
}

export interface INumericSettings {
    min?: number;
    max?: number;
    showSteppers?: boolean;
}

export interface IRadioButtonGroupSettings {
    definition?: IRadioButtonDefinition[];
    defaultChecked?: string;
    layout?: RadioButtonGroupLayout;
}

export interface IBusinessPartnerSelectSettings {
    loadFromDb?: boolean;
}

export interface ILabelSelectSettings {
    oneItemPerHierarchy?: boolean;
}

export interface ITextAreaSettings {
    maxRows?: number;
    minRows?: number;
}

export interface IEditableTextSettings extends ITextAreaSettings {
    showRequiredMark?: boolean;
    isResponsive? :boolean;
}

export interface ICheckBoxSettings {
    checkBoxFieldLabel?: string;
}

export interface ITemporalPropertySettings {
    temporalDialog?: {
        /** Title of the SmartTemporalPropertyDialog */
        dialogTitle?: string;
        /** If not specified, dialog will only contain the current field and DateValidFrom/DateValidTo.
         * This can be used to specify multiple fields that should be edited together.
         * !! WARNING !!
         * If multiple fields should be grouped together, columns has to be specified in each of the fields definitions.
         * This is so that when the fields are edited in the form, not from the dialog,
         * we still know about the relation between all of them.*/
        columns?: string[];
        /** change the type of DateValidFrom/To field */
        dateType?: FieldType;
        /** Only one item can be planned into the future */
        onlyOneFuturePlan?: boolean;
        // columns? for when there are multiple fields (temporal collection?)
        /** Every interval has to be connected to each other, can't plan into the future with a gap */
        withoutGaps?: boolean;
        /** Property cannot be removed, only changed to another value => DateValidTo should be disabled */
        cannotBeEnded?: boolean;
        /** Use to define the default value that is automatically uses for DateValidFrom,
         * so far we need either day (for current day) or month for start of the current month.
         * If something specials is needed, use defaultDateValidFrom instead.
         * "month" is used by default*/
        granularity?: "month" | "day";
        /** Custom default value for DateValidFrom for newly add line item */
        customDateValidFrom?: (items?: TTemporal[]) => Date;
        /** Custom callback to disable arbitrary values in the DateValidFrom popup */
        isCalendarDateDisabledDateValidFrom?: (date: Date, unit: TDisabledDateUnit) => boolean;
        /** Custom callback to disable arbitrary values in the DateValidTo popup */
        isCalendarDateDisabledDateValidTo?: (date: Date, unit: TDisabledDateUnit) => boolean;
        /** If value is somehow synchronized (that means there is null value in the temporalPropertyBag),
         * we need to replace it with real values when dialog is opened. This callback should do the job and
         * return the expanded values */
        extractHistory?: (storage: FormStorage, entity: IEntity, propertyBag: TTemporal[]) => Promise<TTemporal[]>;
    };
    /** Field value is taken from some other entity => field is supposed to look different */
    isSynchronized?: TInfoValue<boolean>;
}

export type TTemporalDialogSettings = ITemporalPropertySettings["temporalDialog"];

export interface IFieldSettings extends IInputSettings, ISelectSettings,
    ISwitchSettings, IDateSettings, ITableSettings, IReportFieldInfoSettings,
    IWriteLineSettings, IFilterSettings, ILabelSelectSettings, ICheckBoxSettings,
    INumericSettings, IColorPickerSettings, ITokenFieldSettings, IRadioButtonGroupSettings,
    IBusinessPartnerSelectSettings, ITextAreaSettings, IEditableTextSettings, ITemporalPropertySettings {

}

export enum AuditTrailFieldMode {
    Default = "Default",
    Hidden = "Hidden",
    DisplayedButIgnored = "Ignored"
}

export type TFieldDefaultValue = TValue | TValue[] | TRecordAny;

// TODO readme (+ storybook) with example and explanation of all the different properties
export interface IFieldInfoProperties {
    // type of control handling the field
    type?: FieldType;
    // type of the value that the field represents (e.g. Number, String, Date..)
    valueType?: ValueType;
    // arbitrary props passed down to the component (e.g. Switch type...)
    fieldSettings?: IFieldSettings;
    // label of the field
    label?: string;
    // another possible part of the label, used e.g. in configuration dialogs, like "Ulice (Korespondenční adresa)"
    description?: string | boolean;
    descriptionPath?: string; // custom description binding context path, e.g. for localContext fields
    showLabelDescription?: boolean;
    tooltip?: TInfoValue<string>;
    disableSort?: boolean;
    // adds extra content to Field before Input
    extraFieldContent?: TInfoValue<React.ReactElement>;
    // adds extra content to Field after Input
    extraFieldContentAfter?: TInfoValue<React.ReactElement>;
    tooltipAfter?: TInfoValue<string>;
    customFieldStyle?: React.CSSProperties;

    // default value of the field
    defaultValue?: TFieldDefaultValue | ((args: IGetValueArgs) => TFieldDefaultValue);
    // name of the column which the filters/autocomplete should use for filtering on the entity set. by default it's key property
    // can be multiple strings to force one filter definition to filter on multiple properties
    filterName?: string | string[];
    shouldNotFilterCollection?: boolean;
    displayFormat?: DisplayFormat;
    // used in Select to define popup columns and in Table to define meta columns
    columns?: IFieldDef[];
    // indicator for required
    isRequired?: TInfoValue<boolean>;
    // with of the wrapper fields&labels will be set to 100%. Set for controls solo in the row
    isFullRow?: boolean;
    // render field in disabled state => lowered opacity
    isDisabled?: TInfoValue<boolean>;
    // render field in readonly state => text only
    isReadOnly?: TInfoValue<boolean>;
    // validation object for yup validation
    validator?: IValidator;
    useForValidation?: boolean;
    // text shown (after click) for disabled items (similar to locked page)
    disabledText?: TInfoValue<string>;
    // formatter for returned values
    // Formatter for selects -> Select is formatted only twice:
    // A. setCurrentValueFromEntity on model when data are loaded
    // B. smart select load function when they are formatted
    // smart field DOES NOT format select values
    // for tabular selects formatter in main info (NOT COLUMN) is used ONLY for formatting selection of current value
    // for columns use formatting of columns themselves
    formatter?: TFormatterFn;
    // formatter for export -> can be used to export more columns than are displayed in table
    // e.g. document status has 3 icons in one table column, but we want to export it as 3 columns,
    // so user can work with single status values
    exportFormatter?: TExportFormatterFn;
    parser?: (val: string, args?: IFormatOptions) => TValue;
    isValid?: (value: TValue, args: IGetValueArgs) => boolean;
    // field visibility for edit or new
    fieldVisibility?: FieldVisibility;
    isVisible?: TInfoValue<boolean>;
    width?: TInfoValue<string>;
    labelStatus?: TInfoValue<LabelStatus>;
    // align of the field input and its label
    textAlign?: TextAlign;

    // other properties that should be retrieved from backend together with the main property
    additionalProperties?: IFieldDef[];

    // if false the field value is sent to BE even if the field is not visible
    // TRUE BY DEFAULT
    clearIfInvisible?: boolean;

    // array of ids which are re-rendered whenever user updates (blur) current field
    // use for dynamically changed properties by callback (required, visible)
    affectedFields?: IAffectedField[];

    /** Defines group of fields hidden in collapsed area after current field */
    collapsedRows?: (IFieldDef[])[];
    // if group is marked as 'creating' meaning its collapsed section content some data for new object,
    // this is the string to be rendered as title of the collapsible group
    creatingTitle?: string;

    // the value of this field will not be stored directly into the entity but to separate model, so it can be cancelled
    isConfirmable?: boolean;

    filter?: IFieldInfoFilter;

    // !!!! IMPORTANT!!!!!
    // due to the customization reasons , GROUPED FIELD MULTISTART AND MULTIEND MUST BE ORDERED IN A ROW WITHOUT ANY OTHER FIELDS AMONG THEM !!!!!!!!!!
    groupedField?: TInfoValue<GroupedField>;
    auditTrailMode?: AuditTrailFieldMode;
    cacheStrategy?: CacheStrategy;

    customizationData?: ICustomizationData;
    // used to check if value is different from orig entity (draft) and to compare in audit trail
    comparisonFunction?: (entity1: IEntity, entity2: IEntity, bc: BindingContext) => boolean;
    // used to ignore value in audit trail check
    useForComparison?: boolean;

    // property is related to BE values returned in evala.metadata (disabled flags)
    //      mappings for errors returned from backend are handled in form views getCorrectErrorBc
    // in some cases, BE use different path for field than we do on FE (+ our local context fields)
    // use this to map FE paths to BE paths
    backendPath?: string;

    render?: TFieldDefFn;
}

export interface IGetValueArgs<T = unknown> {
    data?: TRecordAny;
    item?: TRecordAny;
    context?: IAppContext;
    info?: IFieldInfoProperties;
    // remnants of IFieldCheckArgs
    bindingContext?: BindingContext;
    dataBindingContext?: BindingContext;
    storage?: Model<T>;
    fastEntryListId?: string;
    fetchFn?: IOneFetch;
}

export type TInfoValueProp =
    "isItemVisible"
    | "isRequired"
    | "isDisabled"
    | "isReadOnly"
    | "isRemovable"
    | "isVisible"
    | "isItemRemovable"
    | "isItemCloneable"
    | "isItemDisabled"
    | "isItemReadOnly"
    | "isItemSelectable"
    | "endpoint"
    | "select"
    | "entitySet"
    | "defaultValue"
    | "previewValue"
    | "dependentFields"
    | "filter"
    | "filterOperator"
    | "unit"
    | "tooltip"
    | "groupedField"
    | "isCollapsedVisible"
    | "isValueHelp"
    | "disabledAlert"
    | "title"
    | "hoverAlert"
    | "placeholder"
    | "link"
    | "secondaryBookmark"
    | "itemsSummaryRenderer"
    | "extraFieldContent"
    | "extraFieldContentAfter"
    | "labelStatus"
    | "tooltipAfter"
    | "disabledText"
    | "width"
    | "useForCustomization"
    | "useForValidation"
    | "isDeletable"
    | "workDate"
    | "minDate"
    | "maxDate"
    | "isSynchronized"
    | "queryParams"
    | "trailingTextWithoutFocus"
    | "customReadOnlyParsedValue";

export function isTGetValueFn(val: TInfoValue<unknown>): val is TGetValueFn<unknown> {
    return typeof val === "function";
}

function getValue<T = unknown>(value: TInfoValue<T>, args: IGetValueArgs, obj: TRecordAny): T {
    if (isTGetValueFn(value)) {
        return value(args, obj);
    }

    return value;
}

export const getInfoValue = (obj: TRecordAny, name: TInfoValueProp, args?: IGetValueArgs) => {
    if (isNotDefined(obj)) {
        return undefined;
    }

    return getValue(obj[name], args, obj);
};

export function allowCreateValues(info: IFieldInfoProperties): boolean {
    return info?.fieldSettings?.allowCreate || [FieldType.Autocomplete].includes(info?.type);
}

export function getInfoByPath(storage: FormStorage, path: string): IFieldInfo {
    const accAssBc = storage.data.bindingContext.navigate(path);
    return storage.getInfo(accAssBc);
}

export const isRequired = (args: IGetValueArgs): boolean => {
    if (args.info?.isRequired !== undefined) {
        return getInfoValue(args.info, "isRequired", args);
    }

    return args.bindingContext?.getProperty()?.isNullable() === false;
};

// for customization, we consider just existing condition for required field, so we don't allow to remove field,
// which might become required on certain conditions.
export const isRequiredForCustomization = (args: IGetValueArgs): boolean => {
    const { info } = args;
    if (info?.isRequired === false) {
        // in case we explicitly set false as isRequired, we don't want to check any other conditions
        return false;
    }
    if (isDefined(info?.customizationData?.isRequired)) {
        // required for customization could be conditional, but MUST NOT depend on formular data.
        // it should be consistent accross all use cases where the customization is applied, so pass only
        // context to distinguish e.g. VatPayer companies from non-VatPayer
        const context = args.context ?? args.storage?.context;
        return getInfoValue(info.customizationData, "isRequired", { context });
    }
    if (info?.isRequired !== undefined) {
        return true;
    }

    return args.bindingContext?.getProperty()?.isNullable() === false;
};

export const isFieldDisabledWithoutLock = (fieldInfo: IFieldInfo, storage: Model, bindingContext: BindingContext, fastEntryListId?: string): boolean => {
    return getInfoValue(fieldInfo, "isDisabled", {
        bindingContext,
        storage,
        context: storage?.context,
        data: storage?.data.entity,
        fastEntryListId
    });
};

export const isFieldDisabled = (fieldInfo: IFieldInfo, storage: Model, bc: BindingContext, fastEntryListId?: string): boolean => {
    if (!!(storage as FormStorage).getBackendDisabledFieldMetadata?.(bc)) {
        return true;
    }

    // used when lock or delete table action is active
    const isStorageDisabled = storage?.isDisabled;
    return isStorageDisabled || isFieldDisabledWithoutLock(fieldInfo, storage, bc, fastEntryListId);
};

export const isFieldReadOnly = (fieldInfo: IFieldInfo, storage: Model, bc: BindingContext) => {
    const isReadOnlyForm = (storage as FormStorage).isReadOnly;
    /**
     * Priority is a little tricky here. More situations:
     *  - we are forcing fiscalPeriod on document not to be readOnly even if set on BE
     *  - we have readOnly flag on form and some fields have special conditions also, e.g. on COA/COAHist form
     * Solution: In case whole form is readOnly, we respect that for every field. Otherwise we prioritize local config
     *  over the backend one.
     */
    if (isReadOnlyForm) {
        return true;
    }
    return getInfoValue(fieldInfo, "isReadOnly", {
        bindingContext: bc,
        storage,
        data: storage?.data.entity
    }) ?? bc.getProperty()?.isReadOnly();
};

export const isVisible = (args: IGetValueArgs): boolean => {
    const info = args.info ?? args.storage?.getInfo(args.bindingContext);
    const isVisible = getInfoValue(info, "isVisible", args);

    if (isDefined(isVisible)) {
        return isVisible;
    } else {
        // return true by default only when there is field info -> the field without fieldinfo is not visible
        return !!info;
    }
};

export const isVisibleByPath = (storage: FormStorage, path: string, data?: TRecordAny): boolean => {
    const bindingContext = storage.data.bindingContext.navigate(path);
    const info = storage.getInfo(bindingContext);
    return isVisible({
        storage, bindingContext, info, data, context: storage.context
    });
};

export const isReadOnlyByPath = (storage: FormStorage, path: string): boolean => {
    const bc = storage.data.bindingContext.navigate(path);
    const info = storage.getInfo(bc);
    return isFieldReadOnly(info, storage, bc);
};

export const isFieldDisabledByPath = (storage: FormStorage, path: string): boolean => {
    const bc = storage.data.bindingContext.navigate(path);
    const info = storage.getInfo(bc);
    return isFieldDisabled(info, storage, bc);
};

export const isEditableByPath = (storage: FormStorage, path: string, data?: TRecordAny): boolean => {
    return isVisibleByPath(storage, path, data) && !isReadOnlyByPath(storage, path) && !isFieldDisabledByPath(storage, path);
};

export function ifAny(...args: TInfoValue<boolean>[]): TGetValueFn<boolean> {
    return (...conditionArgs) => args.some(f => getValue<boolean>(f, ...conditionArgs));
}

export function ifAll(...args: TInfoValue<boolean>[]): TGetValueFn<boolean> {
    return (...conditionArgs) => args.every(f => isDefined(f) ? getValue<boolean>(f, ...conditionArgs) : true);
}

export function not(fn: TInfoValue<boolean>): TGetValueFn<boolean> {
    return (...conditionArgs) => !getValue<boolean>(fn, ...conditionArgs);
}

interface IDisabledProps {
    isDisabled: boolean;
    disabledAlert?: IAlertProps;
}

export const getDisabledProps = (obj: TRecordAny, args: IGetValueArgs): IDisabledProps => {
    const disabled = getInfoValue(obj, "isDisabled", args);
    let disabledAlert: IAlertProps;

    if (disabled) {
        const disabledAlertInfo = getInfoValue(obj, "disabledAlert", args);
        disabledAlert = disabledAlertInfo &&
            {
                isSmall: true,
                status: Status.Error,
                ...disabledAlertInfo
            };
    }

    return {
        isDisabled: !!disabled,
        disabledAlert
    };
};

export const getSimpleBoolSelectItems = (): ISelectItem[] => {
    return [
        {
            label: i18next.t("Common:General.Yes"),
            id: true
        },
        {
            label: i18next.t("Common:General.No"),
            id: false
        }
    ];
};

export function getDaysUnit({ storage, bindingContext }: IGetValueArgs): string {
    const count = storage.getValue(bindingContext) ?? 0;
    return storage.t("Common:Time.Days", { count });
}
