import { BusyIndicatorSize } from "@components/busyIndicator/BusyIndicator.utils";
import { ConfirmationDialog } from "@components/dialog/ConfirmationDialog";
import { WithPageLoader, withPageLoader } from "@components/pageLoader/pageLoaderContext";
import { GeneralPermissionCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import { CustomerPortal } from "@pages/home/CustomerPortal.utils";
import { isInCustomerPortal } from "@pages/home/Home.utils";
import { CacheCleaner } from "@utils/CacheCleaner";
import * as History from "history";
import { LocationListener, UnregisterCallback } from "history";
import { isEqual } from "lodash";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import { ErrorBoundary } from "../../components/errorBoundary";
import { IMenuSelected, NavMenu } from "../../components/navigation/NavMenu";
import { AppContext, AppMode, IAppContext } from "../../contexts/appContext/AppContext.types";
import { getLoginUrl, getLoginUrlWithRedirect, SessionType } from "../../contexts/authContext/Auth.utils";
import { WithAuthContext, withAuthContext } from "../../contexts/authContext/withAuthContext";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { CacheStrategy, QueryParam } from "../../enums";
import {
    filterMenu,
    findItemAndGroup,
    getMainMenuDefinition,
    IMenuDefGroup,
    NonMenuNavigations,
    OrganizationSettingsMenuDefinition,
    SettingsDefinition
} from "../../menu-def";
import {
    doesRouteSupportWizard,
    isCompanyRelatedRoute,
    isCustomerPortalRoute,
    isNonCustomerPortalRoute,
    isOrganizationSettingsRoute,
    isUniversalRoute,
    ROUTE_COMPANIES,
    ROUTE_DEVTOOLS,
    ROUTE_HOME,
    ROUTE_MENU,
    ROUTE_ORG_SETTINGS_PREFIX,
    ROUTE_WELCOME_SCREEN
} from "../../routes";
import { getQueryParameters } from "../../routes/Routes.utils";
import TestIds from "../../testIds";
import DateType, { getDevelTimeTravelDate, getUtcDate } from "../../types/Date";
import { ErrorPage } from "../errorPage/ErrorPage";
import { Content, FullPageBusyIndicator, LeftPane, RightPane } from "./Main.style";
import { MenuRoutesSearch } from "./MenuRoutesSearch";
import Shell from "./Shell";
import WrongEvalaVersionDialog from "./WrongEvalaVersionDialog";

interface IMainState {
    navWidth?: number;
    isMenu?: boolean;
}

interface IMainProps extends WithTranslation, WithPageLoader, RouteComponentProps, WithOData, WithPermissionContext, WithAuthContext {
}

class Main extends Component<IMainProps, IMainState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    refMenu = React.createRef<HTMLDivElement>();
    refRightPane = React.createRef<HTMLDivElement>();
    refDashboard = React.createRef<HTMLDivElement>();
    unregisterHistory: UnregisterCallback;

    currentUrl = "";

    constructor(props: IMainProps, context: IAppContext) {
        super(props);

        this.manageCompanyInUrl(props.location, props, context);

        if (this.redirectIfNeeded(props, context)) {
            return;
        }
    }

    redirectIfNeeded = (props: IMainProps, context: IAppContext): boolean => {
        const { history } = props;

        if (!!context.error || !!props.authContext.error) {
            // in this case we main.tsx renders bat animation
            return false;
        }

        if (!context.getData()?.companies?.length && isCompanyRelatedRoute(history.location.pathname)) {
            history.replace(ROUTE_WELCOME_SCREEN);

            return true;
        }

        if (context.getAddingNewCompany() && !doesRouteSupportWizard(history.location.pathname)) {
            history.replace(ROUTE_COMPANIES);

            return true;
        }

        return false;
    };

    getViewPath = (pathname: string, index?: number) => {
        return pathname?.split("/").slice(0, index ?? 2).join("/");
    };

    componentDidMount() {
        this.setState({
            navWidth: this.refMenu.current.clientWidth
        });
        this.manageUrl();
        this.unregisterHistory = this.props.history.listen(this.handleUrlChange);
    }

    componentDidUpdate(prevProps: Readonly<IMainProps>, prevState: Readonly<IMainState>) {
        if (this.redirectIfNeeded(this.props, this.context)) {
            return;
        }

        this.manageUrl(prevProps);
        // route has changed
        if (this.props.location.pathname !== prevProps.location.pathname) {
            CacheCleaner.clear(CacheStrategy.Route);
        }
        const view = this.getViewPath(this.props.location.pathname);
        const prevView = this.getViewPath(prevProps.location.pathname);

        if (view !== prevView) {
            CacheCleaner.clear(CacheStrategy.View);
        }

        const viewBreadcrumbs = this.context.getViewBreadcrumbs();

        if (viewBreadcrumbs && this.props.location.pathname !== viewBreadcrumbs.relatedPathname) {
            // clear view breadCrumbs on page change, because views don't clean up after them themselves
            this.context.setViewBreadcrumbs({ items: [], lockable: false });
        }
    }

    componentWillUnmount() {
        this.unregisterHistory();
    }

    // todo instead of query param use url path (either as prefix or as suffix to the route)
    // places that already use this pattern
    // - NotificationsContext.getNotificationUrl
    // - CompanySelector.handleChange
    // - CompanySelector.handleCompanyClick
    // - Shell.renderIconsGroups.homeRoute
    // manageCompanyInUrl should be called before rendering and thus hopefully not cause pointless re-renders
    //  - actually, it seems that it is only called before rendering when we navigate to another page via link/click/history.push/replace
    //  - when we navigate to another page via browser back/forward buttons, it is called AFTER already rendering Page
    // todo merge with manageUrl?
    manageCompanyInUrl = (location: History.Location<unknown>, props: IMainProps, context: IAppContext) => {
        const queryParams = getQueryParameters({ historyLocation: location });
        const currentCompanyId = context.getCompanyId();
        let isCompanyInUrl = false;
        let companyId: number;

        if (queryParams.CompanyId) {
            companyId = parseInt(queryParams.CompanyId);

            if (isNaN(companyId)) {
                companyId = currentCompanyId;
            } else if (companyId) {
                if (companyId !== currentCompanyId) {
                    const company = context.getData()?.companies?.find(c => c.Id === companyId);

                    if (company) {
                        isCompanyInUrl = true;
                        context.setCurrentCompanyId(companyId);
                    } else {
                        // todo what to do when wrong company in url
                    }
                } else {
                    isCompanyInUrl = true;
                }
            }
        }

        if (!isCompanyInUrl) {
            // Home can be without company id - tenant dashboard, org pages as well
            if ((location.pathname !== ROUTE_HOME && !location.pathname.startsWith(ROUTE_ORG_SETTINGS_PREFIX)) || CustomerPortal.isActive) {
                companyId = companyId ?? currentCompanyId;

                if (companyId) {
                    const newQueryParams = new URLSearchParams({
                        ...queryParams,
                        [QueryParam.CompanyId]: companyId.toString()
                    });
                    props.history.replace({ ...location, search: newQueryParams.toString() });
                }
            } else if (currentCompanyId !== null && !(context.getAddingNewCompany() && doesRouteSupportWizard(this.props.history.location.pathname))) {
                context.setCurrentCompanyId(null);
            }
        }
    };

    handleUrlChange: LocationListener = (location, action): void => {
        this.manageCompanyInUrl(location, this.props, this.context);
    };

    manageUrl = (prevProps?: IMainProps) => {
        const { pathname } = this.props.location;
        if (pathname !== prevProps?.location.pathname) {
            const isOrgSettingsPath = isOrganizationSettingsRoute(pathname);
            const wasOrgSettingsPath = isOrganizationSettingsRoute(prevProps?.location?.pathname) || prevProps?.location?.pathname === ROUTE_HOME;
            const cleanPath = isOrgSettingsPath ? pathname.slice(ROUTE_ORG_SETTINGS_PREFIX.length) : pathname;
            const appMode = this.context.getAppMode();
            const isMenuPath = cleanPath.startsWith(ROUTE_MENU);

            // handle toggle of customer portal if needed
            const _inCustomerPortal = isInCustomerPortal();
            if ((_inCustomerPortal && isNonCustomerPortalRoute(pathname)) || (!_inCustomerPortal && isCustomerPortalRoute(pathname))) {
                const currentCompanyId = this.context.getCompanyId() ?? this.context.getData()?.companies?.[0]?.Id;
                if (!_inCustomerPortal && currentCompanyId) {
                    // switch to customer portal and set current company
                    CustomerPortal.isActive = true;
                } else {
                    if (this.props.authContext.sessionType === SessionType.Customer) {
                        // we can't turn CustomerPortal "off" as we have customer session
                    } else {
                        // turn of customer portal as we don't know which company is active
                        CustomerPortal.isActive = false;
                    }
                }
                this.forceUpdate();
            }

            // Home path can be both (organization home or agenda home). We keep the previous settings in that case
            if (!this.context.getAddingNewCompany() && // don't change anything when new company wizard is running
                !isUniversalRoute(pathname) &&
                (isOrgSettingsPath !== wasOrgSettingsPath
                    || (!isOrgSettingsPath && appMode === AppMode.OrganizationSettings)
                    || (isOrgSettingsPath && appMode !== AppMode.OrganizationSettings))) {
                this.context.setAppMode(isOrgSettingsPath ? AppMode.OrganizationSettings : AppMode.Company);
            }

            if (!isMenuPath) {
                // drive menu selection only from current url instead of handling menu item click
                // this prevents wrong menu (breadcrumbs) values when using browser back/forward
                let selected: IMenuSelected;

                if (isOrgSettingsPath) {
                    selected = findItemAndGroup(OrganizationSettingsMenuDefinition, this.getViewPath(pathname, 3));
                } else {
                    // TODO: 🤷‍♂️
                    const correctPath = this.getViewPath(pathname, 3);
                    const correctPath2 = this.getViewPath(pathname, 2);
                    selected = findItemAndGroup(getMainMenuDefinition(this.context), correctPath)
                        || findItemAndGroup(SettingsDefinition, correctPath)
                        || findItemAndGroup(NonMenuNavigations, correctPath)
                        || findItemAndGroup(getMainMenuDefinition(this.context), correctPath2)
                        || findItemAndGroup(SettingsDefinition, correctPath2)
                        || findItemAndGroup(NonMenuNavigations, correctPath2);
                }

                if (!isEqual(selected, this.context.getSelectedMenu())) {
                    this.context.setSelectedMenu(selected);
                }
            } else {
                const allViews = this.getAllViews();
                const group = allViews.find(group => group.url === pathname) || this.getCurrentViews()[0];
                this.context.setSelectedMenu({ group, item: null });
            }
        }
    };

    handleMenuClick = (key: string, width: number) => {
        const group = this.getCurrentViews().find((item) => {
            return item.key === key;
        });

        this.currentUrl = group?.url;
        this.context.setSelectedMenu({
            ...this.context.getSelectedMenu(),
            group
        });

        this.props.history.push({
            pathname: this.currentUrl
        });
    };

    handleCompanySelectorClick = () => {
        if (this.context.getAddingNewCompany()) {
            // we want to be able to jump out of new company wizard to companies dashboard
            // since we use the same ROUTE_COMPANIES for both wizard and company dashboard,
            // we need to change the current company to OrganizationSettings
            this.context.setAppMode(AppMode.OrganizationSettings);
        }

        this.props.history.push(ROUTE_COMPANIES);
    };

    getAllViews = () => {
        return [...getMainMenuDefinition(this.context), ...OrganizationSettingsMenuDefinition];
    };

    getCurrentViews = () => {
        const context = this.context as IAppContext;

        if (context.getAddingNewCompany() || context.hasLimitedAccess()) {
            return [];
        }

        let views: IMenuDefGroup[];

        if (context.getAppMode() === AppMode.OrganizationSettings) {
            views = OrganizationSettingsMenuDefinition;
        } else {
            views = getMainMenuDefinition(this.context);
        }

        return filterMenu(views, this.context, this.props.permissionContext).filter(group => group.items?.length);
    };

    handleSessionInvalidatedDialogConfirm = async () => {
        const info = await getLoginUrl(getLoginUrlWithRedirect());
        if (info.isDomainRedirect) {
            window.location.href = info.url;
        } else {
            this.props.history.push(info.url);
        }
    };

    renderSessionInvalidatedDialog = () => {
        return (
            <ConfirmationDialog onClose={null}
                                onConfirm={this.handleSessionInvalidatedDialogConfirm}>
                {this.props.t("Common:General.SessionInvalidated")}
            </ConfirmationDialog>
        );
    };

    render() {
        const context = this.context as IAppContext;
        const currentViews = this.getCurrentViews();
        const canCreateCompany = this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.CompanyManagement);
        const hasCompanies = !!context.getData().companies?.length;
        const shouldRenderMenu = !isInCustomerPortal() && (currentViews?.length || canCreateCompany || hasCompanies || context.hasLimitedAccess());
        const develTimeTravelDate = getDevelTimeTravelDate();
        const error = this.context.error ?? this.props.authContext.error;

        return (
            <>
                {process.env.NODE_ENV !== "production" && <MenuRoutesSearch/>}
                <LeftPane ref={this.refMenu}
                          isDisabled={this.context.getAddingNewCompany()}
                          isFullscreenMode={context.isFullscreenMode}>
                    <Shell/>
                    {shouldRenderMenu &&
                        <NavMenu
                            onMenuClick={this.handleMenuClick}
                            onCompanySelectorClick={this.handleCompanySelectorClick}
                            views={currentViews}/>}
                </LeftPane>
                <RightPane ref={this.refRightPane}>
                    <Content
                        _isInactiveCompany={this.context.getAddingNewCompany()}
                        data-testid={TestIds.Content}>
                        {/*TODO proper way to handle and reset error boundary, now key resets it every time url is changed*/}
                        <ErrorBoundary>
                            {this.props.oData && this.context.loaded && !error
                                ? this.props.children
                                : !error && <BusyIndicator size={BusyIndicatorSize.L} isDelayed/>}
                            {error && <ErrorPage/>}
                        </ErrorBoundary>
                    </Content>
                </RightPane>
                {this.props.authContext.isSessionLost && this.renderSessionInvalidatedDialog()}
                {this.props.authContext.isWrongEvalaVersion && <WrongEvalaVersionDialog/>}
                {this.props.isLoading && <FullPageBusyIndicator size={BusyIndicatorSize.L}/>}
                {develTimeTravelDate &&
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div style={{
                        position: "fixed",
                        color: "red",
                        bottom: "50px",
                        left: "78px",
                        backgroundColor: "white",
                        fontSize: "20px",
                        zIndex: "99999999999999999999",
                        padding: "5px",
                        border: "2px solid black",
                        cursor: "pointer"
                    }}
                         onClick={() => {
                             this.props.history.push(`${ROUTE_DEVTOOLS}/TimeTravel`);
                         }}>
                        Time travel: {DateType.format(getUtcDate(develTimeTravelDate))}
                    </div>
                }
            </>
        );
    }
}

export default withAuthContext(withPermissionContext(withTranslation(["Common", "Error"])(withPageLoader(withOData(withRouter(Main))))));

