import { uuidv4 } from "@utils/general";
import * as H from "history";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Prompt } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { FormStorage } from "../../views/formView/FormStorage";
import { WithConfirmationDialog, withConfirmationDialog } from "../dialog/withConfirmationDialog";

interface IProps extends WithTranslation, WithConfirmationDialog, RouteComponentProps {
    title?: string;
    storage: FormStorage;
}

// This is code for custom prompt, which was found very annoying and his service is no longer needed right now
class UnsavedChangesPrompt extends Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    _trigger: symbol;

    _isRedirecting: boolean;

    constructor(props: IProps) {
        super(props);
        // Define a unique global symbol to store a dialog trigger reference accessible via a string key
        this._trigger = Symbol.for(`__PreventTransitionPrompt_${uuidv4()}`);
    }

    /**
     * Attach global dialog trigger for this component
     * instance to our Symbol trigger
     */
    componentDidMount() {
        // @ts-ignore
        window[this._trigger] = this.show;
    }

    /**
     * Ensure we clean up and remove the reference
     * from the global object
     */
    componentWillUnmount() {
        // @ts-ignore
        delete window[this._trigger];
    }

    /**
     * Show the dialog. Invoked primarily from React Router transition
     * handler getUserConfirmation.
     *
     * @param allowTransitionCallback A function that accepts a flag whether or not to allow the route transition
     */
    show = async (allowTransitionCallback: (arg: boolean) => boolean) => {
        // there are cases then we change URL before this is unmounted so this may be triggered several times even
        // after dialog is closed (see Main.tsx, manageCompanyInUrl)
        // for this case we make sure this dialog is shown only once per TRUE confirmation of the dialog
        if (!this._isRedirecting) {
            const res = await this.confirmDialog();
            this._isRedirecting = res;
            allowTransitionCallback(res);
        }
    };

    confirmDialog = async () => {
        return await this.props.confirmationDialog.open({
            confirmText: this.props.t("Common:Form.DiscardChanges"),
            content: this.props.t("Common:Form.UnsavedChanges")
        });
    };

    /**
     * Handles the Router transition. Returns true if allowed
     * or the dialog trigger key to enable the dialog.
     */

    allowTransition = (location: H.Location, action: H.Action) => {
        if (location.pathname + location.search === this.props.location.pathname + this.props.location.search) {
            // ignore when url didn't change
            return true;
        }

        return (this.props.storage.loaded && this.props.storage.isDirty()) ? Symbol.keyFor(this._trigger) : true;
    };


    render() {
        return (
            <>
                {/* React Router prompt, callback will return true to allow transition or dialog key to prevent */}
                <Prompt when={true} message={this.allowTransition}/>
            </>
        );
    }
}

export default withTranslation("Common")(withRouter(withConfirmationDialog(UnsavedChangesPrompt)));
