import { ISelectItem } from "@components/inputs/select/Select.types";
import { ifAny, IFieldDefFn, IGetValueArgs, TGetValueFn } from "@components/smart/FieldInfo";
import { PhoneNumberDef, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import SmartTableManager from "@components/smart/smartTable/SmartTableManager";
import { ICellValueObject } from "@components/table";
import {
    CompanyRoleEntity,
    EntitySetName,
    EntityTypeName,
    GeneralRoleEntity,
    GeneralRolePermissionEntity,
    ICompanyEntity,
    IUserEntity,
    IUserStatusEntity,
    UserCompanyRoleEntity,
    UserEntity,
    UserGeneralRoleEntity
} from "@odata/GeneratedEntityTypes";
import { UserStatusCode } from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import i18next from "i18next";
import React from "react";
import { DefaultTheme } from "styled-components";

import DrillDownLink from "../../../components/drillDown";
import UserInitial from "../../../components/token/UserInitial";
import { DASH_CHARACTER } from "../../../constants";
import { BasicInputSizes, FieldType, LabelStatus, Sort, ValidatorType } from "../../../enums";
import { ColoredText } from "../../../global.style";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { IFilterQuery } from "../../../model/TableStorage";
import BindingContext, { createPath, IEntity } from "../../../odata/BindingContext";
import { ROUTE_USERS } from "../../../routes";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IChangedFilter, ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import CompanyRolesList from "./CompanyRolesList";
import PermissionsOverview from "./PermissionsOverview";
import { UserNameStyled } from "./Users.styles";
import {
    activePath,
    CompaniesPath,
    COMPANY_ROLE_LINES_PATH,
    CompanyRolesPermissionsOverviewPath,
    GeneralRolePermissionsOverviewPath,
    GeneralRolesPath,
    getCompanyRoleSelectItems,
    getUserNameInitials,
    getUserStatusColor,
    isUserOwner,
    IUsersFormCustomData,
    RoleIdPath,
    TUsersFormStorage,
    universalPersonNameFormatter
} from "./Users.utils";
import UsersFormView from "./UsersFormView";


interface IUserNameWithAvatarFormatterOptions {
    isBold?: boolean;
    withLink?: boolean;
    isBig?: boolean;
    suffix?: string;
    isInTable?: boolean;
}

export const userNameWithAvatarTableFormatter = (val: TValue, args?: IFormatOptions): (ICellValueObject | string) => {
    return userNameWithAvatarFormatter(val, args, { isInTable: true });
};

export const userNameWithAvatarFormatter = (val: TValue, args?: IFormatOptions,
                                            {
                                                isBold = true,
                                                withLink = false,
                                                isBig,
                                                suffix = "",
                                                isInTable = false
                                            }: IUserNameWithAvatarFormatterOptions = {
                                                isBold: true,
                                                withLink: false,
                                                suffix: "",
                                                isInTable: false
                                            }): (ICellValueObject | string) => {
    if (!val) {
        return "";
    }

    const user = args.entity as IUserEntity;
    const name = universalPersonNameFormatter(user);
    const shortCut = getUserNameInitials(user);
    const style: React.CSSProperties = {};

    if (isInTable && user.StatusCode === UserStatusCode.Inactive) {
        style.opacity = 0.5;
    }

    const content = (<>
        <UserInitial title={shortCut} tooltip={name} isBig={isBig} style={style}/>
        <UserNameStyled isBold={isBold} isBig={isBig} style={style}>{name}{suffix}</UserNameStyled>
    </>);

    const value = withLink
        ? <DrillDownLink to={`${ROUTE_USERS}/${args.entity.Id}`}>
            {content}
        </DrillDownLink>
        : content;

    return {
        value,
        tooltip: `${name}${suffix}`
    };
};

export function hasCompanies({ storage }: IGetValueArgs): boolean {
    return !!storage.context.getData().companies?.length;
}

function generalRoleNameFormatter(val: TValue, args: IFormatOptions): string {
    return (args.entity as IUserEntity).GeneralRoles?.[0]?.GeneralRole?.GeneralRoleName;
}

const isCompanyRolesDisabled: TGetValueFn<boolean> = (args) => {
    return !!(args.storage as FormStorage).getBackendDisabledFieldMetadata(args.storage.data.bindingContext.navigate(UserEntity.CompanyRoles));
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            UserEntity.FirstName,
            UserEntity.LastName,
            UserEntity.Email,
            UserEntity.Status,
            UserEntity.GeneralRoles
        ],
        filterDefinition: {
            FirstName: {},
            LastName: {},
            Email: {},
            ...withDisplayName(UserEntity.Status),
            [UserEntity.GeneralRoles]: {
                fieldSettings: {
                    displayName: `${UserGeneralRoleEntity.GeneralRole}/${GeneralRoleEntity.GeneralRoleName}`
                },
                filter: {
                    buildFilter: (item: IChangedFilter): IFilterQuery | string => {
                        return {
                            query: `${UserEntity.GeneralRoles}/any(x: startswith(x/${UserGeneralRoleEntity.GeneralRole}/${GeneralRoleEntity.GeneralRoleName},'${item.value}'))`
                        };
                    }
                }
            }
        },
        isValueHelp: true
    }];

    const table: ISplitPageTableDef = {
        filter: "Id gt 0",
        filterBarDef,
        id: `${EntityTypeName.User}Table`,
        columns: [
            UserEntity.FirstName,
            GeneralRolesPath,
            UserEntity.Email,
            UserEntity.Status
        ],
        columnDefinition: {
            FirstName: {
                label: i18next.t("Users:User"),
                width: BasicInputSizes.L,
                formatter: userNameWithAvatarTableFormatter,
                additionalProperties: [{ id: `/${UserEntity.LastName}` }]
            },
            Email: {},
            [GeneralRolesPath]: {
                label: i18next.t("Users:Role"),
                fieldSettings: {
                    disableSort: true
                },
                additionalProperties: [{
                    id: `${UserEntity.GeneralRoles}/${UserGeneralRoleEntity.GeneralRole}/${GeneralRoleEntity.GeneralRoleName}`
                }],
                formatter: generalRoleNameFormatter
            },
            [UserEntity.Status]: {
                fieldSettings: {
                    displayName: "Name"
                },
                formatter: (val, args) => {
                    const entity = args.entity as IUserEntity;
                    const title = entity.StatusCode !== UserStatusCode.RoleAcceptancePending ? val as string : i18next.t("Users:Form.InvitedToRoleOwner");
                    const color: keyof DefaultTheme = getUserStatusColor(entity.StatusCode as UserStatusCode);

                    const colorWrapper = <ColoredText color={color}> {title} </ColoredText>;

                    return { tooltip: title, value: colorWrapper };
                }
            }
        },
        title: i18next.t("Users:Title"),
        initialSortBy: [{ id: UserEntity.FirstName, sort: Sort.Asc }]
    };

    const summary: ISummaryItem[] = [
        { id: UserEntity.Name },
        {
            id: "Status",
            label: i18next.t("Users:Form.Status"),
            formatter: (val: TValue) => {
                if (val) {
                    const status = val as IUserStatusEntity;
                    return status.Code !== UserStatusCode.RoleAcceptancePending ? status.Name : i18next.t("Users:Form.InvitedToRoleOwner");
                }
                return DASH_CHARACTER;
            },
            colorFormatter: (val: TValue) => {
                const status = val as IUserStatusEntity;
                return getUserStatusColor(status?.Code as UserStatusCode);
            }
        }
    ];

    const form: IFormDef = {
        id: `${EntityTypeName.User}Form`,
        summary,
        title: i18next.t("Users:User"),
        translationFiles: getDefinitions.translationFiles,
        formControl: UsersFormView,
        isDeletable: true,
        additionalProperties: [{
            id: UserEntity.StatusCode
        }, {
            id: UserEntity.GeneralRoles,
            additionalProperties: [{
                id: UserGeneralRoleEntity.GeneralRole,
                additionalProperties: [{ id: GeneralRoleEntity.GeneralRoleName }, {
                    id: GeneralRoleEntity.GeneralRolePermissions,
                    additionalProperties: [
                        { id: GeneralRolePermissionEntity.PermissionCode },
                        { id: GeneralRolePermissionEntity.IsEnabled }
                    ]
                }]
            }]
        }, {
            id: UserEntity.CompanyRoles,
            additionalProperties: [{
                id: UserCompanyRoleEntity.CompanyRole,
                additionalProperties: [{ id: CompanyRoleEntity.CompanyRolePermissions }]
            }, {
                id: UserCompanyRoleEntity.Company
            }]
        }],
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("Users:NewUser"), storage.data.entity?.Name),
        fieldDefinition: {
            "FirstName": {},
            "LastName": {},
            "Email": {
                width: BasicInputSizes.L,
                validator: {
                    type: ValidatorType.Email
                }
            },
            "PhoneNumber": {
                ...PhoneNumberDef
            },
            [activePath]: {
                type: FieldType.Switch,
                label: i18next.t("Common:General.Active"),
                isVisible: (args: IGetValueArgs) => {
                    const entity = args.storage.data.entity as IUserEntity;
                    return [UserStatusCode.Inactive, UserStatusCode.Active].includes(entity.StatusCode as UserStatusCode);
                },
                isDisabled: (args: IGetValueArgs) => {
                    const entity = args.storage.data.entity as IUserEntity;
                    const isOwner = isUserOwner(entity);
                    const table = SmartTableManager.getTable("UsersTable");
                    const ownersList = table?.getState()?.rows && Object.values(table.getState().rows).filter(row => {
                        return row.customData?.entity && isUserOwner(row.customData.entity);
                    });

                    return isOwner && ownersList?.length === 1;
                }
            },
            [GeneralRolesPath]: {
                label: i18next.t("Users:Role"),
                isRequired: true,
                type: FieldType.ComboBox,
                fieldSettings: {
                    entitySet: EntitySetName.GeneralRoles,
                    displayName: GeneralRoleEntity.GeneralRoleName,
                    additionalProperties: [{ id: GeneralRoleEntity.GeneralRoleName }, {
                        id: GeneralRoleEntity.GeneralRolePermissions,
                        additionalProperties: [
                            { id: GeneralRolePermissionEntity.PermissionCode },
                            { id: GeneralRolePermissionEntity.IsEnabled }
                        ]
                    }],
                    itemsForRender: (items, args) => {
                        return items?.map(item => {
                            if (item.id === -1 && args.storage.context.getData().userSettings?.GeneralRoles?.[0]?.GeneralRole.Id !== -1) {
                                item.isDisabled = true;
                            }
                            return item;
                        });
                    }
                },
                formatter: generalRoleNameFormatter,
                isDisabled: (args) => {
                    const isDisabled = !!(args.storage as FormStorage).getBackendDisabledFieldMetadata(args.storage.data.bindingContext.navigate(UserEntity.GeneralRoles));

                    return isDisabled || (args.storage.getValueByPath("GeneralRoles")?.[0]?.GeneralRole.Id === -1 &&
                        args.storage.context.getData().userSettings?.GeneralRoles?.[0]?.GeneralRole.Id !== -1);
                },
                affectedFields: [{ id: GeneralRolePermissionsOverviewPath }, { id: CompanyRolesPermissionsOverviewPath }]
            },
            [GeneralRolePermissionsOverviewPath]: {
                type: FieldType.Custom,
                label: i18next.t("Users:Form.GeneralRolesList"),
                labelStatus: LabelStatus.Removed,
                // isVisible: not(isCustomerGeneralRole),
                render: (args: IFieldDefFn) => {
                    const hasRole = !!args.storage.data.entity?.GeneralRoles?.[0];
                    return (<>
                        {hasRole && <PermissionsOverview storage={args.storage as FormStorage}/>}
                    </>);
                }
            },
            [CompanyRolesPermissionsOverviewPath]: {
                type: FieldType.Custom,
                label: i18next.t("Users:Form.CompanyRolesList"),
                labelStatus: LabelStatus.Removed,
                isVisible: (args: IGetValueArgs) => {
                    const companyRoleLines = args.storage.getValueByPath(COMPANY_ROLE_LINES_PATH);
                    return !!companyRoleLines?.some((line: IEntity) => {
                        return !!line[CompaniesPath]?.length;
                    });
                },
                render: (args: IFieldDefFn) => {
                    return (
                        <CompanyRolesList
                            storage={args.storage as FormStorage<IUserEntity, IUsersFormCustomData>}/>);
                }
            },
            [createPath(COMPANY_ROLE_LINES_PATH, RoleIdPath)]: {
                type: FieldType.ComboBox,
                label: i18next.t("Users:Role"),
                isRequired: true,
                isCollectionField: true,
                width: BasicInputSizes.M,
                defaultValue: (args: IGetValueArgs) => {
                    return getCompanyRoleSelectItems(args.storage as TUsersFormStorage)
                        .find(item => !item.isDisabled)?.id;
                },
                fieldSettings: {
                    items: [],
                    itemsForRender: (origItems, args: IGetValueArgs) => {
                        return getCompanyRoleSelectItems(args.storage as TUsersFormStorage);
                    }
                },
                isDisabled: ifAny(isCompanyRolesDisabled, (args: IGetValueArgs) => {
                    return getCompanyRoleSelectItems(args.storage as TUsersFormStorage)?.length <= 1;
                })
            },
            [createPath(COMPANY_ROLE_LINES_PATH, CompaniesPath)]: {
                type: FieldType.MultiSelect,
                label: i18next.t("Users:ForAgenda"),
                isRequired: true,
                isCollectionField: true,
                width: BasicInputSizes.XL,
                isDisabled: (args: IGetValueArgs) => {
                    const isDisabled = isCompanyRolesDisabled(args);
                    const bc = args.bindingContext.getParent().navigate(RoleIdPath);
                    return isDisabled || !args.storage.getValue(bc);
                },
                fieldSettings: {
                    keyPath: "Id",
                    items: [],
                    itemsForRender: (origItems, args: IGetValueArgs) => {
                        const companies = args.storage.context.getData().companies;
                        const items: ISelectItem<number>[] = [];
                        const usedCompaniesIds = args.storage.getValueByPath(COMPANY_ROLE_LINES_PATH)?.reduce((usedIds: number[], line: IEntity) => {
                            if (line[BindingContext.NEW_ENTITY_ID_PROP] === args.bindingContext.getParent().getKey()) {
                                return usedIds; // don't disable selected item
                            }
                            const lineCompanies = line[CompaniesPath]?.map((c: ICompanyEntity) => c.Id) ?? [];
                            return [...usedIds, ...lineCompanies];
                        }, []) ?? [];
                        companies?.forEach((company: ICompanyEntity) => {
                            items.push({
                                label: company.Name,
                                id: company.Id,
                                isDisabled: usedCompaniesIds.includes(company.Id)
                            });
                        });
                        return items;
                    }
                }
            }
        },
        groups: [{
            id: "general",
            rows: [
                [{ id: UserEntity.FirstName }, { id: UserEntity.LastName }, { id: UserEntity.Email }, { id: UserEntity.PhoneNumber }, { id: activePath }]
            ]
        }, {
            id: "systemRoles",
            title: i18next.t("Users:SystemRole"),
            rows: [[{ id: GeneralRolesPath }]]
        }, {
            id: "systemPermissions",
            title: i18next.t("Users:SystemPermissions"),
            rows: [[{ id: GeneralRolePermissionsOverviewPath }]],
            customizationData: {
                isLocked: true
            }
        }, {
            id: COMPANY_ROLE_LINES_PATH,
            title: i18next.t("Users:AgendaRoles"),
            isVisible: hasCompanies,
            lineItems: {
                collection: COMPANY_ROLE_LINES_PATH,
                order: null,
                columns: [{ id: RoleIdPath }, { id: CompaniesPath }],
                isTheOnlyItemRemovable: true,
                canReorder: false,
                isItemCloneable: false,
                isDisabled: isCompanyRolesDisabled,
                isAddDisabled: (args: IGetValueArgs) => {
                    const { storage } = args;
                    const items = getCompanyRoleSelectItems(storage as TUsersFormStorage);
                    const rows = storage.getValueByPath(COMPANY_ROLE_LINES_PATH);
                    return (rows?.length ?? 0) === items?.length;
                }
            }
        }, {
            id: "companyRolesOverview",
            title: i18next.t("Users:CompanyPermissions"),
            isVisible: hasCompanies,
            rows: [[{ id: CompanyRolesPermissionsOverviewPath }]],
            customizationData: {
                isLocked: true
            }
        }]
    };

    return {
        entitySet: EntitySetName.Users,
        table,
        form
    };
};

getDefinitions.translationFiles = ["Users"];
setDefByEntityType(EntityTypeName.User, getDefinitions);