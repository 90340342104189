import React from "react";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { getAlertFromError } from "../../../views/formView/Form.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import BindingContext, { IEntity, TEntityKey } from "../../../odata/BindingContext";
import {
    CompanyPermissionEntity,
    CompanyRoleEntity,
    CompanyRolePermissionEntity,
    EntitySetName,
    GeneralRoleEntity,
    ICompanyEntity,
    ICompanyRoleEntity,
    ICompanyRolePermissionEntity,
    IGeneralRoleEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { WithAuthContext, withAuthContext } from "../../../contexts/authContext/withAuthContext";
import {
    activePath,
    CompaniesPath,
    COMPANY_ROLE_LINES_PATH,
    CustomerGeneralRoleId,
    GeneralRolesPath,
    getCompanyRoleSelectItems,
    IEmailInfoDetail,
    invitationInfoPath,
    IPermissionsWithCompanies,
    IUserEntityExtended,
    IUsersFormCustomData,
    ownerRoleConfirmInfoPath,
    RoleIdPath
} from "./Users.utils";
import { LanguageCode, TimeZoneCode, UserStatusCode } from "@odata/GeneratedEnums";
import { ActionType, addNewLineItem, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { cloneDeep } from "lodash";
import { NEW_ITEM_DETAIL, REST_API_URL } from "../../../constants";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import { isObjectEmpty } from "@utils/general";
import { Button, ButtonGroup } from "../../../components/button";
import SmartFormDeleteButton from "../../../components/smart/smartFormDeleteButton/SmartFormDeleteButton";
import { parseResponse } from "@odata/ODataParser";
import { FormStorage, IContextInitArgs } from "../../../views/formView/FormStorage";
import { Status } from "../../../enums";
import { IGetSmartHeaderCustomInfo } from "@components/smart/smartHeader/SmartHeader.utils";
import DateType, { DisplayFormat, getUtcDate } from "../../../types/Date";
import { CloseIcon } from "@components/icon";
import { AwaitedArray } from "../../../global.types";
import { hasCompanies } from "./UsersDef";

import { ODataError } from "@odata/Data.types";
import { ISelectItem } from "@components/inputs/select/Select.types";

const GET_INVITATION_INFO_URL = `${REST_API_URL}/invitation/Detailforuser`;
const RESEND_INVITATION_INFO_URL = `${REST_API_URL}/invitation/UpsertInvitation`;
const GET_OWNER_CONFIRM_INFO_URL = `${REST_API_URL}/ownerroledeferredupdater/details`;
const RESEND_OWNER_CONFIRM_INFO_URL = `${REST_API_URL}/ownerroledeferredupdater/resendEmail`;
const CANCEL_PENDING_OWNER_CONFIRM_URL = `${REST_API_URL}/ownerroledeferredupdater/cancelpendingupdate`;

class UsersFormView extends FormViewForExtend<IUserEntityExtended, IFormViewProps<IUserEntityExtended, IUsersFormCustomData> & WithAuthContext> {
    getAdditionalLoadPromise = (args: IContextInitArgs) => {
        const id = args.bindingContext.getKey();
        return [
            this.getCompanyRoles(),
            this.getInvitationInfo(id),
            this.getOwnerRoleConfirmationInfo(id)
        ];
    };

    getAdditionalResults(): AwaitedArray<ReturnType<UsersFormView["getAdditionalLoadPromise"]>> {
        return this.props.storage.data.additionalResults;
    }

    onAfterLoad = async () => {
        const { storage } = this.props;

        if (storage.data.bindingContext.isNew()) {
            const res = await storage.oData.getEntitySetWrapper(EntitySetName.GeneralRoles)
                .query()
                .filter(`${GeneralRoleEntity.IsDefaultRole} eq true`)
                .expand(GeneralRoleEntity.GeneralRolePermissions)
                    .fetchData<IGeneralRoleEntity[]>();

            if (res.value) {
                const [defaultRole] = res.value;
                this.entity.GeneralRoles = [{ GeneralRole: defaultRole }];
            }
        } else {
            const invitation = this.getAdditionalResults()[1];
            const ownerRoleConfirm = this.getAdditionalResults()[2];

            if (invitation && !isObjectEmpty(invitation)) {
                storage.setValueByPath(invitationInfoPath, invitation);
                storage.refresh();
            } else if (ownerRoleConfirm && !isObjectEmpty(ownerRoleConfirm)) {
                storage.setValueByPath(ownerRoleConfirmInfoPath, ownerRoleConfirm);
                storage.refresh();
            }

            storage.setValueByPath(activePath, this.entity.StatusCode === UserStatusCode.Active);
            this.setSavedCompanyRoles();
        }

        if (!this.entity?.CompanyRoles?.length && hasCompanies({ storage })) {
            addNewLineItem(storage as unknown as FormStorage, COMPANY_ROLE_LINES_PATH);
        }

        this.recalculatePermissions();

        storage.setValueByPath(GeneralRolesPath, storage.data.entity?.GeneralRoles?.[0]?.GeneralRole?.Id);
        return super.onAfterLoad();
    };

    getInvitationInfo = async (userId: TEntityKey): Promise<IEmailInfoDetail> => {
        if (!userId) {
            return null;
        }

        const res = await customFetch(`${GET_INVITATION_INFO_URL}/${userId}`);

        if (res.ok && res.status !== 204) {
            const emailInfo = await res.json();
            emailInfo.DateEmailSent = getUtcDate(emailInfo.DateEmailSent);

            return emailInfo as IEmailInfoDetail;
        }

        return null;
    };

    getOwnerRoleConfirmationInfo = async (userId: TEntityKey): Promise<IEmailInfoDetail> => {
        if (!userId || userId === NEW_ITEM_DETAIL) {
            return null;
        }

        const res = await customFetch(`${GET_OWNER_CONFIRM_INFO_URL}/${userId}`);

        if (res.ok && res.status !== 204) {
            const emailInfo = await res.json();
            emailInfo.DateEmailSent = getUtcDate(emailInfo.DateEmailSent);

            return emailInfo as IEmailInfoDetail;
        }

        return null;
    };

    getAllCompanyRolePermissions(companyRoleId: number) {
        const { companyRolesSelectItems } = this.props.storage.getCustomData();
        const crp = companyRolesSelectItems?.find(role => role.id === companyRoleId);
        return [...crp.additionalData.CompanyRolePermissions];
    }

    createCompanyRoleLine = (companyRoleId: number, companyIds: number[]): IEntity => {
        return BindingContext.createNewEntity(companyRoleId, {
            [RoleIdPath]: companyRoleId,
            [CompaniesPath]: companyIds.map(Id => ({ Id }))
        });
    }

    setSavedCompanyRoles = (): void => {
        const companyRoleLines: IEntity[] = [];
        this.entity?.CompanyRoles?.forEach((role) => {
            const companyRoleId = role.CompanyRole.Id;
            const companyId = role.Company.Id;
            const line = companyRoleLines.find((r) => r[RoleIdPath] === companyRoleId);
            if (line) {
                line[CompaniesPath] = [...line[CompaniesPath], { Id: companyId }];
            } else {
                companyRoleLines.push(this.createCompanyRoleLine(companyRoleId, [companyId]));
            }
        });
        this.props.storage.setValueByPath(COMPANY_ROLE_LINES_PATH, companyRoleLines);
    };

    getCompanyRoles = async (): Promise<void> => {
        const items: ISelectItem<number>[] = [];
        const companyRoles = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.CompanyRoles)
            .query()
            .select(CompanyRoleEntity.Id, CompanyRoleEntity.CompanyRoleName)
            .expand(CompanyRoleEntity.CompanyRolePermissions, (query => query.select(CompanyRolePermissionEntity.Id, CompanyRolePermissionEntity.IsEnabled)
                .expand(CompanyRolePermissionEntity.CompanyPermission, (q => q.select(CompanyPermissionEntity.Name, CompanyPermissionEntity.Code)))))
            .fetchData<ICompanyRoleEntity[]>();

        companyRoles?.value?.forEach((company: ICompanyRoleEntity) => {
            items.push({
                label: company.CompanyRoleName,
                id: company.Id,
                additionalData: {
                    CompanyRolePermissions: company.CompanyRolePermissions
                }
            });
        });
        this.props.storage.setCustomData({ companyRolesSelectItems: items });
    };

    getDefaultCompanyRoleLine() {
        const items = getCompanyRoleSelectItems(this.props.storage);
        const companyRoleId = items?.find(item => !item.isDisabled)?.id;
        return this.createCompanyRoleLine(companyRoleId as number, []);
    }

    handleGeneralRoleChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === GeneralRolesPath && e.triggerAdditionalTasks) {
            const { storage } = this.props;
            const previousRole = this.entity.GeneralRoles?.[0]?.GeneralRole?.Id;

            this.entity.GeneralRoles = [{ GeneralRole: { ...e.additionalData } }];

            if ((e.value === CustomerGeneralRoleId && previousRole !== CustomerGeneralRoleId)
                    || (e.value !== CustomerGeneralRoleId && previousRole === CustomerGeneralRoleId)) {
                const companyRoleLine = this.getDefaultCompanyRoleLine();
                this.props.storage.setValueByPath(COMPANY_ROLE_LINES_PATH, [companyRoleLine]);
                this.recalculatePermissions();
            }

            // storage.setDefaultValue(createPath(, RoleIdPath));
            storage.refreshGroupByKey("systemRoles");
        }
    };

    recalculatePermissions = (): void => {
        const permissions: IPermissionsWithCompanies[] = [];

        const companyRoles = this.props.storage.getValueByPath(COMPANY_ROLE_LINES_PATH);

        companyRoles?.forEach((companyRoleLine: IEntity) => {
            if (companyRoleLine[CompaniesPath]?.length) {
                const companyRoleId = companyRoleLine[RoleIdPath];
                const allPermissions = this.getAllCompanyRolePermissions(companyRoleId);

                allPermissions?.forEach((p: ICompanyRolePermissionEntity) => {
                    const permission = permissions.find((perm => perm.Code === p.CompanyPermission.Code));
                    if (!permission) {
                        permissions.push({
                            Code: p.CompanyPermission.Code,
                            Name: p.CompanyPermission.Name,
                            companies: p.IsEnabled ? companyRoleLine[CompaniesPath].map((c: ICompanyEntity) => c.Id) : []
                        });
                    } else if (p.IsEnabled) {
                        companyRoleLine[CompaniesPath].forEach((c: ICompanyEntity) => {
                            if (!permission.companies.includes(c.Id)) {
                                permission.companies.push(c.Id);
                            }
                        });
                    }
                });
            }
        });
        this.props.storage.setCustomData({ permissions });
        this.props.storage.refresh();
    };

    handleActiveChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === activePath) {
            this.props.storage.setValueByPath(UserEntity.StatusCode, e.value ? UserStatusCode.Active : UserStatusCode.Inactive);
        }
    };

    handleChange = (e: ISmartFieldChange): void => {
        this.handleGeneralRoleChange(e);
        this.handleActiveChange(e);
        this.props.storage.handleChange(e);
        this.forceUpdate();
    };

    onBeforeSave = (): IUserEntityExtended => {
        const entity = cloneDeep(this.entity);
        if (this.props.storage.data.bindingContext.isNew()) {
            entity.LanguageCode = LanguageCode.Czech;
            entity.TimeZoneCode = TimeZoneCode.UTC_1CentralEuropeanTime_Prague;
        }

        entity.CompanyRoles = [];
        const { origEntity } = this.props.storage.data;
        this.props.storage.getValueByPath(COMPANY_ROLE_LINES_PATH)?.forEach((line: IEntity) => {
            line[CompaniesPath]?.forEach((company: ICompanyEntity) => {
                // find the original role Id, so we can create correct PATCH request with only changes
                const origCompanyRole = origEntity.CompanyRoles?.find(origRole =>
                    origRole.Company?.Id === company.Id && origRole.CompanyRole?.Id === line[RoleIdPath]);

                entity.CompanyRoles.push({
                    Id: origCompanyRole?.Id,
                    Company: { Id: company.Id },
                    CompanyRole: { Id: line[RoleIdPath] }
                });
            });
        });

        return entity;
    };

    handleLineItemsAction = async (args: ISmartFastEntriesActionEvent) => {
        this.props.storage.handleLineItemsAction(args);
        if (args.actionType === ActionType.Remove) {
            this.recalculatePermissions();
        }
        this.props.storage.refresh();
    };

    handleLineItemsChange = (e: ISmartFieldChange) => {
        this.props.storage.handleLineItemsChange(e);
        this.recalculatePermissions();
        this.props.storage.refresh();
    };

    removeEmptyLines = (): void => {
        const companyRoleLines: IEntity[] = this.props.storage.getValueByPath(COMPANY_ROLE_LINES_PATH);
        this.props.storage.setValueByPath(COMPANY_ROLE_LINES_PATH, companyRoleLines?.filter(line => !!line[RoleIdPath] && line[CompaniesPath]?.length) ?? []);
    };

    handleCancelPendingRoleConfirm = async () => {
        await customFetch(`${CANCEL_PENDING_OWNER_CONFIRM_URL}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify(this.entity.Id)
        });


        await this.props.storage.reload();
        this.props.onAfterSave?.(true);
    };

    getCustomHeaderInfo = (): IGetSmartHeaderCustomInfo => {
        const invitation = this.props.storage.getValueByPath(invitationInfoPath) as IEmailInfoDetail;
        const roleConfirm = this.props.storage.getValueByPath(ownerRoleConfirmInfoPath) as IEmailInfoDetail;


        if (invitation) {
            return {
                infoTooltip: null,
                infoText: this.props.storage.t("Users:InvitationSent", {
                    email: invitation.Email,
                    date: DateType.localFormat(invitation.DateEmailSent),
                    time: DateType.localFormat(invitation.DateEmailSent, DisplayFormat.TimeShort)
                }).toString()
            };
        } else if (this.entity.StatusCode === UserStatusCode.RoleAcceptancePending && roleConfirm) {
            const text = this.props.storage.t("Users:OwnerRoleConfirmationSent", {
                email: roleConfirm.Email,
                date: DateType.localFormat(roleConfirm.DateEmailSent),
                time: DateType.localFormat(roleConfirm.DateEmailSent, DisplayFormat.TimeShort)
            }).toString();

            return {
                infoTooltip: null,
                infoText: (
                    <div>
                        {text}
                        <Button icon={<CloseIcon/>}
                                onClick={this.handleCancelPendingRoleConfirm}
                                isTransparent
                                style={{ marginLeft: "10px" }}>
                            {this.props.storage.t("Users:CancelInvite")}
                        </Button>
                    </div>
                )
            };
        } else {
            return null;
        }
    };

    resendInvitation = async (): Promise<void> => {
        const res = await customFetch(RESEND_INVITATION_INFO_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                TenantUserId: this.entity.Id
            })
        });

        if (res.ok) {
            const invitation = await this.getInvitationInfo(this.entity.Id);
            const alert = {
                status: Status.Success,
                title: this.props.storage.t("Common:Validation.SuccessTitle"),
                subTitle: this.props.storage.t("Users:ResendInvitationSuccess")
            };
            this.props.storage.setFormAlert(alert);
            this.props.storage.setValueByPath(invitationInfoPath, invitation);
            this.props.storage.refresh();
        } else {
            const error: ODataError = await parseResponse(res);
            this.props.storage.setFormAlert(getAlertFromError(error));
        }
    };

    resendOwnerConfirm = async (): Promise<void> => {
        const res = await customFetch(RESEND_OWNER_CONFIRM_INFO_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify(this.entity.Id)
        });

        if (res.ok) {
            const ownerRoleConfirm = await this.getOwnerRoleConfirmationInfo(this.entity.Id);
            const alert = {
                status: Status.Success,
                title: this.props.storage.t("Common:Validation.SuccessTitle"),
                subTitle: this.props.storage.t("Users:ResendInvitationSuccess")
            };
            this.props.storage.setFormAlert(alert);
            this.props.storage.setValueByPath(ownerRoleConfirmInfoPath, ownerRoleConfirm);
            this.props.storage.refresh();
        } else {
            const error: ODataError = await parseResponse(res);
            this.props.storage.setFormAlert(getAlertFromError(error));
        }
    };

    renderButtons(): React.ReactElement {
        const isFormReadOnly = this.props.storage.isReadOnly;

        return (
            <ButtonGroup wrap={"wrap"}>
                <Button hotspotId={"formCancel"}
                        onClick={this.handleFormCancel}
                        isTransparent>{this.props.storage.t("Common:General.Cancel")}</Button>
                {this.isDeletable &&
                    <SmartFormDeleteButton storage={this.props.storage}
                                           onClick={this.handleDelete}/>
                }
                {!!this.props.storage.getValueByPath(invitationInfoPath) &&
                    <Button onClick={this.resendInvitation}
                            isTransparent>
                        {this.props.storage.t("Users:ResendInvitation")}
                    </Button>
                }
                {!!this.props.storage.getValueByPath(ownerRoleConfirmInfoPath) &&
                    <Button onClick={this.resendOwnerConfirm}
                            isTransparent>
                        {this.props.storage.t("Users:ResendInvitation")}
                    </Button>
                }
                {!isFormReadOnly &&
                    <Button hotspotId={"formSave"}
                            onClick={this.handleSaveClick}
                            isDisabled={this.shouldDisableSaveButton()}>
                        {this.props.storage.t("Common:General.Save")}
                    </Button>
                }
            </ButtonGroup>
        );
    }

    async save() {
        const result = await super.save();
        if (!result) {
            return result;
        }
        const isNew = this.props.storage.data.bindingContext.isNew();

        if (result && result.data?.Id === this.props.authContext.userId) {
            // if the current user has been changed, its permissions could have been edited
            // remove if BE actually fires WebSocketMessageTypeCode.SessionInvalidated
            // but it doesn't seem to happen now
            await this.props.permissionContext.refreshUserPermissions();
        }

        this.onAfterSave(isNew, false);
        this.forceUpdate(this.scrollPageDown);

        return result;
    }
}

export default withAuthContext(withPermissionContext(UsersFormView));