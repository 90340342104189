import { HOTSPOT_VIEW_ATTR } from "@components/hotspots/Hotspots.utils";
import React, { PropsWithChildren } from "react";
import SimpleBar from "simplebar-react";

import { IScrollBarProps, ScrollBar } from "../components/scrollBar/ScrollBar";
import TestIds from "../testIds";
import { FixedFooter, StyledView, StyledViewWrapper } from "./View.styles";

interface IProps {
    renderScrollbar?: boolean;
    scrollProps?: Partial<IScrollBarProps> & { ref?: React.Ref<SimpleBar> };
    nonScrollableContent?: React.ReactElement;
    withoutPadding?: boolean;

    // content rendered beneath the scrollable part
    fixedFooter?: React.ReactElement;
    isFullscreen?: boolean;

    testid?: string;
    hotspotContextId: string;

    onDragEnter?: (event: React.DragEvent) => void;
    onDragLeave?: (event: React.DragEvent) => void;
    onDragOver?: (event: React.DragEvent) => void;
    onDrop?: (event: React.DragEvent) => void;

    style?: React.CSSProperties;
    className?: string;
    passRef?: React.Ref<HTMLDivElement>;
}

/** Use this component in views to get correct padding and scrollbar */
export default class View extends React.PureComponent<PropsWithChildren<IProps>> {
    static defaultProps = {
        renderScrollbar: true
    };

    handleDragLeave = (event: React.DragEvent) => {
        const isDragLeaveLocal = (event.currentTarget as HTMLElement).contains(event.relatedTarget as HTMLElement);
        if (!isDragLeaveLocal) {
            this.props.onDragLeave?.(event);
        }
    };

    render() {
        let content = (
            <StyledView
                withoutPadding={this.props.withoutPadding}
                data-testid={this.props.testid ?? TestIds.View}
                {...{ [HOTSPOT_VIEW_ATTR]: this.props.hotspotContextId }}
                ref={this.props.passRef}
                style={this.props.style}
                onDragEnter={this.props.onDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.props.onDragOver}
                onDrop={this.props.onDrop}
                className={this.props.className}
            >
                {this.props.children}
            </StyledView>
        );

        if (this.props.renderScrollbar) {
            content = (
                <ScrollBar {...this.props.scrollProps}
                           isInFlex>
                    {content}
                </ScrollBar>
            );
        }

        return (
            <StyledViewWrapper withoutPadding={this.props.withoutPadding}
                               isFullscreen={this.props.isFullscreen}>
                {content}
                {this.props.fixedFooter
                    ? <FixedFooter withoutPadding={this.props.withoutPadding} data-testid={TestIds.Footer}>{this.props.fixedFooter}</FixedFooter>
                    : null}
                {this.props.nonScrollableContent}
            </StyledViewWrapper>
        );
    }
}