import React, { lazy } from "react";
import FormRoute from "../routes/FormRoute";
import {
    ROUTE_CATEGORIES,
    ROUTE_COMPANY_SETTING,
    ROUTE_CURRENCY_SETTINGS, ROUTE_DATA_BOX,
    ROUTE_FISCAL_YEAR,
    ROUTE_LABEL_HIERARCHIES,
    ROUTE_NUMBER_RANGES
} from "../routes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";
import PrivateRoute from "../routes/PrivateRoute";

const FiscalYear = lazy(() => import(/* webpackChunkName: "agendaSettings" */"../pages/fiscalYear/FiscalYear"));
const NumberRange = lazy(() => import(/* webpackChunkName: "agendaSettings" */"../pages/numberRange/NumberRange"));
const Labels = lazy(() => import(/* webpackChunkName: "agendaSettings" */"../pages/labelsHierarchy/labelsHierarchy"));
const CompanySetting = lazy(() => import(/* webpackChunkName: "agendaSettings" */"../pages/companies/CompanySetting"));
const CurrencyUsedByCompany = lazy(() => import(/* webpackChunkName: "agendaSettings" */"../pages/currencyUsedByCompany/CurrencyUsedByCompany"));
const Categories = lazy(() => import(/* webpackChunkName: "agendaSettings" */"../pages/cbaCategories/CbaCategories"));
const DataBox = lazy(() => import(/* webpackChunkName: "agendaSettings" */"../pages/dataBox/DataBox"));


export default function agendaSettingsBundle() {
    return [
        <FormRoute
                path={ROUTE_FISCAL_YEAR}
                key={ROUTE_FISCAL_YEAR}
                entityType={EntityTypeName.FiscalYear}
                permission={CompanyPermissionCode.CustomerSettings}
                page={FiscalYear}/>,
        <FormRoute
                path={ROUTE_NUMBER_RANGES}
                key={ROUTE_NUMBER_RANGES}
                entityType={EntityTypeName.NumberRangeDefinition}
                permission={CompanyPermissionCode.CustomerSettings}
                page={NumberRange}/>,
        <FormRoute
                path={ROUTE_LABEL_HIERARCHIES}
                key={ROUTE_LABEL_HIERARCHIES}
                parentType={EntityTypeName.LabelHierarchy}
                childCollection="Labels"
                permission={CompanyPermissionCode.CustomerSettings}
                entityType={EntityTypeName.Label}
                page={Labels}/>,
        <PrivateRoute
                path={ROUTE_COMPANY_SETTING}
                key={ROUTE_COMPANY_SETTING}
                permission={CompanyPermissionCode.CustomerSettings}
                component={CompanySetting}/>,
        <FormRoute
                path={ROUTE_CURRENCY_SETTINGS}
                key={ROUTE_CURRENCY_SETTINGS}
                permission={CompanyPermissionCode.CustomerSettings}
                page={CurrencyUsedByCompany}
                entityType={EntityTypeName.CurrencyUsedByCompany}
        />,
        <FormRoute
                path={ROUTE_CATEGORIES}
                key={ROUTE_CATEGORIES}
                permission={CompanyPermissionCode.CustomerSettings}
                entityType={EntityTypeName.CbaCategory}
                page={Categories}/>,
        <PrivateRoute
                path={ROUTE_DATA_BOX}
                key={ROUTE_DATA_BOX}
                permission={CompanyPermissionCode.CustomerSettings}
                component={DataBox}/>
    ];
}