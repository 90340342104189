import { ICustomBreadCrumb, THistoryBack } from "@components/breadCrumb";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { getSelectDisplayValue, isSelectBasedComponent } from "@components/inputs/select/SelectAPI";
import { AuditTrailFieldType, IFieldDef, isRequired, isVisible } from "@components/smart/FieldInfo";
import {
    ISmartFieldBlur,
    ISmartFieldChange,
    ISmartFieldTempDataActionArgs
} from "@components/smart/smartField/SmartField";
import {
    getBoundValue,
    getIndexedPathFromBindingContext,
    getUniqueContextsSuffixAsString,
    ICachedFieldState,
    ISetBoundValue,
    isNavigationForUpdate,
    IStorageSmartFieldValues,
    setBoundValue,
    updateDataOnChange
} from "@odata/Data.utils";
import { getFieldInfo, IFieldInfo } from "@odata/FieldInfo.utils";
import { OData } from "@odata/OData";
import { formatValue } from "@odata/OData.utils";
import { forEachKey, isDefined, isNotDefined, isObjectEmpty, uuidv4 } from "@utils/general";
import Emittery from "emittery";
import * as History from "history";
import { TFunction } from "i18next";
import { cloneDeep, debounce } from "lodash";
import React from "react";
import ReactRouter from "react-router-dom";
import { DefaultTheme } from "styled-components/macro";
import { ObjectSchema } from "yup";

import { IProps as IIconProps } from "../components/icon";
import { ContextEvents, IAppContext } from "../contexts/appContext/AppContext.types";
import { Status, ValidationErrorType } from "../enums";
import { TRecordAny, TValue } from "../global.types";
import BindingContext, { areBindingContextsDifferent, IEntity } from "../odata/BindingContext";
import { IFormDef } from "../views/formView/Form";
import { IContextInitArgs } from "../views/formView/FormStorage";
import { ISplitPageTableDef } from "../views/table/TableView.utils";
import { Validator } from "./Validator";
import { IValidationError } from "./Validator.types";

export enum ModelType {
    BaseModel = "basemodel",
    Form = "form",
    Table = "table"
}

export interface IModel {
    id: string;
    context?: IAppContext;
    bindingContext?: BindingContext;
    theme?: DefaultTheme;
    definition?: IFormDef | ISplitPageTableDef;
    oData?: OData;
    t?: TFunction;
    history?: History.History<IHistoryState>;
    match?: ReactRouter.match;
    refresh?: (refreshPage?: boolean) => void;

    // state received via history state
    initialHistoryState?: IHistoryState;
}

export enum AuditTrailLineComparison {
    Default = "Default",
    AdditionalRow = "AdditionalRow",
    MissingRow = "MissingRow"
}

export interface IAuditTrailLineData {
    order?: number;
    orderChanged?: boolean;
    type: AuditTrailLineComparison;
}

export interface IAuditTrailData {
    lineData?: IAuditTrailLineData;
    type?: AuditTrailFieldType;
    // hashtable with paths of not matching items in multifileds(checkbox group,...)
    missmatchedPaths?: Record<string, boolean>;
}

export interface IValidationResult {
    status: Status;
    message: string;
    icon?: React.ComponentType<IIconProps>;
}

export interface IAdditionalData {
    error?: IValidationError;
    dirty?: boolean;
    parsedValue?: TValue;
    selectedItems?: ISelectItem[];
    auditTrailData?: IAuditTrailData;
    isBusy?: boolean;
    validationResult?: IValidationResult;
    currentFieldState?: ICachedFieldState;
}

export type TMergedDefinition = Record<string, IMergedFieldDef>;

export interface IMergedFieldDef {
    groupId?: string;
    path: string;
    useForValidation?: boolean;
    useForLoad?: boolean;
    /** Required fields that are not present in current variant are still add to mergedDefinition,
     * so that their default values are set or their values are fetched during loading.
     * This property is stored to easily find out if the fields is part of the variant without having to run complicated search through the whole variant definition. */
    isNotInVariant?: boolean;
    fieldDef?: IFieldDef;
}

interface ITemporalData {
    value?: TValue;
    additionalFieldData?: IAdditionalData;
    // not the same as additionalFieldData
    // additionalData can be part of ISmartFieldChange event
    // we need to store them, so that we can send it to onChange handler when temporal data are confirmed
    additionalData?: TRecordAny;
    // Original event the temporal data was set - we need it during confirmation, when we call/simulate handleChange
    //  which should be exactly same call as it would be called from the component, e.g. including selectedItems
    //  in multiselect change event, etc...
    originalEvent?: ISmartFieldChange;
}

export enum FieldAdditionalData {
    Error = "error",
    ParsedValue = "parsedValue",
    CurrentValue = "currentValue",
    AuditTrailData = "auditTrailData"
}

export interface IGetCurrentValue {
    info?: IFieldInfo;
    processMultiValue?: boolean;
}

interface IGetValueOptions {
    useDirectValue?: boolean;
    skipTemporaryValue?: boolean;
    dataStore?: TRecordAny;
}

export interface IModelData<E extends IEntity = IEntity, C extends TRecordAny = object> {
    uuid?: string;
    // main data object
    entity?: Partial<E>;
    fieldsInfo?: Record<string, IFieldInfo>;
    // definition?: IDefinition;
    bindingContext?: BindingContext;
    // data related to fields (everything but the value it self which is in 'entity' object)
    // stored by bindingContext path
    additionalFieldData?: Record<string, IAdditionalData>;
    // for arbitrary use from the view controllers
    // basically anything that doesn't belong somewhere else in 'data' belongs here
    customData?: C;

    temporalData?: Record<string, ITemporalData>;
}

export interface IActiveField {
    reload?: boolean;
    revalidate?: boolean;
}

export interface IAfterSaveEventArgs {
    isNew: boolean;
    bindingContext: BindingContext;
}

export enum ModelEvent {
    AfterLoad = "after-load",
    AfterSave = "after-save",
    FilterChanged = "filter-changed",
    FiltersCleared = "filters-cleared",
    GroupStatusChanged = "group-status-changed",
    LineItemsAction = "line-items-action",
    RecalculateScrollbar = "recalculate-scrollbar",
    RecalculateFastEntryList = "recalculate-fast-entry-list",
    FilesUploaded = "files-uploaded",
    CustomFileAction = "custom-file-action",
    RequestCreateDraft = "request-create-draft",
    VariantChanged = "variant-changed"
}

type TRefComponent = React.Component<{ bindingContext?: BindingContext } & any>

export interface IHistoryState {
    back?: THistoryBack;
    // could be used instead of filters in url
    // drillDownFilters?: TRecordValue;
    // for drilldown and intent navigation, we want to display full breadcrumbs history
    breadCrumbs?: ICustomBreadCrumb[];
    // e.g. "created" in ChartOfAccountsTemplates
    customData?: any;
}

// use ModelData generic to enforce correct types on "store" method
// TODO better default value for C generic, object doesn't catch setCustomData calls on storage without generic set
export class Model<ModelData extends IModelData = IModelData, E extends IEntity = IEntity, C extends TRecordAny = object> {
    id: string;

    oData: OData;
    history: History.History<IHistoryState>;
    match: ReactRouter.match;
    context: IAppContext;
    theme: DefaultTheme;
    t: TFunction;

    refreshSync: (refreshPage?: boolean) => void;
    // debounced
    refresh: (refreshPage?: boolean) => void;
    refs: Record<string, TRefComponent[]>;
    customRefs: Record<string, TRefComponent[]>;
    activeFields: Record<string, IActiveField>;
    data: IModelData = {};
    loading: boolean;
    afterLoaded: boolean;
    type: ModelType = ModelType.BaseModel;
    emitter = new Emittery();


    _validationSchema: ObjectSchema<unknown>;
    _fullRefreshWhenUpdate = false;

    // state received via history state
    initialHistoryState: IHistoryState;

    constructor(args: IModel) {
        this.id = args.id;
        this.context = args.context;
        this.theme = args.theme;
        this.oData = args.oData;
        this.history = args.history;
        this.match = args.match;
        this.t = args.t;
        this.refreshSync = (refreshPage?: boolean) => {
            this.clearActiveFieldsOnRefresh();
            args.refresh?.(refreshPage);
        };
        this.refresh = debounce(this.refreshSync);

        this.refs = {};
        // refs fires only when trigger additional task is true
        this.customRefs = {};
        this.activeFields = {};
        this.data = {
            entity: {},
            bindingContext: args?.bindingContext,
            fieldsInfo: {},
            temporalData: {},
            additionalFieldData: {}
        };

        this.initialHistoryState = args.initialHistoryState;

        // company can be changed without unmounting rest of the page
        // => rerender all models that can rely on company data
        if (this.context) {
            this.context.eventEmitter?.on(ContextEvents.CompanyUpdated, this.handleCompanyUpdate);
        }

        this.getInfo = this.getInfo.bind(this);
    }

    async onAfterLoad(): Promise<void> {
        await this.emitter.emit(ModelEvent.AfterLoad);
    }

    handleCompanyUpdate = () => {
        this.refresh();
    };

    getAdditionalLoadPromise(args: IContextInitArgs): (Promise<unknown> | void)[] {
        return undefined;
    }

    // todo improve interfaces and generics,
    // remove this function  if we manage to use correct generics in functions like updateDataOnChange
    // this is just hack to make TS work without them
    getThis = (): Model => {
        return this as unknown as Model;
    };

    getEntity<Entity = Partial<E>>(): Entity {
        return this.data.entity as Entity;
    }

    /** Stores given given object into the models data object */
    store = <K extends keyof ModelData>(newData: (Pick<ModelData, K> | ModelData | null)): void => {
        this.data = {
            ...this.data,
            ...newData
        };
    };

    /** Stores given value into the 'name' property of the models data object */
    storeSingleValue = (name: keyof IModelData, value: any) => {
        this.storeSingleValueWithoutCheck(name, value);
    };

    storeSingleValueWithoutCheck = (name: string, value: any): void => {
        this.data = {
            ...this.data,
            [name]: value
        };
    };

    // TODO store vs set

    /** Sets given object into the customData object */
    setCustomData = <K extends keyof C, >(customData: Pick<C, K> | C): void => {
        this.data.customData = {
            ...this.data?.customData,
            ...customData
        };
    };

    getCustomData<CustomData = Partial<C>>(): CustomData {
        return this.data.customData as CustomData ?? {} as unknown as CustomData;
    }

    // overridden in extended classes
    get isDisabled(): boolean {
        return false;
    }

    setDirty = (bc: BindingContext): void => {
        this.setAdditionalFieldData(bc, "dirty", true, false);
    };

    isDirty = (bc: BindingContext = this.data.bindingContext): boolean => {
        return Boolean(this.getAdditionalFieldData(bc, "dirty"));
    };

    isDirtyPath = (path: string = null): boolean => {
        let bc = this.data.bindingContext;
        if (path) {
            bc = bc.navigate(path);
        }
        return this.isDirty(bc);
    };

    setAdditionalFieldData = <K extends keyof IAdditionalData>(bc: BindingContext, where: K, newValue: IAdditionalData[K], addToActiveField = true): void => {
        if (addToActiveField) {
            this.addActiveField(bc);
        }

        const path = bc.getFullPath();
        const field = this.data.additionalFieldData[path];

        if (!field) {
            this.data.additionalFieldData[path] = {
                [where]: newValue
            };
        } else {
            field[where] = newValue;
        }
    };

    /**
     * Completely remove all additional field data for given bc
     * if the bc has key (points to entity), removes additional data for all of its properties
     */
    clearAdditionalFieldData = (bc: BindingContext): void => {
        const fullPath = bc.getFullPath();

        if (!bc.getKey()) {
            this.clearAdditionalFieldDataByPath(fullPath);
        } else { // remove errors on all properties of given entity
            this.clearCollectionAdditionalFieldDataByPath(fullPath);
        }
    };

    clearAdditionalFieldDataByPath = (path: string): void => {
        delete this.data.additionalFieldData[path];
    };

    clearCollectionAdditionalFieldDataByPath = (path: string): void => {
        for (const fullPath of Object.keys(this.data.additionalFieldData)) {
            if (fullPath.startsWith(path)) {
                delete this.data.additionalFieldData[fullPath];
            }
        }
    };

    getAdditionalFieldData = <K extends keyof IAdditionalData>(bc: BindingContext, name: K): IAdditionalData[K] => {
        const field = this.data.additionalFieldData[bc.getFullPath()];
        if (field) {
            return field[name];
        }

        return null;
    };

    getAdditionalFieldDataByPath = (path: string, name: keyof IAdditionalData): IAdditionalData[keyof IAdditionalData] => {
        return this.getAdditionalFieldData(this.data.bindingContext.navigate(path), name);
    };

    getError = (bc: BindingContext): IValidationError => {
        return this.data.additionalFieldData?.[bc.getFullPath()]?.error;
    };

    getErrorByPath = (path: string): IValidationError => {
        return this.getError(this.data.bindingContext.navigate(path));
    };

    setError = (bc: BindingContext, newValue: IValidationError): void => {
        this.setAdditionalFieldData(bc, "error", newValue);
    };

    setErrorByPath = (path: string, newValue: IValidationError): void => {
        this.setError(this.data.bindingContext.navigate(path), newValue);
    };

    hasError = (bc: BindingContext): boolean => {
        return !!this.data.additionalFieldData?.[bc.getFullPath()]?.error;
    };

    hasErrorByPath = (path: string): boolean => {
        return this.hasError(this.data.bindingContext.navigate(path));
    };

    isAnyError = (): boolean => {
        return Object.values(this.data.additionalFieldData).some(fieldData => fieldData.error);
    };

    clearError = (bc: BindingContext): void => {
        this.setAdditionalFieldData(bc, "error", null);
    };

    clearErrorByPath = (path: string): void => {
        return this.clearError(this.data.bindingContext.navigate(path));
    };

    clearAllErrors = (removeFieldErrors?: boolean): boolean => {
        let allCleared = true;

        for (const data of Object.values(this.data.additionalFieldData)) {
            // skip ValidationErrorType.Field
            // those errors has to be removed manually, one by one
            if (data.error?.errorType !== ValidationErrorType.Field || removeFieldErrors) {
                delete data.error;
            } else {
                allCleared = false;
            }
        }

        return allCleared;
    };

    setTemporalError = (bc: BindingContext, error: IValidationError): void => {
        const data = this.getTemporalData(bc) ?? {};

        data.additionalFieldData = {
            ...data.additionalFieldData,
            error
        };

        this.addActiveField(bc);
    };

    getTemporalError = (bc: BindingContext): IValidationError => {
        return this.getTemporalData(bc)?.additionalFieldData?.error;
    };

    clearTemporalError = (bc: BindingContext): void => {
        const data = this.getTemporalData(bc);

        data.additionalFieldData = {
            ...data.additionalFieldData,
            error: null
        };

        this.addActiveField(bc);
    };

    confirmFields = (bcs: BindingContext[] = [], fnChange: (args: ISmartFieldChange) => void = this.handleChange.bind(this)): Promise<boolean> => {
        return Promise.all(bcs.map(bc => this.handleConfirm({
            bindingContext: bc
        }, fnChange)))
            .then(confirmations => !confirmations.includes(false));
    };

    handleConfirm = async (args: ISmartFieldTempDataActionArgs, fnChange: (args: ISmartFieldChange) => void = this.handleChange.bind(this)): Promise<boolean> => {

        await this.validateField(args.bindingContext, { isConfirm: true });

        if (this.getTemporalError(args.bindingContext) || this.getError(args.bindingContext)) {
            // In case the temporal data are not valid, we don't confirm the data, keep it as temporal
            // so user can return to previous valid state - e.g. textarea with error renders always
            // in editable state, @see DEV-6022, DEV-6021
            return false;
        }

        const tempData = this.getTemporalData(args.bindingContext);

        if (tempData) {
            // call fnChange with original event, which comes to handleTemporalChange, however...
            // ...sometimes originalEvent might not be set, but we need to copy the data to model anyway, e.g.
            // when field with default value was not touched by the user. Just copy the simple value
            fnChange(tempData.originalEvent ? tempData.originalEvent : {
                bindingContext: args.bindingContext,
                value: tempData.value,
                additionalData: tempData.additionalData
            });

            this.clearError(args.bindingContext);
        }

        await this.handleCancel(args);
        return true;
    };

    cancelFields = (bcs: BindingContext[] = []): void => {
        for (const bc of bcs) {
            this.handleCancel({
                bindingContext: bc
            });
        }
    };

    handleCancel = async (args: ISmartFieldTempDataActionArgs): Promise<void> => {
        if (this.data.temporalData) {
            delete this.data.temporalData[args.bindingContext.getFullPath()];
            this.clearError(args.bindingContext);
        }

        this.addActiveField(args.bindingContext);
    };

    handleChange(e: ISmartFieldChange): void {
        this.addActiveField(e.bindingContext);
        this.addActiveFieldsFromAffectedFields(e.bindingContext);

        if (areBindingContextsDifferent(e.bindingContext.getRootParent(), this.data.bindingContext.getRootParent())) {
            // throw away change events with wrong path - e.g. form has old binding context, after selecting another
            // document with opened form, as we don't use busy indicator
            return;
        }

        const updatedData = updateDataOnChange({
            storage: this.getThis(),
            bindingContext: e.bindingContext,
            data: this.data.entity,
            newValue: e.value,
            currentValue: e.currentValue
        });

        this.store({
            entity: updatedData
        });
    }

    blur(args: ISmartFieldBlur): void {
        this.addActiveFieldsFromAffectedFields(args.bindingContext);
    }

    createValidationSchema = (columnNames: string[], useCollections = true): void => {
        this._validationSchema = Validator.createValidationSchema({
            columnNames,
            context: this.context,
            storage: this.getThis(),
            useCollections
        });
    };

    _validationProps = (fieldBc: BindingContext, data: IModelData = this.data): {
        path: string;
        entity: IEntity;
        isTemporal: boolean
    } => {
        let path = "";

        // if (fieldBc.isCollection()) {
            // don't validate fields that points to collection (e.g. labels on document)
            // those should be multi selects, the value is provided by us and it is just id
        // todo: possible remove this at all. If there is some issue with validation of some collections,
        //  solve it in different way or by some flag in fieldInfo. If uncommented,
        //  validation of "allowedCompanies" in ItemTemplatesFormView.tsx won't work
        // return null;
        // }

        // this may be confusing -
        // for select, we test values in keyProperty name binding context
        // but we store the error in binding context of navigation property (if there is any)
        // note: not for collections as collectionName.Id is not valid path...
        const validationBc = fieldBc.isCollection() ? fieldBc : fieldBc.getNavigationBindingContext();

        if (validationBc.isAnyPartCollection()) {
            path = getIndexedPathFromBindingContext(validationBc, data.entity, data.bindingContext);
        } else {
            path = getUniqueContextsSuffixAsString(validationBc, data.bindingContext, ".");
        }

        const temporalData = this.getTemporalData(fieldBc);
        let { entity } = data;
        if (temporalData) {
            // in case temporal data are set, we validate them instead entity data
            entity = cloneDeep(entity);
            setBoundValue({
                data: entity,
                bindingContext: validationBc,
                dataBindingContext: data.bindingContext,
                newValue: temporalData.value,
                preventCloning: true
            });
        }

        return { path, entity, isTemporal: !!temporalData };
    };

    _processError = (err: Partial<IValidationError> & {
        type: ValidationErrorType
    }, fieldBc: BindingContext, isTemporal: boolean): IValidationError => {
        const errorData: IValidationError = err && {
            params: err.params,
            errorType: err.type,
            message: err.message
        };

        if (isTemporal) {
            // if validation is triggered on empty temporal field, actually the original field is validated.
            // when temporal field is changed, temporal data are validated, but there might be still the original error
            // -> clear it.
            this.clearError(fieldBc);
            this.setTemporalError(fieldBc, errorData);
        } else {
            this.setError(fieldBc, errorData);
        }

        return errorData;
    };

    getFieldValidationErrorSync(fieldBc: BindingContext, context: TRecordAny = {}, data: IModelData = this.data): {
        err: any;
        isTemporal: boolean
    } {
        const { path, entity, isTemporal } = this._validationProps(fieldBc, data) || {};
        let err = null;

        if (!path) {
            return null;
        }

        try {
            if (Validator.schemaHasPath(this._validationSchema, path)) {
                this._validationSchema.validateSyncAt(path, entity, { context });
            }
        } catch (e) {
            err = e;
        }

        return err ? { err, isTemporal } : null;
    }

    validateFieldSync = (fieldBc: BindingContext, context: TRecordAny = {}, data: IModelData = this.data): IValidationError => {
        const { err, isTemporal } = this.getFieldValidationErrorSync(fieldBc, context, data) ?? {};

        return this._processError(err, fieldBc, isTemporal);
    };

    validateField = async (fieldBc: BindingContext, context: TRecordAny = {}, data: IModelData = this.data): Promise<IValidationError> => {
        const { path, entity, isTemporal } = this._validationProps(fieldBc, data) || {};
        let err = null;

        if (!path) {
            return null;
        }

        try {
            if (Validator.schemaHasPath(this._validationSchema, path)) {
                await this._validationSchema.validateAt(path, entity, { context });
            }
        } catch (e) {
            err = e;
        }

        return this._processError(err, fieldBc, isTemporal);
    };

    validateFields = (fields: BindingContext[]): Promise<IValidationError[]> => {
        return Promise.all(fields.map(field => this.validateField(field)));
    };

    /** Sets values inside the data.entity object, adds bindingContext into active fields */
    setValue = (bindingContext: BindingContext, value: any, refreshBindingContext?: BindingContext, skipTempData?: boolean): void => {
        const info = this.getInfo(bindingContext);

        if (info?.isConfirmable && !skipTempData) {
            this.addActiveField(bindingContext);
            this.setTemporalData(bindingContext, {
                value: value
            });
        } else {
            const shouldNavigateBindingContext = isNavigationForUpdate(bindingContext, value);
            this.store({
                entity: this.setBoundValue({
                    bindingContext: shouldNavigateBindingContext ? bindingContext.navigate(bindingContext.getKeyPropertyName()) : bindingContext,
                    data: this.data.entity,
                    newValue: value,
                    preventCloning: true
                }, refreshBindingContext || bindingContext)
            });
            if (bindingContext.isNavigation() && bindingContext.isEnum()) {
                this.correctEnumValue(bindingContext, value);
            }
        }
    };

    // if target is enum, set also related value, e.g. target is "Currency" -> set also "CurrencyCode" property
    correctEnumValue = (bindingContext: BindingContext, value: TValue, data?: IEntity): void => {
        if (bindingContext.isEnum() && bindingContext.isNavigation() && !bindingContext.isCollection()) {
            const keyPropName = bindingContext.getKeyPropertyName();
            const path = bindingContext.getPath();
            const enumCodeBc = bindingContext.getParent().navigate(`${path}${keyPropName}`);
            let newValue = (value as IEntity)?.[keyPropName] ?? value;
            if (isObjectEmpty(value)) {
                newValue = null;
            }
            if (data) {
                setBoundValue({
                    bindingContext: enumCodeBc,
                    data, newValue,
                    dataBindingContext: this.data.bindingContext,
                    preventCloning: true
                });
            } else {
                this.clearAndSetValue(enumCodeBc, newValue, true);
            }
        }
    };

    /** Same as 'setValue' methods, but takes bindingContext from the data object */
    setValueByPath = (path: string, value: any, skipTempData?: boolean): void => {
        this.setValue(this.data.bindingContext.navigate(path), value, undefined, skipTempData);
    };

    /** Sets value for the given context.
     * AUTOMATICALLY clears both error and currentValue */
    clearAndSetValue = (bindingContext: BindingContext, newValue: any, skipTempData?: boolean) => {
        this.clearError(bindingContext);
        this.setValue(bindingContext, newValue, null, skipTempData);
    };

    clearAndSetValueByPath = (path: string, newValue: any, skipTempData?: boolean): void => {
        this.clearAndSetValue(this.data.bindingContext.navigate(path), newValue, skipTempData);
    };

    clearValue = (bindingContext: BindingContext, keepAdditionalData = false, skipTempData?: boolean): void => {
        // set null instead of undefined,
        // - null means that the empty value will be saved to BE
        this.clearAndSetValue(bindingContext, null, skipTempData);
        if (!keepAdditionalData) {
            this.clearAdditionalFieldData(bindingContext);
        }
    };

    clearValueByPath = (path: string, keepAdditionalData = false, skipTempData?: boolean): void => {
        this.clearValue(this.data.bindingContext.navigate(path), keepAdditionalData, skipTempData);
    };

    getValue = (bindingContext: BindingContext, options?: IGetValueOptions) => {
        const tempData = this.getTemporalData(bindingContext);
        if (tempData && !options?.skipTemporaryValue) {
            return tempData.value;
        }

        // todo: for select there may be usefull to get directly id
        const bc = options?.useDirectValue !== false ? bindingContext : bindingContext.getNavigationBindingContext();

        return getBoundValue({
            bindingContext: bc,
            data: options?.dataStore ?? this.data.entity,
            dataBindingContext: this.data.bindingContext
        });
    };

    getValueByPath = (path: string, options?: IGetValueOptions) => {
        return this.getValue(this.data.bindingContext.navigate(path), options);
    };


    isValidPath = (path: string): boolean => {
        return this.data.bindingContext.isValidNavigation(path);
    };

    getDisplayValue = (info: IFieldInfo): string => {
        const entity = this.data.entity;
        let value;

        if (isSelectBasedComponent(info.type)) {
            value = getSelectDisplayValue({
                storage: this.getThis(),
                info,
                fieldBindingContext: info.bindingContext,
                processMultiValue: true
            });
        }
        // if value is not retrieved from getSelectDisplayValue, fallback to getValue method with formatting
        // (e.g. BP Name with SingleBusinessPartnerSelect component)
        return value ?? formatValue(this.getValue(info.bindingContext, { useDirectValue: false }), info, {
            entity,
            readonly: true,
            storage: this.getThis()
        });
    };

    setPreviewValue = (bindingContext: BindingContext, previewValue: Date): void => {
        const info = this.getInfo(bindingContext);

        this.addActiveField(bindingContext);
        info.fieldSettings.previewValue = previewValue;
    };

    setPreviewValueByPath = (path: string, previewValue: Date): void => {
        this.setPreviewValue(this.data.bindingContext.navigate(path), previewValue);
    };

    getFieldValues(bc: BindingContext, info?: IFieldInfo): IStorageSmartFieldValues {
        const idBindingContext = bc.isNavigation() && !bc.isCollection() ?
            bc.navigate(bc.getKeyPropertyName()) : bc;

        info = info || this.getInfo(bc);

        const isConfirmable = info?.isConfirmable;
        // first try temporal Data
        const tempData = isConfirmable && this.getTemporalData(bc);
        let value;
        if (tempData) {
            value = tempData.value;
        } else {
            value = getBoundValue({
                bindingContext: idBindingContext,
                data: this.data.entity,
                dataBindingContext: this.data.bindingContext
            }) as TValue;
        }

        const additionalFieldData = tempData?.additionalFieldData ?? this.data.additionalFieldData[bc.getFullPath()];
        const args = {
            info,
            bindingContext: bc,
            storage: this.getThis(),
            context: this.context
        };

        return {
            additionalFieldData,
            value,
            isVisible: isVisible(args),
            isRequired: isRequired(args)
        };
    }


    getTemporalData = (bc: BindingContext): ITemporalData => {
        return this.data.temporalData?.[bc.getFullPath()];
    };

    setTemporalData = (bc: BindingContext, data: ITemporalData): void => {
        if (!this.data.temporalData) {
            this.data.temporalData = {};
        }
        this.data.temporalData[bc.getFullPath()] = data;
    };

    async loadInfo(column: IMergedFieldDef, bindingContext: BindingContext): Promise<IFieldInfo> {
        // our goal is to make def immutable, so it mean immutable info too?
        // so far we store items& additionalitems, which we try to move elsewhere in the future
        //  => deepclone of fieldSettings to minimum load
        if (column?.fieldDef?.fieldSettings) {
            column.fieldDef.fieldSettings = cloneDeep(column?.fieldDef?.fieldSettings);
        }

        return await getFieldInfo({
            bindingContext: bindingContext,
            context: this.context,
            fieldDef: {
                ...column.fieldDef
            }
        });
    }

    _addRef = (collection: Record<string, TRefComponent[]>, ref: TRefComponent, bc?: BindingContext): void => {
        if (ref) {
            const path = this.getRefPath(bc || ref.props.bindingContext);

            if (!collection[path]) {
                collection[path] = [ref];
            } else {
                const has = collection[path].find((item) => item === ref);
                if (!has) {
                    collection[path].push(ref);
                }
            }
        }
    };

    /**
     * Removes ref from collection. If BindingContext is not defined, removes the ref from all paths.
     * @param collection
     * @param ref
     * @param bc
     */
    _removeRef = (collection: Record<string, TRefComponent[]>, ref: TRefComponent, bc?: BindingContext): void => {
        function _remove(refArr: TRefComponent[], ref: TRefComponent) {
            const idx = refArr?.indexOf(ref);
            if (isDefined(idx) && idx !== -1) {
                refArr.splice(idx, 1);
            }
        }

        if (ref) {
            if (bc) {
                const path = this.getRefPath(bc);
                _remove(collection[path], ref);
            } else {
                Object.keys(collection).forEach(key => {
                    _remove(collection[key], ref);
                });
            }
        }
    };

    removeRef = (ref: TRefComponent, bc?: BindingContext): void => {
        this._removeRef(this.refs, ref, bc);
    };

    addRef = (ref: TRefComponent, bc?: BindingContext): void => {
        this._addRef(this.refs, ref, bc);
    };

    addCustomRef = (ref: TRefComponent, bc?: BindingContext): void => {
        if (!ref) {
            return;
        }

        const path = this.getRefPath(bc || ref.props.bindingContext);
        // for complex ref we support only one ref per BC, which should be sufficient and makes support of different instances easier
        // in case of trouble we need to support better mechanism of clearing complex ref when invalidating or changing form (of the same entity set)
        this.customRefs[path] = [ref];
    };

    clearActiveFieldsOnRefresh = (): void => {
        const activeFieldsToRevalidate: Record<string, IActiveField> = {};
        forEachKey(this.activeFields, (key) => {
            if (this.activeFields[key].revalidate) {
                activeFieldsToRevalidate[key] = this.activeFields[key];
            }
        });

        this.activeFields = {
            ...activeFieldsToRevalidate
        };
    };

    addActiveFieldsFromAffectedFields = (bc: BindingContext): void => {
        if (bc) {
            const info = this.getInfo(bc);
            if (info?.affectedFields) {
                for (const field of info.affectedFields) {
                    const fieldBc = field.navigateFromParent ? bc.getParent().navigate(field.id) : this.data.bindingContext.navigate(field.id);
                    // TODO: this is primary to refresh all "Items" so navigation from parent doesn't make sense but may be useful in the future ?
                    if (!field.navigateFromParent) {
                        const collData = fieldBc.splitByCollectionPath();
                        if (collData?.path) {
                            // TODO: when getFull path is fixed for collection we can do more complex check
                            // const path = collData.collectionBindingContext.removeKey().getFullPath(true);
                            // const rootPath = this.data.bindingContext.removeKey().getFullPath(true);
                            // const path = collData.collectionBindingContext.getFullPath(true);
                            // const rootPath = this.data.bindingContext.getFullPath(true);
                            // check that this is not the case of collection of "entity set" like ChartOfAccount -> Accounts
                            // if (path.startsWith(rootPath) && path !== rootPath) {
                            const path = collData.collectionBindingContext.getPath(true);
                            const rootPath = this.data.bindingContext.getPath(true);
                            // check that this is not the case of collection of "entity set" like ChartOfAccount -> Accounts
                            if (path !== rootPath) {
                                const collName = collData.collectionBindingContext.getPath();
                                const entity = this.getValue(collData.collectionBindingContext);
                                for (const element of this.data.bindingContext.iterateNavigation(collName, entity)) {
                                    const subFieldBc = element.bindingContext.navigate(collData.path);
                                    this.addActiveField(subFieldBc, {
                                        reload: field.reload,
                                        revalidate: field.revalidate
                                    });
                                }
                                continue;
                            }
                        }
                    }

                    this.addActiveField(fieldBc, {
                        reload: field.reload,
                        revalidate: field.revalidate
                    });
                }
            }
        }
    };

    addActiveField = (bc: BindingContext, settings: IActiveField = {}): void => {
        const path = this.getRefPath(bc);
        this.activeFields[path] = settings;
    };

    setBoundValue = (args: ISetBoundValue, bc: BindingContext = args.bindingContext) => {
        this.addActiveField(bc);
        args.dataBindingContext = args.dataBindingContext || this.data.bindingContext;
        return setBoundValue(args);
    };

    getRefPath = (bc: BindingContext): string => {
        return bc.getNavigationPath(false, this.data.bindingContext);
    };

    forceRefreshOnUpdate = (): void => {
        this._fullRefreshWhenUpdate = true;
    };

    _shouldRefreshField = (path: string): boolean => {
        return true;
    };

    updateUuid = (): void => {
        this.storeSingleValue("uuid", uuidv4());
    };

    refreshField(field: string, settings?: IActiveField, triggerAdditionalTasks?: boolean): void {
        this.refs[field]?.forEach((ref) => {
            // clearing items data when active field is changed
            if (settings?.reload && triggerAdditionalTasks) {
                const bc = this.data.bindingContext.navigate(field);
                const info = this.getInfo(bc);
                info && (info.fieldSettings.items = null);
                this.clearAndSetValue(bc, undefined);
                (ref as any).uuid = uuidv4();
            } else if (settings?.revalidate && triggerAdditionalTasks) {
                const bc = this.data.bindingContext.navigate(field);

                this.validateField(bc).then(() => {
                    if (this._shouldRefreshField(field)) {
                        ref.forceUpdate();
                    }
                });
            }

            if (this._shouldRefreshField(field)) {
                ref.forceUpdate();
            }

            if (!isObjectEmpty(this.activeFields)) {
                delete this.activeFields[field];
            }
        });

        // either not select triggerAdditionalTasks is not defined or SELECT field change (not just typing)
        if (isNotDefined(triggerAdditionalTasks) || triggerAdditionalTasks) {
            this.customRefs[field]?.forEach((ref: any) => ref.forceUpdate());
        }
    }

    refreshFieldsSync(triggerAdditionalTasks?: boolean): void {
        if (this._fullRefreshWhenUpdate) {
            this.refresh();
            this._fullRefreshWhenUpdate = false;
            return;
        }

        const activeFields = { ...this.activeFields };

        for (const [field, settings] of Object.entries(activeFields)) {
            this.refreshField(field, settings, triggerAdditionalTasks);
        }
    }

    refreshFieldsDebounced = debounce((triggerAdditionalTasks?: boolean): void => {
        this.refreshFieldsSync(triggerAdditionalTasks);
    }, 0, { trailing: true, leading: true });

    refreshFields(triggerAdditionalTasks?: boolean, debounce?: boolean): void {
        if (!debounce) {
            this.refreshFieldsSync(triggerAdditionalTasks);
        } else {
            this.refreshFieldsDebounced(triggerAdditionalTasks);
        }
    }

    getInfo(bc: BindingContext): IFieldInfo {
        if (bc.isinSecondLevelCollectionWithKey()) {
            const path = bc.getFullPath(false);
            const info = this.data.fieldsInfo[path];
            if (!info) {
                const parentInfo = this.data.fieldsInfo[bc.getFullPath(true)];
                if (parentInfo) {
                    const { bindingContext, ...toClone } = parentInfo;
                    const newInfo: IFieldInfo = {
                        ...cloneDeep(toClone),
                        bindingContext: bc
                    };

                    this.data.fieldsInfo[path] = newInfo;

                    return newInfo;
                }
            }

            return info;
        }
        // we use paths without keys by default, but factory filters has path with keys as there are more
        // filters generated from one definition -> use fallback for them (e.g. LabelHierarchies)
        return this.data.fieldsInfo[bc.getFullPath(true)] ?? this.data.fieldsInfo[bc.getFullPath(false)];
    }

    clearInfo = (bc: BindingContext): void => {
        const fullPath = bc.getFullPath();

        if (!bc.getKey()) {
            delete this.data.fieldsInfo[fullPath];
        } else { // remove errors on all properties of given entity
            this.clearCollectionInfoByPath(fullPath);
        }
    };

    clearCollectionInfoByPath = (path: string): void => {
        for (const fullPath of Object.keys(this.data.fieldsInfo)) {
            if (fullPath.startsWith(path)) {
                delete this.data.fieldsInfo[fullPath];
            }
        }
    };
}