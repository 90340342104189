import { getSortedFYs } from "@pages/fiscalYear/FiscalYear.utils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { getIcon } from "../../components/icon";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { ElectronicSubmissionTypeCode, VatStatementFrequencyCode } from "../../odata/GeneratedEnums";
import TestIds from "../../testIds";
import DateType, { DateFormat, getUtcDateBy, getUtcDayjs } from "../../types/Date";
import { capitalize } from "../../utils/string";
import {
    ElCol,
    ElectronicSubmissionCellStyled,
    GridStyled,
    HeaderCol,
    HeaderTile,
    IconWrapper,
    RowHeaderCell,
    TileTitle
} from "./ElectronicSubmission.styles";
import {
    ElectronicSubmissionNamespaces,
    ISubmissionOverviewRow,
    SubmissionStatus,
    SubmissionTypeColumnIndex
} from "./ElectronicSubmission.utils";
import ElectronicSubmissionTableCell from "./ElectronicSubmissionTableCell";
import YearSwitcher from "./YearSwitcher";

interface IProps extends WithPermissionContext, WithTranslation {
    rows: ISubmissionOverviewRow[];
    year: number;
    onSelectYear?: (year: number) => void;
    onSelectDetail?: (type: ElectronicSubmissionTypeCode, month: number) => void;
}

class ElectronicSubmissionOverviewGrid extends React.PureComponent<IProps> {
    static contextType = AppContext;

    handleChangeYear = (year: number) => {
        this.props.onSelectYear?.(year);
    };

    handleSelect = (type: ElectronicSubmissionTypeCode, date: Date) => {
        this.props.onSelectDetail(type, date.getMonth());
    };

    renderVatStatementCell(date: Date, index: number, type: ElectronicSubmissionTypeCode) {
        const row = this.props.rows[index];
        // value for the grid that starts from 1 + 1 for the header (icons)
        const rowIndex = index + 2;
        const submissionInfo = row?.[type];

        if (!submissionInfo || submissionInfo.status === SubmissionStatus.NonExisting) {
            return null;
        }

        const rowSpan = submissionInfo.frequency === VatStatementFrequencyCode.Monthly ? 1 : 3;

        if (submissionInfo.status === SubmissionStatus.Empty) {
            return (
                <ElectronicSubmissionCellStyled $rowSpan={rowSpan}
                                                $rowIndex={rowIndex}
                                                $colIndex={SubmissionTypeColumnIndex[type]}
                                                data-testid={TestIds.ElSubPeriodCell}
                                                data-id={`${rowIndex - 1}:${SubmissionTypeColumnIndex[type] - 1}`}
                />
            );
        }

        // we are displaying end of period date
        const displayedDate = getUtcDayjs(date).endOf("month").add(rowSpan - 1, "months").toDate();
        let warningMessage = "";

        if (submissionInfo.isCorrective) {
            // deadline is 25th of the next month
            // https://solitea-cz.atlassian.net/browse/DEV-30547
            const deadline = getUtcDayjs(displayedDate).startOf("month").add(1, "month").set("date", 25);
            const today = getUtcDayjs();
            const isDeadlineMissed = today.isAfter(deadline, "date");

            warningMessage = this.props.t(`ElectronicSubmission:VatSubmission.${isDeadlineMissed ? "CorrectionDeadlineMissed" : "CorrectionStillAvailable"}`);
        }


        return (
            <ElectronicSubmissionTableCell date={date}
                                           displayedDate={displayedDate}
                                           rowIndex={rowIndex}
                                           colIndex={SubmissionTypeColumnIndex[type]}
                                           rowSpan={rowSpan}
                                           type={type}
                                           onSelect={this.handleSelect}
                                           isLocked={submissionInfo.status === SubmissionStatus.Locked}
                                           isSelected={submissionInfo.isSelected}
                                           warningMessage={warningMessage}/>
        );
    }

    renderRow = (date: Date, index: number) => {
        return (
            <React.Fragment key={date.getTime().toString()}>
                <RowHeaderCell>
                    {capitalize(DateType.format(date, DateFormat.month))}
                </RowHeaderCell>
                {this.renderVatStatementCell(date, index, ElectronicSubmissionTypeCode.VatStatement)}
                {this.renderVatStatementCell(date, index, ElectronicSubmissionTypeCode.VatControlStatement)}
                {this.renderVatStatementCell(date, index, ElectronicSubmissionTypeCode.VatVIESStatement)}
            </React.Fragment>
        );
    };

    renderRows = (year: number) => {
        const rows = [];

        for (let i = 0; i < 12; i++) {
            const date = getUtcDateBy(year, i, 1);

            rows.push(this.renderRow(date, i));
        }

        return rows;
    };

    renderHeaderTile = (iconName: string, title: string) => {
        const Icon = getIcon(iconName);

        return (
            <HeaderTile>
                <IconWrapper>
                    <Icon/>
                </IconWrapper>
                <TileTitle>
                    {title}
                </TileTitle>
            </HeaderTile>
        );
    };

    render() {
        if (!this.props.rows) {
            return null;
        }

        const context = this.context as IAppContext;
        const fiscalYears = getSortedFYs(context);
        const minYear = fiscalYears[0].DateStart.getFullYear();
        const maxYear = fiscalYears[fiscalYears.length - 1].DateStart.getFullYear();

        return (
            <GridStyled>
                <ElCol>
                    <YearSwitcher year={this.props.year} onYearMove={this.handleChangeYear}
                                  minYear={minYear} maxYear={maxYear}/>
                </ElCol>
                <HeaderCol>
                    {this.renderHeaderTile("VatStatement", this.props.t("ElectronicSubmission:VatSubmission.VatSt"))}
                </HeaderCol>
                <HeaderCol>
                    {this.renderHeaderTile("VatControlStatement", this.props.t("ElectronicSubmission:VatSubmission.VatControlSt"))}
                </HeaderCol>
                <HeaderCol>
                    {this.renderHeaderTile("VatViesStatement", this.props.t("ElectronicSubmission:VatSubmission.VatViesSt"))}
                </HeaderCol>
                {this.renderRows(this.props.year)}
            </GridStyled>
        );
    }
}

export default withTranslation(ElectronicSubmissionNamespaces)(withPermissionContext(ElectronicSubmissionOverviewGrid));