import { FeatureCode } from "@odata/GeneratedEnums";
import { DashboardManager } from "@pages/home/DashboardManager";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDefaultPostParams } from "@utils/customFetch";
import i18next from "i18next";

import { GET_POST_LOGIN_ACTIONS_URL, LOGIN_PAGE_SUBDOMAIN, LOGIN_URL, SESSION_URL } from "../../constants";
import { SessionType } from "../../contexts/authContext/Auth.utils";
import { getSystemConfig } from "../../global.utils";
import LocalSettings from "../../utils/LocalSettings";
import { AnimationPlayStatus, AnimationType, getBikerAnimationMaxStep, getError, RequestStatus } from "../Login.utils";
import { setAnimation, setPostLoginActions } from "./loginSlice";
import { AppThunk, TRootLoginState } from "./store";

export interface ISessionData {
    Type: SessionType;
    IsEmailVerified: boolean;
    IsVerifiedDevice: boolean;
    DeviceGuid: string;
    LoginEmail: string;
    UserId?: number;
    TenantId?: number;
    CompanyId?: number;
    EnabledFeatures?: FeatureCode[];
}

interface ISessionState {
    sessionData: ISessionData | null;
    status: RequestStatus;
    error: string | null;
    // indication if session has been loaded for the first time
    sessionLoaded: boolean;
}

const initialState: ISessionState = {
    /* use undefined only before session is load */
    sessionData: undefined,
    status: null,
    error: null,
    sessionLoaded: false
};

const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        loadSessionStart(state) {
            state.status = RequestStatus.Pending;
            state.error = null;
        },
        setSessionData(state, action: PayloadAction<ISessionData>) {
            state.sessionData = action.payload;
            state.status = RequestStatus.Idle;
            state.error = null;
            state.sessionLoaded = true;
        },
        loadSessionFailure(state, action: PayloadAction<string>) {
            state.status = RequestStatus.Error;
            state.error = action.payload;
            state.sessionLoaded = true;
        },
        clearError(state) {
            state.error = null;
        },
        setDeviceGUID(state, action: PayloadAction<string>) {
            state.sessionData.DeviceGuid = action.payload;
        },
        setDeviceIsVerified(state, action: PayloadAction<boolean>) {
            state.sessionData.IsVerifiedDevice = action.payload;
        },
        clearSessionData(state) {
            state.sessionData = null;
            state.status = RequestStatus.Idle;
            state.error = null;
        }
    }
});

export const {
    loadSessionStart,
    setSessionData,
    clearError,
    loadSessionFailure,
    setDeviceGUID,
    setDeviceIsVerified,
    clearSessionData
} = sessionSlice.actions;

export default sessionSlice.reducer;

export const checkSubdomainAndLoadSession = (): AppThunk => async dispatch => {
    const { host, protocol, pathname, search } = window.location;
    // if the subdomain starts with "app." we are on the correct subdomain
    if (!host.startsWith(`${LOGIN_PAGE_SUBDOMAIN}.`)) {
        const config = await getSystemConfig();
        if (config?.DisableSubdomains === false && !!config?.Domain) {
            // we need to check if user is on correct subdomain (app.*),
            // so session cookies are correctly set if subdomains are enabled
            window.location.assign(`${protocol}//${LOGIN_PAGE_SUBDOMAIN}.${config.Domain}${pathname}${search}`);
            // do not continue with loading session, as we are redirecting
            return;
        }
    }

    dispatch(loadSession());
};

// Thunk action to load session data from the API
export const loadSession = (): AppThunk => async dispatch => {
    try {
        dispatch(loadSessionStart());

        const response = await fetch(SESSION_URL);
        if (response.ok) {
            const sessionData: ISessionData = await response.json();
            dispatch(setSessionData(sessionData));
        } else {
            dispatch(setSessionData(null));
        }

    } catch (error) {
        dispatch(loadSessionFailure(error.message));
    }
};

export const loadPostLoginVerifications = (): AppThunk => async (dispatch) => {
    try {
        const response = await fetch(GET_POST_LOGIN_ACTIONS_URL);
        const postLoginVerifications = await response.json();

        dispatch(setPostLoginActions(postLoginVerifications));
    } catch (e) {
        // fail silently, doesn't matter all that much if we skip post login actions
    }
};

export const login = (email: string, password: string): AppThunk => async dispatch => {
    try {
        dispatch(loadSessionStart());

        const response = await fetch(LOGIN_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                Email: email,
                Password: password
            })
        });

        // todo use context if login and its related routes
        // will get moved to App.tsx, so that the ContextProvider is available
        // otherwise, keep using LocalSettings instead and remove this comment
        // this.context.setAppMode(AppMode.OrganizationSettings);
        LocalSettings.set("App", { isFirstRenderAfterLogin: true });

        if (response.ok) {
            // start the animation BEFORE setting session data, so it prevent redirect...
            const step = getBikerAnimationMaxStep();
            dispatch(setAnimation({ type: AnimationType.BikerWithSign, step, status: AnimationPlayStatus.Playing }));

            // clear DashboardManager cache
            DashboardManager.clear();

            const body = await response.json();

            dispatch(setSessionData({ ...body }));
        } else {
            dispatch(loadSessionFailure(i18next.t("Login:Login.WrongCredentials")));
        }
    } catch (e) {
        dispatch(loadSessionFailure(e.message));
    }
};

// Selector to get the session data from the state
export const selectSessionData = (state: TRootLoginState): ISessionData => state.session.sessionData;
export const selectSessionRequestStatus = (state: TRootLoginState): RequestStatus => state.session.status;
export const selectSessionError = (state: TRootLoginState): { message: string } => getError(state.session.error);
export const selectIsSessionLoaded = (state: TRootLoginState): boolean => state.session.sessionData !== undefined;
export const selectFirstSessionLoaded = (state: TRootLoginState): boolean => state.session.sessionLoaded;

