import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import React from "react";

import { Button } from "../../components/button";
import Dialog from "../../components/dialog/Dialog";
import { WithPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { FormStorage } from "../../views/formView/FormStorage";
import { ICompanyFormCustomData } from "./Company.utils";
import CompanyAvatarSelector from "./CompanyAvatarSelector";
import CompanyFormView, { CompanyFormViewClean } from "./CompanyFormView";

interface IProps extends Partial<WithPermissionContext> {
    id?: number;
    isReadOnly?: boolean;
    storage: FormStorage<ICompanyEntity, ICompanyFormCustomData>;
    onClose: () => void;
    onSave?: () => void;
    passRef?: React.Ref<CompanyFormViewClean>;
}

export class CompanyDialog extends React.Component<IProps> {
    _dialogBodyRef = React.createRef<HTMLDivElement>();

    getTitle(isAvatar: boolean): string {
        const { storage, id } = this.props;
        if (isAvatar) {
            return storage.t("Companies:Form.AvatarSelectionTitle");
        }

        return storage?.t(!id ? "Companies:Dashboard.NewClient" : "Companies:Dashboard.EditClient");
    }

    handleBackToDefault = (): void => {
        this.props.storage.setCustomData({ isAvatarSelectionOpen: false });
        this.props.storage.refresh();
    };

    render() {
        const { storage } = this.props;
        const { isAvatarSelectionOpen } = storage.getCustomData();

        return (
            <Dialog title={this.getTitle(isAvatarSelectionOpen)}
                    onClose={this.props.onClose}
                    onConfirm={this.props.onSave}
                    bodyRef={this._dialogBodyRef}
                    // make smaller if we solve issue with using SimpleBar inside Dialog
                    // otherwise this has to be bigger to make space for the browser scrollbar
                    width="676px" // if this should change in the future see CompanyAvatarSelector render method columnsCount={6} in FocusManager
                    height="100%"
                    hasBackIcon={isAvatarSelectionOpen}
                    onBack={this.handleBackToDefault}
                    footer={!isAvatarSelectionOpen &&
                            <>
                                <Button isTransparent
                                        onClick={this.props.onClose}>
                                    {this.props.storage?.t("Common:General.Cancel")}
                                </Button>
                                <Button onClick={this.props.onSave}
                                    isDisabled={this.props.isReadOnly || this.props.storage.loading}>
                                    {this.props.storage?.t("Common:General.Create")}
                            </Button>
                        </>
                    }>
                {isAvatarSelectionOpen ? (
                    <CompanyAvatarSelector storage={this.props.storage}/>
                ) : (
                        <CompanyFormView ref={this.props.passRef}
                                         storage={this.props.storage}
                                         scrollRef={this._dialogBodyRef}
                                         formProps={{
                                             renderScrollbar: false,
                                             withoutPadding: true
                                         }}
                                         inDialog/>
                )}
            </Dialog>
        );
    }
}