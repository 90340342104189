import { DateRangeSpecialValue } from "@components/inputs/date/popup/Calendar.utils";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem, ISummaryItemEditProps } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    IPrEmploymentEntity,
    PrEmployeeEntity,
    PrEmploymentEntity,
    PrEmploymentExtraSalaryEntity,
    PrEmploymentSalaryComponentEntity,
    PrEmploymentTemplateExtraSalaryEntity,
    PrEmploymentTemplateSalaryComponentEntity,
    PrEmploymentTemplateTemporalEntity,
    PrEmploymentTemporalEntity,
    PrExtraSalaryEntity
} from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { getTemporalAdditionalProperties, getTemporalPropertyFieldDefinition } from "@odata/TemporalUtils";
import i18next from "i18next";
import React from "react";

import { BasicInputSizes, FieldType, GroupedField, Sort } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import BindingContext from "../../../odata/BindingContext";
import { DATE_MAX } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import TemporalFormDialog from "../../../views/formView/TemporalFormDialog";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { userNameWithAvatarFormatter, userNameWithAvatarTableFormatter } from "../../admin/users/UsersDef";
import { setDefByEntityType } from "../../getDefByEntityType";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { employeeSelectDef } from "../employee/Employee.utils";
import { getCommonEmploymentDefs, getCommonEmploymentGroupDef } from "../employmentTemplate/EmploymentTemplateDef";
import { EmploymentTranslationFiles } from "./Employment.utils";
import EmploymentFormView from "./EmploymentFormView";
import TemplateSummaryItem from "./TemplateSummaryItem";


export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrEmployment}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrEmploymentEntity.Employee,
                PrEmploymentEntity.NumberOurs,
                PrEmploymentEntity.Type
            ],
            filterDefinition: {
                [PrEmploymentEntity.Employee]: {
                    formatter: (val: TValue, args: IFormatOptions) => {
                        return userNameWithAvatarTableFormatter(val, {
                            ...args,
                            entity: (args.entity as IPrEmploymentEntity).Employee
                        });
                    },
                    additionalProperties: [
                        { id: PrEmployeeEntity.FirstName },
                        { id: PrEmployeeEntity.LastName }
                    ]
                },
                [PrEmploymentEntity.NumberOurs]: {},
                [PrEmploymentEntity.Type]: {
                    fieldSettings: {
                        displayName: "Name"
                    }
                }
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrEmploymentEntity.NumberOurs, sort: Sort.Asc }],
        columns: [
            PrEmploymentEntity.NumberOurs,
            PrEmploymentEntity.Employee,
            PrEmploymentEntity.Type,
            PrEmploymentEntity.DateStart,
            PrEmploymentEntity.DateEnd,
        ],
        columnDefinition: {
            [PrEmploymentEntity.Employee]: {
                formatter: (val: TValue, args: IFormatOptions) => {
                    return userNameWithAvatarFormatter(val, {
                        ...args,
                        entity: (args.entity as IPrEmploymentEntity).Employee
                    });
                },
                additionalProperties: [
                    { id: PrEmployeeEntity.FirstName },
                    { id: PrEmployeeEntity.LastName }
                ]
            },
            [PrEmploymentEntity.NumberOurs]: {},
            [PrEmploymentEntity.Type]: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [PrEmploymentEntity.DateStart]: {},
            [PrEmploymentEntity.DateEnd]: {},
            [PrEmploymentEntity.DateCalculatePayslipStart]: {},
        },
        title: i18next.t("Employment:Title"),
    };

    const summary: ISummaryItem[] = [
        { ...getNumberOursSummaryDef("Employment") },
        {
            id: BindingContext.localContext("TemplateSummary"),
            additionalProperties: [{ id: PrEmploymentEntity.Template }],
            renderEdit: (args: ISummaryItemEditProps) => (
                    <TemporalFormDialog storage={args.storage}
                                        fieldPaths={[[PrEmploymentEntity.Template]]}
                                        title={args.item.additionalData?.dialogTitle}
                                        onChange={args.onChange}
                                        onClose={args.onClose}
                    />
            ),
            isReadOnly: ({ storage }: IGetValueArgs) => {
                const { entity, bindingContext } = storage.data;
                return !bindingContext.isNew() || !!(entity as IPrEmploymentEntity).Template?.Id;
            },
            label: i18next.t("Employment:Summary.Template"),
            additionalData: {
                dialogTitle: i18next.t("Employment:Summary.TemplateDialogTitle")
            },
            formatter(val, { entity, storage }) {
                if (entity.Template?.Name) {
                    return (
                            <TemplateSummaryItem storage={storage as FormStorage<IPrEmploymentEntity>}/>
                    );
                }
                return i18next.t("Employment:Summary.WithoutTemplate");
            },
        }
    ];
    const form: IFormDef = {
        id: `${EntityTypeName.PrEmployment}Form`,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Employment:New"), i18next.t("Employment:FormTitle")),
        isDeletable: true,
        formControl: EmploymentFormView,
        summary,
        additionalProperties: [
            ...getTemporalAdditionalProperties(PrEmploymentTemporalEntity, PrEmploymentEntity),
            ...NumberRangeAdditionalProperties,
            {
                id: PrEmploymentEntity.ExtraSalaries,
                additionalProperties: [
                    {
                        id: PrEmploymentExtraSalaryEntity.Default,
                        additionalProperties: [
                            { id: PrExtraSalaryEntity.TypeCode },
                            { id: PrExtraSalaryEntity.BaseTypeCode }
                        ]
                    },
                    {
                        id: PrEmploymentExtraSalaryEntity.Template,
                        additionalProperties: [
                            {
                                id: PrEmploymentTemplateExtraSalaryEntity.Default,
                                additionalProperties: [
                                    { id: PrExtraSalaryEntity.TypeCode },
                                    { id: PrExtraSalaryEntity.BaseTypeCode }
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                id: PrEmploymentEntity.SalaryComponents,
                additionalProperties: [
                    { id: PrEmploymentSalaryComponentEntity.Default },
                    {
                        id: PrEmploymentSalaryComponentEntity.Template,
                        additionalProperties: [
                            { id: PrEmploymentTemplateSalaryComponentEntity.Default },
                        ]
                    }
                ]
            }
        ],
        fieldDefinition: {
            ...getNumberRangeFieldDefs("Employment"),
            [PrEmploymentEntity.Employee]: { ...employeeSelectDef },
            [PrEmploymentEntity.Type]: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.L,
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [PrEmploymentEntity.DateStart]: {
                type: FieldType.Date,
                groupedField: GroupedField.MultiStart,
            },
            [PrEmploymentEntity.DateEnd]: {
                type: FieldType.Date,
                groupedField: GroupedField.MultiEnd,
                fieldSettings: {
                    showSpecialValue: DateRangeSpecialValue.WithoutEnd
                },
                defaultValue: DATE_MAX
            },
            [PrEmploymentEntity.DateCalculatePayslipStart]: {
                type: FieldType.MonthYear
            },
            ...getTemporalPropertyFieldDefinition({
                propName: PrEmploymentTemplateTemporalEntity.GuaranteedSalaryDegree,
                definition: {
                    type: FieldType.ComboBox,
                    fieldSettings: {
                        displayName: "Name"
                    }
                }
            }),
            [PrEmploymentEntity.Template]: {
                type: FieldType.ComboBox,
                label: i18next.t("Employment:Template.TitleSingular"),
                width: BasicInputSizes.L,
                fieldSettings: {
                    displayName: "Name"
                },
            },
            ...getCommonEmploymentDefs(false)
        },
        groups: [
            {
                id: "main",
                rows: [[
                    { id: PrEmploymentEntity.Employee },
                    { id: PrEmploymentEntity.Type },
                    { id: PrEmploymentEntity.DateStart },
                    { id: PrEmploymentEntity.DateEnd },
                    { id: PrEmploymentEntity.DateCalculatePayslipStart }
                ]]
            },
            ...getCommonEmploymentGroupDef(false)
        ]
    };
    return {
        entitySet: EntitySetName.PrEmployments,
        table,
        form
    };
};
getDefinitions.translationFiles = [...EmploymentTranslationFiles];
setDefByEntityType(EntityTypeName.PrEmployment, getDefinitions);