import { IAlertProps } from "@components/alert/Alert";
import { FileToolbarItem } from "@components/fileViewers/FileViewers.utils";
import {
    AttachmentIcon,
    FieldValidationNegativeIcon,
    FieldValidationPositiveIcon,
    FieldValidationWarningIcon
} from "@components/icon";
import { Autocomplete } from "@components/inputs/select/Autocomplete";
import Switch from "@components/inputs/switch";
import { SwitchType } from "@components/inputs/switch/Switch";
import TextArea from "@components/inputs/textArea";
import { ODataError } from "@odata/Data.types";
import { DataBoxEntity, EntitySetName, IDataBoxEntity, IUserEntity } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";
import { parseResponse } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { logger } from "@utils/log";
import { isAbortException } from "@utils/oneFetch";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import React, { lazy, ReactElement, Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import BusyIndicator from "../../components/busyIndicator";
import { Button } from "../../components/button";
import { SegmentedButton } from "../../components/button/SegmentedButton";
import Clickable from "../../components/clickable";
import Dialog from "../../components/dialog/Dialog";
import PDFViewer from "../../components/fileViewers/pdfViewer/PDFViewer";
import Checkbox from "../../components/inputs/checkbox/Checkbox";
import Field from "../../components/inputs/field/Field";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import { IInputOnChangeEvent } from "../../components/inputs/input/Input";
import { DATABOX_REST_URL, EMAIL_REGEX, INPUT_DEBOUNCE_TIME } from "../../constants";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { BasicInputSizes, HTTPStatusCode, IconSize, LabelStatus, Status } from "../../enums";
import { TRecordAny } from "../../global.types";
import { KeyName } from "../../keyName";
import { IValidationError } from "../../model/Validator.types";
import TestIds from "../../testIds";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import memoizeOne from "../../utils/memoizeOne";
import { getAlertFromError } from "../../views/formView/Form.utils";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import {
    AlertStyled,
    CheckboxWrapper,
    ClickableMessage,
    ContentEditableMessage,
    DataBoxInfo,
    MaxWidthField, TextAreaSubject
} from "./Messages.styles";
import { ISelectGroup, ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";

const Linkify = lazy(() => import("linkify-react"));

enum MessageType {
    Email = "email",
    DataBox = "dataBox"
}

export enum DataBoxState {
    Empty = "empty",
    Blocked = "blocked",
    Enabled = "enabled"
}

interface IProps extends WithTranslation, WithOData, WithPermissionContext {
    title: string;
    message: ReactElement;
    addSignature?: boolean;
    subject: string;
    emailSettings: IEmailSettings;
    dataBoxSettings: IDataBoxSettings;
    fileSettings?: IFileSettings[];
    onAfterOpen?: () => void;
    onAfterSend?: () => void;
    handleClose: () => void;
    additionalRequestData?: TRecordAny;
}

export interface IEmailSettings {
    selectGroups?: ISelectGroup[];
    selectItems?: ISelectItem<string>[];
    sendMessageUrl: string;
    defaultEmail?: string;
}

export interface IDataBoxSettings {
    defaultId?: string;
    sendMessageUrl: string;
    isReport?: boolean;
}

export interface IFileSettings {
    name?: string;
    previewUrl?: string;
    fileId?: number;
    shouldHideCheckBox?: boolean;
}

interface ICzechDataboxFromAPI {
    Ico: string,
    IdOVM: string,
    NazevOsoby: string,
    ISDS: string,
    PDZ: boolean,
    TypSubjektu: string,
    AdresaSidla: {
        AdresaTextem: string,
        OkresNazev: string,
        ObecNazev: string,
        CastObceNazev: string,
        UliceNazev: string,
        PostaKod: string,
        TypCislaDomovnihoKod: string,
        CisloDomovni: string,
        CisloOrientacni: string,
        CisloOrientacniPismeno: string
    },
    IdentifikatorOvm: string,
    KategorieOvm: string
}

interface IState {
    // message content
    dataBoxId: string;
    subject: string;
    email: string;
    emailCopies: string;
    includedFiles: number[];
    message: ReactElement;
    // dialog state
    isBusy: boolean;
    messageType: string;
    emailError: IValidationError;
    emailCopiesError: IValidationError;
    dataBox: ICzechDataboxFromAPI;
    dataBoxSelectItems: ISelectItem[];
    showEmailCopyField: boolean;
    showPreview: boolean;
    selectedFile: number;
    canSendByDataBox: boolean;
    companyDataBoxId: string;
    alert: IAlertProps,
    warnAlert: IAlertProps,
    subjectError: IValidationError
}

const DATA_BOX_MESSAGE_PRICE = 15;

class SendMessageDialog extends React.Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static findDataBoxByIdUrl = `${DATABOX_REST_URL}/check/`;
    static dataBoxIdSearchUrl = `${DATABOX_REST_URL}/search/`;
    static checkDataBoxCreditUrl = `${DATABOX_REST_URL}/credit`;
    static canSendMessageUrl = `${DATABOX_REST_URL}/CanSendMessage`;

    _messageRef = React.createRef<HTMLDivElement>();

    state: IState = {
        dataBoxId: "",
        subject: "",
        message: null,
        emailCopies: "",
        email: "",
        emailError: null,
        emailCopiesError: null,
        includedFiles: [],
        dataBoxSelectItems: [],
        dataBox: null,
        isBusy: true,
        messageType: MessageType.Email,
        showEmailCopyField: false,
        showPreview: false,
        selectedFile: null,
        canSendByDataBox: false,
        companyDataBoxId: null,
        alert: null,
        warnAlert: null,
        subjectError: null
    };

    getIconState = memoizeOne((dataBox: ICzechDataboxFromAPI): DataBoxState => {
        if (dataBox) {
            return !dataBox.PDZ && !dataBox.IdOVM ? DataBoxState.Blocked : DataBoxState.Enabled;
        }
        return DataBoxState.Empty;
    });

    searchForDataBoxes = debounce(async (value: string) => {
        try {
            const response = await customFetch(`${SendMessageDialog.dataBoxIdSearchUrl}${value}`);
            if (!response.ok) {
                logger.warn("Hasn't found any data box.");
            } else {
                const searchResult = await response.json();
                const dataBoxSelectItems: ISelectItem[] = [];

                for (const dataBox of searchResult) {
                    dataBoxSelectItems.push({
                        id: dataBox.ISDS,
                        label: dataBox.ISDS,
                        tabularData: [`${dataBox.NazevOsoby ?? ""}`, `${dataBox.ISDS ?? ""}`, dataBox.Ico]
                    });
                }
                this.setState({ dataBoxSelectItems });
            }
        } catch (e) {
            if (isAbortException(e)) {
                return;
            } else {
                logger.error("error in data box search", e);
            }
        }
    }, INPUT_DEBOUNCE_TIME);

    componentDidMount = async () => {
        const canSendByDataBox = await this.agendaCanSendByDataBox();
        const includedFiles = this.props.fileSettings?.map(file => file.fileId) ?? [];

        this.setState({ canSendByDataBox, includedFiles, isBusy: false });
    };

    componentDidUpdate = async (prevProps: IProps) => {
        const defaultId = this.props.dataBoxSettings.defaultId;
        if (this.props.emailSettings?.selectItems?.[0] && prevProps.emailSettings !== this.props.emailSettings) {
            this.setState({ email: this.props.emailSettings.selectItems[0].id });
        }

        if (this.props.fileSettings !== prevProps.fileSettings) {
            const includedFiles = this.props.fileSettings?.map(file => file.fileId) ?? [];
            this.setState({ includedFiles });
        }

        if (prevProps.dataBoxSettings.defaultId !== defaultId) {
            this.setState({ dataBoxId: defaultId, dataBox: null });
            if (defaultId) {
                await this.fetchDataBox(defaultId);
            }
        }
    };

    agendaCanSendByDataBox = async () => {
        if (!this.props.permissionContext.companyPermissions.has(CompanyPermissionCode.DataBox)) {
            return false;
        }
        const result = await this.props.oData
            .getEntitySetWrapper(EntitySetName.DataBoxes)
            .query()
            .select(DataBoxEntity.DataBoxId, DataBoxEntity.CanSendMessages, DataBoxEntity.IsActive)
            .top(1)
            .fetchData<IDataBoxEntity[]>();

        this.setState({ companyDataBoxId: result.value[0]?.DataBoxId });

        return result.value[0]?.CanSendMessages && result.value[0]?.IsActive;
    };

    handleCloseDialog = () => {
        this.props.handleClose();
    };

    getMessage = () => {
        // replace default contenteditable div line breaks with new line chars, and strip all other html tags
        const div = document.createElement("div");
        div.innerHTML = this._messageRef.current.innerHTML.replace(/<br ?\/?>/g, "\n");
        return div.textContent || "";
    };

    handleContentEditableDivKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === KeyName.Enter) {
            // prevent dialog confirmation
            e.stopPropagation();
        }
    };

    getWarnAlert = () => {
        return (<AlertStyled {...this.state.warnAlert} isFullWidth/>);
    };

    getAlert = () => {
        return (<AlertStyled {...this.state.alert} isFullWidth/>);
    };

    setDefaults = async () => {
        const user = this.context.getData().userSettings as IUserEntity;
        const company = this.context.getCompany();
        const organizationName = this.context.getOrganization().Name;

        // there shall be some templates in the future
        this.setState({
            message: (<>
                {this.props.message}
                {this.props.addSignature && <>S přáním pěkného dne<br/><br/>
                    {user.Name}<br/>
                    účetní pro {company.LegalName}<br/>
                    E-mail: {user.Email}<br/>
                    {organizationName}<br/></>}</>),
            subject: this.props.subject,
            email: this.props.emailSettings.defaultEmail ?? this.state.email,
            alert: null,
            warnAlert: null
        });
    };

    onAfterOpen = async () => {
        await this.props.onAfterOpen?.();
        await this.setDefaults();
    };

    handleSendMessage = async () => {
        this.setState({ isBusy: true });
        const isEmail = MessageType.Email === this.state.messageType;
        const url = isEmail ? this.props.emailSettings.sendMessageUrl : this.props.dataBoxSettings.sendMessageUrl;
        const recipient = isEmail ? this.state.email : this.state.dataBoxId;

        const requestBody: TRecordAny = {
            To: recipient,
            Subject: this.state.subject,
            Message: this.getMessage(),
            IncludeFile: !!this.state.includedFiles?.length,
            UseFileIds: this.state.includedFiles.filter(fileId => Number.isInteger(fileId)),
            ...this.props.additionalRequestData
        };

        if (isEmail && this.state.emailCopies) {
            requestBody.Copy = this.state.emailCopies.replace(" ", "").split(",");
        }

        const response = await customFetch(url, {
            ...getDefaultPostParams(),
            body: JSON.stringify(requestBody)
        });

        this.setState({ isBusy: false });

        if (!response.ok) {
            const title = this.props.t("Common:Errors.ErrorHappened");
            let subtitleKey;
            switch (response.status) {
                case HTTPStatusCode.NotFound:
                    subtitleKey = "DataBox:Errors.NotFound";
                    break;
                case HTTPStatusCode.Unauthorized:
                    subtitleKey = "DataBox:Errors.Unauthorized";
                    break;
                case HTTPStatusCode.Conflict:
                    subtitleKey = "DataBox:Errors.Conflict";
                    break;
            }
            let alert: IAlertProps;
            if (!subtitleKey) {
                const error = await parseResponse(response);
                alert = getAlertFromError(error as ODataError);
            } else {
                alert = {
                    status: Status.Error,
                    title, subTitle: this.props.t(subtitleKey)
                };
            }
            this.setState({ alert });
        } else {
            this.props.onAfterSend?.();
            this.handleCloseDialog();
        }
    };

    fetchDataBox = async (dataBoxId: string) => {
        try {
            const response = await customFetch(`${SendMessageDialog.findDataBoxByIdUrl}${dataBoxId}`);

            if (!response.ok) {
                logger.warn("Data box not found");
                return;
            } else {
                const data = await response.json();
                const dataBox = data?.[0];
                this.setState({ dataBox });
                if (this.getIconState(dataBox) === DataBoxState.Enabled && !dataBox.IdOVM) {
                    await this.checkIfMessageCanBeSend(dataBoxId);
                }
            }
        } catch (e) {
            if (isAbortException(e)) {
                return;
            } else {
                logger.error("error in data box fetch", e);
            }
        }
    };

    checkIfMessageCanBeSend = async (dataBoxId: string) => {
        const response = await customFetch(`${SendMessageDialog.canSendMessageUrl}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({ To: dataBoxId })
        });

        if (!response.ok) {
            const creditResponse = await customFetch(`${SendMessageDialog.checkDataBoxCreditUrl}`);
            if (creditResponse.ok) {
                const credit = await creditResponse.text();
                if (parseInt(credit) < DATA_BOX_MESSAGE_PRICE) {
                    const warnAlert = {
                        status: Status.Warning,
                        title: this.props.t("DataBox:DataBoxProblem", { dataBoxId: this.state.companyDataBoxId }),
                        subTitle: this.props.t("DataBox:LowCredit", { credit })
                    };
                    this.setState({ warnAlert });
                }
            } else {
                logger.warn("couldn't fetch data box credit");
            }
        }
    };

    handleDataBoxChange = async (e: ISelectionChangeArgs) => {
        const value = e.value as string;
        this.setState({ dataBoxId: value, warnAlert: null, alert: null });
        if (value?.length === 7) {
            try {
                await this.fetchDataBox(value);
            } catch (e) {
                if (isAbortException(e)) {
                    return;
                } else {
                    logger.error("error in data box fetch", e);
                }
            }
            this.setState({ dataBoxSelectItems: [] });
        } else {
            this.setState({ dataBox: null });
        }
    };

    handleFormTypeChange = (messageType: string) => {
        this.setState({ messageType, alert: null, warnAlert: null });
        // wait for data box icon to render, so we can pass ref to tooltip
        setTimeout(() => {
            this.forceUpdate();
        });
    };

    handleSubjectChange = (e: IInputOnChangeEvent<string>) => {
        const subjectError = e.value ? null : {
            message: this.props.t("Common:Validation.Required")
        };
        this.setState({ subject: e.value, subjectError });
    };

    handleEmailChange = (e: ISelectionChangeArgs) => {
        this.setState({ email: e.value as string, emailError: null });
    };

    validateEmail = () => {
        if (!EMAIL_REGEX.test(this.state.email)) {
            this.setState({ emailError: { message: this.props.t("Messages:EmailErrorMessage") } });
        }
    };

    validateCopies = (): void => {
        const copies = this.state.emailCopies;
        if (copies && copies.split(",").some((copy: string) => !EMAIL_REGEX.test(copy.trim()))) {
            this.setState({ emailCopiesError: { message: this.props.t("Messages:EmailErrorMessage") } });
        }
    };

    handleEmailCopiesChange = (e: IInputOnChangeEvent<string>) => {
        this.setState({ emailCopies: e.value, emailCopiesError: null });
    };

    toggleEmail = () => {
        this.setState({ emailCopies: "", showEmailCopyField: !this.state.showEmailCopyField });
    };

    includeFile = (value: boolean, fileId: number) => {
        const includedFiles = [...this.state.includedFiles];
        const index = includedFiles.indexOf(fileId);
        if (!value && index > -1) {
            includedFiles.splice(index, 1);
        } else if (value) {
            includedFiles.push(fileId);
        }
        this.setState({ includedFiles });
    };

    togglePreview = (selectedFile?: number) => {
        this.setState({ showPreview: !this.state.showPreview, selectedFile });
    };

    getIconFromDataBoxState = (state: DataBoxState): ReactElement => {
        let Icon;
        let title = "Negative";

        switch (state) {
            case DataBoxState.Enabled:
                Icon = FieldValidationPositiveIcon;
                title = "Positive";
                break;
            case DataBoxState.Blocked:
                Icon = FieldValidationWarningIcon;
                title = "Warning";
                break;
            default:
                Icon = FieldValidationNegativeIcon;
        }
        return <Icon title={this.props.t(`Messages:DataBoxState.${title}`)} width={IconSize.M}/>;
    };

    renderForm = () => {
        const dataBox = this.state.dataBox;
        const dataBoxState = this.getIconState(dataBox);
        const icon = this.getIconFromDataBoxState(dataBoxState);
        const dataBoxIsReadOnly = !this.props.dataBoxSettings?.isReport;

        return (<>
            {this.state.warnAlert && this.getWarnAlert()}
            {this.state.alert && this.getAlert()}
            {this.state.messageType === MessageType.DataBox &&
                <>
                    <Field label={this.props.t("DataBox:DataBox")}
                           name="dataBox"
                           hasPadding={dataBoxIsReadOnly}
                           isRequired={true}>
                        <Autocomplete
                            value={this.state.dataBoxId}
                            width={BasicInputSizes.L}
                            items={this.state.dataBoxSelectItems}
                            onChange={this.handleDataBoxChange}
                            onItemsFetchRequested={(value: string) => this.searchForDataBoxes(value)}
                            name="dataBox"
                            inputIcon={!!this.state.dataBoxId ? icon : null}
                            isReadOnly={dataBoxIsReadOnly}
                            isIconWithoutAction
                        />
                    </Field>
                    {dataBox && (
                        <DataBoxInfo data-testid={TestIds.DataBoxInfo}>
                            <tbody>
                            <tr>
                                <td>{this.props.t("Messages:Name")}:</td>
                                <td>{dataBox.NazevOsoby}</td>
                            </tr>
                            {dataBox.Ico && // financial administration office doesn't have legalNumber
                                <tr>
                                    <td>{this.props.t("Messages:LegalNumber")}:</td>
                                    <td>{dataBox.Ico}</td>
                                </tr>}
                            <tr>
                                <td>{this.props.t("Messages:Address")}:</td>
                                <td>{dataBox.AdresaSidla?.AdresaTextem}</td>
                            </tr>
                            </tbody>
                        </DataBoxInfo>
                    )}
                </>
            }
            {this.state.messageType === MessageType.Email &&
                <>
                    <Field label="E-mail"
                           name="email"
                           isRequired={true}>
                        <Autocomplete
                            value={this.state.email}
                            width={BasicInputSizes.XL}
                            error={this.state.emailError}
                            groups={this.props.emailSettings.selectGroups ?? []}
                            items={this.props.emailSettings.selectItems ?? []}
                            onChange={this.handleEmailChange}
                            onBlur={this.validateEmail}
                        />
                    </Field>
                    <Field labelStatus={LabelStatus.Removed}
                           name="emailCopy"
                           isRequired={true}>
                        <Switch label={this.props.t("DataBox:SendDialog.SendCopy")}
                                type={SwitchType.YesNo}
                                checked={this.state.showEmailCopyField} onChange={this.toggleEmail}/>
                    </Field>
                    {this.state.showEmailCopyField &&
                        <MaxWidthField tooltip={this.props.t("Messages:CopyOnEmailTooltip")}
                                       label={this.props.t("Messages:CopyOnEmail")}
                                       isRequired
                                       name="copies">
                            <TextArea
                                value={this.state.emailCopies}
                                maxRows={1}
                                width="100%"
                                isSharpLeft={true}
                                isSharpRight={true}
                                name="copies"
                                onChange={this.handleEmailCopiesChange}
                                onBlur={this.validateCopies}
                                error={this.state.emailCopiesError}
                            />
                        </MaxWidthField>}
                </>
            }
            <MaxWidthField label={this.props.t("Messages:Subject")}
                           isRequired={true}
                           width="505px"
                           name="subject">
                <TextAreaSubject value={this.state.subject}
                          error={this.state.subjectError}
                          maxRows={1}
                          width="100%"
                          isSharpLeft={true}
                          isSharpRight={true}
                          name="subject"
                          onChange={this.handleSubjectChange}/>
            </MaxWidthField>
            <MaxWidthField label={this.props.t("Messages:Message")}
                           onBlur={() => this.forceUpdate()}
                           width="100%"
                           name="message">
                <Suspense fallback={<BusyIndicator/>}>
                    <Linkify options={{
                        attributes: {
                            contentEditable: "false",
                            target: "blank",
                            suppressContentEditableWarning: "true"
                        }
                    }}>
                        <ContentEditableMessage
                            ref={this._messageRef}
                            onKeyDown={this.handleContentEditableDivKeyDown}
                            contentEditable={true}
                            data-testid={TestIds.ContentEditableMessage}>
                            {this.state.message}
                        </ContentEditableMessage>
                    </Linkify>
                </Suspense>
            </MaxWidthField>
            {this.props.fileSettings?.map((file: IFileSettings) => {
                return file?.name &&
                    <FieldsWrapper key={"file"}>
                        <CheckboxWrapper data-testid={TestIds.CheckboxWrapper} key={file.fileId}>
                            {!file.shouldHideCheckBox &&
                                <Checkbox checked={this.state.includedFiles.includes(file.fileId)}
                                          onChange={(e) => {
                                              this.includeFile(e.value, file.fileId);
                                          }}/>

                            }
                            <ClickableMessage link={file.previewUrl} isLink>
                                <AttachmentIcon width={IconSize.S} height={IconSize.S} color={"C_TEXT_primary"}/>
                                {file.name}
                            </ClickableMessage>
                        </CheckboxWrapper>
                    </FieldsWrapper>;
            })}
        </>);
    };

    render = () => {
        if (!this.props.tReady) {
            return null;
        }

        const enableSend = this.state.subject && !this.state.warnAlert &&
            ((this.state.messageType === MessageType.DataBox && this.getIconState(this.state.dataBox) === DataBoxState.Enabled) ||
                (this.state.messageType === MessageType.Email && this.state.email && EMAIL_REGEX.test(this.state.email) &&
                    !(this.state.emailCopies && this.state.emailCopies.split(",").some((copy: string) => !EMAIL_REGEX.test(copy.trim())))));

        const selectedFile = this.props.fileSettings?.find((file: IFileSettings) => file.fileId === this.state.selectedFile);

        return (
            <>
                <Dialog aretateWidth
                        title={this.props.title}
                        onClose={this.handleCloseDialog}
                        onConfirm={this.handleSendMessage}
                        onAfterOpen={this.onAfterOpen}
                        busy={this.state.isBusy}
                        footer={
                            <ConfirmationButtons
                                confirmText={this.props.t("DataBox:SendDialog.Send")} isDisabled={!enableSend}
                                onCancel={this.handleCloseDialog} onConfirm={this.handleSendMessage}
                                useWrapper={false}/>
                        }>
                    <FieldsWrapper isColumn>
                        {this.state.canSendByDataBox && (!!this.props.dataBoxSettings.defaultId || this.props.dataBoxSettings.isReport) &&
                            <Field labelStatus={LabelStatus.Removed}
                                   name={"messageType"}>
                                <SegmentedButton
                                    selectedButtonId={this.state.messageType}
                                    onChange={this.handleFormTypeChange}
                                    def={[{
                                        id: MessageType.Email,
                                        label: this.props.t("DataBox:SendDialog.SendByEmail")
                                    }, {
                                        id: MessageType.DataBox,
                                        label: this.props.t("DataBox:SendDialog.SendToDataBox")
                                    }]}/>
                            </Field>
                        }
                        {this.renderForm()}
                    </FieldsWrapper>
                </Dialog>
                {selectedFile?.previewUrl && this.state.showPreview &&
                    <Dialog
                        onConfirm={null}
                        onClose={this.togglePreview}
                        width={"900px"}
                        height={"100%"}
                        aretateHeight
                        footer={(
                            <Button onClick={() => this.togglePreview()}>
                                {this.props.t("Common:General.Close")}
                            </Button>)}>
                        <PDFViewer src={selectedFile.previewUrl}
                                   hiddenItems={[FileToolbarItem.Dashboard]}
                                   isReadOnly/>
                    </Dialog>}
            </>
        );
    };
}

export default withPermissionContext(withOData(withTranslation(["Common", "DataBox", "Messages"])(SendMessageDialog)));
